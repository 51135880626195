const Joi = require("joi");

const AddNodeSchema = Joi.object({
  id: Joi.allow(null),
  name: Joi.string().min(2).max(50).required().messages({
    "string.empty": `Please enter a title`,
    "string.min": `Title must be 2 characters long`,
    "string.max": `Title can be max 50 characters long`,
  }),
});

export default AddNodeSchema;
