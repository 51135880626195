import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import classes from "../../assets/styles/CustomURLModal.module.css";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import showToast from "../../helpers/showToast";
import { post } from "../../server";
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import { PAY_MEMBER_PAYMENT } from "../../utils/constants/apiUrl";
import { get } from "../../server";
import { GET_USER } from "../../utils/constants/apiUrl";

import Form from "react-bootstrap/Form";

const cardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#fff",
      fontSize: "14px",
      color: "#fff",

      "::placeholder": {
        color: "#fff",
      },
    },
    invalid: {
      color: "#ff0000",
    },
  },
};

const PendingPaymentModal = ({
  pendingMembers,
  setPendingMember,
  handleCloseModal,
  userTeamId,
  show,
  selectedId,
}) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [perTeamMemberPrice, setPerTeamMemberPrice] = useState(null);
  let navigate = useNavigate();
  const checkbox = useRef();
  const [selectedOption, setSelectedOption] = useState("monthly");
  // const [subscriptions, setSubscriptions] = useState([]);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // const loadSubscriptions = async () => {
  //   // setLoading(true);
  //   let { status, message, data } = await get(
  //     PACKAGES_LISTING,
  //     Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //   );

  //   if (status) {
  //     if (data) {
  //       let ownerpackages = data.filter(
  //         // (singlePackage) => singlePackage.package_type == "owner"
  //         (singlePackage) =>
  //           singlePackage.package_type === "owner" ||
  //           singlePackage.package_type === "customer_specific_owner"
  //       );
  //       setSubscriptions(ownerpackages);
  //       console.log("ownerpackages ===> ", ownerpackages);
  //       console.log("subscriptions ===> ", subscriptions);
  //     }
  //   } else {
  //     showToast(status, message);
  //   }
  //   // setLoading(false);
  // };
  // useEffect(() => {
  //   loadSubscriptions();
  // }, []);
  const getSubscriptionDetails = async () => {
    let { status, message, data } = await get(
      GET_USER,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (data && data.package_details && data.package_details.length > 0) {
        const teamOwnerPackages = data.package_details.filter(
          (pkg) => pkg.is_team_owner === 1
        );

        // console.log(
        //   "Team owner packages:",
        //    teamOwnerPackages[0].price_per_user_after_limit
        // );
        //setPerTeamMemberPrice(teamOwnerPackages[0].price_per_user_after_limit);
        if (teamOwnerPackages.length > 0) {
          setPerTeamMemberPrice(
            teamOwnerPackages[0].price_per_user_after_limit
          );
        }
      } else {
        console.log("No package details available or empty.");
      }
    } else {
      showToast(status, message);
      navigate("/");
    }
  };

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);

    try {
      const { error: stripeError, token } = await stripe.createToken(
        cardElement
      );

      if (stripeError) {
        setIsLoading(false);
        setError(stripeError.message);
        return;
      }

      let paymentObj = {
        team_id: userTeamId,
        user_id: selectedId,
        subscription_type: "monthly",
        stripe_token: token.id,
      };

      // Send payment method ID and payment intent id to your backend serve
      let { status, message } = await post(
        `${PAY_MEMBER_PAYMENT}`,
        paymentObj,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (!status) {
        setError(message);
        setIsLoading(false);
        return;
      }

      const remainingMembers = pendingMembers.filter(
        (obj) => obj.id != selectedId
      );
      setPendingMember(remainingMembers);
      setIsLoading(false);
      setError(null);
      handleCloseModal(true);
      showToast(true, message);
      navigate("/pendingPayments");
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
      return;
    }
  };

  // Handle Update
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleCloseModal();
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>Payment</span>
          <div
            className="modalGradientCircle"
            style={{ position: "absolute", top: "25px" }}
          ></div>
          <MdClose
            onClick={() => {
              handleCloseModal();
              setError(null);
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>
        <>
          <Modal.Body>
            <div style={{ marginTop: "10px" }}>
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    border: "2px solid var(--border_color)",
                    padding: "10px",
                    borderRadius: "50px",
                  }}
                  className="cardElementWrapper"
                >
                  <CardElement id="card-element" options={cardElementOptions} />
                </div>
                {/* <div className={classes.autoCheckbox}>
                 <span>Kindly select subscription type</span>
                 <br></br>
                 <Form.Check
                   inline
                   label="Monthly"
                   name="subscription_type"
                   type="radio"
                   value="monthly"
                   checked={selectedOption === "monthly"}
                   onChange={handleRadioChange}
                 />
                 <Form.Check
                   inline
                   label="Yearly"
                   name="subscription_type"
                   type="radio"
                   value="yearly"
                   checked={selectedOption === "yearly"}
                   onChange={handleRadioChange}
                 />
               </div> */}
                <div className={classes.bottomDetail}>
                  {/* <p style={{ padding: "10px 0px" }}>
   Package type:
   <span style={{ paddingLeft: "10px" }}>Elite</span>
 </p> */}
                  <p>
                    Subscription charges:
                    <span style={{ paddingLeft: "10px" }}>
                      {/* $ 00 per/month */}${perTeamMemberPrice} per/month
                      {/* {console.log(subscriptions.price_monthly)} */}
                    </span>
                  </p>
                </div>

                <div>
                  {error && <p className="errorText">{error}</p>}
                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      disabled={!stripe || isLoading}
                      className={classes.payBtn}
                    >
                      {isLoading ? "Processing..." : "Pay"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </>
      </Modal>
    </>
  );
};

export default PendingPaymentModal;
