import React from "react";
import baseURL from "../../utils/constants/baseUrl";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";
import { Image } from "react-bootstrap";
import classes from "../../assets/styles/ManageNodes.module.css";
import { TreeNode } from "react-organizational-chart";
import { BsThreeDotsVertical } from "react-icons/bs";

const ViewRenderTree = ({
  node,
  handleQrOpen,
  handleTeamMemberViewFunctions,
  handleActiveFunctionQrOpen,
}) => {
  return (
    <TreeNode
      label={
        <div className="manageNodesDropdownWrapper">
          {node.team_member || node.hasOwnProperty("check_status") ? (
            <>
              {node.hasOwnProperty("check_status") ? (
                <div className="dropdown">
                  <button
                    className="btn customActionDropdown dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className={classes.memberNodeBox}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <div className={classes.teamMemberImage}>
                            <Image
                              src={
                                node.user_data.profile_image
                                  ? `${baseURL.PUBLIC_URL}${node.user_data.profile_image}`
                                  : dummyImage
                              }
                              alt={"Team Member Image"}
                              roundedCircle
                            />
                          </div>
                          <div>
                            <p className={classes.nodeName}>{`${
                              node.user_data.first_name || ""
                            } ${node.user_data.last_name || ""}`}</p>

                            <p className={classes.nodeDetail}>
                              {node.user_data.email}
                            </p>
                          </div>
                        </div>
                        <div className={classes.threedotsWrapper}>
                          <BsThreeDotsVertical />
                        </div>
                      </div>
                    </div>
                  </button>
                  <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => handleTeamMemberViewFunctions(node)}
                      >
                        View Functions
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className={classes.memberNodeBox}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                      <div className={classes.teamMemberImage}>
                        <Image
                          src={
                            node.team_member.user.profile_image
                              ? `${baseURL.PUBLIC_URL}${node.team_member.user.profile_image}`
                              : dummyImage
                          }
                          alt={"Team Member Image"}
                          roundedCircle
                        />
                      </div>
                      <div>
                        <p className={classes.nodeName}>{`${
                          node.team_member.user.first_name || ""
                        } ${node.team_member.user.last_name || ""}`}</p>

                        <p className={classes.nodeDetail}>
                          {node.team_member.user.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : node.hasOwnProperty("activeFunction") ? (
            <>
              {node.function_type === "card" ||
              node.function_type === "emergency_contact" ? (
                <div className="dropdown">
                  <button
                    className="btn customActionDropdown dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className={classes.memberNodeBox}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <div className={classes.teamMemberImage}>
                            {/* <Image
                              src={
                                node.function.profile_image
                                  ? `${baseURL.PUBLIC_URL}${node.function.profile_image}`
                                  : dummyImage
                              }
                              alt={"Team Member Image"}
                              roundedCircle
                            /> */}
                            <div
                              className={classes.nodeImage}
                              style={{
                                color: "var(--primary_button_color)",
                                borderColor: "var(--primary_button_color)",
                                background: "#fff",
                              }}
                            >
                              {node?.function_type?.charAt(0)}
                            </div>
                          </div>
                          <div>
                            <p className={classes.nodeName}>
                              {node.function_type === "emergency_contact"
                                ? "Emergency Contact"
                                : node.function_type}
                              <span
                                className={classes.nodeDetail}
                                style={{ marginLeft: "2px" }}
                              >
                                {node.function_type !== "emergency_contact"
                                  ? `(${node.function.card_type})`
                                  : ""}
                              </span>
                            </p>
                            <p className={classes.nodeName}>
                              {`${node.function.first_name} ${
                                node.function.last_name || ""
                              }`}
                            </p>
                            <p className={classes.nodeDetail}>
                              {node.function.email || ""}
                            </p>
                          </div>
                        </div>
                        <div className={classes.threedotsWrapper}>
                          <BsThreeDotsVertical />
                        </div>
                      </div>
                    </div>
                  </button>
                  <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => handleActiveFunctionQrOpen(node)}
                      >
                        QR Code
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="dropdown">
                  <button
                    className="btn customActionDropdown dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className={classes.memberNodeBox}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <div className={classes.teamMemberImage}>
                            <div
                              className={classes.nodeImage}
                              style={{
                                color: "var(--primary_button_color)",
                                borderColor: "var(--primary_button_color)",
                                background: "#fff",
                              }}
                            >
                              {node?.function_type?.charAt(0)}
                            </div>
                          </div>
                          <div>
                            <p className={classes.nodeName}>
                              {node.function_type === "cash_app"
                                ? "Cash App"
                                : node.function_type === "custom_url"
                                ? "Custom URL"
                                : node.function_type === "file_upload"
                                ? "File Upload"
                                : node.function_type === "custom_payment"
                                ? "Custom Payment"
                                : "Venmo"}
                            </p>
                            <p className={classes.nodeName}>
                              {node.function.title}
                            </p>
                            <p className={classes.nodeDetail}>
                              {node.function.url_path}
                            </p>
                          </div>
                        </div>
                        <div className={classes.threedotsWrapper}>
                          <BsThreeDotsVertical />
                        </div>
                      </div>
                    </div>
                  </button>
                  <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => handleActiveFunctionQrOpen(node)}
                      >
                        QR Code
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </>
          ) : (
            <div className="dropdown">
              <button
                className="btn customActionDropdown dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className={classes.memberNodeBox}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                      <div className={classes.teamMemberImage}>
                        <div className={classes.nodeImage}>
                          {node?.name?.charAt(0)}
                        </div>
                      </div>
                      <div>
                        <p className={classes.nodeName}>{node.name}</p>
                      </div>
                    </div>
                    <div className={classes.threedotsWrapper}>
                      <BsThreeDotsVertical />
                    </div>
                  </div>
                </div>
              </button>
              <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
                <li>
                  <span
                    className="dropdown-item"
                    onClick={() => handleQrOpen(node.uuid)}
                  >
                    QR Code
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
      }
    >
      {node?.children &&
        node?.children.map((child) => (
          <ViewRenderTree
            key={child.id}
            node={child}
            handleQrOpen={handleQrOpen}
            handleTeamMemberViewFunctions={handleTeamMemberViewFunctions}
            handleActiveFunctionQrOpen={handleActiveFunctionQrOpen}
          />
        ))}
    </TreeNode>
  );
};

export default ViewRenderTree;
