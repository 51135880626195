import React, { useState, useEffect } from "react";
import FunctionCard from "../../components/PageComponents/Function/FunctionCard";
import classes from "../../assets/styles/Pages/Function/Function.module.css";
import HeroSection from "../../components/HeroSection";
import FunctionCreateCardsData from "../../utils/data/FunctionCreateCardsData";
import { useParams } from "react-router-dom";
import { HOMEDASHBOARD, PRIORITY } from "../../utils/constants/apiUrl";
import { get, post } from "../../server";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import { useAtom } from "jotai";
import showToast from "../../helpers/showToast";
import { RiListUnordered } from "react-icons/ri";
import { Modal, Form } from "react-bootstrap";
import crossicon from "../../assets/images/global_icons/crossicon.svg";
import FormInput from "../../components/Forms/FormInput";
const Function = () => {
  const { teamId, userId } = useParams();
  const [loggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(true);
  const [personalCardsCount, setPersonalCardsCount] = useState(0);
  const [businessCardsCount, setBusinessCardsCount] = useState(0);
  const [formBuilderCount, setFormBuilderCount] = useState(0);
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [customUrls, setCustomUrls] = useState([]);
  const [payment, setPayment] = useState([]);
  const [cashApps, setCashApps] = useState([]);
  const [venmos, setVenmos] = useState([]);
  const [files, setFiles] = useState([]);
  const [showPriorityModal, setShowPriorityModal] = useState(false);
  const [sortedFunctionData, setSortedFunctionData] = useState([]);

  const getHomeDashboardData = async () => {
    let queryParams = "";

    if (userTeamId) {
      queryParams = `?team_id=${userTeamId}`;
      console.log("queryParams", queryParams);
    }
    let { status, data, message } = await get(
      `${HOMEDASHBOARD}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (Object.keys(data).length > 0) {
        setPersonalCardsCount(data.personalCardCount);
        setBusinessCardsCount(data.businessCardCount);
        setCustomUrls(data.customUrls);
        setEmergencyContacts(data.emergencyContacts);
        setPayment(data.onlyPayments);
        setFiles(data.fileUploads);
        setFormBuilderCount(data.formBuilderCount);
      }
    }
  };

  const handlePriorityModalShow = () => {
    setShowPriorityModal(true);
  };

  const handlePriorityModalClose = () => {
    setShowPriorityModal(false);
  };

  const handlePriorityChange = (index, newValue) => {
    const updatedSortedFunctionData = [...sortedFunctionData];
    updatedSortedFunctionData[index].priority = newValue;
    setSortedFunctionData(updatedSortedFunctionData);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userFunctionSettings = [];
    const functionRows = document.querySelectorAll(".function-row");
    functionRows.forEach((row) => {
      // const functionType = row.querySelector(
      //   'select[name="function_type"]'
      // ).value;
      const functionType = row.querySelector('input[name="function_type"]').value;
      const priority = row.querySelector('input[name="priority"]').value;
      const isVisible = row.querySelector('input[type="checkbox"]').checked
        ? 1
        : 0;
      userFunctionSettings.push({
        function_type: functionType,
        priority: priority,
        is_visible: isVisible,
      });
    });
    const payload = {
      team_id: teamId,
      userFunctionSettings: userFunctionSettings,
    };

    // Make API call
    let { status, message, data } = await post(
      PRIORITY,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      priorityFunction();
      setShowPriorityModal(false);
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };
  const priorityFunction = async () => {
    setLoading(true);
    const queryString = `?team_id=${userTeamId}`;
    let { status, message, data } = await get(
      userTeamId ? PRIORITY + queryString : PRIORITY,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      priorityData(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const priorityData = (sortPriorityData) => {
    const sortedData = [...FunctionCreateCardsData].map((card) => {
      const priorityItem = sortPriorityData.find(
        (item) => item.function_type === card.function_type
      );
      console.log("priorityItem", priorityItem);
      return {
        ...card,
        priority: priorityItem ? priorityItem.priority : 0,
        is_visible: priorityItem ? priorityItem.is_visible === "1" : "0",
      };
    });

    sortedData.sort((a, b) => {
      if (a.priority !== b.priority) {
        return a.priority - b.priority;
      }
    });

    setSortedFunctionData(sortedData);
  };
  useEffect(() => {
    getHomeDashboardData();
    priorityFunction();
  }, [userTeamId]);

  return (
    <>
      <div className="mt-3">
        <h6 className={classes.title}>Functions</h6>
      </div>
      <div className={classes.mainWrapper}>
        <HeroSection />
      </div>

      <div className={classes.boxWrapper}>
        <button
          className={classes.priorityBtn}
          onClick={handlePriorityModalShow}
        >
          <RiListUnordered /> <span>PRIORITY FUNCTION</span>
        </button>
        {/* {FunctionCreateCardsData.map((card) => {
          return (
            <FunctionCard
              key={card.id}
              {...card}
              teamId={teamId}
              userId={userId}
              personalCardsCount={personalCardsCount}
              businessCardsCount={businessCardsCount}
              customUrls={customUrls}
              emergencyContacts={emergencyContacts}
              payment={payment}
              files={files}
            />
          );
        })} */}
        {sortedFunctionData.map((card) => (
          <FunctionCard
            key={card.id}
            {...card}
            teamId={teamId}
            userId={userId}
            personalCardsCount={personalCardsCount}
            businessCardsCount={businessCardsCount}
            customUrls={customUrls}
            emergencyContacts={emergencyContacts}
            payment={payment}
            files={files}
            formBuilderCount={formBuilderCount}
            isVisible={card.is_visible ? " " : "cardHide"}
          />
        ))}
      </div>
      <Modal
        centered
        className={`customModal priorityModal`}
        size="md"
        show={showPriorityModal}
      >
        <Modal.Header className={classes.priorityModalHeader}>
          <span>Function Priority List</span>
          <img
            src={crossicon}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handlePriorityModalClose}
          />
        </Modal.Header>

        <Modal.Body className={classes.priorityModalBody}>
          <div className={`${classes.imageUpload}`}>
            {sortedFunctionData.map((functionItem, index) => (
              <div
                className="row function-row align-items-center mb-3"
                key={index}
              >
                {/* <div className="col-lg-5">
                  <label className={classes.label}>Select Function Type</label>
                  <div className="input-group globalInputWithIcon">
                    <select
                      style={{ border: "0" }}
                      className="form-select customSelect"
                      name="function_type"
                      value={functionItem.function_type}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="card">Digital Card</option>
                      <option value="custom_url">Custom Urls</option>
                      <option value="emergency_contact">
                        Emergency Contacts
                      </option>
                      <option value="file_upload">File Uploads</option>
                      <option value="payment">Payment Service</option>
                      <option value="form_builder">Form Builder</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-4">
                  <FormInput
                    type="text"
                    name="priority"
                    label="Enter Priority Number"
                    value={functionItem.priority}
                  />
                </div> */}
                <div className="col-lg-2 d-none">
                      <FormInput
                        type="text"
                        name="function_type"
                        label="Function Name"
                        value={functionItem.function_type}
                        disabled
                      />
                    </div>
                    <div className="col-lg-9">
                      <FormInput
                        type="text"
                        name="priority"
                        label={`Enter Priority Number for ${functionItem.title}`}
                        value={functionItem.priority === 0 ? "" : functionItem.priority}
                        handleChange={(e) => handlePriorityChange(index, e.target.value)}
                      />
                    </div>
                <div className="col-lg-3">
                  <div className="form-check mt-4">
                    {console.log(
                      "functionItem.is_visible",
                      functionItem.is_visible
                    )}
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id={`isVisible${index}`}
                    />
                    <label
                      className="form-check-label mb-0"
                      htmlFor={`isVisible${index}`}
                    >
                      Visible
                    </label>
                  </div>
                </div>
              </div>
            ))}
            {/* {[1, 2, 3, 4, 5].map((index) => (
                  <div
                    className="row function-row align-items-center mb-3"
                    key={index}
                  >
                    <div className="col-lg-5">
                      <label className={classes.label}>
                        Select Function Type
                      </label>
                      <div className="input-group globalInputWithIcon">
                        <select
                          style={{ border: "0" }}
                          className="form-select customSelect"
                          name="function_type"
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          <option value="card">Digital Card</option>
                          <option value="custom_url">Custom Urls</option>
                          <option value="emergency_contact">
                            Emergency Contacts
                          </option>
                          <option value="file_upload">File Uploads</option>
                          <option value="payment">Payment Service</option>
                          <option value="form_builder">Form Builder</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <FormInput
                        type="text"
                        name="priority"
                        label="Enter Priority Number"
                      />
                    </div>
                    <div className="col-lg-3">
                      <div className="form-check text-start">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={`isVisible${index}`}
                        />
                        <label
                          className="form-check-label mb-0"
                          htmlFor={`isVisible${index}`}
                        >
                          Visible
                        </label>
                      </div>
                    </div>
                  </div>
                ))} */}
          </div>
          <div className="text-center mt-4">
            <button
              className={`${classes.saveBtn} mb-3 mt-0`}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Function;
