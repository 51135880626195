import useLogout from "../hooks/useLogout";

const Unauthorized = () => {
  const logout = useLogout();

  const handleLogout = () => {
    logout();
  };
  return (
    <div className="notfoundMainWrapper">
      <div className="notfound">
        <div className="notfoundBg">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h1>Unauthorized</h1>
        <h2>You do not have access to the requested page.</h2>
        <button onClick={handleLogout}>Go Back </button>
      </div>
    </div>
  );
};

export default Unauthorized;
