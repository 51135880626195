import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Settings/Notification.module.css";
import { TEAMDETAIL } from "../../../utils/constants/apiUrl";
import { post } from "../../../server";
import { loggedInUser, teamIDCheck } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../Loader";
import showToast from "../../../helpers/showToast";
import _ from "lodash";
const CancelSubscription = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);

  const [subscription, setSubscription] = useState({
    auto_renew: false,
  });

  const [loading, setLoading] = useState(false);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);

  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = e.currentTarget.checked;
    setSubscription({ ...subscription, [name]: value });
  };

  const handleSubmit = async (e) => {
    let subscriptionObj = subscription;
    subscriptionObj.team_id = userTeamId;
    setLoading(true);
    let { status, message, data } = await post(
      `${TEAMDETAIL}/autoRenew`,
      subscriptionObj,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    let userData = _.cloneDeep(loggedUser);
    userData.teams.auto_renew = subscriptionObj.auto_renew;
    setLoggedUser(userData);
    showToast(status, message);
    setLoading(false);
  };

  useEffect(() => {
    setSubscription({
      auto_renew: loggedUser?.teams?.auto_renew,
    });
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className={classes.notificationWrapper}>
        <div className={classes.inputWrapper}>
          <div className="row">
            <div className="col-md-4 col-12">
              <div className={classes.switchWrapper}>
                <p className={classes.emailTitle}>Auto Subscription</p>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    name="auto_renew"
                    checked={subscription.auto_renew}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <p
                className={classes.info}
                style={{
                  borderBottom: "1px solid var(--border_color)",
                  paddingBottom: "15px",
                }}
              >
                {/* After cancelling subscription, if your auto-subscription is
                active, then it will be marked as de-active and next
                subscriptions won't be auto subscribed. */}
                {/* Using this toggle button, you can enable and disable your auto
                subscription. */}
                After your subscription is cancelled, this will automatically be
                removed if your auto subscription is active.
              </p>
            </div>
            <div className="col-md-4 col-12"></div>
            <div className="col-md-4 col-12"></div>
          </div>
        </div>
        <div className={classes.submitBtnWrapper} onClick={handleSubmit}>
          <button type="button">Update Subscription</button>
        </div>
      </div>
    </>
  );
};

export default CancelSubscription;
