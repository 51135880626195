const functionTypes = {
  CARD: "card",
  PAYMENT: "payment",
  FILE_UPLOAD: "file_upload",
  EMERGENCY_CONTACT: "emergency_contact",
  CUSTOM_URL: "custom_url",
  FORM_BUILDER: "form_builder",
  CONNECTION: "connection",
  ADD: "add",
  DELETE: "delete",
};

export default functionTypes;
