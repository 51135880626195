import React from "react";

const ViewStatsIcon = ({ primaryColor }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill={primaryColor}
      stroke="none"
    >
      <path
        d="M4250 5105 c-163 -40 -296 -186 -318 -350 -11 -84 2 -173 34 -240
l27 -57 -301 -301 -300 -300 -63 27 c-133 60 -306 42 -420 -44 -30 -22 -59
-38 -66 -36 -7 3 -118 87 -248 187 -220 169 -236 184 -231 208 25 108 29 154
17 220 -25 141 -102 246 -225 306 -85 43 -165 56 -256 44 -86 -13 -153 -45
-222 -108 -93 -85 -138 -187 -138 -313 0 -69 27 -171 56 -212 13 -20 -2 -37
-288 -323 l-301 -302 -40 21 c-173 88 -374 53 -508 -89 -214 -227 -113 -600
189 -693 353 -109 664 264 499 598 l-21 43 301 301 301 301 54 -26 c73 -35
160 -48 240 -37 77 11 117 27 183 73 l50 34 250 -193 c184 -141 249 -197 247
-211 -1 -10 -7 -46 -13 -79 -49 -267 201 -517 476 -477 143 20 267 111 329
242 29 62 31 73 31 176 0 100 -3 115 -29 173 l-29 64 301 301 302 302 42 -22
c65 -33 109 -44 186 -44 124 -1 219 38 304 124 233 235 124 628 -197 712 -71
18 -131 18 -205 0z m199 -181 c59 -25 98 -62 127 -120 64 -131 10 -286 -121
-345 -50 -23 -152 -25 -203 -4 -49 21 -110 80 -133 130 -25 53 -25 156 0 210
57 126 201 182 330 129z m-2353 -359 c219 -129 121 -467 -136 -467 -221 0
-332 269 -177 430 41 43 78 62 136 71 61 9 124 -3 177 -34z m1172 -841 c97
-48 147 -136 140 -243 -6 -75 -23 -114 -70 -162 -73 -72 -200 -94 -292 -50
-126 61 -179 217 -116 340 64 126 214 177 338 115z m-2383 -342 c84 -43 134
-127 135 -225 0 -100 -44 -174 -131 -222 -94 -51 -210 -38 -292 34 -52 46 -79
109 -80 184 0 107 50 186 148 234 41 20 61 23 115 20 39 -2 81 -12 105 -25z"
      />
      <path
        d="M3879 3397 c-9 -7 -21 -22 -27 -34 -9 -16 -12 -421 -12 -1607 l0
-1586 -85 0 -85 0 -2 1000 -3 999 -23 23 -23 23 -437 3 c-460 3 -499 0 -520
-40 -9 -17 -13 -272 -15 -1013 l-2 -990 -85 0 -85 0 -5 1428 -5 1429 -24 19
c-22 18 -45 19 -477 19 l-453 0 -28 -24 -28 -24 -3 -1426 -2 -1426 -85 0 -85
0 0 824 0 825 -24 28 -24 28 -460 0 -460 0 -26 -24 -26 -24 0 -829 0 -828
-130 0 -130 0 0 -85 0 -85 2560 0 2560 0 0 85 0 85 -130 0 -130 0 0 1595 0
1596 -25 24 -24 25 -458 0 c-344 -1 -462 -4 -474 -13z m811 -1692 l0 -1535
-340 0 -340 0 0 1535 0 1535 340 0 340 0 0 -1535z m-2390 -170 l0 -1365 -340
0 -340 0 0 1365 0 1365 340 0 340 0 0 -1365z m1200 -425 l0 -940 -340 0 -340
0 0 940 0 940 340 0 340 0 0 -940z m-2390 -170 l0 -770 -340 0 -340 0 0 770 0
770 340 0 340 0 0 -770z"
      />
    </g>
  </svg>
);

export default ViewStatsIcon;
