import React, { useRef, useState, useEffect } from "react";
import {
  HIERARCHY_TREE,
  CREATE_NODE,
  UPDATE_NODE,
  DELETE_NODE,
  CHANGE_MEMBER_PARENT,
  REMOVE_MEMBER,
  ATTACH_MEMBER,
  REASSIGN_NODE,
  GET_MEMBER_FUNCTIONS,
  MANAGE_MEMEBER_ACTIVE_FUNCTION,
  REMOVE_ACTIVE_FUNCTION,
} from "../../utils/constants/apiUrl";
import classes from "../../assets/styles/ManageNodes.module.css";
import { get, post, deleteRecord, put } from "../../server";
import { loggedInUser, mobileViewCheck, teamIDCheck } from "../../utils/store";
import { useAtom } from "jotai";
import { BsThreeDotsVertical } from "react-icons/bs";
import Loader from "../../components/Loader";
import { Tree } from "react-organizational-chart";
import NodeAddEditModal from "../../components/Modals/NodeAddEditModal";
import ManageTeamMemberModal from "../../components/Modals/ManageTeamMemberModal";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import showToast from "../../helpers/showToast";
import { Overlay, Tooltip } from "react-bootstrap";
import html2canvas from "html2canvas";
import RenderTree from "./RenderTree";
import QRCodeModal from "../../components/Modals/QRCodeModal";
import { SHARE_QR_NODE } from "../../utils/constants/common";
import ManageActiveFunctionModal from "../../components/Modals/ManageActiveFunctionModal";

const ManageNodes = () => {
  const target = useRef(null);
  const targetDivRef = useRef(null);

  const [loggedUser] = useAtom(loggedInUser);
  const [userTeamId] = useAtom(teamIDCheck);
  const [mobileView] = useAtom(mobileViewCheck);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [activeFunctionModalLoading, setActiveFunctionModalLoading] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showActiveFunctionModal, setShowActiveFunctionModal] = useState(false);
  const [showRemoveActiveFunctionModal, setShowRemoveActiveFunctionModal] =
    useState(false);

  const [showOtherMembersModal, setShowOtherMembersModal] = useState(false);
  const [showAllNodesModal, setShowAllNodesModal] = useState(false);

  const [nodesWithoutSubNodes, setNodesWithoutSubNodes] = useState([]);
  const [remainingSubNodes, setRemainingSubNodes] = useState([]);
  const [allNodes, setAllNodes] = useState([]);
  const [remainingNodes, setRemainingNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState("");
  const [teamMember, setTeamMember] = useState("");
  const [selectedData, setSelectedData] = useState({ name: "" });
  const [selectedActiveFunction, setSelectedActiveFunction] = useState({
    function_type: "",
    function_id: null,
  });
  const [activeFunctionNode, setActiveFunctionNode] = useState(null);

  const [allFunctions, setAllFunctions] = useState({});
  const [selectedFunctions, setSelectedFunctions] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [treeData, setTreeData] = useState({
    id: null,
    name: "",
    team_id: null,
    children: [],
  });
  const [unassignedMembers, setUnassignedMembers] = useState([]);
  const [teamQrOpen, setTeamQrOpen] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [activeFunctionQrOpen, setActiveFunctionQrOpen] = useState(false);
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showRemoveConfirmationModal, setShowRemoveConfirmationModal] =
    useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [removeMemberData, setRemoveMemberData] = useState({});
  const [lastNode, setLastNode] = useState({});
  const [reassignedNode, setReassignedNode] = useState({});
  const [removingMembersIds, setRemovingMembersIds] = useState([]);

  function checkHierarchyTreeIDExists(data) {
    const childrenArray = data?.children;
    return childrenArray?.some((child) =>
      child.hasOwnProperty("hierarchy_tree_id")
    );
  }

  // Recursive function to find the specified node and collect its team members
  function findNodeAndTeamMembers(children, nodeId) {
    for (const node of children) {
      // Check if the current node matches the specified node ID
      if (node.id === nodeId) {
        // If found, collect the team members associated with the node
        return collectTeamMembers(node);
      } else if (node.children && node.children.length > 0) {
        // If the current node has children, recursively search for the node within its children
        const teamMembers = findNodeAndTeamMembers(node.children, nodeId);
        // If team members are found, return them
        if (teamMembers.length > 0) {
          return teamMembers;
        }
      }
    }
    // Return an empty array if the node is not found
    return [];
  }

  // Recursive function to collect team members from a node and its subnodes
  function collectTeamMembers(node) {
    const teamMembers = [];
    if (node.team_member_id) {
      // If the node has a team member, add its ID to the result array
      teamMembers.push(node.team_member_id);
    }
    if (node.children && node.children.length > 0) {
      // Iterate over each child node and collect their team members recursively
      for (const child of node.children) {
        const childTeamMembers = collectTeamMembers(child);
        // Add the child node's team members to the result array
        teamMembers.push(...childTeamMembers);
      }
    }
    // Return the collected team member IDs
    return teamMembers;
  }

  const handleQrOpen = (uuid) => {
    setSelectedData(uuid);
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleTeamQrOpen = (uuid) => {
    setSelectedData(uuid);
    setTeamQrOpen(true);
  };

  const handleTeamQRClose = () => {
    setTeamQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleActiveFunctionQrOpen = (functionData) => {
    setActiveFunctionQrOpen(true);
    setSelectedData({
      functionType: functionData.function_type,
      uuid: functionData.function.uuid,
    });
  };
  const handleActiveFunctionQrClose = () => {
    setActiveFunctionQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleActiveFunctionQrDownload = () => {
    html2canvas(document.querySelector("#active-function-qr-code")).then(
      function (canvas) {
        const link = document.createElement("a");
        link.download = "qrcode.png";
        link.href = canvas.toDataURL();
        link.click();
      }
    );
  };
  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handleTeamQrDownload = () => {
    html2canvas(document.querySelector("#team-qr-code")).then(function (
      canvas
    ) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handleSaveSelectedNodes = async (id) => {
    setModalLoading(true);
    const payload = {
      team_id: treeData?.team_id,
      parent_id: teamMember?.hierarchy_tree_id,
      member_id: teamMember?.team_member_id,
      selected_id: parseInt(id),
    };
    let { status, message, data } = await post(
      CHANGE_MEMBER_PARENT,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await getTrees();
    }
    showToast(status, message);
    setModalLoading(false);
    handleCloseMemberModal();
  };

  const handleSaveReassignedNode = async (id) => {
    setModalLoading(true);

    const payload = {
      team_id: treeData?.team_id,
      node_id: reassignedNode.id,
      assign_to: id,
      member_ids: removingMembersIds,
    };
    let { status, message, data } = await post(
      REASSIGN_NODE,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await getTrees();
    }
    showToast(status, message);
    setModalLoading(false);
    handleCloseALLNodesModal();
  };

  const handleSaveSelectedMembers = async (id) => {
    setModalLoading(true);
    const payload = {
      team_id: treeData?.team_id,
      node_id: lastNode?.id,
      member_id: id,
    };
    let { status, message, data } = await post(
      ATTACH_MEMBER,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await getTrees();
    }
    showToast(status, message);
    setModalLoading(false);
    handleCloseOtherMembersModal();
  };

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleShowRemoveActiveFunctionModal = (id) => {
    setDeleteId(id);
    setShowRemoveActiveFunctionModal(true);
  };
  const handleCloseRemoveActiveFunctionModal = () => {
    setDeleteId(null);
    setShowRemoveActiveFunctionModal(false);
  };

  const handleConfirmDeleteActiveFunction = async () => {
    setLoading(true);

    let { status, message } = await put(
      `${REMOVE_ACTIVE_FUNCTION}${deleteId}`,
      deleteId,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await getTrees();
      setLoading(false);
    }
    setLoading(false);
    handleCloseRemoveActiveFunctionModal();
  };

  const getTeamMemberFunctions = async (userId) => {
    setActiveFunctionModalLoading(true);

    let queryParams = "";

    if (Object.keys(loggedUser).length !== 0) {
      queryParams = `?team_id=${userTeamId}`;
    }

    if (userId) {
      queryParams += `${queryParams ? "&" : "?"}user_id=${userId}`;
    }

    let { status, message, data } = await get(
      `${GET_MEMBER_FUNCTIONS}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setAllFunctions(data);
    }

    setActiveFunctionModalLoading(false);
  };

  const handleSaveActiveFunction = async (activeFunctiondata) => {
    setActiveFunctionModalLoading(true);
    let payload;
    if (activeFunctiondata.function_type === "payment") {
      let selectedPayment = selectedFunctions.find(
        (func) => func.id === Number(activeFunctiondata.function_id)
      );

      payload = {
        id: Number(activeFunctionNode),
        function_id: Number(activeFunctiondata.function_id),
        function_type: selectedPayment.payment_type,
      };
    } else {
      payload = {
        id: Number(activeFunctionNode),
        function_id: Number(activeFunctiondata.function_id),
        function_type: activeFunctiondata.function_type,
      };
    }

    let { status, message, data } = await post(
      MANAGE_MEMEBER_ACTIVE_FUNCTION,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await getTrees();
    }
    showToast(status, message);
    setActiveFunctionModalLoading(false);

    handleCloseActiveFunctionModal();
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setIsCheckAll(false);
  };

  const handleShowRemoveConfirmationModal = () =>
    setShowRemoveConfirmationModal(true);
  const handleCloseRemoveConfirmationModal = () => {
    setShowRemoveConfirmationModal(false);
    setRemoveMemberData({});
  };

  const handleShowMemberModal = () => setShowMemberModal(true);
  const handleShowActiveFunctionModal = (nodeData) => {
    if (Object.keys(nodeData).length > 0) {
      setActiveFunctionNode(nodeData.id);
      getTeamMemberFunctions(nodeData.team_member.user_id);
    }
    setShowActiveFunctionModal(true);
  };

  const handleCloseActiveFunctionModal = () => {
    setSelectedActiveFunction({
      function_type: "",
      function_id: "",
    });
    setActiveFunctionNode(null);
    setShowActiveFunctionModal(false);
  };
  const handleShowOtherMembersModal = () => setShowOtherMembersModal(true);

  const handleShowAllNodesModal = (obj) => {
    const newRemainingNodes = allNodes.filter((node) => node?.id !== obj?.id);
    setRemainingNodes(newRemainingNodes);
    setReassignedNode({ ...reassignedNode, ...obj });
    const teamMembersIds = findNodeAndTeamMembers(treeData.children, obj.id);
    setRemovingMembersIds([...removingMembersIds, ...teamMembersIds]);
    setShowAllNodesModal(true);
  };

  useEffect(() => {
    if (selectedActiveFunction.function_type === "card") {
      setSelectedFunctions(allFunctions.cards);
    } else if (selectedActiveFunction.function_type === "custom_url") {
      setSelectedFunctions(allFunctions.customUrls);
    } else if (selectedActiveFunction.function_type === "emergency_contact") {
      setSelectedFunctions(allFunctions.emergencyContacts);
    } else if (selectedActiveFunction.function_type === "file_upload") {
      setSelectedFunctions(allFunctions.fileUploads);
    } else if (selectedActiveFunction.function_type === "payment") {
      setSelectedFunctions(allFunctions.payments);
    } else {
      setSelectedFunctions([]);
    }
  }, [selectedActiveFunction.function_type]);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);

    const payload = {
      id: deleteId,
      check_all: isCheckAll,
    };
    let { status, message } = await post(
      `${DELETE_NODE}`,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await getTrees();
      setLoading(false);
    }
    setLoading(false);
  };

  // handle Remve Team Member from Node
  const handleRemoveMember = (obj) => {
    setRemoveMemberData(obj);
    handleShowRemoveConfirmationModal(true);
  };
  const handleConfirmRemoveMember = async () => {
    handleCloseRemoveConfirmationModal(true);
    setLoading(true);
    const payload = {
      team_id: removeMemberData.team_id,
      node_id: removeMemberData.hierarchy_tree_id,
      member_id: removeMemberData.team_member_id,
    };
    let { status, message } = await post(
      `${REMOVE_MEMBER}`,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await getTrees();
      setLoading(false);
    }
    setLoading(false);
  };

  const handleShowModal = (obj) => {
    setIsEdit(true);
    if (obj && Object.keys(obj).length) setSelectedData(obj);
    setShowModal(true);
  };
  const handleTeamMemberClick = (obj) => {
    setTeamMember(obj);
    const newRemainingSubNodes = nodesWithoutSubNodes.filter(
      (node) => node.id !== obj.hierarchy_tree_id
    );
    setRemainingSubNodes(newRemainingSubNodes);
  };

  const handleTeamMemberViewFunctions = (obj) => {
    window.open(
      `/publicFunctions/${treeData.team_id}/${
        obj.hasOwnProperty("check_status")
          ? obj.user_id
          : obj.team_member.user_id
      }`,
      "_blank"
    );
  };

  const handleNodeClick = (nodeData) => {
    setSelectedData({ ...selectedData, id: nodeData.id });

    const treeIdExists = checkHierarchyTreeIDExists(nodeData);
    if (nodeData?.children?.length === 0 || treeIdExists) {
      setLastNode({ ...lastNode, ...nodeData });
    }
  };

  // This function Prevents to add duplicate node
  const handleSaveShowModal = async (newData) => {
    setModalLoading(true);

    const payload = {
      name: newData.name,
      // team_id: loggedUser?.teams?.id,
      team_id: treeData?.team_id,
    };

    // Function to check for duplicate names recursively
    // const isDuplicateName = (node) => {
    //   if (node.name === newData.name) {
    //     return true;
    //   }
    //   if (node.children && node.children.length > 0) {
    //     for (let i = 0; i < node.children.length; i++) {
    //       if (isDuplicateName(node.children[i])) {
    //         return true;
    //       }
    //     }
    //   }
    //   return false;
    // };

    const isDuplicateName = (node) => {
      if (isEdit && node.id === selectedData.id) {
        // Skip name comparison for the node being updated
        return false;
      }
      if (node.name === newData.name) {
        return true;
      }
      if (node.children && node?.children?.length > 0) {
        for (let i = 0; i < node?.children?.length; i++) {
          if (isDuplicateName(node.children[i])) {
            return true;
          }
        }
      }
      return false;
    };

    // Check for duplicate name in treeData
    if (isDuplicateName(treeData)) {
      showToast(false, "Name already exists. Please choose a different name.");
      return;
    }

    let { status, message, data } = isEdit
      ? await post(
          UPDATE_NODE,
          { ...payload, id: selectedData.id },
          loggedUser?.token
        )
      : await post(
          CREATE_NODE,
          { ...payload, parent_id: selectedData.id || null },
          loggedUser?.token
        );

    if (status) {
      await getTrees();
    }
    setModalLoading(false);
    showToast(status, message);
    handleCloseModal();
  };
  const handleCloseMemberModal = () => {
    setShowMemberModal(false);
    setTeamMember("");
    setRemainingSubNodes([]);
    setSelectedNode("");
  };
  const handleCloseOtherMembersModal = () => {
    setShowOtherMembersModal(false);
    setSelectedNode("");
  };
  const handleCloseALLNodesModal = () => {
    setShowAllNodesModal(false);
    setRemainingNodes([]);
    setRemovingMembersIds([]);
    setReassignedNode({});
  };

  const handleCloseModal = () => {
    setSelectedData({ name: "" });
    setShowModal(false);
    setIsEdit(false);
  };

  // More Understandable

  // Define a recursive function to traverse the node array
  function traverseNode(node, teamMembers) {
    // Loop through each node object
    for (let i = 0; i < node?.length; i++) {
      // Get the current node object
      let currentNode = node[i];
      // Loop through each team member object
      // Check if the node object has any children
      if (currentNode?.children?.length > 0) {
        // Call the function recursively on the children array
        traverseNode(currentNode.children, teamMembers);
      }

      for (let j = 0; j < teamMembers?.length; j++) {
        // Get the current team member object
        let currentTeamMember = teamMembers[j];

        // Compare the id of the node object with the hierarchy tree id of the team member object
        if (currentNode.id === currentTeamMember.hierarchy_tree_id) {
          // Push the team member object into the children key of the node object

          if (currentTeamMember.function_type !== null) {
            // Create a 'children' array in the current team member if it doesn't exist
            currentTeamMember.children = [];
            // Push the 'function' object into the 'children' array
            currentTeamMember.children.push({
              function: currentTeamMember.function,
              function_type: currentTeamMember.function_type,
              id: currentTeamMember.id,
              activeFunction: "activeFunction",
            });
          }
          currentNode?.children?.push(currentTeamMember);
        }
      }
    }
  }

  // Define a recursive function to traverse the data and collect the nodes without sub nodes
  function collectNodesWithoutSubNodes(node, result) {
    // Use the forEach method to iterate over each node object
    node.forEach((currentNode) => {
      // Check if the node object has any children
      if (currentNode?.children?.length === 0) {
        // Push the node object into the result array
        result?.push(currentNode);
      } else {
        // Call the function recursively on the children array
        collectNodesWithoutSubNodes(currentNode.children, result);
      }
    });
  }
  // Define a recursive function to traverse the data and collect all the nodes
  function collectAllNodes(node, result) {
    node.forEach((currentNode) => {
      result.push(currentNode);
      if (currentNode?.children?.length > 0) {
        collectAllNodes(currentNode.children, result);
      }
    });
  }

  const getTrees = async () => {
    setLoading(true);

    let { status, message, data } = await get(
      `${HIERARCHY_TREE}/${userTeamId || null}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      // Define an empty array to store the result
      let allDataNodes = [];
      // Call the function on the node array and the result array
      collectAllNodes(data.node, allDataNodes);

      // Push the team name object to allNodes array

      allDataNodes.unshift({ name: data.team.name, id: "main" });
      setAllNodes(allDataNodes);

      // Define an empty array to store the result
      let nodesWithoutSubNodes = [];

      // Call the function on the node array and the result array
      collectNodesWithoutSubNodes(data.node, nodesWithoutSubNodes);

      setNodesWithoutSubNodes(nodesWithoutSubNodes);

      // Call the function on the node array and the team members array
      traverseNode(data.node, data.team_members);

      setTreeData((prevTreeData) => {
        return {
          ...prevTreeData,
          name: data.team.name,
          team_id: data.team.id,
          children: data.node,
          // children:
          //   data.node && data?.node?.length > 0
          //     ? data?.node
          //     : data?.other_members, // Update the children array directly with the fetched data
        };
      });
      setUnassignedMembers(data.other_members);
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  useEffect(() => {
    getTrees();
  }, []);

  useEffect(() => {
    // Scroll to the target div when the component mounts
    if (treeData && Object.keys(treeData).length > 0) {
      const targetDiv = targetDivRef.current;
      if (targetDiv) {
        targetDiv.scrollIntoView({
          behavior: "instant",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [treeData]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="mt-3">
        <h6 className={classes.title}>Manage Cards</h6>
      </div>

      <div className={classes.boxWrapper}>
        <div>
          <Tree
            lineWidth={"2px"}
            lineHeight="50px"
            lineColor={"var(--primary_text_color)"}
            lineBorderRadius={"10px"}
            label={
              <div className="manageNodesDropdownWrapper">
                <div className="dropdown" ref={targetDivRef}>
                  <button
                    className="btn customActionDropdown dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div
                      className={classes.memberNodeBox}
                      onClick={() => handleNodeClick(treeData)}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <div className={classes.teamMemberImage}>
                            <div className={classes.nodeImage}>
                              {treeData?.name?.charAt(0)}
                            </div>
                          </div>
                          <div>
                            <p className={classes.nodeName}>{treeData.name}</p>
                          </div>
                        </div>
                        <div className={classes.threedotsWrapper}>
                          <BsThreeDotsVertical />
                        </div>
                      </div>
                    </div>
                  </button>
                  <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => handleTeamQrOpen(treeData.team_id)}
                      >
                        QR Code
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => setShowModal(true)}
                      >
                        Create
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            }
          >
            {treeData?.children &&
              treeData?.children.map((child) => (
                <RenderTree
                  key={child.id}
                  node={child}
                  handleShowModal={handleShowModal}
                  setShowModal={setShowModal}
                  handleNodeClick={handleNodeClick}
                  handleDelete={handleDelete}
                  handleQrOpen={handleQrOpen}
                  handleShowMemberModal={handleShowMemberModal}
                  handleShowOtherMembersModal={handleShowOtherMembersModal}
                  handleTeamMemberClick={handleTeamMemberClick}
                  handleTeamMemberViewFunctions={handleTeamMemberViewFunctions}
                  checkHierarchyTreeIDExists={checkHierarchyTreeIDExists}
                  handleRemoveMember={handleRemoveMember}
                  handleShowAllNodesModal={handleShowAllNodesModal}
                  unassignedMembers={unassignedMembers}
                  handleShowActiveFunctionModal={handleShowActiveFunctionModal}
                  handleShowRemoveActiveFunctionModal={
                    handleShowRemoveActiveFunctionModal
                  }
                  handleActiveFunctionQrOpen={handleActiveFunctionQrOpen}
                />
              ))}
          </Tree>
        </div>
      </div>
      {showModal && (
        <NodeAddEditModal
          show={showModal}
          data={selectedData}
          isEdit={isEdit}
          setSelectedData={setSelectedData}
          handleCloseModal={handleCloseModal}
          handleSaveShowModal={handleSaveShowModal}
          loading={modalLoading}
        />
      )}
      {showMemberModal && (
        <ManageTeamMemberModal
          show={showMemberModal}
          data={remainingSubNodes}
          handleCloseModal={handleCloseMemberModal}
          setSelectedNode={setSelectedNode}
          handleSaveSelectedNodes={handleSaveSelectedNodes}
          selectedNode={selectedNode}
          loading={modalLoading}
        />
      )}
      {showAllNodesModal && (
        <ManageTeamMemberModal
          show={showAllNodesModal}
          data={remainingNodes}
          isAllNodes={true}
          handleSaveSelectedNodes={handleSaveReassignedNode}
          handleCloseModal={handleCloseALLNodesModal}
          setSelectedNode={setSelectedNode}
          selectedNode={selectedNode}
          loading={modalLoading}
        />
      )}
      {showOtherMembersModal && (
        <ManageTeamMemberModal
          show={showOtherMembersModal}
          data={unassignedMembers}
          handleCloseModal={handleCloseOtherMembersModal}
          unlistedMembers={true}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          handleSaveSelectedNodes={handleSaveSelectedMembers}
        />
      )}
      {showActiveFunctionModal && (
        <ManageActiveFunctionModal
          loading={activeFunctionModalLoading}
          show={showActiveFunctionModal}
          handleCloseModal={handleCloseActiveFunctionModal}
          handleSaveShowModal={handleSaveActiveFunction}
          setSelectedData={setSelectedActiveFunction}
          selectedData={selectedActiveFunction}
          data={selectedFunctions}
        />
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          show={showConfirmationModal}
          handleCloseModal={handleCloseConfirmationModal}
          handleConfirmDelete={handleConfirmDelete}
          confirmTitle="node"
          deleteAll={true}
          setIsCheckAll={setIsCheckAll}
        />
      )}
      {showRemoveActiveFunctionModal && (
        <ConfirmationModal
          show={showRemoveActiveFunctionModal}
          handleCloseModal={handleCloseRemoveActiveFunctionModal}
          confirmTitle="active function"
          handleConfirmDelete={handleConfirmDeleteActiveFunction}
        />
      )}
      {showRemoveConfirmationModal && (
        <ConfirmationModal
          show={showRemoveConfirmationModal}
          handleCloseModal={handleCloseRemoveConfirmationModal}
          confirmTitle="member from the card"
          removeMember={true}
          handleConfirmDelete={handleConfirmRemoveMember}
        />
      )}

      {teamQrOpen && (
        <QRCodeModal
          target={target}
          qrOpen={teamQrOpen}
          handleQRClose={handleTeamQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleTeamQrDownload}
          url={`${SHARE_QR_NODE}team/${selectedData}`}
          qrCodeId="team-qr-code"
        />
      )}
      {qrOpen && (
        <QRCodeModal
          target={target}
          qrOpen={qrOpen}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleDownload}
          url={`${SHARE_QR_NODE}node/${selectedData}`}
        />
      )}
      {activeFunctionQrOpen && (
        <QRCodeModal
          target={target}
          qrOpen={activeFunctionQrOpen}
          handleQRClose={handleActiveFunctionQrClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleActiveFunctionQrDownload}
          functionType={
            selectedData.functionType === "cash_app"
              ? "cashApp"
              : selectedData.functionType === "custom_payment"
              ? "customPayment"
              : selectedData.functionType === "custom_url"
              ? "customUrl"
              : selectedData.functionType === "file_upload"
              ? "fileUpload"
              : selectedData.functionType === "emergency_contact"
              ? "emergencyContact"
              : selectedData.functionType
          }
          functionUuid={selectedData.uuid}
          qrCodeId="active-function-qr-code"
        />
      )}
      <Overlay
        target={target.current}
        show={showCopyPopup}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default ManageNodes;
