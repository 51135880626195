const Joi = require("joi");

const SendInvitationsSchema = Joi.object({
  id: Joi.allow(null),

  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),
});

export default SendInvitationsSchema;
