import React, { useMemo } from "react";
import logo from "../assets/images/footer_icons/logo.svg";
import logoDark from "../assets/images/sidebar_icons/logo-dark.svg";
import facebook from "../assets/images/signup_icons/face.svg";
import twitter from "../assets/images/signup_icons/twitterLogo.svg";
import linkedin from "../assets/images/signup_icons/linkedin.svg";
import tiktokicon from "../assets/images/signup_icons/tiktokicon.svg";
import insta from "../assets/images/signup_icons/insta.svg";
import playstore from "../assets/images/footer_icons/playstore.svg";
import appstore from "../assets/images/footer_icons/appstore.svg";
import { Link } from "react-router-dom";
import classes from "../assets/styles/Footer.module.css";
import { features, shop, legal, contact } from "../utils/data/FooterListItems";
import {
  globalAppSetting,
  loggedInUser,
  teamIDCheck,
  userIDCheck,
  themeColorAtom,
  buttonColorAtom,
} from "../utils/store";
import { useAtom } from "jotai";
import chroma from "chroma-js";

const Footer = () => {
  const [loggedUser] = useAtom(loggedInUser);
  const [appSetting, setAppSetting] = useAtom(globalAppSetting);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [userID] = useAtom(userIDCheck);
  const [themeColor, setThemeColor] = useAtom(themeColorAtom);
  const checkAdmin = useMemo(() => {
    return (
      loggedUser?.role?.some(
        (role) => role === "customer" || role === "team_owner"
      ) === false
    );
  }, []);

  const footerManualStyle = {
    borderTop: 0,
    marginTop: 0,
    paddingTop: 0,
    textAlign: "center",
  };
  const isLightTheme = themeColor
    ? chroma(themeColor).luminance() > 0.4
    : false;
  return (
    <>
      <footer className={classes.footer}>
        <div className={classes.footerContentWrapper}>
          <div
            className={`g-0 gy-3 pt-md-5 pt-4 pb-3 ${checkAdmin ? "" : "row"}`}
          >
            {!checkAdmin ? (
              <>
                <div className="col-xl-4 col-12">
                  <Link to="/">
                    {/* <img src={logo} alt="logo" /> */}
                    <img src={isLightTheme ? logoDark : logo} alt="logo" />
                  </Link>
                  <p>
                    Our mission is to bring you the future of representing your
                    brand,
                  </p>
                  <ul
                    className={`d-flex  align-items-center flex-wrap ${classes.social_icons}`}
                  >
                    <a
                      href={appSetting.facebook_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li className={classes.avatarIcon}>
                        <img src={facebook} alt="" />
                      </li>
                    </a>
                    <a
                      href={appSetting.twitter_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li className={classes.avatarIcon}>
                        <img src={twitter} alt="" />
                      </li>
                    </a>
                    <a
                      href={appSetting.linkdin_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li className={classes.avatarIcon}>
                        <img src={linkedin} alt="" />
                      </li>
                    </a>
                    <a
                      href={appSetting.instagram_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li className={classes.avatarIcon}>
                        <img src={insta} alt="" />
                      </li>
                    </a>
                    <a
                      href={appSetting.tiktok_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li className={classes.avatarIcon}>
                        <img src={tiktokicon} alt="" />
                      </li>
                    </a>
                  </ul>
                </div>

                <div className="col-xl-8 col-12">
                  <div className="row g-0">
                    <div className="col-md-6 col-12">
                      <div className="row">
                        <div className="col-6">
                          <h6>Features</h6>
                          <ul>
                            {features.map((feature) => {
                              return (
                                <li key={feature.id}>
                                  <Link
                                    to={`${feature.url}${
                                      loggedUser?.role?.includes("team_owner")
                                        ? `?team_id=${
                                            userTeamId || null
                                          }&user_id=${userID || null}`
                                        : ""
                                    }`}
                                  >
                                    {feature.text}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="col-6">
                          <h6>Shop</h6>
                          <ul>
                            {shop.map((product) => {
                              return (
                                <li key={product.id}>
                                  <a
                                    href={product.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {product.text}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="row g-0">
                        <div className="col-6">
                          <h6>Legal</h6>
                          <ul>
                            {legal.map((law) => {
                              return (
                                <li key={law.id}>
                                  <Link to={law.url}>{law.text}</Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="col-6">
                          <h6>Contact us</h6>
                          <ul>
                            {contact.map((query) => {
                              return (
                                <li key={query.id}>
                                  <Link to={query.url}>{query.text}</Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div>
                          <ul className={classes.app_stores}>
                            <li>
                              <a
                                href="https://play.google.com/store/apps/details?id=com.holguinmedia.contactapp"
                                target="_blank"
                              >
                                <img src={playstore} alt="" />
                              </a>
                            </li>
                            <li className="ml-lg-4">
                              <a
                                href="https://apps.apple.com/pk/app/contact-share-digital-info/id1662851237"
                                target="_blank"
                              >
                                <img src={appstore} alt="" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div
              className={classes.rights}
              style={checkAdmin ? footerManualStyle : {}}
              // style={styles.footerManualStyle}
            >
              © {new Date().getFullYear()} Contact Co, All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
