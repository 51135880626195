import React, { useState, useRef } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import classes from "../assets/styles/PageComponentsStyles/Settings/Subscription.module.css";

import showToast from "../helpers/showToast";
import { post, get } from "../server";
import {
  loggedInUser,
  isOwnerPackage,
  subscribedPackage,
  accountType,
} from "../utils/store";
import { TRANSACTION, GET_USER } from "../utils/constants/apiUrl";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import useFunctionsCount from "../hooks/useFunctionsCount";
import FormInput from "../components/Forms/FormInput";

const cardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "var(--primary_text_color)",
      fontSize: "14px",
      color: "#fff",

      "::placeholder": {
        color: "var(--primary_text_color)",
      },
    },
    invalid: {
      color: "#ff0000",
    },
  },
};

const CheckoutForm = ({
  handleCloseModal,
  selectedPackage,
  selectedSubscriptionType,
  couponData,
}) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [isOwnerPackageActive, setIsOwnerPackageActive] =
    useAtom(isOwnerPackage);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);
  const [isChecked, setIsChecked] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const checkbox = useRef();
  const { userActions } = useFunctionsCount();
  console.log("selectedPackagefgbsfrhjbgsjkg", selectedPackage);
  console.log("selectedSubscriptionType", selectedSubscriptionType);
  console.log("selectedPackage", selectedPackage);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedPackage.id === 1 && !isChecked) {
      setError("Please confirm the downgrade by checking the checkbox.");
      return;
    }

    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);

    try {
      // const { error: stripeError, token } = await stripe.createToken(
      //   cardElement
      // );

      // if (stripeError) {
      //   setIsLoading(false);
      //   showToast(false, stripeError.message);
      //   return;
      // }
      let stripeToken = null;
      if (
        selectedPackage.id !== 1 &&
        couponData?.package_price_after_discount !== 0
      ) {
        const { error: stripeError, token } = await stripe.createToken(
          cardElement
        );
        if (stripeError) {
          setIsLoading(false);
          showToast(false, stripeError.message);
          return;
        }
        stripeToken = token.id; // Assign token ID
      }
      // const amount =
      //   selectedSubscriptionType === "monthly"
      //     ? selectedPackage?.price_monthly
      //     : selectedPackage?.price_yearly;
      let originalAmount =
        selectedSubscriptionType === "monthly"
          ? selectedPackage?.price_monthly
          : selectedSubscriptionType === "yearly"
          ? selectedPackage?.price_yearly
          : selectedPackage.price;
      let amount =
        selectedSubscriptionType === "monthly" || selectedPackage.id === 5
          ? selectedPackage?.price_monthly
          : selectedSubscriptionType === "yearly"
          ? selectedPackage?.price_yearly
          : selectedPackage.price;
      console.log("amount", selectedPackage);
      let couponCode = null;
      let couponCodeId = null;
      let discount = 0;
      let discountAmount = 0;
      // console.log("couponDatahjavghvf", couponData);
      // console.log("couponData.success", couponData.coupons_applied_status);
      if (couponData && couponData.coupons_applied_status) {
        couponCode = couponData?.coupon_code_id;
        couponCodeId = couponData?.coupon_code_id;
        discount = couponData?.discount_percentage;
        discountAmount = couponData?.discount_amt;
        amount = couponData?.package_price_after_discount;
        originalAmount = couponData?.package_price;
      }

      let paymentObj = {
        stripe_token: stripeToken,
        amount: amount,
        auto_renew: checkbox.current.checked ? true : false,
        duration: selectedPackage?.duration,
        package_id: selectedPackage?.id,
        coupon_code: couponCode,
        coupon_code_id: couponCodeId,
        discount: discount,
        discount_amount: discountAmount,
        original_amount: originalAmount,
        subscription_type: selectedSubscriptionType,
      };

      if (amount === 0) {
        paymentObj = {
          amount: amount,
          auto_renew: checkbox.current.checked ? true : false,
          duration: selectedPackage?.duration,
          package_id: selectedPackage?.id,
          coupon_code: couponCode,
          coupon_code_id: couponCodeId,
          discount: discount,
          discount_amount: discountAmount,
          original_amount: originalAmount,
          subscription_type: selectedSubscriptionType,
        };
      }

      console.log("paymentObj", paymentObj);
      // Send payment method ID and payment intent id to your backend serve
      let { status, message } = await post(
        // `${TRANSACTION}/processPayment`,
        `${TRANSACTION}/${
          // selectedPackage.id !== 6
          [1, 2, 3].includes(selectedPackage.id)
            ? "individualUserProcessPayment"
            : "processPayment"
        }`,
        paymentObj,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (!status) {
        setIsLoading(false);
        showToast(false, message);
        return;
      }

      let paymentMessage = message;
      if (status) {
        let { status, message, data } = await get(
          GET_USER,
          Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
        );

        if (status) {
          if (Object.keys(data).length > 0) {
            data.token = loggedUser.token;
            setLoggedUser(data);
            userActions(data);
            setIsLoading(false);
            setError(null);
            handleCloseModal(true);
            showToast(true, paymentMessage);
            if (personalAccount) {
              navigate("/");
            } else {
              setIsOwnerPackageActive(true);
              setTimeout(() => {
                navigate("/team");
              }, 1000);
            }
          }
        } else {
          showToast(status, message);
          navigate("/");
        }
      }
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
      showToast(false, error.message);
      return;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {selectedPackage.id !== 1 ? (
          <>
            {couponData?.package_price_after_discount !== 0 ? (
              <div
                style={{
                  border: "2px solid var(--border_color)",
                  padding: "10px",
                  borderRadius: "50px",
                }}
                className="cardElementWrapper"
              >
                <CardElement id="card-element" options={cardElementOptions} />
              </div>
            ) : (
              ""
            )}
            <div className={classes.autoCheckbox}>
              <Form.Check
                type="checkbox"
                id={`default-checkbox`}
                label={`Auto Subscription`}
                // onChange={handleAutoCheck}
                ref={checkbox}
              />
            </div>
          </>
        ) : (
          <div className={classes.autoCheckbox}>
            <Form.Check
              type="checkbox"
              id={`downgrade-checkbox`}
              label={`Are you sure downgrade your package?`}
              ref={checkbox}
              onChange={() => setIsChecked(!isChecked)}
            />
          </div>
        )}
        <div>
          {error && <p className="errorText">{error}</p>}
          {/* {couponData?.package_price_after_discount !== 0 ? (
            <div className="text-center mt-4">
              <button
                type="submit"
                disabled={!stripe || isLoading}
                className={classes.payBtn}
              >
                {isLoading ? "Processing..." : "Pay"}
              </button>
            </div>
          ) : (
            <div className="text-center mt-4">
              <button
                type="submit"
                className={classes.payBtn}
                disabled={!isChecked}
              >
                Submit
              </button>
            </div>
          )} */}
          <div className="text-center mt-4">
            <button
              type="submit"
              disabled={
                !stripe || isLoading || (selectedPackage.id === 1 && !isChecked)
              }
              className={classes.payBtn}
            >
              {selectedPackage.id === 1 ||
              couponData?.package_price_after_discount == 0
                ? "Submit"
                : isLoading
                ? "Processing..."
                : "Pay"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default CheckoutForm;
