import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/Pages/Function/CustomURLSListing.module.css";
import ShowCustomURLSFunctions from "../../components/PageComponents/Function/ShowCustomURLSFunctions";
import Loader from "../../components/Loader";
import { useAtom } from "jotai";
import { get, deleteRecord, post, put } from "../../server";
import { CUSTOME_URL, FUNCTION_STATUS } from "../../utils/constants/apiUrl";
import {
  loggedInUser,
  teamIDCheck,
  subscribedPackage,
} from "../../utils/store";
import { toast } from "react-toastify";
import showToast from "../../helpers/showToast";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import CustomURLSAddEditModal from "../../components/Modals/CustomURLSAddEditModal";
import addcontact from "../../assets/images/home_page_images/addcontact.svg";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import FormInput from "../../components/Forms/FormInput";
import searchIcon from "../../assets/images/global_icons/searchicon.svg";
import { formatUrl } from "../../utils/functions/index";
import _ from "lodash";
import EmptyListingBanner from "../../components/EmptyListingBanner";
import { ImGift } from "react-icons/im";
import useFunctionsCount from "../../hooks/useFunctionsCount";
import functionTypes from "../../utils/constants/functionTypes";
import { FaCirclePlus } from "react-icons/fa6";
import FunctionsLimitModal from "../../components/Modals/FunctionsLimitModal";

const CustomURLSListing = () => {
  let emptyCustomUrlData = {
    isNew: true,
    id: "",
    title: "",
    url_path: "",
  };
  const navigate = useNavigate();
  const { teamId, userId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");

  const [loading, setLoading] = useState(true);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [customUrls, setCustomUrls] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyCustomUrlData);
  const [filterText, setFilterText] = useState("");
  const { compareLimits, updateCount, limitExceededForStarter } =
    useFunctionsCount();
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  // Handle main modal
  const handleShowModal = (obj) => {
    if (obj && Object.keys(obj).length) setSelectedData(obj);
    setShowModal(true);
  };

  const handleShowCustomUrlModal = async () => {
    if (purchasedPackage.id === 1) {
      limitExceededForStarter()
        .then((isLimitExceeded) => {
          console.log("Is limit exceeded:", isLimitExceeded);
          if (isLimitExceeded) {
            setShowLimitModal(true);
          } else {
            setShowModal(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      const isLimitExceeded = compareLimits(functionTypes.CUSTOM_URL);
      if (isLimitExceeded) {
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
      } else {
        setShowModal(true);
      }
    }
    // if (isLimitExceeded)
    //   return showToast(
    //     false,
    //     "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
    //   );

    // if (isLimitExceeded) {
    //   setShowLimitModal(true);
    //   return;
    // }
  };

  const handleSaveShowModal = async () => {
    setLoading(true);

    let payload = _.cloneDeep(selectedData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};
    if (selectedData.id) {
      result = await put(
        CUSTOME_URL + `/${selectedData.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (teamId && userId) {
        payload.team_id = teamId;
        payload.user_id = userId;
      }
      if (team_id && user_id) {
        payload.team_id = team_id;
        payload.user_id = user_id;
      }
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        CUSTOME_URL,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      setLoading(false);
      if (!selectedData.id) {
        updateCount(functionTypes.CUSTOM_URL, functionTypes.ADD);
      }
      let customUrlsArr = _.cloneDeep(customUrls);

      // On Update
      if (selectedData.id) {
        const index = customUrls.findIndex((el) => el.id == selectedData.id);
        customUrlsArr[index] = data;
      } else {
        customUrlsArr.unshift(data);
      }

      setCustomUrls(customUrlsArr);
      handleCloseModal();
    } else {
      setLoading(false);
    }

    showToast(status, message);
  };

  const handleCloseModal = () => {
    setSelectedData(emptyCustomUrlData);
    setShowModal(false);
    setShowLimitModal(false);
  };

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }

    let { status, message } = await deleteRecord(
      `${CUSTOME_URL}/${deleteId}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await getCustomURLS();
      updateCount(functionTypes.CUSTOM_URL, functionTypes.DELETE);
      setLoading(false);
    }
    setLoading(false);
  };

  // Get all custom urls
  const getCustomURLS = async () => {
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }

    if (userId || user_id) {
      queryParams += `${queryParams ? "&" : "?"}user_id=${userId || user_id}`;
    }
    let { status, message, data } = await get(
      `${CUSTOME_URL}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setCustomUrls(data);
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  const handleStatus = async (id) => {
    const allCustomUrls = customUrls.find((customUrl) => customUrl.id === id);
    let newStatus = allCustomUrls.status == true ? 0 : 1;
    const payload = {
      id: allCustomUrls.id,
      status: newStatus,
      type: allCustomUrls.function_type,
      team_id: allCustomUrls.team_id,
    };
    let { status, message } = await post(
      FUNCTION_STATUS,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await getCustomURLS();
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCustomURLS();
  }, [userTeamId]);

  const filteredItems = useMemo(() => {
    const filtered = customUrls.filter(
      (item) =>
        `${item.title || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.url_path || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, customUrls]);
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className={classes.mainWrapper}>
            <h6 className={classes.title}>
              <Link
                onClick={handleGoBack}
                style={{ color: "var(--heading_text_color)" }}
              >
                Functions
              </Link>
              {` > Custom URL’s`}
            </h6>

            {customUrls.length !== 0 ? (
              <FormInput
                icon={searchIcon}
                placeholder="Search..."
                value={filterText}
                handleChange={handleFilter}
              />
            ) : null}
          </div>

          {customUrls.length === 0 ? (
            <EmptyListingBanner
              headLineText="custom URLs"
              title="Create URL"
              onClick={handleShowCustomUrlModal}
              showAdd={
                loggedUser.role.includes("customer") ||
                loggedUser.role.includes("team_owner")
              }
            />
          ) : (
            <div className={classes.customURLSWrapper}>
              <div>
                <div className={classes.addWrapper}>
                  {(Object.keys(loggedUser).length !== 0 &&
                    loggedUser.role.includes("customer")) ||
                  loggedUser.role.includes("team_owner") ? (
                    <span onClick={handleShowCustomUrlModal}>
                      <div className={classes.addContentWrapper}>
                        {/* <img src={addcontact} alt="" /> */}
                        <FaCirclePlus />
                        <p>Create URL</p>
                      </div>
                    </span>
                  ) : null}
                </div>

                <ShowCustomURLSFunctions
                  loading={loading}
                  show={showModal}
                  customUrls={filteredItems}
                  handleShowModal={handleShowModal}
                  handleCloseModal={handleCloseModal}
                  handleDelete={handleDelete}
                  handleStatus={handleStatus}
                />
              </div>
            </div>
          )}

          {/* Custom url modals */}
          {showModal && (
            <CustomURLSAddEditModal
              show={showModal}
              data={selectedData}
              setSelectedData={setSelectedData}
              handleSaveShowModal={handleSaveShowModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div>
      )}

      {/* confirmation modal */}

      <ConfirmationModal
        show={showConfirmationModal}
        handleCloseModal={handleCloseConfirmationModal}
        handleConfirmDelete={handleConfirmDelete}
        confirmTitle="custom url"
      />
      <FunctionsLimitModal
        show={showLimitModal}
        handleClose={handleCloseModal}
        getData={getCustomURLS}
      />
    </>
  );
};

export default CustomURLSListing;
