// import { FaFacebookF, FaSoundcloud, FaTumblr } from "react-icons/fa";
// import { AiFillYoutube, AiFillInstagram, AiOutlineTwitter, AiFillAndroid, AiFillLinkedin, AiFillSkype, AiOutlineDropbox } from "react-icons/ai";
// import { BsSpotify, BsVimeo, BsPinterest, BsSnapchat, BsBehance, BsMessenger, BsWhatsapp, BsWordpress, BsGoogle } from "react-icons/bs";
import { ReactComponent as YoutubeIcon } from "../../assets/images/social_media_icons/Youtube 2.svg";
import { ReactComponent as DribbleIcon } from "../../assets/images/social_media_icons/Dribbble.svg";
import { ReactComponent as VimeoIcon } from "../../assets/images/social_media_icons/Vimeo.svg";
import { ReactComponent as SpotifyIcon } from "../../assets/images/social_media_icons/Spotify.svg";
import { ReactComponent as PinterestIcon } from "../../assets/images/social_media_icons/Pinterest.svg";
import { ReactComponent as AndroidIcon } from "../../assets/images/social_media_icons/Andriod.svg";
import { ReactComponent as FacebookIcon } from "../../assets/images/social_media_icons/Facebook.svg";
import { ReactComponent as SoundcloudIcon } from "../../assets/images/social_media_icons/Soundcloud.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/social_media_icons/Instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/images/social_media_icons/Linkedin.svg";
import { ReactComponent as TwitterIcon } from "../../assets/images/social_media_icons/TwitterLogo.svg";
import { ReactComponent as SnapchatIcon } from "../../assets/images/social_media_icons/Snapchat.svg";
import { ReactComponent as MessangerIcon } from "../../assets/images/social_media_icons/Messenger.svg";
import { ReactComponent as TumblrIcon } from "../../assets/images/social_media_icons/Tumblr.svg";
import { ReactComponent as BehanceIcon } from "../../assets/images/social_media_icons/Behance.svg";
import { ReactComponent as DropboxIcon } from "../../assets/images/social_media_icons/Dropbox.svg";
import { ReactComponent as SkpyeIcon } from "../../assets/images/social_media_icons/Skype.svg";
import { ReactComponent as PaypalIcon } from "../../assets/images/social_media_icons/Paypal.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/images/social_media_icons/Whatsapp.svg";
import { ReactComponent as WordpressIcon } from "../../assets/images/social_media_icons/Wordpress.svg";
import { ReactComponent as GoogleIcon } from "../../assets/images/social_media_icons/Google.svg";
import { ReactComponent as CustomIcon } from "../../assets/images/social_media_icons/Custom.svg";
import { ReactComponent as TikTokIcon } from "../../assets/images/social_media_icons/TikTok.svg";
import { ReactComponent as OtherIcon } from "../../assets/images/social_media_icons/OtherIcon.svg";

// CUSTOMIZE ANY UI BUTTON
import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  LoginSocialGithub,
  LoginSocialInstagram,
  LoginSocialLinkedin,
  LoginSocialMicrosoft,
  LoginSocialPinterest,
  LoginSocialTwitter,
  LoginSocialApple,
  LoginSocialTiktok,
} from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  GithubLoginButton,
  AmazonLoginButton,
  InstagramLoginButton,
  LinkedInLoginButton,
  MicrosoftLoginButton,
  TwitterLoginButton,
  AppleLoginButton,
} from "react-social-login-buttons";

const CardBackGroundImages = [
  {
    id: 1,
    img: "images/card-background/CardBg1.png",
  },
  {
    id: 2,
    img: "images/card-background/CardBg2.png",
  },
  {
    id: 3,
    img: "images/card-background/CardBg3.png",
  },
  {
    id: 4,
    img: "images/card-background/CardBg4.png",
  },
  {
    id: 5,
    img: "images/card-background/CardBg5.png",
  },
  {
    id: 6,
    img: "images/card-background/CardBg6.png",
  },
  {
    id: 7,
    img: "images/card-background/CardBg7.png",
  },
  {
    id: 8,
    img: "images/card-background/CardBg8.png",
  },
  {
    id: 9,
    img: "images/card-background/CardBg9.png",
  },
];

const Colors = [
  {
    id: 1,
    bgColor: "#22A081",
  },
  {
    id: 2,
    bgColor: "#E35252",
  },
  {
    id: 3,
    bgColor: "#E4A56B",
  },
  {
    id: 4,
    bgColor: "#7000B5",
  },
  {
    id: 5,
    bgColor: "#EEB8C9",
  },
  {
    id: 6,
    bgColor: "#DFF87C",
  },
  {
    id: 7,
    bgColor: "#34D844",
  },
  {
    id: 8,
    bgColor: "#3B58BD",
  },
  {
    id: 9,
    bgColor: "#FF5099",
  },
];
const AddMoreCustomUrls = [
  {
    id: 1,
    icon: <FacebookIcon />,
    url: "https://www.facebook.com/",
    name: "Facebook",
  },
  {
    id: 2,
    icon: <YoutubeIcon />,
    url: "https://www.linkedin.com/",
    name: "Youtube",
  },
  {
    id: 3,
    icon: <InstagramIcon />,
    url: "https://www.instagram.com/",
    name: "Instagram",
  },
  {
    id: 4,
    icon: <SpotifyIcon />,
    url: "https://twitter.com/",
    name: "Spotify",
  },
  {
    id: 5,
    icon: <VimeoIcon />,
    url: "https://www.facebook.com/",
    name: "Vimeo",
  },
  {
    id: 6,
    icon: <TwitterIcon />,
    url: "https://www.linkedin.com/",
    name: "Twitter",
  },
  {
    id: 7,
    icon: <DribbleIcon />,
    url: "https://www.instagram.com/",
    name: "Dribble",
  },
  {
    id: 8,
    icon: <SoundcloudIcon />,
    url: "https://twitter.com/",
    name: "SoundCloud",
  },
  {
    id: 9,
    icon: <PinterestIcon />,
    url: "https://www.facebook.com/",
    name: "Pinterest",
  },
  {
    id: 10,
    icon: <AndroidIcon />,
    url: "https://www.linkedin.com/",
    name: "Android",
  },
  {
    id: 11,
    icon: <LinkedinIcon />,
    url: "https://www.instagram.com/",
    name: "LinkedIn",
  },
  {
    id: 12,
    icon: <SnapchatIcon />,
    url: "https://twitter.com/",
    name: "SnapChat",
  },
  {
    id: 13,
    icon: <BehanceIcon />,
    url: "https://www.facebook.com/",
    name: "Behance",
  },
  {
    id: 14,
    icon: <TumblrIcon />,
    url: "https://www.linkedin.com/",
    name: "Tumblr",
  },
  {
    id: 15,
    icon: <MessangerIcon />,
    url: "https://www.instagram.com/",
    name: "Messenger",
  },
  {
    id: 16,
    icon: <WhatsappIcon />,
    url: "https://twitter.com/",
    name: "Whatsapp",
  },
  {
    id: 17,
    icon: <SkpyeIcon />,
    url: "https://www.facebook.com/",
    name: "Skype",
  },
  {
    id: 18,
    icon: <DropboxIcon />,
    url: "https://www.linkedin.com/",
    name: "Dropbox",
  },
  {
    id: 19,
    icon: <WordpressIcon />,
    url: "https://www.instagram.com/",
    name: "Wordpress",
  },
  {
    id: 20,
    icon: <GoogleIcon />,
    url: "https://www.instagram.com/",
    name: "Google",
  },
  {
    id: 21,
    icon: <TikTokIcon />,
    url: "https://www.tiktok.com/en/",
    name: "Tiktok",
  },
  {
    id: 22,
    icon: <OtherIcon />,
    url: "https://www.instagram.com/",
    name: "Custom",
  },
];

export { CardBackGroundImages, Colors, AddMoreCustomUrls };
