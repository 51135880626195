import React from "react";

const Paymenticon = ({ primaryColor, secondaryColor }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="128.000000pt"
    height="128.000000pt"
    viewBox="0 0 128.000000 128.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
      fill={primaryColor}
      stroke="none"
    >
      <path
        d="M711 997 c-85 -72 -150 -132 -144 -134 12 -4 405 116 417 127 8 8
-99 141 -113 139 -3 0 -75 -59 -160 -132z"
        fill={secondaryColor}
      />
      <path
        d="M890 926 l-194 -61 118 -3 c64 -1 166 -1 225 0 l107 3 -18 63 c-10
34 -24 62 -31 61 -7 -1 -100 -29 -207 -63z"
        fill={secondaryColor}
      />
      <path
        d="M46 814 c-8 -20 -8 -628 0 -648 6 -14 67 -16 594 -16 527 0 588 2
594 16 8 20 8 628 0 648 -6 14 -67 16 -594 16 -527 0 -588 -2 -594 -16z m1029
-94 c9 -17 27 -35 40 -40 l25 -10 0 -179 0 -179 -26 -6 c-16 -4 -29 -17 -36
-36 l-10 -30 -428 0 -428 0 -10 30 c-7 19 -20 32 -36 36 l-26 6 0 179 0 179
25 10 c13 5 31 23 40 40 l16 30 419 0 419 0 16 -30z"
        fill={secondaryColor}
      />
      <path
        d="M229 696 c-6 -13 -22 -30 -35 -37 -24 -12 -24 -14 -24 -171 0 -136 2
-158 15 -158 9 0 24 -13 33 -30 l17 -30 138 0 137 0 -42 38 c-56 50 -78 101
-78 182 0 81 25 139 83 191 l42 39 -138 0 c-135 0 -138 0 -148 -24z"
      />
      <path
        d="M584 709 c-50 -12 -118 -68 -144 -120 -26 -50 -26 -140 -1 -194 78
-168 324 -168 402 0 25 54 25 144 -1 194 -28 57 -93 108 -152 120 -27 6 -52
11 -56 10 -4 -1 -25 -5 -48 -10z m66 -57 c0 -10 14 -28 30 -40 19 -15 30 -31
30 -47 0 -32 -18 -32 -29 -1 -20 52 -85 38 -79 -17 3 -26 8 -32 38 -40 51 -13
70 -34 70 -78 0 -30 -6 -42 -30 -59 -16 -12 -30 -30 -30 -41 0 -10 -4 -19 -10
-19 -5 0 -10 9 -10 19 0 11 -13 29 -30 41 -22 16 -30 29 -30 51 0 35 16 30 31
-8 7 -20 15 -24 41 -21 30 3 33 6 33 38 0 32 -4 36 -39 48 -56 18 -66 29 -66
73 0 31 6 43 30 61 17 12 30 30 30 40 0 10 5 18 10 18 6 0 10 -8 10 -18z"
        fill={secondaryColor}
      />
      <path
        d="M810 679 c57 -55 80 -109 80 -188 0 -79 -24 -134 -81 -185 l-39 -36
137 0 138 0 17 30 c9 17 24 30 33 30 13 0 15 22 15 159 0 151 -1 160 -20 166
-11 4 -27 20 -35 36 l-16 29 -135 0 -136 0 42 -41z"
      />
    </g>
  </svg>
);

export default Paymenticon;
