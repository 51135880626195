import React, { useState, useEffect } from "react";
import classes from "../assets/styles/Pages/Function/ViewFormBuilder.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import playstore from "../assets/images/footer_icons/playstore.svg";
import appstore from "../assets/images/footer_icons/appstore.svg";
import Loader from "../components/Loader";
import Image from "react-bootstrap/Image";
import { LEAD_ADD, IMAGE_UPLOAD } from "../utils/constants/apiUrl";
import { get, post } from "../server";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import showToast from "../helpers/showToast";
import baseURL from "../utils/constants/baseUrl";
import FormInput from "../components/Forms/FormInput";
import { Link, useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import formatPhoneNumber from "../helpers/formatPhoneNumber";
import { SHARE_QR } from "../utils/constants/common";
import baseUrl from "../utils/constants/baseUrl";
import ImageUploadSquare from "../components/ImageUploadSquare";
import { Modal, Form } from "react-bootstrap";
import crossicon from "../assets/images/global_icons/crossicon.svg";
import { BsImageFill } from "react-icons/bs";
import { Colors } from "../utils/data/CardFunctionsData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pictureicon from "../assets/images/function_page_icons/picIcon.svg";

const PublicFormBuilder = ({ formBuilderData }) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [VCFData, setVCFData] = useState(formBuilderData);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [image, selectImage] = useState("");
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [currentUploadField, setCurrentUploadField] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const countryCodes = require("country-codes-list");

  const generateCountryOptions = () => {
    const myCountryCodesObject = countryCodes.customList(
      "countryCode",
      "[{countryCode}] {countryNameEn}: +{countryCallingCode}"
    );
    // const countryOptions = Object.entries(myCountryCodesObject).map(
    //   ([code, label]) => ({
    //     value: code,
    //     label: label.replace(`[${code}] `, ""),
    //   })
    // );
    const countryOptions = Object.entries(myCountryCodesObject).map(
      ([code, label]) => {
        const callingCode = label.match(/\+(\d+)/)[1];
        return {
          value: callingCode,
          label: label.replace(`[${code}] `, ""),
        };
      }
    );
    return countryOptions;
  };
  // const handleInputChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleUploadModalOpen = (field) => {
    setCurrentUploadField(field);
    setUploadModalOpen(true);
  };

  const handleUploadModalClose = () => {
    setCurrentUploadField(null);
    setUploadModalOpen(false);
  };

  const renderFormField = (field) => {
    switch (field.type) {
      case "heading":
        return <h4>{field.label}</h4>;
      case "paragraph":
        return <p>{field.label}</p>;
      case "line":
        return (
          <hr
            style={{
              borderTopColor: "var(--border_color)",
              opacity: "1",
            }}
          />
        );
      case "list":
        return (
          <div>
            {/* <h5>{field.label}</h5> */}
            <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
              {field.formBuilderFieldData.map((item) => (
                <li key={item.id}>{item.data}</li>
              ))}
            </ul>
          </div>
        );
      case "inputbox":
        return (
          <FormInput
            type="text"
            label={field?.label}
            name={field?.label}
            field_type={field?.field_type}
            onChange={handleInputChange}
          />
        );
      case "textarea":
        return (
          <>
            <label>{field.label}</label>
            <textarea
              className="globalTextArea"
              type="text"
              name={field?.label}
              field_type={field?.field_type}
              onChange={handleInputChange}
            ></textarea>
          </>
        );
      case "radio":
        return (
          <div>
            <h6>{field.label}</h6>
            {field.formBuilderFieldData.map((option) => (
              <div key={option.id}>
                <input
                  type="radio"
                  id={option.id}
                  name={field.label}
                  value={option.data}
                  onChange={handleInputChange}
                />
                <label htmlFor={option.id} className={classes.inputLabel}>
                  {option.data}
                </label>
              </div>
            ))}
          </div>
        );
      case "checkbox":
        return (
          <div>
            <h6>{field.label}</h6>
            {field.formBuilderFieldData.map((option) => (
              <div key={option.id}>
                <input
                  type="checkbox"
                  id={option.id}
                  name={field.label}
                  value={option.data}
                  onChange={handleInputChange}
                />
                <label htmlFor={option.id} className={classes.inputLabel}>
                  {option.data}
                </label>
              </div>
            ))}
          </div>
        );
      case "color":
        return (
          <div>
            <div>
              <label>{field.label}</label>
              <div className="colorBorder">
                {field.formBuilderFieldData.map((option) => (
                  <div
                    key={option.id}
                    className="form-check form-check-inline form-check-color"
                  >
                    <input
                      type="radio"
                      id={option.id}
                      name={field.label}
                      value={option.data}
                      onChange={handleInputChange}
                      className="form-check-input"
                    />
                    <label htmlFor={option.id} className={classes.inputLabel}>
                      <div
                        style={{ backgroundColor: option.data }}
                        className="colorBox"
                      />
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="colorBorder">
              {Colors.map((color) => {
                const { id, bgColor } = { ...color };
                return (
                  <div
                    className="form-check form-check-inline form-check-color"
                    key={`color${id}`}
                  >
                    <input
                      type="radio"
                      id={`color${id}`}
                      class="form-check-input"
                      name="color"
                      value={bgColor}
                      onChange={handleInputChange}
                    />
                    <label>
                      <div
                        style={{ backgroundColor: bgColor }}
                        className="colorBox"
                      />
                    </label>
                  </div>
                );
              })}
            </div> */}
          </div>
        );
      case "preDropdown":
        return (
          <div>
            <div className={classes.selectWrapper}>
              <label>{field.label}</label>
              <div className="input-group globalInputWithIcon">
                <select
                  style={{ border: "0" }}
                  name={field.label}
                  className="form-select customSelect"
                  onChange={handleInputChange}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="home">Home</option>
                  <option value="mobile">Mobile</option>
                  <option value="office">Office</option>
                </select>
              </div>
            </div>
          </div>
        );
      case "dropdown":
        return (
          <div>
            <div className={classes.selectWrapper}>
              <label>{field.label}</label>
              <div className="input-group globalInputWithIcon">
                <select
                  style={{ border: "0" }}
                  name={field.label}
                  className="form-select customSelect"
                  onChange={handleInputChange}
                >
                  {field.formBuilderFieldData.map((item) => (
                    <option key={item.id} value={item.data}>
                      {item.data}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      case "countryList":
        return (
          <div>
            <div className={classes.selectWrapper}>
              <label>{field.label}</label>
              <div className="input-group globalInputWithIcon">
                <select
                  style={{ border: "0" }}
                  name={field.label}
                  className="form-select customSelect"
                  onChange={handleInputChange}
                >
                  {generateCountryOptions().map((country, index) => (
                    <option key={index} value={country.value}>
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      case "date":
        return (
          <div>
            <label className={classes.formLabel}>{field.label}</label>
            {/* <div className="input-group globalInputWithIcon">
              <input
                type="date"
                className="form-control"
                name={field.label}
                onChange={handleInputChange}
                dateFormat="MM/dd/yyyy"
              />
            </div> */}
            <DatePicker
              selected={formData[field.label]}
              onChange={(date) => handleDateInputChange(field.label, date)}
              name={field.label}
              dateFormat="MM/dd/yyyy"
              className="dobInput profile"
              placeholderText="MM/dd/yyyy"
              showYearDropdown
              isClearable
              //maxDate={new Date()}
              yearDropdownItemNumber={110}
              scrollableYearDropdown
            />
          </div>
        );
      case "link":
        return (
          <div>
            <label className={classes.formLabel}>{field.label}</label>
            <input type="url" name={field.label} onChange={handleInputChange} />
          </div>
        );
      case "upload":
        return (
          <div>
            <label className={classes.formLabel}>{field.label}</label>
            <Image
              className={classes.profileImage}
              src={
                uploadedImageUrl[field.label]
                  ? baseUrl.PUBLIC_URL + uploadedImageUrl[field.label]
                  : pictureicon
              }
              roundedCircle={true}
            />
            <button
              onClick={() => handleUploadModalOpen(field)}
              className="btn btn-primary ms-4"
            >
              Upload
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const handleSave = async () => {
    setLoading(true);

    if (image && typeof image === "object") {
      let formDataObj = new FormData();
      formDataObj.append("image_type", "form_builder");
      formDataObj.append("image", image);

      let { status, message, data } = await post(
        IMAGE_UPLOAD,
        formDataObj,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        setUploadedImageUrl((prevUploadedImages) => ({
          ...prevUploadedImages,
          [currentUploadField.label]: data,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          [currentUploadField.label]: data,
        }));
        showToast(status, message);
        setUploadModalOpen(false);
      } else {
        showToast(status, message);
      }
    }
  };
  const handleDateInputChange = (fieldName, date) => {
    setFormData({
      ...formData,
      [fieldName]: date,
    });
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked
          ? [...(prevFormData[name] || []), value]
          : (prevFormData[name] || []).filter((item) => item !== value),
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const userLeadData = formBuilderData.formBuilderField.map((field) => {
      let formDataItem;
      if (field.type === "heading" || field.type === "paragraph") {
        formDataItem = {
          form_builder_field_id: field.id,
          label: field.label,
          type: field.type,
          data: field.label,
          field_type: field.field_type,
        };
      } else if (field.type === "list") {
        formDataItem = {
          form_builder_field_id: field.id,
          label: field.label,
          type: field.type,
          data: field.formBuilderFieldData.map((item) => item.data).join(", "),
          field_type: field.field_type,
        };
      } else if (field.type === "checkbox") {
        formDataItem = {
          form_builder_field_id: field.id,
          label: field.label,
          type: field.type,
          data: formData[field.label].join(", ") || "",
          field_type: field.field_type,
        };
      } else if (field.type === "line") {
        formDataItem = {
          form_builder_field_id: field.id,
          label: field.label,
          type: field.type,
          data: "true",
          field_type: field.field_type,
        };
      } else {
        formDataItem = {
          form_builder_field_id: field.id,
          label: field.label,
          type: field.type,
          data: formData[field.label] || "",
          field_type: field.field_type,
        };
      }

      return formDataItem;
    });

    const payload = {
      form_builder_id: formBuilderData.id,
      userLeadData: userLeadData,
    };
    let { status, message, data } = await post(
      LEAD_ADD,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      console.log("data", data);
      showToast("success", "Form submitted successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      showToast(status, message);
    }
  };

  return (
    <>
      <div className="container">
        <div className="py-5">
          <div className="row justify-content-center">
            <div className="col-md-7 col-12">
              <div className={classes.mainWrapper}>
                <h6 className={classes.title}>{formBuilderData.name}</h6>
                <div className={classes.cardWrapper}>
                  <div className={classes.generatedForm}>
                    {formBuilderData.formBuilderField.map((field) => (
                      <div key={field.id} className="mb-4">
                        {renderFormField(field)}
                      </div>
                    ))}
                    <div className="text-center mt-4">
                      <button
                        type="submit"
                        className="submitButton"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>

                <div className={classes.cardFooter}>
                  <div className="d-flex align-items-center gap-3 justify-content-center">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.holguinmedia.contactapp"
                      target="_blank"
                    >
                      <img src={playstore} alt="" />
                    </a>
                    <a
                      href="https://apps.apple.com/pk/app/contact-share-digital-info/id1662851237"
                      target="_blank"
                    >
                      <img src={appstore} alt="" />
                    </a>
                  </div>
                  <p>
                    © {new Date().getFullYear()} Contact Co, All rights
                    reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        className={`customModal uploadModal`}
        size="sm"
        show={uploadModalOpen}
      >
        <Modal.Header className={classes.mediaModalHeader}>
          <span>Upload</span>
          <img
            src={crossicon}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleUploadModalClose}
          />
        </Modal.Header>

        <Modal.Body className={classes.mediaModalBody}>
          <div className="text-center">
            <div
              className={`d-flex justify-content-center align-items-center flex-column position-relative ${classes.imageUpload}`}
            >
              {currentUploadField && (
                <input
                  type="file"
                  accept="image/*"
                  name={currentUploadField.label}
                  field-type={currentUploadField.field_type}
                  onChange={(e) => selectImage(e.target.files[0])}
                />
              )}
            </div>
            <button
              className={`${classes.saveBtn} primary_button mb-3 mt-0`}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <style type="text/css">
        {`
        .uploadModal{
          color: var(--heading_text_color);
        }
        .colorBorder{
          border: 2px solid var(--border_color);
          border-radius: 60px;
          padding: 9px 15px 0;
        }
        .form-check-color{
          padding-left: 0;
          position:relative;
        }
        .form-check-color .form-check-input{
          float: none;
          margin-left: 0;
          position: absolute;
          opacity: 0;
          margin-top: 0;
          width: 20px;
          height: 20px;
          left: 0;
          right: 0;
          margin: auto;
        }
        .form-check-color .form-check-input + label{margin-bottom:0}
        .form-check-color .form-check-input + label .colorBox {
          width:20px;
          height:20px;
          border-radius:50%;
          border: 3px solid transparent;
        } 
        .form-check-color .form-check-input:checked + label .colorBox {
          width:20px;
          height:20px;
          border-radius:50%;
          border: 3px solid var(--border_color);
        } 
        `}
      </style>
    </>
  );
};

export default PublicFormBuilder;
