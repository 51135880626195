import React, { useState, useEffect, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import DataTable, { createTheme } from "react-data-table-component";
import { useAtom } from "jotai";
import classes from "../../assets/styles/Contact.module.css";
import {
  INVITATION,
  PENDING_MEMBER_PAYMENT,
} from "../../utils/constants/apiUrl";
import { get, post, deleteRecord } from "../../server";
import showToast from "../../helpers/showToast";
import { loggedInUser, teamIDCheck, isOwnerPackage } from "../../utils/store";
import Loader from "../../components/Loader";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import FormInput from "../../components/Forms/FormInput";
import searchIcon from "../../assets/images/global_icons/searchicon.svg";
import PendingPaymentModal from "../../components/Modals/PendingPaymentModal";
import formatPhoneNumber from "../../helpers/formatPhoneNumber";
import _ from "lodash";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// Load Stripe Data
const stripePromise = loadStripe(
  // "pk_test_51OZ7XQSBnxMCCnjEI1odHcAyBmW5WZd48fFUxnvHPpg2LDNKRGGCOJ3sU78SW9vi5wOmuLrwX2qxf8slIZpXFukR00DuK51ScU"
  "pk_live_51LsD48ErALx3fhV7pL6Gg2Wd2ymytgrKjj1tjb9cJICFlMAO24seG1sVDFTtKBFuOeD9luN1KSlS7Ho3xuY9tRZt00GZDEzFrx"
);

// Stripe OPtions
const elementsOptions = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
  locale: "en",
  placeholder: {
    number: "Card number",
    cvc: "CVC",
    expiry: "MM/YY",
  },
  style: {
    base: {
      fontSize: "16px",
      color: "#fff",
      "::placeholder": {
        color: "#fff",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const emptyInvitationData = {
  email: "",
};

const PendingMemberPayments = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [pendingMembers, setPendingMember] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyInvitationData);
  const [isOwnerPackageActive, setIsOwnerPackageActive] =
    useAtom(isOwnerPackage);
  console.log("isOwnerPackageActive", isOwnerPackageActive);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const handleOpenModal = (id) => {
    if (!isOwnerPackageActive) {
      showToast(
        false,
        "You can not subscribe because your team is not subscribed to any package"
      );
      return;
    }
    setShowModal(true);
    setSelectedId(id);
  };

  const handleCloseModal = () => {
    setSelectedData({});
    setShowModal(false);
  };

  const getPendingMember = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      `${PENDING_MEMBER_PAYMENT}/${userTeamId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setPendingMember(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPendingMember();
  }, []);

  const filteredItems = useMemo(() => {
    const filtered = pendingMembers.filter(
      (item) =>
        `${item.first_name || ""} ${item.last_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.email || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.city || ""}`.toLowerCase().includes(filterText.toLowerCase()) ||
        `${item.country || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.country_phone ? `+${item.country_phone}` : ""}${
          item.phone_number || ""
        }`.includes(filterText)
    );

    return filtered;
  }, [filterText, pendingMembers]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Name",
      width: "200px",
      cell: (row) => (
        <p className="globalDetail">
          {row.first_name} {row.last_name}
        </p>
      ),
    },
    {
      name: "Email",
      width: "200px",
      cell: (row) => (
        <a
          href={`mailto:${row.email}`}
          className="globalDetail"
          style={{ display: "block" }}
        >
          {row.email}
        </a>
      ),
    },
    {
      name: "Phone #",
      width: "150px",
      cell: (row) => (
        <>
          {row.phone_number ? (
            <a
              href={`tel:${
                row.country_phone
                  ? "+" +
                    row.country_phone +
                    " " +
                    formatPhoneNumber(row.phone_number)
                  : row.phone_number || ""
              }`}
              className="globalDetail"
            >
              {row.country_phone
                ? "+" +
                  row.country_phone +
                  " " +
                  formatPhoneNumber(row.phone_number)
                : row.phone_number || ""}
            </a>
          ) : (
            <p className="globalDetail">-</p>
          )}
        </>
      ),
    },
    {
      width: "200px",
      name: "City, Country",
      cell: (row) => (
        <>
          {row.city || row.country ? (
            <a
              href={`http://maps.google.com/?q=${row.city || ""}${
                row.city && row.country ? ", " : ""
              }${row.country || " "}`}
              className="globalDetail"
              target="_blank"
              rel="noreferrer"
            >
              {row.city || ""}
              {row.city && row.country ? "," : ""} {row.country}
            </a>
          ) : (
            <p className="globalDetail">-</p>
          )}
        </>
      ),
    },

    {
      name: "Action",
      width: "20%",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <span
                className="dropdown-item"
                onClick={() => handleOpenModal(row.id)}
              >
                Subscribe
              </span>
            </li>
            {/* <li>
              <span
                className="dropdown-item"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </span>
            </li> */}
          </ul>
        </div>
      ),
    },
  ];
  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${INVITATION}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await getPendingMember();
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div className="">
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center gap-3">
              <p className="pageTitle">
                Members with pending subscriptions
                <span>({pendingMembers.length})</span>
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-2">
              <FormInput
                icon={searchIcon}
                placeholder="Search..."
                value={filterText}
                handleChange={handleFilter}
              />
            </div>
          </div>

          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              theme="solarized"
              pagination
            />
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="invitation"
          />
        </div>
      ) : (
        <Loader />
      )}

      <Elements stripe={stripePromise} options={elementsOptions}>
        <PendingPaymentModal
          show={showModal}
          stripePromise={stripePromise}
          data={selectedData}
          setSelectedData={setSelectedData}
          userTeamId={userTeamId}
          handleCloseModal={handleCloseModal}
          loading={modalLoading}
          selectedId={selectedId}
          pendingMembers={pendingMembers}
          setPendingMember={setPendingMember}
        />
      </Elements>
    </>
  );
};

export default PendingMemberPayments;
