import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { get, post, put } from "../../../server";
import { TUTORIAL, IMAGE_UPLOAD } from "../../../utils/constants/apiUrl";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../../components/Loader";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
import AddEditTutorialSchema from "../../../utils/SchemasValidation/AddEditTutorialSchema";
import ValidateSchema from "../../../helpers/ValidateSchema";
import Button from "react-bootstrap/Button";
import ImageUploadSquare from "../../../components/ImageUploadSquare";
import baseUrl from "../../../utils/constants/baseUrl";
import { formatUrl } from "../../../utils/functions";
import _ from "lodash";

const AddEditTutorial = () => {
  let emptyObj = {
    title: "",
    url: "",
    image: "",
    start_up_video: false,
  };

  const { tutorialId } = useParams();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [tutorial, setTutorial] = useState(emptyObj);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [image, selectImage] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "start_up_video") {
      setTutorial({ ...tutorial, [name]: e.currentTarget.checked });
    } else {
      setTutorial({ ...tutorial, [name]: value });
    }
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(tutorial, AddEditTutorialSchema);
    if (!image) {
      newErrors.image = "Please select image";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    // Upload Image
    if (image && typeof image == "object") {
      let formDataObj = new FormData();
      formDataObj.append("image_type", "tutorial");
      formDataObj.append("image", image);

      let { status, message, data } = await post(
        IMAGE_UPLOAD,
        formDataObj,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (!status) {
        showToast(status, message);
        setLoading(false);
        return false;
      } else {
        Object.assign(tutorial, { image: data });
      }
    }

    let payload = _.cloneDeep(tutorial);
    payload.url = formatUrl(payload.url);
    if (tutorialId) {
      let { status, message, data } = await put(
        TUTORIAL + `/${tutorialId}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        navigate("/tutorial");
      }
      showToast(status, message);
    } else {
      let { status, message, data } = await post(
        TUTORIAL,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        navigate("/tutorial");
      }
      showToast(status, message);
    }
    setLoading(false);
  };

  const loadSingleTutorial = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      TUTORIAL + `/${tutorialId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setTutorial(data);
      selectImage(data.image);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (tutorialId) loadSingleTutorial();
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <h6> {tutorialId ? "Edit" : "Add"} Tutorial </h6>
          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className={classes.inputsWrapper}>
              <div>
                <input
                  type="text"
                  placeholder="Tutorial Title"
                  name="title"
                  value={tutorial.title}
                  className={
                    errors?.title ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.title || ""}</p>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Enter Url"
                  name="url"
                  value={tutorial.url}
                  className={
                    errors?.url ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.url || ""}</p>
              </div>
              <div>
                <ImageUploadSquare
                  selectedImage={
                    typeof image == "object"
                      ? image
                      : baseUrl.PUBLIC_URL + image
                  }
                  setSelectedImage={selectImage}
                  title="Background Image"
                />
                <p className="errorText">{errors?.image || ""}</p>
              </div>
              <div>
                <label className={classes.switchTitle}>App Walk Through</label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    name="start_up_video"
                    checked={tutorial.start_up_video}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="formBottom">
              <Button
                className="submitButton"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AddEditTutorial;
