import React, { useState } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Home/AddNewCard.module.css";
import addnewcard from "../../../assets/images/home_page_images/addnewcard.svg";
import useFunctionsCount from "../../../hooks/useFunctionsCount";
import functionTypes from "../../../utils/constants/functionTypes";
import showToast from "../../../helpers/showToast";
import { useNavigate } from "react-router-dom";
import FunctionsLimitModal from "../../Modals/FunctionsLimitModal";
import { subscribedPackage } from "../../../utils/store";
import { useAtom } from "jotai";

const AddNewCard = ({ teamId, userId, team_id, user_id, getData }) => {
  const { compareLimits, updateCount, limitExceededForStarter } =
    useFunctionsCount();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);

  // const handleNavigation = () => {
  //   const res = compareLimits(functionTypes.CARD);
  //   // if (res)
  //   //   return showToast(
  //   //     false,
  //   //     "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
  //   //   );
  //   if (res) {
  //     setShowModal(true);
  //     return;
  //   }

  //   let link =
  //     teamId && userId
  //       ? `/team/${teamId}/teamMembers/member/${userId}/function/cardfunctions`
  //       : `/function/cardfunctions${
  //           team_id && user_id ? `?team_id=${team_id}&user_id=${user_id}` : ""
  //         }`;
  //   navigate(link);
  // };

  const handleNavigation = () => {
    if (purchasedPackage.id === 1) {
      limitExceededForStarter()
        .then((isLimitExceeded) => {
          console.log("Is limit exceeded:", isLimitExceeded);
          if (isLimitExceeded) {
            setShowModal(true);
          } else {
            navigate(
              teamId && userId
                ? `/team/${teamId}/teamMembers/member/${userId}/function/cardfunctions`
                : `/function/cardfunctions${
                    team_id && user_id
                      ? `?team_id=${team_id}&user_id=${user_id}`
                      : ""
                  }`
            );
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      const res = compareLimits(functionTypes.CARD);
      console.log("res", res);
      if (res) {
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
      } else {
        navigate(
          teamId && userId
            ? `/team/${teamId}/teamMembers/member/${userId}/function/cardfunctions`
            : `/function/cardfunctions${
                team_id && user_id
                  ? `?team_id=${team_id}&user_id=${user_id}`
                  : ""
              }`
        );
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <a onClick={handleNavigation}>
        <div className={classes.cardWrapper}>
          <img src={addnewcard} alt="" />
          <p>Add New Card</p>
        </div>
      </a>
      <FunctionsLimitModal
        show={showModal}
        handleClose={handleCloseModal}
        getData={getData}
      />
    </>
  );
};

export default AddNewCard;
