import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Function/Card.module.css";
import Image from "react-bootstrap/Image";
import baseURL from "../../../utils/constants/baseUrl";
import { FiMail } from "react-icons/fi";
import { MdLocationOn } from "react-icons/md";
import { BsGlobe, BsFillTelephoneFill, BsFillShareFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import { GrClose } from "react-icons/gr";
import { MdClose } from "react-icons/md";
import { GiShare } from "react-icons/gi";
import { CARD_VCF_FILE_URL } from "../../../utils/constants/apiUrl";
import { MdOutlineQrCode } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { post } from "../../../server/index";
import pictureicon from "../../../assets/images/function_page_icons/pictureicon.svg";
import companylogo from "../../../assets/images/function_page_icons/companylogo.svg";
import contacticon from "../../../assets/images/function_page_icons/contacticon.svg";
import playstore from "../../../assets/images/footer_icons/playstore.svg";
import appstore from "../../../assets/images/footer_icons/appstore.svg";

import qrcode from "../../../assets/images/function_page_icons/qrcode.svg";
import { ReactComponent as HomePhoneIcon } from "../../../assets/images/function_page_icons/HomePhone.svg";
import { ReactComponent as OfficePhoneIcon } from "../../../assets/images/function_page_icons/OfficePhone.svg";
import locationIcon from "../../../assets/images/function_page_icons/location-icon.svg";
import mailIcon from "../../../assets/images/function_page_icons/mail-icon.svg";
import websiteIcon from "../../../assets/images/function_page_icons/website-icon.svg";
import phoneIcon from "../../../assets/images/function_page_icons/phone-icon.svg";
import cardEdit from "../../../assets/images/card-edit.svg";
import formatPhoneNumber from "../../../helpers/formatPhoneNumber";
import showToast from "../../../helpers/showToast";
import _ from "lodash";

const skeletonColor = "#E8E8E8";

const tagBorderColors = {
  "#22A081": "#ABEDDD",
  "#E35252": "#C52020",
  "#E4A56B": "#DB8A3D",
  "#7000B5": "#D899FF",
  "#EEB8C9": "#E184A1",
  "#DFF87C": "#B1DA0B",
  "#34D844": "#21AB2F",
  "#3B58BD": "#9EACE0",
  "#FF5099": "#FF1A79",
};

export default function Card({
  data,
  showSidebar = false,
  handleClose,
  handleQrOpen,
  handleShareOpen,
  shareOpen,
  showCountryCodeSeparate,
  inViewMode = false,
  hideHeader = false,
  publicMode,
  selectedProfileShape,
  selectedLogoShape,
}) {
  console.log("datasfcsdf", data);
  const navigate = useNavigate();
  const { id, uuid } = useParams();
  const [address, setAddress] = useState("");
  //console.log(content_view_type);
  const [showFullText, setShowFullText] = useState(false);
  const displayText = showFullText
    ? data?.description
    : data?.description?.length > 60
    ? data?.description?.slice(0, 60) + "..."
    : data?.description;

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  let showCountryCodeSeparateUpdate = showCountryCodeSeparate;
  let checkPath = window.location.pathname;
  if (checkPath.includes("viewcontact") || checkPath.includes("share")) {
    showCountryCodeSeparateUpdate = true;
  }
  useEffect(() => {
    if (data?.city || data?.state || data?.country) {
      let addressArr = [];
      if (data?.address) addressArr.push(data?.address);
      if (data?.city) addressArr.push(data?.city);
      if (data?.state) addressArr.push(data?.state);
      if (data?.country) addressArr.push(data?.country);
      handleAddress(addressArr);
    }
  }, [data?.address, data?.city, data?.state, data?.country]);

  const handleAddress = (data) => {
    let commaAddress = data.join(", ");
    setAddress(commaAddress);
  };

  const handleVcfFile = async () => {
    // setLoading(true);

    let payload = _.cloneDeep(data);
    payload["cardPhone"] = data?.card_phone;
    payload["cardSocialMedia"] = data?.card_social_media;

    delete payload.card_phone;
    delete payload.card_social_media;
    let {
      status,
      message,
      data: finalResult,
    } = await post(
      CARD_VCF_FILE_URL + `/${uuid ? uuid : payload.uuid}`,
      payload
    );

    if (status) {
      const link = document.createElement("a");
      link.download = "temp.vcf";
      link.href = finalResult.url;
      link.click();
    } else {
      showToast(status, message);
    }
    // setLoading(false);
  };

  //console.log("Current selected shape:", selectedProfileShape);
  //console.log("Current selected logo shape:", selectedLogoShape);

  return (
    <>
      <div className={classes.mainDiv}>
        <div
          className={classes.header}
          style={uuid || hideHeader ? { display: "none" } : null}
        >
          <span>Card Views</span>
          <MdClose onClick={handleClose ? handleClose : null} />
        </div>

        <div
          className={classes.outerWrapper}
          style={{
            background: hideHeader && "none",
            padding: hideHeader && "0",
          }}
        >
          {showSidebar && !publicMode ? (
            <div className={classes.shareSidebar}>
              <img
                src={cardEdit}
                onClick={() => {
                  navigate(`/function/cardfunctions/${data.id}`);
                }}
              />
              <GiShare
                className={shareOpen ? classes.activeLink : null}
                onClick={handleShareOpen}
              />
              <img
                src={qrcode}
                onClick={() => {
                  handleQrOpen();
                }}
              />
            </div>
          ) : null}

          <div className={classes.mainWrapper}>
            <div
              className={`${classes.cardWrapper} ${
                data?.content_view_type ? data.content_view_type : null
              }`}
            >
              <div className={classes.imgWrapper}>
                <div className={classes.coverImg}>
                  {data?.background_image ? (
                    <Image
                      src={
                        typeof data?.background_image == "object"
                          ? `${URL.createObjectURL(data?.background_image)}`
                          : `${baseURL.PUBLIC_URL}${data?.background_image}`
                      }
                      alt="cover-img"
                    />
                  ) : (
                    <div style={{ backgroundColor: skeletonColor }} />
                  )}
                </div>

                {/* <div
                key={selectedProfileShape}
                className={`${classes.profileImg} ${
                  selectedProfileShape
                    ? classes[selectedProfileShape]
                    : classes.defaultShape
                }`}
              > */}
                <div
                  key={selectedProfileShape}
                  className={`${classes.profileImg} ${
                    data?.profile_image_shape
                      ? data.profile_image_shape
                      : classes.defaultShape
                  } profileImg`}
                >
                  {data?.profile_image ? (
                    <Image
                      src={
                        typeof data?.profile_image == "object"
                          ? `${URL.createObjectURL(data?.profile_image)}`
                          : `${baseURL.PUBLIC_URL}${data?.profile_image}`
                      }
                      onError={(e) => {
                        e.currentTarget.src = pictureicon;
                      }}
                      alt="profile-img"
                    />
                  ) : inViewMode ? (
                    <Image src={pictureicon} alt="logo-img" />
                  ) : (
                    <div
                      style={{
                        backgroundColor: skeletonColor,
                      }}
                    />
                  )}
                </div>

                {data?.card_type === "business" ? (
                  // <div
                  //   //className={classes.logoWrapper}
                  //   key={selectedLogoShape}
                  //   className={`${classes.logoWrapper} ${
                  //     selectedLogoShape
                  //       ? classes[selectedLogoShape]
                  //       : classes.defaultShape
                  //   }`}
                  // >
                  <div
                    key={selectedLogoShape}
                    className={`${classes.logoWrapper} ${
                      data?.logo_shape ? data.logo_shape : classes.defaultShape
                    } logoWrapper`}
                  >
                    {data?.logo ? (
                      <Image
                        src={
                          typeof data?.logo == "object"
                            ? `${URL.createObjectURL(data?.logo)}`
                            : `${baseURL.PUBLIC_URL}${data?.logo}`
                        }
                        alt="logo-img"
                        onError={(e) => {
                          e.currentTarget.src = companylogo;
                        }}
                      />
                    ) : inViewMode ? null : (
                      <div style={{ backgroundColor: skeletonColor }} />
                    )}
                  </div>
                ) : null}
              </div>

              <div className={classes.infoWrapper}>
                <div className={classes.leftSide}>
                  {data?.card_type ? (
                    <div
                      className={classes.cardType}
                      style={{
                        backgroundColor: data?.color ? data?.color : "#22A081",
                        border: `1px solid ${
                          data?.color
                            ? tagBorderColors[`${data?.color}`]
                            : "ABEDDD"
                        }`,
                      }}
                    >
                      <span>{data?.card_type}</span>
                    </div>
                  ) : null}

                  {data?.first_name || data?.last_name ? (
                    <div
                      className={classes.name}
                      style={{ color: data.color ? data.color : "#232c3d" }}
                    >
                      {`${data?.first_name || ""} ${data?.last_name || ""}`}
                    </div>
                  ) : (
                    <div
                      className={classes.textSkeleton}
                      style={{
                        width: "130px",
                        height: "17px",
                        margin: "auto",
                        marginBottom: "15px",
                      }}
                    />
                  )}

                  {(inViewMode ? data?.pronouns : true) && (
                    <>
                      {data?.pronouns ? (
                        <div
                          className={classes.name}
                          style={{
                            color: data.color ? data.color : "#232c3d",
                            fontSize: "12px",
                            textTransform: [
                              "she/her",
                              "he/him",
                              "they/them",
                            ].includes(data?.pronouns)
                              ? "capitalize"
                              : null,
                          }}
                        >
                          {`(${data?.pronouns || ""})`}
                        </div>
                      ) : (
                        <div
                          className={classes.textSkeleton}
                          style={{
                            width: "100px",
                            height: "12px",
                            margin: "auto",
                            marginBottom: "15px",
                            marginTop: "5px",
                          }}
                        />
                      )}
                    </>
                  )}

                  {data?.card_type === "business" ? (
                    <>
                      {(inViewMode ? data.company_position : true) && (
                        <>
                          {data.company_position ? (
                            <div className={classes.profession}>
                              {data.company_position}
                            </div>
                          ) : (
                            <div
                              className={classes.textSkeleton}
                              style={{
                                width: "92px",
                                height: "10px",
                                margin: "auto",
                                marginBottom: "10px",
                              }}
                            />
                          )}
                        </>
                      )}

                      {(inViewMode ? data.company : true) && (
                        <>
                          {data.company ? (
                            <div className={classes.company}>
                              {data.company}
                            </div>
                          ) : (
                            <div
                              className={classes.textSkeleton}
                              style={{
                                width: "92px",
                                height: "10px",
                                margin: "auto",
                              }}
                            />
                          )}
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </div>

              {(inViewMode ? data?.description : true) && (
                <div className={classes.descriptionWrapper}>
                  <div className={classes.leftSide}>
                    {/* {data?.description ? (
                    <h6>About me</h6>
                  ) : (
                    <div
                      className={classes.textSkeleton}
                      style={{
                        width: "100px",
                        height: "13px",
                        marginBottom: "10px",
                      }}
                    />
                  )} */}
                    {data?.description ? (
                      // <span>{data.description}</span>
                      <span>
                        {displayText}
                        {data?.description?.length > 60 && (
                          <button onClick={toggleText}>
                            {showFullText ? "Show Less" : "Read More"}
                          </button>
                        )}
                      </span>
                    ) : (
                      <>
                        <div
                          className={classes.textSkeleton}
                          style={{
                            width: "100%",
                            height: "10px",
                            marginBottom: "10px",
                            marginTop: "10px",
                          }}
                        />
                        <div
                          className={classes.textSkeleton}
                          style={{
                            width: "100%",
                            height: "10px",
                            marginBottom: "10px",
                          }}
                        />
                        <div
                          className={classes.textSkeleton}
                          style={{
                            width: "100%",
                            height: "10px",
                            marginBottom: "10px",
                          }}
                        />
                      </>
                    )}
                    <hr />
                  </div>
                </div>
              )}

              {(inViewMode
                ? data?.email ||
                  data?.address ||
                  data?.city ||
                  data?.state ||
                  data?.country ||
                  data?.website_url ||
                  data?.phone_number ||
                  data?.card_phone?.length !== 0
                : true) && (
                <div className={`${classes.contactInfo} contactInfo`}>
                  <div className="contactInfoInner">
                    {data?.email ||
                    data?.address ||
                    data?.city ||
                    data?.state ||
                    data?.country ||
                    data?.website_url ||
                    data?.phone_number ||
                    data?.card_phone?.length !== 0 ||
                    data?.contact_card_phone?.length !== 0 ? (
                      <h6>Contact Info</h6>
                    ) : (
                      <div
                        className={classes.textSkeleton}
                        style={{
                          width: "100px",
                          height: "13px",
                          marginBottom: "10px",
                        }}
                      />
                    )}
                    {/* <hr /> */}

                    {(inViewMode ? data?.email : true) && (
                      <div
                        className={`${classes.infoContnt} infoContnt`}
                        style={{
                          borderColor: data.color ? data.color : "#232c3d",
                        }}
                      >
                        {data.color ? (
                          <>
                            {data?.email ? (
                              <a
                                href={`mailto:${data?.email}`}
                                style={{ backgroundColor: data.color }}
                                className={`${classes.moreInfoIcon} moreInfoIcon`}
                              >
                                {data?.email ? <FiMail /> : null}
                              </a>
                            ) : (
                              <div
                                style={{ backgroundColor: data.color }}
                                className={`${classes.moreInfoIcon} moreInfoIcon`}
                              >
                                {data?.email ? <FiMail /> : null}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className={classes.iconSkeleton} />
                        )}

                        {data.email ? (
                          <>
                            <div>
                              <p
                                className={`${classes.subHeading} subHeading`}
                                style={{
                                  color: data.color ? data.color : "#232c3d",
                                }}
                              >
                                Email
                              </p>
                              {/* <a href={`mailto:${data.email}`}>{data.email}</a> */}
                              {data.content_view_type === "iconDesign" ? (
                                <a href={`mailto:${data.email}`}>
                                  {/* <MailIcon /> */}
                                  <img src={mailIcon} alt="" />
                                </a>
                              ) : (
                                <a href={`mailto:${data.email}`}>
                                  {data.email}
                                </a>
                              )}
                            </div>
                          </>
                        ) : (
                          <div
                            className={classes.textSkeleton}
                            style={{ width: "60%", height: "10px" }}
                          />
                        )}
                      </div>
                    )}

                    {(inViewMode
                      ? data?.address ||
                        data?.city ||
                        data?.state ||
                        data?.country
                      : true) && (
                      <div
                        className={`${classes.infoContnt} infoContnt`}
                        style={{
                          borderColor: data.color ? data.color : "#232c3d",
                        }}
                      >
                        {data.color ? (
                          <>
                            {data?.address ||
                            data?.city ||
                            data?.state ||
                            data?.country ? (
                              <a
                                href={`http://maps.google.com/?q=${address}`}
                                style={{ backgroundColor: data.color }}
                                className={`${classes.moreInfoIcon} moreInfoIcon`}
                                target="_blank"
                              >
                                {data?.address ||
                                data?.city ||
                                data?.state ||
                                data?.country ? (
                                  <MdLocationOn />
                                ) : null}
                              </a>
                            ) : (
                              <div
                                style={{ backgroundColor: data.color }}
                                className={`${classes.moreInfoIcon} moreInfoIcon`}
                              >
                                {data?.address ||
                                data?.city ||
                                data?.state ||
                                data?.country ? (
                                  <MdLocationOn />
                                ) : null}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className={classes.iconSkeleton} />
                        )}

                        {data?.address ||
                        data?.city ||
                        data?.state ||
                        data?.country ? (
                          <>
                            <div>
                              <p
                                className={`${classes.subHeading} subHeading`}
                                style={{
                                  color: data.color ? data.color : "#232c3d",
                                }}
                              >
                                Location
                              </p>
                              {/* <a
                              href={`http://maps.google.com/?q=${address}`}
                              target="_blank"
                              style={{
                                lineHeight: "1.3",
                                display: "inline-flex",
                                whiteSpace: "normal",
                              }}
                            >
                              {address}
                            </a> */}
                              {data.content_view_type === "iconDesign" ? (
                                <a
                                  href={`http://maps.google.com/?q=${address}`}
                                  target="_blank"
                                  style={{
                                    lineHeight: "1.3",
                                    display: "inline-flex",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  <img src={locationIcon} alt="" />
                                </a>
                              ) : (
                                <a
                                  href={`http://maps.google.com/?q=${address}`}
                                  target="_blank"
                                  style={{
                                    lineHeight: "1.3",
                                    display: "inline-flex",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {address}
                                </a>
                              )}
                            </div>
                          </>
                        ) : (
                          <div
                            className={classes.textSkeleton}
                            style={{ width: "60%", height: "10px" }}
                          />
                        )}
                      </div>
                    )}

                    {(inViewMode ? data?.website_url : true) && (
                      <div
                        className={`${classes.infoContnt} infoContnt`}
                        style={{
                          borderColor: data.color ? data.color : "#232c3d",
                        }}
                      >
                        {data.color ? (
                          <>
                            {data?.website_url ? (
                              <a
                                style={{ backgroundColor: data.color }}
                                className={`${classes.moreInfoIcon} moreInfoIcon`}
                                href={data?.website_url}
                                target="_blank"
                              >
                                {data?.website_url ? <BsGlobe /> : null}
                              </a>
                            ) : (
                              <div
                                style={{ backgroundColor: data.color }}
                                className={`${classes.moreInfoIcon} moreInfoIcon`}
                              >
                                {data?.website_url ? <BsGlobe /> : null}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className={classes.iconSkeleton} />
                        )}

                        {data?.website_url ? (
                          <>
                            <div>
                              <p
                                className={`${classes.subHeading} subHeading`}
                                style={{
                                  color: data.color ? data.color : "#232c3d",
                                }}
                              >
                                Website
                              </p>
                              {/* <a href={data?.website_url} target="_blank">
                              {data?.website_url}
                            </a> */}
                              {data.content_view_type === "iconDesign" ? (
                                <a href={`mailto:${data.email}`}>
                                  {/* <WebsiteIcon /> */}
                                  <img src={websiteIcon} alt="" />
                                </a>
                              ) : (
                                <a href={data?.website_url} target="_blank">
                                  {data?.website_url}
                                </a>
                              )}
                            </div>
                          </>
                        ) : (
                          <div
                            className={classes.textSkeleton}
                            style={{ width: "60%", height: "10px" }}
                          />
                        )}
                      </div>
                    )}

                    {(inViewMode ? data?.card_phone?.length !== 0 : true) && (
                      <>
                        {data?.card_phone?.map((singlePhone) => {
                          return (
                            <div
                              className={`${classes.infoContnt} infoContnt`}
                              style={{
                                borderColor: data.color
                                  ? data.color
                                  : "#232c3d",
                              }}
                            >
                              {data?.color ? (
                                <>
                                  {data?.card_phone?.length !== 0 &&
                                  singlePhone?.phone_number ? (
                                    <a
                                      style={{ backgroundColor: data.color }}
                                      className={`${classes.moreInfoIcon} moreInfoIcon`}
                                      href={`tel:${
                                        showCountryCodeSeparateUpdate
                                          ? `+${
                                              singlePhone?.country_phone
                                            }${formatPhoneNumber(
                                              singlePhone?.phone_number
                                            )}`
                                          : `+${
                                              singlePhone.country_phone
                                            }${formatPhoneNumber(
                                              singlePhone.phone_number.slice(
                                                singlePhone.country_phone
                                                  .length,
                                                singlePhone.phone_number.length
                                              )
                                            )}`
                                      }`}
                                    >
                                      {singlePhone.phone_number ? (
                                        <>
                                          {singlePhone.phone_type === "home" ? (
                                            <HomePhoneIcon />
                                          ) : singlePhone.phone_type ===
                                            "office" ? (
                                            <OfficePhoneIcon />
                                          ) : (
                                            <BsFillTelephoneFill />
                                          )}
                                        </>
                                      ) : null}
                                    </a>
                                  ) : (
                                    <div
                                      style={{ backgroundColor: data.color }}
                                      className={`${classes.moreInfoIcon} moreInfoIcon`}
                                    >
                                      {singlePhone.phone_number ? (
                                        <>
                                          {singlePhone.phone_type === "home" ? (
                                            <HomePhoneIcon />
                                          ) : singlePhone.phone_type ===
                                            "office" ? (
                                            <OfficePhoneIcon />
                                          ) : (
                                            <BsFillTelephoneFill />
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div className={classes.iconSkeleton} />
                              )}

                              {singlePhone.phone_number ? (
                                <>
                                  <div>
                                    <p
                                      className={`${classes.subHeading} subHeading`}
                                      style={{
                                        color: data.color
                                          ? data.color
                                          : "#232c3d",
                                      }}
                                    >
                                      Phone
                                    </p>
                                    {/* <a
                                    href={`tel:${
                                      showCountryCodeSeparateUpdate
                                        ? `+${
                                            singlePhone.country_phone
                                          }${formatPhoneNumber(
                                            singlePhone.phone_number
                                          )}`
                                        : `+${
                                            singlePhone.country_phone
                                          }${formatPhoneNumber(
                                            singlePhone.phone_number.slice(
                                              singlePhone.country_phone.length,
                                              singlePhone.phone_number.length
                                            )
                                          )}`
                                    }`}
                                  >
                                    {showCountryCodeSeparateUpdate
                                      ? `+${
                                          singlePhone.country_phone
                                        }${formatPhoneNumber(
                                          singlePhone.phone_number
                                        )}`
                                      : `+${
                                          singlePhone.country_phone
                                        }${formatPhoneNumber(
                                          singlePhone.phone_number.slice(
                                            singlePhone.country_phone.length,
                                            singlePhone.phone_number.length
                                          )
                                        )}`}
                                  </a> */}
                                    {data.content_view_type === "iconDesign" ? (
                                      <a href={`mailto:${data.email}`}>
                                        {/* <PhoneIcon /> */}
                                        <img src={phoneIcon} alt="" />
                                      </a>
                                    ) : (
                                      <a
                                        href={`tel:${
                                          showCountryCodeSeparateUpdate
                                            ? `+${
                                                singlePhone.country_phone
                                              }${formatPhoneNumber(
                                                singlePhone.phone_number
                                              )}`
                                            : `+${
                                                singlePhone.country_phone
                                              }${formatPhoneNumber(
                                                singlePhone.phone_number.slice(
                                                  singlePhone.country_phone
                                                    .length,
                                                  singlePhone.phone_number
                                                    .length
                                                )
                                              )}`
                                        }`}
                                      >
                                        {showCountryCodeSeparateUpdate
                                          ? `+${
                                              singlePhone.country_phone
                                            }${formatPhoneNumber(
                                              singlePhone.phone_number
                                            )}`
                                          : `+${
                                              singlePhone.country_phone
                                            }${formatPhoneNumber(
                                              singlePhone.phone_number.slice(
                                                singlePhone.country_phone
                                                  .length,
                                                singlePhone.phone_number.length
                                              )
                                            )}`}
                                      </a>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <div
                                  className={classes.textSkeleton}
                                  style={{ width: "60%", height: "10px" }}
                                />
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}

                    {console.log("data", data)}

                    {(inViewMode
                      ? data?.contact_card_phone?.length !== 0
                      : true) && (
                      <>
                        {data?.contact_card_phone?.map((singlePhone) => {
                          return (
                            <div
                              className={`${classes.infoContnt} infoContnt`}
                              style={{
                                borderColor: data.color
                                  ? data.color
                                  : "#232c3d",
                              }}
                            >
                              {data?.color ? (
                                <>
                                  {data?.card_phone?.length !== 0 &&
                                  singlePhone?.phone_number ? (
                                    <a
                                      style={{ backgroundColor: data.color }}
                                      className={`${classes.moreInfoIcon} moreInfoIcon`}
                                      href={`tel:${
                                        showCountryCodeSeparateUpdate
                                          ? `+${
                                              singlePhone?.country_phone
                                            }${formatPhoneNumber(
                                              singlePhone?.phone_number
                                            )}`
                                          : `+${
                                              singlePhone.country_phone
                                            }${formatPhoneNumber(
                                              singlePhone.phone_number.slice(
                                                singlePhone.country_phone
                                                  .length,
                                                singlePhone.phone_number.length
                                              )
                                            )}`
                                      }`}
                                    >
                                      {singlePhone.phone_number ? (
                                        <>
                                          {singlePhone.phone_type === "home" ? (
                                            <HomePhoneIcon />
                                          ) : singlePhone.phone_type ===
                                            "office" ? (
                                            <OfficePhoneIcon />
                                          ) : (
                                            <BsFillTelephoneFill />
                                          )}
                                        </>
                                      ) : null}
                                    </a>
                                  ) : (
                                    <div
                                      style={{ backgroundColor: data.color }}
                                      className={`${classes.moreInfoIcon} moreInfoIcon`}
                                    >
                                      {singlePhone.phone_number ? (
                                        <>
                                          {singlePhone.phone_type === "home" ? (
                                            <HomePhoneIcon />
                                          ) : singlePhone.phone_type ===
                                            "office" ? (
                                            <OfficePhoneIcon />
                                          ) : (
                                            <BsFillTelephoneFill />
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div className={classes.iconSkeleton} />
                              )}

                              {singlePhone.phone_number ? (
                                <>
                                  <div>
                                    <p
                                      className={`${classes.subHeading} subHeading`}
                                      style={{
                                        color: data.color
                                          ? data.color
                                          : "#232c3d",
                                      }}
                                    >
                                      Phone
                                    </p>
                                    {data.content_view_type === "iconDesign" ? (
                                      <a href={`mailto:${data.email}`}>
                                        {/* <PhoneIcon /> */}
                                        <img src={phoneIcon} alt="" />
                                      </a>
                                    ) : (
                                      <a
                                        href={`tel:${
                                          showCountryCodeSeparateUpdate
                                            ? `+${
                                                singlePhone.country_phone
                                              }${formatPhoneNumber(
                                                singlePhone.phone_number
                                              )}`
                                            : `+${
                                                singlePhone.country_phone
                                              }${formatPhoneNumber(
                                                singlePhone.phone_number.slice(
                                                  singlePhone.country_phone
                                                    .length,
                                                  singlePhone.phone_number
                                                    .length
                                                )
                                              )}`
                                        }`}
                                      >
                                        {showCountryCodeSeparateUpdate
                                          ? `+${
                                              singlePhone.country_phone
                                            }${formatPhoneNumber(
                                              singlePhone.phone_number
                                            )}`
                                          : `+${
                                              singlePhone.country_phone
                                            }${formatPhoneNumber(
                                              singlePhone.phone_number.slice(
                                                singlePhone.country_phone
                                                  .length,
                                                singlePhone.phone_number.length
                                              )
                                            )}`}
                                      </a>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <div
                                  className={classes.textSkeleton}
                                  style={{ width: "60%", height: "10px" }}
                                />
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              )}

              {(inViewMode ? data?.card_social_media?.length !== 0 : true) && (
                <div className={`${classes.socialMediaWrapper} mb-3`}>
                  {data?.card_social_media?.length !== 0 ? (
                    <h6>Social Media</h6>
                  ) : (
                    <div
                      className={classes.textSkeleton}
                      style={{
                        width: "100px",
                        height: "13px",
                        marginBottom: "10px",
                      }}
                    />
                  )}
                  {/* <hr /> */}

                  <div className={classes.socialMediaIconWrapper}>
                    {data?.card_social_media?.map((single, index) => (
                      <>
                        {console.log("single.icon", single.icon)}
                        <a
                          key={`socialMediaCard${index}`}
                          style={{ backgroundColor: data.color }}
                          className={classes.socialMediaIcon}
                          href={single.url}
                          target="_blank"
                        >
                          {single.icon === "custom"
                            ? single.custom_label.charAt(0)
                            : single.icon}
                        </a>
                      </>
                    ))}
                    {data?.card_social_media?.length === 0 ? (
                      <>
                        {data.color ? (
                          <>
                            <div
                              style={{ backgroundColor: data.color }}
                              className={classes.iconSkeleton}
                            />
                            <div
                              style={{ backgroundColor: data.color }}
                              className={classes.iconSkeleton}
                            />
                          </>
                        ) : (
                          <>
                            <div className={classes.iconSkeleton} />
                            <div className={classes.iconSkeleton} />
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              )}

              {(inViewMode
                ? data?.contact_card_other_data?.length !== 0
                : true) && !data.contact_card_other_data ? (
                ""
              ) : (
                <div className={`${classes.contactInfo} contactInfo`}>
                  <div className="contactInfoInner">
                    {data.contact_card_other_data?.length !== 0 ? (
                      <>
                        {console.log(
                          "hnabhjd",
                          !data.contact_card_other_data ? "fhbhdjbv" : ""
                        )}
                        <h6>Other Info</h6>
                      </>
                    ) : (
                      ""
                    )}
                    {data.contact_card_other_data?.map((item, index) => (
                      <>
                        <div
                          className={`${classes.infoContnt} infoContnt mt-0`}
                          style={{
                            borderColor: data.color ? data.color : "#232c3d",
                          }}
                        >
                          <div key={index}>
                            {item.type === "heading" ||
                            item.type === "paragraph" ||
                            item.type === "list" ||
                            item.type === "line" ? (
                              ""
                            ) : (
                              <>
                                <p
                                  className={`${classes.subHeading} subHeading mt-3`}
                                  style={{
                                    color: data.color ? data.color : "#232c3d",
                                  }}
                                >
                                  {item.label}
                                </p>
                                <p>{item.data}</p>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              )}

              {inViewMode ? (
                <div
                  className={`text-center  ${classes.savePhonebookBtnWrapper}`}
                >
                  <button onClick={handleVcfFile}>
                    <span>
                      <img src={contacticon} alt="" />
                    </span>
                    Save Contact
                  </button>
                </div>
              ) : null}
            </div>
            {uuid ? (
              <div className={classes.cardFooter}>
                <div className="d-flex align-items-center gap-3 justify-content-center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.holguinmedia.contactapp"
                    target="_blank"
                  >
                    <img src={playstore} alt="" />
                  </a>
                  <a
                    href="https://apps.apple.com/pk/app/contact-share-digital-info/id1662851237"
                    target="_blank"
                  >
                    <img src={appstore} alt="" />
                  </a>
                </div>
                <p>
                  © {new Date().getFullYear()} Contact Co, All rights reserved.
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <style type="text/css">
        {`
          .listDesign {
            //background-color: #e1e17d;
          }
          
          .gridDesign .contactInfoInner {
            display: flex;
            flex-wrap: wrap;
            justify-content:space-between;
          }
          .gridDesign .infoContnt {
            width: 48%;
            flex: 0 0 auto;
            padding: 0.5rem;
            display: block;id
            text-align: center;
            border:1px solid var(--tertiary_color);
            background:#fff;
            border-radius: 5px;
          }
          .treeDesign .contactInfoInner h6, .treeDesign .contactInfoInner .infoContnt{
            position:relative;
          }
          .gridDesign .infoContnt a{
            white-space:normal;
            display: inline-flex;
            word-break: break-word;
          }
          .gridDesign .contactInfo h6{
            width:100%;
          }
          .gridDesign .moreInfoIcon{
            max-width:38px;
            max-height:38px;
            margin:0 auto 10px;
          }
          .treeDesign .contactInfoInner{position:relative;padding-left:15px}
          .treeDesign .contactInfoInner:before{
            content:"";
            border-left: 1px solid #B2B2B2;
            height: 100%;
            position: absolute;id
            top: 13px;
            left: 0px;
          }
          
          .treeDesign .contactInfoInner h6{
            background-color: #DBDBDB;
            padding: 0.2rem 0.75rem;
            display:inline-block;
          }
          .treeDesign .contactInfoInner h6:before{
            content:"";
            width: 15px;
            position: absolute;
            left: -15px;
            top: 0px;
            bottom: 0;
            height: 1px;
            margin: auto;id
            position: absolute;
            height: 1px;
            background-color: #B2B2B2;
            left: -15px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          .treeDesign .contactInfoInner .infoContnt:before{
            content:"";
            width: 15px;
            position: absolute;
            height: 1px;
            background-color: #B2B2B2;
            left: -15px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          .treeDesign .contactInfoInner .infoContnt:after{
            content:"";
            width: 5px;
            height: 5px;
            background-color: #B2B2B2;
            border-radius: 50%;
            position: absolute;
            left: -2.4%;
          }
          .iconDesign .contactInfoInner{
            display:flex;
            flex-wrap:wrap;
          }
          .iconDesign .contactInfo h6{
            width:100%;
          }
          .iconDesign .contactInfoInner .infoContnt{
            width:30.33333%;
            flex: 0 0 auto;
            margin-right:3%;
          }
          .iconDesign .contactInfoInner .infoContnt .moreInfoIcon{
            display:none;
          }
          .iconDesign .contactInfoInner .infoContnt .subHeading{
            display:none;
          }
          .profileImg.circle div,
          .profileImg.circle img {
            border-radius: 50%;
            transition: 0.5s all ease;
          }
          .profileImg.square div,
          .profileImg.square img {
            border-radius: 2px;
            transition: 0.5s all ease;
          }
          .profileImg.rectangular div,
          .profileImg.rectangular img {
            border-radius: 2px;
            width: 90px;
            height: 65px;
            transition: 0.5s all ease;
          }
          .logoWrapper{
            position: absolute;
            top: 115px;
            right: 45px;
          }
          .logoWrapper.circle div,
          .logoWrapper.circle img {
            border-radius: 50%;
            transition: 0.5s all ease;
          }
          .logoWrapper.square div,
          .logoWrapper.square img {
            border-radius: 2px;
            transition: 0.5s all ease;
          }
          .logoWrapper.rectangular div,
          .logoWrapper.rectangular img {
            border-radius: 2px;
            width: 50px;
            height: 25px;
            transition: 0.5s all ease;
          }
        `}
      </style>
    </>
  );
}
