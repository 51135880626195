import React, { useState, useEffect } from "react";
import classes from "../../../assets/styles/Pages/CouponManagement/CouponManagement.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import SettingsInput from "../../../components/Forms/SettingsInput";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import { post, get } from "../../../server";
import _ from "lodash";
import { GENERATE_QRCODE } from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
const AddEditQRCode = () => {
  let { id } = useParams();
  // console.log("paramId", id);
  //   let emptyObj = {
  //     name: "",
  //     quantity: "",
  //     is_logo: false,
  //   };
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [qrCodes, setQRCodes] = useState({
    name: "",
    quantity: "",
    is_logo: false,
  });
  const [isLogo, setIsLogo] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    setQRCodes({ ...qrCodes, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsLogo(isChecked);
    setQRCodes({ ...qrCodes, is_logo: isChecked });
  };
  console.log("isLogo", isLogo);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let payload = _.cloneDeep(qrCodes);
    let { status, message, data } = await post(
      // id ? COUPON_UPDATE : COUPON_CREATE,
      GENERATE_QRCODE,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setQRCodes(data);
      navigate("/generateQRCode");
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle"> {id ? "Edit" : "Generate"} QR Code</p>
            </div>
          </div>
          <div className={classes.content}>
            <div className={`mainWrapper ${classes.outerWrapper}`}>
              <div className="row gx-5 gy-3">
                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Title"
                    type="text"
                    placeholder="Enter QR Code Batch Title"
                    name="name"
                    value={qrCodes.name}
                    handleChange={handleChange}
                    error={errors.name}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Quantity"
                    type="text"
                    placeholder="Enter QR Code Quantity"
                    name="quantity"
                    value={qrCodes.quantity}
                    handleChange={handleChange}
                    error={errors.quantity}
                  />
                </div>
                <div className="col-md-4 col-12"></div>
                <div className="col-md-4 col-12">
                  <Form.Check
                    type="checkbox"
                    label={"QR Code Logo"}
                    checked={isLogo}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
              <div className="formBottom">
                <Button
                  className="submitButton"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Generate
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <style type="text/css">
        {`
            .form-check-label{
                font-size: 12px;
                line-height: 18px;
                color: var(--heading_text_color);
            }
        `}
      </style>
    </>
  );
};

export default AddEditQRCode;
