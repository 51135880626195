import React from "react";

const FormBuilderLogo = ({ primaryColor, secondaryColor }) => (
  <svg
    width="88"
    height="88"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1201_848)">
      <path
        d="M44.9167 7.33333H40.8833C39.6733 3.11667 35.7867 0 31.1667 0C26.5467 0 22.66 3.11667 21.45 7.33333H17.4167C15.9133 7.33333 14.6667 8.58 14.6667 10.0833V15.5833C14.6667 19.1033 17.5633 22 21.0833 22H41.25C44.77 22 47.6667 19.1033 47.6667 15.5833V10.0833C47.6667 8.58 46.42 7.33333 44.9167 7.33333Z"
        fill={secondaryColor}
      />
      <path
        d="M52.25 11H51.3333V15.5833C51.3333 21.1567 46.8233 25.6667 41.25 25.6667H21.0833C15.51 25.6667 11 21.1567 11 15.5833V11H10.0833C4.51 11 0 15.51 0 21.0833V66.9167C0 72.49 4.51 77 10.0833 77H37.1433L37.95 72.49C38.3167 70.4367 39.27 68.6033 40.7367 67.1L43.67 64.1667H13.75C12.2467 64.1667 11 62.92 11 61.4167C11 59.9133 12.2467 58.6667 13.75 58.6667H48.5833C48.7667 58.6667 48.9133 58.6667 49.0967 58.74H49.1333L62.3333 45.54V21.0833C62.3333 15.51 57.8233 11 52.25 11ZM48.5833 52.25H13.75C12.2467 52.25 11 51.0033 11 49.5C11 47.9967 12.2467 46.75 13.75 46.75H48.5833C50.0867 46.75 51.3333 47.9967 51.3333 49.5C51.3333 51.0033 50.0867 52.25 48.5833 52.25ZM48.5833 40.3333H13.75C12.2467 40.3333 11 39.0867 11 37.5833C11 36.08 12.2467 34.8333 13.75 34.8333H48.5833C50.0867 34.8333 51.3333 36.08 51.3333 37.5833C51.3333 39.0867 50.0867 40.3333 48.5833 40.3333Z"
        fill={primaryColor}
      />
      <path
        d="M45.9323 88C45.21 88 44.506 87.714 43.989 87.1933C43.3547 86.559 43.0687 85.657 43.2263 84.7697L45.1697 73.7513C45.265 73.1977 45.5363 72.6843 45.9323 72.2847L73.1573 45.0634C76.5013 41.712 79.7866 42.6177 81.5833 44.4144L86.119 48.95C88.6233 51.4507 88.6233 55.5207 86.119 58.025L58.894 85.25C58.498 85.6497 57.9846 85.9173 57.4273 86.0127L46.409 87.956C46.2513 87.9853 46.09 88 45.9323 88Z"
        fill={secondaryColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_1201_848">
        <rect width="88" height="88" fill={primaryColor} />
      </clipPath>
    </defs>
  </svg>
);

export default FormBuilderLogo;
