
import React, { useEffect, useState, useMemo } from "react";
import classes from "../../../assets/styles/Pages/CouponManagement/CouponManagement.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { get, post } from "../../../server";
import { GENERATE_QRCODE } from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { BsThreeDotsVertical } from "react-icons/bs";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import { useParams } from "react-router-dom";
const BatchQRCode = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [errors, setErrors] = useState({});
  const [generateQRCode, setGenerateQRCode] = useState([]);
  const [couponId, setCouponId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  let { id } = useParams();
  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const generateQRCodeList = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      `${GENERATE_QRCODE}/${id}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setGenerateQRCode(data.adminQRCodeImage);
      console.log("setGenerateQRCode", data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const filteredItems = useMemo(() => {
    const filtered = generateQRCode.filter(
      (item) =>
        `${item.name || ""}`.toLowerCase().includes(filterText.toLowerCase()) ||
        `${item.coupon || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.discount || ""}`.includes(filterText)
    );

    return filtered;
  }, [filterText, generateQRCode]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "QR Code ID",
      cell: (row) => <p className="globalDetail">{row.uuid}</p>,
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
          <li>
            <Link className="dropdown-item" to={`/generateQRCode/batchQRCode/assignQRCode/${row.uuid}`}>
              Assign
            </Link>
          </li>
            {/* <li>
              <Link className="dropdown-item" to={`AddEditCoupons/${row.id}`}>
                Edit
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li> */}
          </ul>
        </div>
      ),
    },
  ];

  useEffect(() => {
    generateQRCodeList();
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                All QR Code <span>({generateQRCode.length})</span>
              </p>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              </div>
            </div>
          </div>
          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              theme="solarized"
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default BatchQRCode;
