import React, { useState, useEffect } from "react";
import RichTextEditor from "react-rte";
import classes from "../../assets/styles/Pages/Function/CustomURLSListing.module.css";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  EMAIL_TEMPLATE,
  CREATE_EMAIL_TEMPLATE,
  SEND_EMAIL_TEMPLATE,
} from "../../utils/constants/apiUrl";
import { post, get, put } from "../../server";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import showToast from "../../helpers/showToast";
import { useAtom } from "jotai";
import { toast } from "react-toastify";

const EmailTemplate = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [emailStatus, setEmailStatus] = useState(false);
  const navigate = useNavigate();

  const handleChange = (value) => {
    setEditorState(value);
    console.log("editorState", editorState);
  };

  const getHandleStatus = async () => {
    const queryString = `?function_type=form_builder`;
    let { status, message, data } = await get(
      EMAIL_TEMPLATE + queryString,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data !== null) {
        setEmailStatus(data.email_to_lead_user);
      } else {
        setEmailStatus(0);
      }
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  const handleStatusChange = async () => {
    let newEmailStatus = emailStatus == true ? 0 : 1;
    const payload = {
      email_to_lead_user: newEmailStatus,
    };
    let { status, message } = await put(
      SEND_EMAIL_TEMPLATE,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await getHandleStatus();
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const template = editorState.toString("html");
    const payload = {
      team_id: userTeamId,
      function_type: "form_builder",
      template: template,
    };
    let { status, message, data } = await post(
      CREATE_EMAIL_TEMPLATE,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      getEmailTemplate();
      showToast(status, message);
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  const getEmailTemplate = async () => {
    setLoading(true);
    const queryString = `?function_type=form_builder`;
    let { status, message, data } = await get(
      EMAIL_TEMPLATE + queryString,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      const templateFromApi = data.template;
      setEditorState(
        RichTextEditor.createValueFromString(templateFromApi, "html")
      );
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    getEmailTemplate();
    getHandleStatus();
  }, []);

  return (
    <>
      <div className="emailEditor">
        <div className={`${classes.mainWrapper} mb-4`}>
          <h6 className={classes.title}>
            <Link
              onClick={handleGoBack}
              style={{ color: "var(--heading_text_color)" }}
            >
              Form Builder
            </Link>
            {` > Email Template`}
          </h6>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              checked={emailStatus}
              onChange={handleStatusChange}
            />
            <label
              class="form-check-label"
              style={{ color: "var(--heading_text_color)" }}
            >
              {emailStatus == true
                ? "Trun Off Send Email"
                : "Want To Send Email"}
            </label>
          </div>
        </div>
        <RichTextEditor
          className="textEditor"
          value={editorState}
          onChange={handleChange}
        />
        <div className="text-center mt-3">
          <button className="primary_button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
      <style type="text/css">
        {`
        .emailEditor{
          color:#000;
        }
        .textEditor{
          height:70vh
        }
      `}
      </style>
    </>
  );
};

export default EmailTemplate;
