import React from "react";

const ContactActiveDirectoryIcon = ({ primaryColor }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill={primaryColor}
      stroke="none"
    >
      <path
        d="M2365 4864 c-93 -52 -177 -102 -185 -110 -12 -11 -16 -52 -20 -192
        l-5 -177 -447 -248 -447 -248 -38 24 c-103 66 -316 177 -340 177 -33 0 -349
        -171 -380 -206 -22 -24 -23 -31 -23 -243 0 -196 2 -221 18 -241 10 -12 81 -57
        158 -100 l139 -77 0 -623 0 -623 -139 -77 c-77 -43 -148 -88 -158 -100 -16
        -20 -18 -45 -18 -241 0 -212 1 -219 22 -243 30 -33 348 -206 379 -206 13 0 89
        36 168 79 l144 79 51 -28 c28 -16 245 -136 481 -268 l430 -239 5 -176 c4 -139
        8 -180 20 -191 27 -27 358 -206 380 -206 25 0 362 183 382 207 9 11 14 68 18
        192 l5 175 420 234 c231 128 447 248 481 267 l60 34 145 -79 c79 -44 155 -80
        169 -80 32 0 347 172 378 206 21 24 22 31 22 245 l0 221 -27 27 c-16 15 -88
        60 -161 100 l-132 72 2 620 3 621 135 75 c74 41 145 86 158 99 21 24 22 31 22
        244 0 213 -1 220 -23 244 -31 35 -341 206 -374 206 -23 0 -236 -109 -345 -177
        l-37 -23 -448 249 -448 248 -5 175 c-4 123 -9 180 -18 191 -22 26 -358 207
        -383 207 -13 -1 -100 -44 -194 -96z m318 -143 l117 -64 0 -151 -1 -151 -114
        -62 c-63 -35 -119 -63 -125 -63 -6 0 -62 28 -125 63 l-114 62 -1 150 0 150
        118 67 c64 36 120 65 123 65 3 -1 58 -31 122 -66z m-332 -563 l129 -72 0 -178
        c0 -177 0 -179 25 -203 15 -16 36 -25 55 -25 19 0 40 9 55 25 25 24 25 26 25
        202 l0 178 128 70 c70 39 134 70 142 70 9 0 221 -114 473 -254 l458 -254 -3
        -115 -3 -115 -180 -113 c-99 -62 -188 -123 -197 -135 -24 -28 -23 -60 3 -93
        39 -49 70 -39 276 90 l185 115 116 -63 117 -64 0 -624 0 -624 -118 -64 -117
        -64 -186 116 c-206 128 -235 138 -273 90 -28 -36 -26 -68 7 -103 15 -16 105
        -77 200 -136 l172 -108 0 -151 0 -152 -465 -258 c-255 -142 -468 -256 -473
        -254 -4 1 -65 35 -135 73 l-127 70 0 258 0 258 -25 24 c-32 33 -78 33 -110 0
        l-25 -24 0 -259 0 -258 -130 -73 c-72 -40 -137 -71 -144 -69 -7 2 -218 117
        -469 257 l-457 253 0 157 1 156 170 105 c94 58 182 115 195 127 31 28 32 80 4
        111 -40 43 -72 31 -269 -93 l-183 -115 -119 66 -119 66 2 620 3 620 116 64
        116 65 183 -116 c198 -124 230 -136 270 -93 28 31 27 83 -4 111 -13 12 -101
        69 -195 127 l-170 105 -1 116 0 115 463 257 c254 141 466 257 470 257 5 0 67
        -32 138 -72z m-1350 -304 l119 -66 0 -148 0 -148 -120 -67 -120 -67 -120 67
        -120 67 0 148 0 148 118 66 c64 36 119 66 121 66 2 0 57 -30 122 -66z m3360 0
        l119 -66 0 -148 0 -148 -120 -67 -120 -67 -120 67 -120 67 0 148 0 148 118 66
        c64 36 119 66 121 66 2 0 57 -30 122 -66z m-3361 -2080 l120 -66 0 -148 0
        -148 -120 -67 -120 -67 -120 67 -120 67 0 148 0 149 118 65 c64 36 118 65 120
        66 2 0 57 -30 122 -66z m3360 0 l120 -66 0 -148 0 -148 -120 -67 -120 -67
        -120 67 -120 67 0 148 0 149 118 65 c64 36 118 65 120 66 2 0 57 -30 122 -66z
        m-1675 -947 l110 -62 3 -151 3 -150 -121 -67 -120 -66 -120 67 -120 67 0 150
        1 150 112 62 c61 34 119 62 127 62 8 0 65 -28 125 -62z"
      />
      <path
        d="M2176 3350 c-197 -110 -362 -200 -367 -200 -4 0 -17 -12 -28 -26 -21
        -26 -21 -36 -21 -485 0 -311 4 -466 11 -482 8 -17 116 -83 386 -233 207 -115
        386 -210 398 -212 34 -5 779 413 794 444 8 17 11 166 11 483 0 422 -1 460 -17
        479 -26 30 -756 432 -784 432 -13 0 -186 -90 -383 -200z m659 -122 c208 -116
        272 -155 262 -164 -19 -17 -523 -294 -535 -294 -11 0 -529 284 -544 299 -5 5
        522 308 540 311 2 0 127 -69 277 -152z m-630 -450 l275 -152 0 -349 0 -348
        -77 42 c-43 24 -168 93 -278 154 l-200 110 -3 348 c-1 191 0 347 3 347 3 0
        129 -68 280 -152z m993 -195 l-3 -348 -200 -110 c-110 -61 -235 -130 -277
        -153 l-78 -43 0 348 0 348 273 152 c149 83 275 152 280 152 4 1 6 -155 5 -346z"
      />
    </g>
  </svg>
);

export default ContactActiveDirectoryIcon;
