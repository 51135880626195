const Joi = require("joi");

const ContactUsSchema = Joi.object({
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .allow("", null)
    .messages({
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),

  // phone_no: Joi.string().allow("", null).label("Phone number"),

  facebook_url: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("", null)
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),

  twitter_url: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("", null)
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),

  instagram_url: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("", null)
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),

  linkdin_url: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("", null)
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),
  tiktok_url: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("", null)
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),

  address: Joi.string().allow("", null).label("Address"),
});

export default ContactUsSchema;
