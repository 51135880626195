import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { get, post, put } from "../../../server";
import { SETTING } from "../../../utils/constants/apiUrl";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../../components/Loader";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
import AddEditFaqSchema from "../../../utils/SchemasValidation/AddEditFaqSchema";
import ValidateSchema from "../../../helpers/ValidateSchema";
import Button from "react-bootstrap/Button";

const AddEditFaqs = () => {
  let emptyObj = {
    question: "",
    answer: "",
  };

  const { faqId } = useParams();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [faq, setFaq] = useState(emptyObj);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFaq({ ...faq, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(faq, AddEditFaqSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);

    if (faqId) {
      let { status, message } = await put(
        SETTING + `/faq/${faqId}`,
        faq,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        navigate("/faqs");
      }
      showToast(status, message);
    } else {
      let { status, message } = await post(
        SETTING + "/faq",
        faq,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        navigate("/faqs");
      }
      showToast(status, message);
    }
    setLoading(false);
  };

  const loadSingleFaq = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      SETTING + `/faq/${faqId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setFaq(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (faqId) loadSingleFaq();
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <h6> {faqId ? "Edit" : "Add"} Faqs </h6>
          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className={classes.inputsWrapper}>
              <div>
                <input
                  type="text"
                  placeholder=" Question"
                  name="question"
                  value={faq.question}
                  className={
                    errors?.question ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.question || ""}</p>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Answer"
                  name="answer"
                  value={faq.answer}
                  className={
                    errors?.answer ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.answer || ""}</p>
              </div>
            </div>
            <div className="formBottom">
              <Button
                className="submitButton"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <style type="text/css">
        {`

	      `}
      </style>
    </>
  );
};

export default AddEditFaqs;
