const Joi = require("joi");
const AddEditFaqSchema = Joi.object({
  id: Joi.allow(null),
  question: Joi.string().min(3).required().messages({
    "string.empty": `Please enter question`,
    "string.min": `Question must be minimum 3 characters!`,
  }),
  answer: Joi.string().min(3).required().messages({
    "string.empty": `Please enter answer`,
    "string.min": `Answer must be minimum 3 characters!`,
  }),
});

export default AddEditFaqSchema;
