import React, { useRef, useState } from "react";
import { HIERARCHY_TREE } from "../../utils/constants/apiUrl";
import classes from "../../assets/styles/ManageNodes.module.css";
import { useEffect } from "react";
import { get } from "../../server";
import { loggedInUser, mobileViewCheck, teamIDCheck } from "../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../components/Loader";
import { Tree } from "react-organizational-chart";
import showToast from "../../helpers/showToast";
import { Overlay, Tooltip } from "react-bootstrap";
import html2canvas from "html2canvas";
import { BsThreeDotsVertical } from "react-icons/bs";
import ViewRenderTree from "./ViewRenderTree";
import QRCodeModal from "../../components/Modals/QRCodeModal";
import { SHARE_QR_NODE } from "../../utils/constants/common";

const ViewHierarchy = () => {
  const target = useRef(null);
  const targetDivRef = useRef(null);

  const [loggedUser] = useAtom(loggedInUser);
  const [userTeamId] = useAtom(teamIDCheck);
  const [mobileView] = useAtom(mobileViewCheck);
  const [loading, setLoading] = useState(false);
  const [activeFunctionQrOpen, setActiveFunctionQrOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({ name: "" });

  const [treeData, setTreeData] = useState({
    id: null,
    name: "",
    team_id: null,
    children: [],
  });
  const [teamQrOpen, setTeamQrOpen] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [showCopyPopup, setShowCopyPopup] = useState(false);

  const handleActiveFunctionQrOpen = (functionData) => {
    setActiveFunctionQrOpen(true);
    setSelectedData({
      functionType: functionData.function_type,
      uuid: functionData.function.uuid,
    });
  };
  const handleActiveFunctionQrClose = () => {
    setActiveFunctionQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData({ name: "" });
  };

  const handleActiveFunctionQrDownload = () => {
    html2canvas(document.querySelector("#active-function-qr-code")).then(
      function (canvas) {
        const link = document.createElement("a");
        link.download = "qrcode.png";
        link.href = canvas.toDataURL();
        link.click();
      }
    );
  };

  const handleQrOpen = (uuid) => {
    setSelectedData(uuid);
    setQrOpen(true);
  };
  const handleQRClose = () => {
    setQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleTeamQrOpen = (uuid) => {
    setSelectedData(uuid);
    setTeamQrOpen(true);
  };

  const handleTeamQRClose = () => {
    setTeamQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handleTeamQrDownload = () => {
    html2canvas(document.querySelector("#team-qr-code")).then(function (
      canvas
    ) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handleTeamMemberViewFunctions = (obj) => {
    window.open(
      `/publicFunctions/${treeData.team_id}/${
        obj.hasOwnProperty("check_status")
          ? obj.user_id
          : obj.team_member.user_id
      }`,
      "_blank"
    );
  };

  // More Understandable

  // Define a recursive function to traverse the node array
  // function traverseNode(node, teamMembers) {
  //   // Loop through each node object
  //   for (let i = 0; i < node?.length; i++) {
  //     // Get the current node object
  //     let currentNode = node[i];
  //     // Loop through each team member object
  //     for (let j = 0; j < teamMembers.length; j++) {
  //       // Get the current team member object
  //       let currentTeamMember = teamMembers[j];
  //       // Compare the id of the node object with the hierarchy tree id of the team member object
  //       if (currentNode.id === currentTeamMember.hierarchy_tree_id) {
  //         // Push the team member object into the children key of the node object
  //         currentNode.children.push(currentTeamMember);
  //       }
  //     }
  //     // Check if the node object has any children
  //     if (currentNode?.children?.length > 0) {
  //       // Call the function recursively on the children array
  //       traverseNode(currentNode.children, teamMembers);
  //     }
  //   }
  // }

  function traverseNode(node, teamMembers) {
    // Loop through each node object
    for (let i = 0; i < node?.length; i++) {
      // Get the current node object
      let currentNode = node[i];
      // Loop through each team member object
      // Check if the node object has any children
      if (currentNode?.children?.length > 0) {
        // Call the function recursively on the children array
        traverseNode(currentNode.children, teamMembers);
      }

      for (let j = 0; j < teamMembers?.length; j++) {
        // Get the current team member object
        let currentTeamMember = teamMembers[j];

        // Compare the id of the node object with the hierarchy tree id of the team member object
        if (currentNode.id === currentTeamMember.hierarchy_tree_id) {
          // Push the team member object into the children key of the node object

          if (currentTeamMember.function_type !== null) {
            // Create a 'children' array in the current team member if it doesn't exist
            currentTeamMember.children = [];
            // Push the 'function' object into the 'children' array
            currentTeamMember.children.push({
              function: currentTeamMember.function,
              function_type: currentTeamMember.function_type,
              id: currentTeamMember.id,
              activeFunction: "activeFunction",
            });
          }
          currentNode?.children?.push(currentTeamMember);
        }
      }
    }
  }

  const getTrees = async () => {
    setLoading(true);

    let { status, message, data } = await get(
      `${HIERARCHY_TREE}/${userTeamId || null}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      // Call the function on the node array and the team members array
      traverseNode(data.node, data.team_members);

      setTreeData((prevTreeData) => {
        return {
          ...prevTreeData,
          name: data.team.name,
          team_id: data.team.id,
          children: data.node,
          // children:
          //   data.node && data?.node?.length > 0
          //     ? data?.node
          //     : data?.other_members, // Update the children array directly with the fetched data
        };
      });
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  useEffect(() => {
    getTrees();
  }, []);

  useEffect(() => {
    // Scroll to the target div when the component mounts
    if (treeData && Object.keys(treeData).length > 0) {
      const targetDiv = targetDivRef.current;
      if (targetDiv) {
        targetDiv.scrollIntoView({
          behavior: "instant",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [treeData]);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="mt-3">
        <h6 className={classes.title}>Hierarchy Tree</h6>
      </div>

      <div className={classes.boxWrapper}>
        <div>
          <Tree
            lineWidth={"2px"}
            lineHeight="50px"
            lineColor={"var(--primary_text_color)"}
            lineBorderRadius={"10px"}
            label={
              <div className="manageNodesDropdownWrapper">
                <div className="dropdown" ref={targetDivRef}>
                  <button
                    className="btn customActionDropdown dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className={classes.memberNodeBox}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <div className={classes.teamMemberImage}>
                            <div className={classes.nodeImage}>
                              {treeData?.name?.charAt(0)}
                            </div>
                          </div>
                          <div>
                            <p className={classes.nodeName}>{treeData.name}</p>
                          </div>
                        </div>
                        <div className={classes.threedotsWrapper}>
                          <BsThreeDotsVertical />
                        </div>
                      </div>
                    </div>
                  </button>
                  <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => handleTeamQrOpen(treeData.team_id)}
                      >
                        QR Code
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            }
          >
            {treeData?.children &&
              treeData?.children.map((child) => (
                <ViewRenderTree
                  key={child.id}
                  node={child}
                  handleQrOpen={handleQrOpen}
                  handleTeamMemberViewFunctions={handleTeamMemberViewFunctions}
                  handleActiveFunctionQrOpen={handleActiveFunctionQrOpen}
                />
              ))}
          </Tree>
        </div>
      </div>

      {teamQrOpen && (
        <QRCodeModal
          target={target}
          qrOpen={teamQrOpen}
          handleQRClose={handleTeamQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleTeamQrDownload}
          url={`${SHARE_QR_NODE}team/${selectedData}`}
          qrCodeId="team-qr-code"
        />
      )}
      {qrOpen && (
        <QRCodeModal
          target={target}
          qrOpen={qrOpen}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleDownload}
          url={`${SHARE_QR_NODE}node/${selectedData}`}
        />
      )}
      {activeFunctionQrOpen && (
        <QRCodeModal
          target={target}
          qrOpen={activeFunctionQrOpen}
          handleQRClose={handleActiveFunctionQrClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleActiveFunctionQrDownload}
          functionType={
            selectedData.functionType === "cash_app"
              ? "cashApp"
              : selectedData.functionType === "custom_payment"
              ? "customPayment"
              : selectedData.functionType === "custom_url"
              ? "customUrl"
              : selectedData.functionType === "file_upload"
              ? "fileUpload"
              : selectedData.functionType === "emergency_contact"
              ? "emergencyContact"
              : selectedData.functionType
          }
          functionUuid={selectedData.uuid}
          qrCodeId="active-function-qr-code"
        />
      )}
      <Overlay
        target={target.current}
        show={showCopyPopup}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default ViewHierarchy;
