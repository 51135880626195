import React, { useState, useEffect } from "react";
import classes from "../../assets/styles/Pages/Help/PrivacyAndPolicy.module.css";
import privacy_image from "../../assets/images/help/privacy.svg";
import { Link } from "react-router-dom";
import { SETTING } from "../../utils/constants/apiUrl";
import { get } from "../../server";
import showToast from "../../helpers/showToast";
import Loader from "../../components/Loader";

const PrivacyAndPolicy = () => {
  const [loading, setLoading] = useState(false);
  const [privacy, setprivacy] = useState({});

  const loadPrivacyPolicy = async () => {
    setLoading(true);
    let { status, message, data } = await get(SETTING + "/page");
    if (status) {
      if (data.length > 0) {
        const privacyPolicy = data.filter(
          (page) => page.page_type === "privacy"
        );
        setprivacy(privacyPolicy["0"]);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPrivacyPolicy();
  }, []);
  return (
    <>
      <div className="helpBackLinkWrapper">
        <Link to="/help">Back</Link>
      </div>
      <div className={classes.helpHeader}>
        <div className="row flex-md-row flex-column-reverse gy-4">
          {/* PrivacyAndPolicy top left section */}
          <div className="col-sm-6">
            <h3 className="helpHeading">Privacy & Policy</h3>
            <p className="helpSubHeading">Contact Co Privacy Policy</p>
            <p className="helpDetail">
              Contact Co (“us”, “we”, or “our”) operates the Contact Co website
              and mobile application (the “Service”). This page informs you of
              our policies regarding the collection, use, and disclosure of
              Personal Information when you use our Service, including
              information related to our subscription services.
            </p>
          </div>

          {/*Right Section Image */}
          <div className="col-sm-6">
            <img src={privacy_image} alt="" className="helpImage" />
          </div>
        </div>
      </div>

      {!loading ? (
        <>
          <div className={`${classes.contentWrapper}`}>
            <p className="helpSubHeading">{privacy?.title}</p>

            <p
              className={classes.detail}
              dangerouslySetInnerHTML={{ __html: privacy?.description }}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default PrivacyAndPolicy;
