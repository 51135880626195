import React, { useState } from "react";
import FilterIcon from "../assets/images/contact_page_icons/filtericon.svg";
import { Card_URL } from "../utils/constants/apiUrl";
import classes from "../assets/styles/Contact.module.css";
import GridView from "../components/PageComponents/Contact/GridView";
import ListView from "../components/PageComponents/Contact/ListView";
import { BsGrid } from "react-icons/bs";
import { TbLayoutList } from "react-icons/tb";
import { useEffect } from "react";
import { get, deleteRecord } from "../server";
import { loggedInUser, teamIDCheck } from "../utils/store";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import Loader from "../components/Loader";
import ConfirmationModal from "../components/Modals/ConfirmationModal";
import showToast from "../helpers/showToast";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import AddNewCard from "../components/PageComponents/Home/AddNewCard";
import PersonalCard from "../components/PageComponents/Home/PersonalCard";
import BusinessCard from "../components/PageComponents/Home/BusinessCard";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import heading from "../assets/images/home_page_images/heading.svg";
import arrow from "../assets/images/home_page_images/arrow.svg";
import dashedArrow from "../assets/images/home_page_images/dashedArrow.svg";
import remove from "../assets/images/home_page_images/remove.svg";
import EmptyListingBanner from "../components/EmptyListingBanner";
import functionTypes from "../utils/constants/functionTypes";
import useFunctionsCount from "../hooks/useFunctionsCount";

const Card = () => {
  const navigate = useNavigate();
  const { teamId, userId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [gridView, setGridView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [personalCards, setPersonalCards] = useState([]);
  const [businessCards, setBusinessCards] = useState([]);
  const [removeInfoSection, setRemoveInfoSection] = useState(false);
  const { compareLimits, updateCount } = useFunctionsCount();

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleRemoveInfoSection = () => {
    setRemoveInfoSection(true);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseModal(true);
    setLoading(true);
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }

    let { status, message } = await deleteRecord(
      `${Card_URL}/${deleteId}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    showToast(status, message);

    if (status) {
      updateCount(functionTypes.CARD, functionTypes.DELETE);
      await loadCard();
      setLoading(false);
    }
  };

  const loadCard = async () => {
    setLoading(true);
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }

    if (userId || user_id) {
      queryParams += `${queryParams ? "&" : "?"}user_id=${userId || user_id}`;
    }
    let { status, message, data } = await get(
      `${Card_URL}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (data.length > 0) {
        handlePersonalBusinessCard(data);
        setCards(data);
      } else {
        setPersonalCards([]);
        setBusinessCards([]);
        setCards([]);
      }
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  const handlePersonalBusinessCard = (data) => {
    let personalCardsData = [];
    let businessCardsData = [];

    data.forEach((card) => {
      if (card.card_type === "personal") {
        personalCardsData.push(card);
      } else {
        businessCardsData.push(card);
      }

      setPersonalCards(personalCardsData);
      setBusinessCards(businessCardsData);
    });
  };

  const handlePersonalScrollLeft = () => {
    document.getElementById("personalCardWrapper").scrollLeft -= 220;
  };

  const handlePersonalScrollRight = () => {
    document.getElementById("personalCardWrapper").scrollLeft += 220;
  };

  const handleBusinessScrollLeft = () => {
    document.getElementById("businessCardWrapper").scrollLeft -= 220;
  };

  const handleBusinessScrollRight = () => {
    document.getElementById("businessCardWrapper").scrollLeft += 220;
  };

  useEffect(() => {
    loadCard();
  }, [userTeamId]);
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
      {!loading ? (
        <div className="">
          <div className="">
            <div className={`cardsMainWrapper`}>
              <h6 className={classes.title}>
                <Link
                  onClick={handleGoBack}
                  style={{ color: "var(--heading_text_color)" }}
                >
                  Functions
                </Link>
                {` > Cards`}
              </h6>
              {personalCards.length === 0 && businessCards.length === 0 ? (
                <EmptyListingBanner
                  headLineText="card functions"
                  title="Add Card"
                  type="card"
                  link={
                    teamId && userId
                      ? `/team/${teamId}/teamMembers/member/${userId}/function/cardfunctions`
                      : `/function/cardfunctions${
                          team_id && user_id
                            ? `?team_id=${team_id}&user_id=${user_id}`
                            : ""
                        }`
                  }
                  getData={loadCard}
                />
              ) : (
                <>
                  <div className="cardsWrapper">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="totalBusinessCardsTitle">
                        Personal Card ({personalCards.length})
                      </p>
                      <div>
                        <FiChevronLeft
                          className="arrowBtn"
                          onClick={handlePersonalScrollLeft}
                        />
                        <FiChevronRight
                          className="arrowBtn"
                          onClick={handlePersonalScrollRight}
                        />
                      </div>
                    </div>

                    <div className="boxWrapper" id="personalCardWrapper">
                      <AddNewCard
                        teamId={teamId}
                        userId={userId}
                        team_id={team_id}
                        user_id={user_id}
                        getData={loadCard}
                      />
                      {personalCards.map((card) => {
                        return (
                          <PersonalCard
                            key={card.id}
                            {...card}
                            handleDelete={handleDelete}
                            cardListing={personalCards}
                            setCardListing={setPersonalCards}
                            teamId={teamId}
                            userId={userId}
                            team_id={team_id}
                            user_id={user_id}
                            loadCard={loadCard}
                          />
                        );
                      })}
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="totalBusinessCardsTitle">
                        Business Card ({businessCards.length})
                      </p>
                      <div>
                        <FiChevronLeft
                          className="arrowBtn"
                          onClick={handleBusinessScrollLeft}
                        />
                        <FiChevronRight
                          className="arrowBtn"
                          onClick={handleBusinessScrollRight}
                        />
                      </div>
                    </div>
                    <div className="boxWrapper" id="businessCardWrapper">
                      {businessCards.map((card) => {
                        return (
                          <BusinessCard
                            key={card.id}
                            {...card}
                            handleDelete={handleDelete}
                            cardListing={businessCards}
                            setCardListing={setBusinessCards}
                            teamId={teamId}
                            userId={userId}
                            team_id={team_id}
                            user_id={user_id}
                            loadCard={loadCard}
                          />
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <style type="text/css">
        {`
        .cardsMainWrapper {
          
        }
        .cardsMainWrapper h6 {
          font-weight: 700;
          line-height: 100%;
        }

        .cardsWrapper {
          background: var(--secondary_color);
          padding: 40px 24px;
          margin: 18px 0px;
          border-radius: 20px;
        }

        .linkViewAll {
          padding: 6px 10px;
          background: var(--secondary_Color);
          border-radius: 10px;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: var(--primary_text_color);
          text-transform: capitalize;
        }
        .linkViewAll span {
          margin-left: 15px;
        }
        .linkViewAll:hover {
          color: var(--primary_text_color);
          opacity: 0.7;
        }

        .boxWrapper {
          display: flex;
          flex-wrap: nowrap;
          column-gap: 30px;
          margin-top: 20px;
          overflow-y: auto;
          overflow-x: scroll;
          scroll-behavior: smooth;
          scrollbar-width: none;
          padding-top: 20px;
          padding-bottom: 30px;
        }

        .boxWrapper::-webkit-scrollbar {
          display: none;
          width: 5px;
          height: 3px;
        }

        /* Track */
        .boxWrapper::-webkit-scrollbar-track {
          border-radius: 0;
          background: var(--white);
        }

        /* Handle */
        .boxWrapper::-webkit-scrollbar-thumb {
          border-radius: 0px;
          background: var(--dark);
        }

        .totalBusinessCardsTitle {
          font-size: 14px;
          line-height: 100%;
          color: var(--primary_text_color);
        }


        .arrowBtn {
          width: 30px;
          height: 30px;
          background-color: var(--secondary_Color);
          color: var(--primary_text_color);
          border-radius: 10px;
          padding: 4px;
          margin-left: 15px;
          cursor: pointer;
        }

        .arrowBtn:hover {
          color: var(--primary_text_color);
          opacity: 0.7;
        }

        .romoveInfoMainWrapper {
          display: none;
        }

        .infoWrapper {
          background: var(--tertiary_color);
          border: 2px solid var(--border_color);
          border-radius: 20px;
          padding: 15px;
          margin-top: 70px;
          position: relative;
        }

        .infoRemoveBtn {
          position: absolute;
          top: -20px;
          right: 20px;
          cursor: pointer;
        }

        .contentWrapper {
          display: flex;
          align-items: flex-start;
          gap: 20px;
        }
        
        .contentWrapper img { 
          width: 28px;
          height: 28px;
        }

        .contentWrapper h6 {
          font-weight: 600;
          line-height: 24px;
          color: var(--primary_text_color);
          padding-bottom: 10px;
        }

        .contentWrapper p {
          font-size: 14px;
          line-height: 21px;
          color: var(--primary_text_color);
          padding-bottom: 10px;
        }

        .arrowImgWrapper {
          display: flex;
          align-items: flex-end;
          margin-bottom: 200px;
        }

        .arrowImg {
          margin-inline: 20px;
          flex: 0 0 40%;
          max-width: 40%;
        }

        .createBtnWrapper {
          flex: 1;
        }

        .arrowImg img {
          width: 100%;
        }

        .createBtnWrapper a {
          white-space: nowrap;
          padding: 10px 20px;
          background: linear-gradient(106.6deg, var(--primary_button_color_light) 0%, var(--primary_button_color) 99.84%);
          box-shadow: 0px 6px 20px rgba(34, 160, 129, 0.4);
          border-radius: 50px;
          color: var(--primary_text_color);
          transition: 0.3s all ease;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
        }
        
        .createBtnWrapper a:hover {
          opacity: 0.7;
        }

	`}
      </style>

      <ConfirmationModal
        show={showModal}
        handleCloseModal={handleCloseModal}
        handleConfirmDelete={handleConfirmDelete}
        confirmTitle="card"
      />
    </>
  );
};

export default Card;
