import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import classes from "../assets/styles/CardView.module.css";
import Card from "../components/PageComponents/Function/Card";
const CardView = () => {
  return (
    <>
      <div className="container">
        <div className={classes.breadCrumb}>
          <Link>Functions</Link>
          <span>
            <MdKeyboardArrowRight />
          </span>
          <Link>Card view</Link>
        </div>
        <div className={classes.cardsMainWrapper}>
          <div className={classes.cardsWrapper}>
            <Card />
          </div>
        </div>
      </div>
    </>
  );
};

export default CardView;
