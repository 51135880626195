import React, { useState, useEffect, useRef } from "react";
import classes from "../../assets/styles/Pages/QRCode/QRCode.module.css";
import { QRCode } from "react-qrcode-logo";
import qrCodeBorder from "../../assets/images/qr-code-border.svg";
import { SHARE_QR } from "../../utils/constants/common";
import qrCodeLogo from "../../assets/images/sidebar_icons/collapsedLogo.svg";
import { GiShare } from "react-icons/gi";
import baseUrl from "../../utils/constants/baseUrl";
import Loader from "../../components/Loader";
import { useAtom } from "jotai";
import { GET_USER, DYNAMIC_QRCODE } from "../../utils/constants/apiUrl";
import { get, deleteRecord, post } from "../../server";
import showToast from "../../helpers/showToast";
import formatPhoneNumber from "../../helpers/formatPhoneNumber";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import ShareModal from "../../components/Modals/ShareModal";
import {
  loggedInUser,
  teamIDCheck,
  subscribedPackage,
  mobileViewCheck,
} from "../../utils/store";
import html2canvas from "html2canvas";
import TestQRCode from "./TestQRCode";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
const functionTypeMap = {
  card: "Digital Card",
  payment: "Payment Service",
  emergency_contact: "Emergency Contact",
  custom_url: "Custom URL’s",
  file_upload: "File Upload URL",
};
const ProfileQRCode = ({
  url,
  qr_bg_color,
  qr_front_color,
  qr_image,
  qrCodeId,
  value,
  logo,
}) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [teamID, setTeamID] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [bgColor, setBgColor] = useState(qr_bg_color || "#ffffff");
  const [fgColor, setFgColor] = useState(qr_front_color || "#000000");
  const [logoImageURL, setLogoImageURL] = useState(qr_image || qrCodeLogo);
  const [qrCodeData, setQRCodeData] = useState([]);
  const [shareOpen, setShareOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [uuid, setUuid] = useState();
  const [data, setData] = useState({});
  const [qrcodeImage, setQRcodeImage] = useState();
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [mobileView] = useAtom(mobileViewCheck);
  const functionType = teamID ? "profile_owner" : "profile_personal";
  const target = useRef(null);
  const loadProfile = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      GET_USER,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setData(data);
      if (teamID) {
        setQRcodeImage(
          baseUrl.PUBLIC_URL + data.qrCodeCustomizeOwner?.qr_code_image
        );
      } else {
        setQRcodeImage(
          baseUrl.PUBLIC_URL + data.qrCodeCustomizePersonal?.qr_code_image
        );
      }

      console.log("setQRcodeImage", qrcodeImage);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const handleShareOpen = (uuid) => {
    setShareOpen(true);
    setUuid(uuid);
  };
  const handleShareClose = () => {
    setShareOpen(false);
    setShowCopyPopup(false);
    setShow(false);
  };

  useEffect(() => {
    loadProfile();
  }, []);
  const qrRef = useRef(null);
  const handleCapture = () => {
    html2canvas(qrRef.current).then((canvas) => {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.href = url;
      a.download = "qrcode.png";
      a.click();
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-12">
          {/* <h6
            style={{
              marginBottom: "20px",
              fontSize: "14px",
              fontWeight: "700",
            }}
          >
            Contact Code
          </h6> */}
          <div className={classes.qrWrapper}>
            <div className={classes.qrCodeInner}>
              <div className={classes.qrCode}>
                <div className={classes.modalGradientCircle}></div>
                <img src={qrCodeBorder} />
                {data.qrCodeCustomizeOwner?.qr_code_image == undefined ? (
                  <QRCode
                    value={
                      url ? url : `${SHARE_QR}${functionType}/${data.uuid}`
                    }
                    id={qrCodeId ? qrCodeId : "qr-code"}
                    logoImage={logoImageURL ? logoImageURL : qrCodeLogo}
                    logoOpacity={1}
                    logoPadding={3}
                    bgColor={bgColor}
                    fgColor={fgColor}
                    eyeRadius={0}
                    removeQrCodeBehindLogo
                  />
                ) : data.qrCodeCustomizePersonal?.qr_code_image == undefined ? (
                  <QRCode
                    value={
                      url ? url : `${SHARE_QR}${functionType}/${data.uuid}`
                    }
                    id={qrCodeId ? qrCodeId : "qr-code"}
                    logoImage={logoImageURL ? logoImageURL : qrCodeLogo}
                    logoOpacity={1}
                    logoPadding={3}
                    bgColor={bgColor}
                    fgColor={fgColor}
                    eyeRadius={0}
                    removeQrCodeBehindLogo
                  />
                ) : (
                  <>
                    <img
                      className={classes.qrCodeImage}
                      src={qrcodeImage}
                      style={{
                        borderRadius: "10px",
                        top: "-10px",
                        width: "170px",
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <h5 style={{ margin: "10px 0 20px" }}>QR Code</h5>
            <p className={classes.contentText} style={{}}>
              {data.name}
            </p>
            {data.email ? (
              <p className={classes.contentText}>
                <a href={`mailto:${data.email}`}>{data.email}</a>
              </p>
            ) : (
              ""
            )}
            {data.phone_number ? (
              <p className={classes.contentText}>
                <a href={`tel:${data.phone_number}`}>
                  {`+${data.country_phone}${formatPhoneNumber(
                    data.phone_number
                  )}`}
                </a>
              </p>
            ) : (
              ""
            )}
            <div className={classes.action}>
              <button
                className="btn customActionDropdown dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
              </button>
              <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
                {/* <li>
                  <Link className="dropdown-item" to={`addEditQRCode`}>
                    Edit
                  </Link>
                </li> */}
                <li>
                  <Link
                    className="dropdown-item"
                    onClick={() => handleShareOpen(data.uuid)}
                  >
                    Share
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {shareOpen && (
        <>
          {console.log(
            "data.qrCodeCustomizeOwner",
            data.qrCodeCustomizeOwner?.admin_qr_code_image_id !== 0
              ? "admin_qr_code"
              : teamID
          )}
          <ShareModal
            shareOpen={shareOpen}
            target={target}
            handleShareClose={handleShareClose}
            setShowCopyPopup={setShowCopyPopup}
            functionType={
              data.qrCodeCustomizeOwner !== null &&
              data.qrCodeCustomizeOwner?.admin_qr_code_image_id !== 0
                ? "admin_qr_code"
                : teamID
                ? "profile_owner"
                : data.qrCodeCustomizePersonal !== null &&
                  data.qrCodeCustomizePersonal?.admin_qr_code_image_id !== 0
                ? "admin_qr_code"
                : "profile_personal"
            }
            //functionUuid={uuid}
            // functionUuid={
            //   data.qrCodeCustomizeOwner !== null
            //     ? data.qrCodeCustomizeOwner.admin_q_r_code_image.uuid
            //     : teamID
            //     ? uuid
            //     : data.qrCodeCustomizePersonal !== null &&
            //       data.qrCodeCustomizePersonal?.admin_qr_code_image_id !== 0
            //     ? data.qrCodeCustomizePersonal.admin_qr_code_image.uuid
            //     : uuid
            // }
            functionUuid={
              teamID && data.qrCodeCustomizeOwner !== null
                ? data.qrCodeCustomizeOwner.admin_q_r_code_image.uuid
                : teamID
                ? uuid
                : data.qrCodeCustomizePersonal !== null &&
                  data.qrCodeCustomizePersonal.admin_qr_code_image_id !== 0
                ? data.qrCodeCustomizePersonal.admin_q_r_code_image.uuid
                : uuid
            }
          />
          <Overlay
            target={target.current}
            show={showCopyPopup}
            placement={mobileView ? "left" : "right"}
          >
            {(props) => (
              <Tooltip id="overlay-example" {...props}>
                Link Copied
              </Tooltip>
            )}
          </Overlay>
        </>
      )}
      <style type="text/css">
        {`
          .form-check{
            background-color: var(--secondary_color);
            border-radius: 10px;
            padding: .75rem .5rem .75rem 2.15rem;
            margin-bottom:.75rem;
            width:100%;
          }
          .form-check-input:disabled, .form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label{
            opacity: 1;
          }
          .form-check-label{
            font-size:13px;
          }
          .imageWrapper {
            position: relative;
            width: 16px !important;
            height: 16px !important;
            margin: auto;
          }
          .imageWrapper::before{
            background:transparent !important;
          }
          .imageWrapper img{display:none;}
          .imageWrapper .icon {
            color:var(--primary_button_color);
            font-size:18px
          }
          .customizedSidebar{
            background-color: var(--secondary_color);
            border-radius: 10px;
            padding: 15px;
            height:fit-content;
          }
          .customizedSidebar li img{
            width: 16px !important;
            height: 16px !important;
          }
          .customizedSidebar li input{
            width: 16px;
            height: 16px;
            border: 0;
            padding: 0;
            border-radius:50%;
          }
          .customizedSidebar li button{
            background-color:transparent;
            border:0;
            padding:0;
          }
        `}
      </style>
    </>
  );
};
export default ProfileQRCode;
