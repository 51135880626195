import React, { useState, useRef, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import classes from "../../../assets/styles/PageComponentsStyles/Function/ShowCustomURLSFunctions.module.css";
import DataTable, { createTheme } from "react-data-table-component";
import { MdContentCopy } from "react-icons/md";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import html2canvas from "html2canvas";
import { useAtom } from "jotai";
import { mobileViewCheck } from "../../../utils/store";
import ShareModal from "../../Modals/ShareModal";
import QRCodeModal from "../../Modals/QRCodeModal";
import { Link } from "react-router-dom";
import { FORM_BUILDER } from "../../../utils/constants/apiUrl";
import { deleteRecord } from "../../../server";
import { useParams } from "react-router-dom";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import { loggedInUser } from "../../../utils/store";

const ShowFormBuilderFunctions = ({ FormBuilders, handleDelete }) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const target = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [mobileView] = useAtom(mobileViewCheck);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  let { id } = useParams();
  // console.log("selectedData", selectedData.uuid);
  const handleQrOpen = (obj) => {
    setSelectedData(obj);
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleShareOpen = (obj) => {
    setSelectedData(obj);
    setShareOpen(true);
  };

  const handleShareClose = () => {
    setShareOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Form Name",
      width: "30%",
      cell: (row) => <p className="globalTitle">{row.name}</p>,
    },
    {
      name: "Form Fields",
      width: "60%",
      cell: (row) => (
        <p
          className="globalTitle"
          style={{ maxWidth: "max-content", whiteSpace: "normal" }}
        >
          {row.formBuilderField
            .map((field) => {
              if (field.type !== "paragraph") {
                return field.label;
              } else {
                return field.type.charAt(0).toUpperCase() + field.type.slice(1);
              }
            })
            .join(", ")}
        </p>
      ),
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <span className="dropdown-item" onClick={() => handleQrOpen(row)}>
                QR Code
              </span>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to={`/function/formBuilder/${row.id}`}
              >
                Edit
              </Link>
            </li>
            <li>
              <span
                className="dropdown-item"
                onClick={() => handleShareOpen(row)}
              >
                Share
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252", cursor: "pointer" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </span>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="listingWrapper">
        <DataTable
          columns={columns}
          data={FormBuilders}
          theme="solarized"
          pagination
        />
      </div>

      {qrOpen && (
        <QRCodeModal
          qrOpen={qrOpen}
          target={target}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleDownload}
          functionType="form_builder"
          functionUuid={selectedData.uuid}
          id={selectedData.id}
          function_type={selectedData.function_type}
          function_id={selectedData.id}
          qr_bg_color={selectedData.qrCodeCustomize?.qr_bg_color}
          qr_front_color={selectedData.qrCodeCustomize?.qr_front_color}
          qr_image={selectedData.qrCodeCustomize?.qr_image}
          qr_code_image={selectedData.qrCodeCustomize?.qr_code_image}
        />
      )}

      {shareOpen && (
        <ShareModal
          target={target}
          shareOpen={shareOpen}
          handleShareClose={handleShareClose}
          setShowCopyPopup={setShowCopyPopup}
          functionType="form_builder"
          functionUuid={selectedData.uuid}
        />
      )}

      <Overlay
        target={target.current}
        show={showCopyPopup}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default ShowFormBuilderFunctions;
