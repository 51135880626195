import { useState, useRef } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Home/GetEmergencyContact.module.css";
import phone from "../../../assets/images/home_page_images/phone.svg";
import { Image } from "react-bootstrap";
import Loader from "../../Loader";
import baseUrl from "../../../utils/constants/baseUrl";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlinePhone } from "react-icons/hi";
import { Link } from "react-router-dom";
import dummyImage from "../../../assets/images/no_image.jpg";
import { MdContentCopy } from "react-icons/md";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import html2canvas from "html2canvas";
import { GiShare } from "react-icons/gi";
import { QRCode } from "react-qrcode-logo";
import { MdClose } from "react-icons/md";
import { Modal } from "react-bootstrap";
import EmergencyAddEditModal from "../../Modals/EmergencyAddEditModal";
import qrCodeBorder from "../../../assets/images/qr-code-border.svg";
// import pictureicon from "../../../assets/images/function_page_icons/pictureicon.svg";
import pictureicon from "../../../assets/images/function_page_icons/picIcon.svg";
import { useAtom } from "jotai";
import { mobileViewCheck } from "../../../utils/store";
import formatPhoneNumber from "../../../helpers/formatPhoneNumber";
import ShareModal from "../../Modals/ShareModal";
import QRCodeModal from "../../Modals/QRCodeModal";

const GetEmergencyContact = ({
  id,
  image,
  first_name,
  last_name,
  phone_number,
  country_phone,
  description,
  uuid,
  emptyEmergencyContactData,
  handleSaveShowModal,
  selectedData,
  setSelectedData,
  handleEmergencyDelete,
  loading,
  publicMode,
}) => {
  let name = first_name + " " + last_name;
  let length = 15;

  const target = useRef(null);

  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [mobileView] = useAtom(mobileViewCheck);

  const handleOpenModal = () => {
    let newObj = {
      id,
      uuid,
      first_name,
      last_name,
      image,
      phone_number,
      country_phone,
      description,
    };

    if (newObj && Object.keys(newObj).length) {
      if (newObj.phone_number) {
        newObj.phone_number = `${
          newObj.country_phone ? newObj.country_phone : ""
        }${newObj.phone_number}`;
      }

      if (newObj.country_phone) {
        newObj.country_phone = `+${newObj.country_phone}`;
      }
    }

    setSelectedData(newObj);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedData(emptyEmergencyContactData);
    setShowModal(false);
  };

  const handleQrOpen = () => {
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShowCopyPopup(false);
  };

  const handleShareOpen = () => {
    setShareOpen(true);
  };

  const handleShareClose = () => {
    setShareOpen(false);
    setShowCopyPopup(false);
  };

  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  return (
    <>
      <div className={classes.emergencyContactWrapper}>
        <div className={classes.contentWrapper}>
          <Image
            className="globalIMageFit"
            src={image ? baseUrl.PUBLIC_URL + image : pictureicon}
            width="46px"
            height="46px"
            roundedCircle={true}
            loading="lazy"
            decoding="async"
            onError={(e) => {
              e.currentTarget.src = pictureicon;
            }}
          />

          <div>
            <p className={classes.name}>{name}</p>

            <div className={classes.numberWrapper}>
              <HiOutlinePhone />

              <a
                href={`tel:${`${country_phone ? `+${country_phone}` : ""}${
                  formatPhoneNumber(phone_number) || ""
                }`}`}
                className={classes.number}
              >
                {`${country_phone ? `+${country_phone}` : ""}${
                  formatPhoneNumber(phone_number) || ""
                }`}
              </a>
            </div>
          </div>
        </div>

        <div className={classes.actionsWrapper}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={classes.threeDotsIcon} />
          </button>
          {publicMode ? (
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
              <li>
                <span className="dropdown-item" onClick={handleOpenModal}>
                  View
                </span>
              </li>
              <li>
                <span className="dropdown-item" onClick={handleQrOpen}>
                  QR Code
                </span>
              </li>
            </ul>
          ) : (
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
              <li>
                <Link
                  className="dropdown-item"
                  to={`/function/viewemergencycontact/${id}`}
                >
                  View
                </Link>
              </li>
              <li>
                <span className="dropdown-item" onClick={handleQrOpen}>
                  QR Code
                </span>
              </li>
              <li>
                <span className="dropdown-item" onClick={handleOpenModal}>
                  Edit
                </span>
              </li>
              <li>
                <span className="dropdown-item" onClick={handleShareOpen}>
                  Share
                </span>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  to="#"
                  style={{ color: "#E35252" }}
                  onClick={() => handleEmergencyDelete(id)}
                >
                  Delete
                </span>
              </li>
            </ul>
          )}
        </div>
      </div>

      {showModal && (
        <EmergencyAddEditModal
          show={showModal}
          data={selectedData}
          setSelectedData={setSelectedData}
          // handleSaveShowModal={async () => {
          //   await handleSaveShowModal(selectedData);
          //   handleCloseModal();
          // }}
          handleSaveShowModal={handleSaveShowModal}
          handleCloseModal={handleCloseModal}
          loading={loading}
          publicMode={publicMode}
        />
      )}

      {qrOpen && (
        <QRCodeModal
          qrOpen={qrOpen}
          target={target}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleDownload}
          functionType="emergencyContact"
          functionUuid={uuid}
        />
      )}

      {shareOpen && (
        <ShareModal
          target={target}
          shareOpen={shareOpen}
          handleShareClose={handleShareClose}
          setShowCopyPopup={setShowCopyPopup}
          functionType="emergencyContact"
          functionUuid={uuid}
        />
      )}

      <Overlay
        target={target.current}
        show={showCopyPopup}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default GetEmergencyContact;
