const Joi = require("joi");

const ContactUsSchema = Joi.object({
  first_name: Joi.string().required().messages({
    "string.empty": `Please enter first name`,
  }),

  last_name: Joi.string().required().messages({
    "string.empty": `Please enter last name`,
  }),

  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),

  // phone_no: Joi.string().required().messages({
  //   "string.empty": `Please enter phone number`,
  // }),
  // phone_no: Joi.string()
  //   .pattern(
  //     new RegExp(
  //       /^(?!\b(0)\1+\b)(\+?\d{1,3}[. -]?)?\(?\d{3}\)?([. -]?)\d{3}\3\d{4}$/
  //     )
  //   )
  //   .required()
  //   .messages({
  //     "string.empty": `Please enter your phone number`,
  //     "string.pattern.base": `Please enter a valid phone number`,
  //   }),

  subject: Joi.string().required().messages({
    "string.empty": `Please enter subject`,
  }),

  message: Joi.string().required().messages({
    "string.empty": `Please enter message`,
  }),
});

export default ContactUsSchema;
