import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { get, post, put } from "../../../server";
import { CONTACT_US } from "../../../utils/constants/apiUrl";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../../components/Loader";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
import AdminUserSchema from "../../../utils/SchemasValidation/AdminUserSchema";
import ValidateSchema from "../../../helpers/ValidateSchema";

const AddEditContactUs = () => {
  let emptyObj = {
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    subject: "",
    message: "",
  };

  const { contactUsId } = useParams();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [contactUs, setContactUs] = useState(emptyObj);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setContactUs({ ...contactUs, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(contactUs, AdminUserSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    if (contactUsId) {
      let { status, message, data } = await put(
        CONTACT_US + `/${contactUsId}`,
        contactUs,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        navigate("/contactUs");
      }

      showToast(status, message);
    } else {
      let { status, message, data } = await post(
        CONTACT_US,
        contactUs,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        navigate("/contactUs");
      }
      showToast(status, message);
    }
    setLoading(false);
  };

  const loadSingleContactUs = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      CONTACT_US + `/${contactUsId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setContactUs(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (contactUsId) loadSingleContactUs();
  }, []);
  return (
    <>
      {!loading ? (
        <div>
          <h6>Query Detail</h6>
          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className={classes.inputsWrapper}>
              <div>
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={contactUs.first_name}
                  disabled
                  className={
                    errors?.first_name
                      ? "globalInput errorInput"
                      : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.first_name || ""}</p>
              </div>

              <div>
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={contactUs.last_name}
                  disabled
                  className={
                    errors?.last_name ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.last_name || ""}</p>
              </div>

              <div>
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={contactUs.email}
                  disabled
                  className={
                    errors?.email ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.email || ""}</p>
              </div>

              <div>
                <label htmlFor="">Contact Number</label>
                <input
                  type="text"
                  placeholder="Phone #"
                  name="phone_no"
                  value={`+${contactUs.phone_no}`}
                  disabled
                  className={
                    errors?.phone_no ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.phone_no || ""}</p>
              </div>

              <div>
                <label htmlFor="">Subject</label>
                <input
                  type="text"
                  placeholder="Subject"
                  name="subject"
                  value={contactUs.subject}
                  disabled
                  className={
                    errors?.subject ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.subject || ""}</p>
              </div>
              <div>
                <label htmlFor="">Message</label>
                <textarea
                  name="message"
                  id=""
                  cols="100"
                  rows="10"
                  disabled
                  style={{
                    marginTop: "0px",
                  }}
                  value={contactUs.message}
                  placeholder="Message"
                  onChange={handleChange}
                  className={
                    errors?.message
                      ? "globalTextArea errorInput"
                      : "globalTextArea"
                  }
                />

                <p className="errorText">{errors?.message || ""}</p>
              </div>
            </div>
            {/* <div className="" style={{ margin: "20px 0px" }}>
                <Button
                  style={{ background: "var(--primary_button_color)", border: 0 }}
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div> */}
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <style type="text/css">
        {`
        label {
          display: block;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary_text_color);
    margin-bottom: 10px;
        }
        }
	`}
      </style>
    </>
  );
};

export default AddEditContactUs;
