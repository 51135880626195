import nodes from "../../assets/images/function_page_icons/nodes.svg";
import diagram from "../../assets/images/function_page_icons/diagram.svg";
import management from "../../assets/images/function_page_icons/management.svg";
import chart from "../../assets/images/function_page_icons/chart.svg";
import ContactActiveDirectoryIcon from "../../pages/Function/FunctionLogo/ContactActiveDirectoryIcon";
import ViewStatsIcon from "../../pages/Function/FunctionLogo/ViewStatsIcon";
import ManageTeamMembersIcon from "../../pages/Function/FunctionLogo/ManageTeamMembersIcon";

const TeamData = [
  // {
  //   id: 1,
  //   bgColor:
  //     "radial-gradient(52.22% 52.22% at 50% 39.76%, #22A081 0%, rgba(34, 160, 129, 0) 100%)",
  //   image: nodes,
  //   title: "Manage Nodes",
  //   description: "Manage Your Nodes",
  //   url: "/team/manageNodes",
  //   text: "Manage",
  // },
  {
    id: 1,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, var(--gradient_color_dark) 0%, rgba(34, 160, 129, 0) 100%)",
    //image: nodes,
    image: (
      <ContactActiveDirectoryIcon primaryColor="var(--heading_text_color)" />
    ),
    title: "Contact Active Directory",
    description: "Manage Cards and View Directory",
    url: "/team/manageNodes",
    text: "Manage and View",
  },

  {
    id: 2,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, var(--gradient_color_light) 0%, rgba(0, 214, 50, 0) 100%)",
    //image: management,
    image: <ManageTeamMembersIcon primaryColor="var(--heading_text_color)" />,
    title: "Manage Team Members",
    description: "Manage Your Team Members",
    url: "/team/teamMembers",
    text: "Manage",
  },
  // {
  //   id: 3,
  //   bgColor:
  //     " radial-gradient(52.22% 52.22% at 50% 39.76%, #22A081 0%, rgba(34, 160, 129, 0) 100%)",
  //   image: diagram,
  //   title: "View Hierarchy Tree",
  //   description: "View Your Hierarchy Tree",
  //   url: "/team/viewHierarchy",
  //   text: "View",
  // },
  {
    id: 4,
    bgColor:
      " radial-gradient(52.22% 52.22% at 50% 39.76%, var(--gradient_color_dark) 0%, rgba(34, 160, 129, 0) 100%)",
    //image: chart,
    image: <ViewStatsIcon primaryColor="var(--heading_text_color)" />,
    title: "View Stats",
    description: "View Your Stats",
    url: "/team/stats",
    text: "View",
  },
];

export default TeamData;
