const Joi = require("joi");

const socialMediaLinksSchema = Joi.array().items(
  Joi.object({
    url: Joi.string()
      .pattern(
        new RegExp(
          "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
          "i"
        )
      )
      .required()
      .messages({
        "string.empty": `Please enter a url`,
        "string.pattern.base": `Please enter a valid url`,
      }),
  })
);

export default socialMediaLinksSchema;
