import Modal from "react-bootstrap/Modal";
import React from "react";
import { MdClose } from "react-icons/md";
import classes from "../../assets/styles/ConfirmationModal.module.css";
const ConfirmationModal = ({
  show,
  handleCloseModal,
  handleConfirmDelete,
  fromContact = false,
  deleteAccountMessage,
  confirmTitle = "record",
  deleteAll,
  setIsCheckAll,
  removeMember,
  isRemoveMemberMessage,
}) => {
  const handleChange = (e) => {
    const value = e.currentTarget.checked;
    setIsCheckAll(value);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        className="customModal"
        size="sm"
      >
        <Modal.Header>
          <div className="modalGradientCircle"></div>
          <MdClose onClick={handleCloseModal} className="modalCloseBtn" />
        </Modal.Header>

        <Modal.Body
          style={{ padding: "0" }}
          className={`d-flex justify-content-center flex-column align-items-center`}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ width: "fit-content", lineHeight: "18px" }}
          >
            <h5>Are you sure?</h5>
            <p
              style={{
                lineHeight: "18px",
                textAlign: "center",
                padding: "0px 20px",
              }}
            >
              {confirmTitle === "nfc" ? (
                <>
                  NFC tag and associated function will be removed from your
                  account. Are you sure you want to unlink your bracelet?
                </>
              ) : isRemoveMemberMessage ? (
                <>
                  Are you sure you want to remove this member from your team.
                  His team subscription along with team data created by him will
                  be lost permanently.
                </>
              ) : (
                <>
                  {deleteAccountMessage ? deleteAccountMessage : " "}
                  {deleteAccountMessage
                    ? " Are you sure you want to"
                    : `Are you sure you want to`}
                  <br />
                  {removeMember ? "remove" : "delete"}{" "}
                  {deleteAccountMessage ? "your" : "this"} {confirmTitle} ?
                </>
              )}
            </p>
          </div>
          {deleteAll ? (
            <div
              className="form-check customCheckBox d-flex align-items-center gap-3 mt-4"
              style={{ margin: "0px 20px" }}
            >
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                onChange={(e) => handleChange(e)}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                I agree to delete all cards associated with this card. Also
                member in that hierarchy will be unassigned.
              </label>
            </div>
          ) : null}

          <div className={`customModalBtnWrapper ${classes.btnWrapper}`}>
            <button
              onClick={handleConfirmDelete}
              style={{
                background: "#E35252",
                boxShadow: "0px 6px 20px rgba(227, 82, 82, 0.4)",
              }}
            >
              {removeMember ? "Remove" : "Delete"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ConfirmationModal;
