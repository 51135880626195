import {
  teamIDCheck,
  subscribedPackage,
  userRemainingFunctions,
  memberRemainingFunctions,
  loggedInUser,
} from "../utils/store";

import { useAtom } from "jotai";
import { get } from "../server";
import { HOMEDASHBOARD } from "../utils/constants/apiUrl";

const useFunctionsCount = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [userAvailableFunctions, setUserAvailableFunctions] = useAtom(
    userRemainingFunctions
  );

  const [memberAvailableFunctions, setMemberAvailableFunctions] = useAtom(
    memberRemainingFunctions
  );

  const userActions = (userData) => {
    const actionInfoWithTeam = userData?.user_action_info?.filter(
      (obj) => obj.team_id !== null
    );
    const actionInfoWithoutTeam = userData?.user_action_info?.filter(
      (obj) => obj.team_id === null
    );

    setMemberAvailableFunctions({
      ...memberAvailableFunctions,
      total_cards: actionInfoWithTeam?.[0]?.total_cards,
      total_connections: actionInfoWithTeam?.[0]?.total_connections,
      total_custom_urls: actionInfoWithTeam?.[0]?.total_custom_urls,
      total_emergency_contacts:
        actionInfoWithTeam?.[0]?.total_emergency_contacts,
      total_file_uploads: actionInfoWithTeam?.[0]?.total_file_uploads,
      total_payments: actionInfoWithTeam?.[0]?.total_payments,
      total_form_builder: actionInfoWithTeam?.[0]?.total_form_builder,
    });

    setUserAvailableFunctions({
      ...userAvailableFunctions,
      total_cards: actionInfoWithoutTeam?.[0]?.total_cards,
      total_connections: actionInfoWithoutTeam?.[0]?.total_connections,
      total_custom_urls: actionInfoWithoutTeam?.[0]?.total_custom_urls,
      total_emergency_contacts:
        actionInfoWithoutTeam?.[0]?.total_emergency_contacts,
      total_file_uploads: actionInfoWithoutTeam?.[0]?.total_file_uploads,
      total_payments: actionInfoWithoutTeam?.[0]?.total_payments,
      total_form_builder: actionInfoWithTeam?.[0]?.total_form_builder,
    });
  };

  // const functionCount = async () => {
  //   let { status, message, data } = await get(
  //     HOMEDASHBOARD,
  //     Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //   );
  //   if (status) {
  //     console.log("data", data);
  //   }
  // };

  const compareLimits = (functionType) => {
    // Get the limit value from data based on the functionType
    const limitValue =
      purchasedPackage?.[
        `limit_${
          functionType === "connection" ? "connections" : functionType
        }_${functionType === "connection" ? "save" : "create"}`
      ];
    // Get the total value from userAvailableFunctions based on the functionType
    let totalValue;
    if (userTeamId) {
      totalValue = memberAvailableFunctions[`total_${functionType}s`];
    } else {
      totalValue = userAvailableFunctions[`total_${functionType}s`];
    }
    // Perform the comparison and return true or false
    if (limitValue && (limitValue > totalValue || limitValue == "unlimited")) {
      console.log("false", 5);
      return false;
    } else {
      console.log("true", 3);
      return true;
    }
  };

  const updateCount = (functionType, actionType) => {
    if (actionType === "add") {
      if (userTeamId) {
        setMemberAvailableFunctions((prevFunctions) => ({
          ...prevFunctions,
          [`total_${functionType}s`]:
            prevFunctions[`total_${functionType}s`] + 1,
        }));
      } else {
        setUserAvailableFunctions((prevFunctions) => ({
          ...prevFunctions,
          [`total_${functionType}s`]:
            prevFunctions[`total_${functionType}s`] + 1,
        }));
      }
    } else if (actionType === "delete") {
      if (userTeamId) {
        setMemberAvailableFunctions((prevFunctions) => ({
          ...prevFunctions,
          [`total_${functionType}s`]:
            prevFunctions[`total_${functionType}s`] > 0
              ? prevFunctions[`total_${functionType}s`] - 1
              : 0,
        }));
      } else {
        setUserAvailableFunctions((prevFunctions) => ({
          ...prevFunctions,
          [`total_${functionType}s`]:
            prevFunctions[`total_${functionType}s`] > 0
              ? prevFunctions[`total_${functionType}s`] - 1
              : 0,
        }));
      }
    }
  };

  const limitExceededForStarter = async () => {
    let { status, message, data } = await get(
      HOMEDASHBOARD,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i++) {
        console.log("data[keys[i]]", data[keys[i]]);
        if (data[keys[i]] === 1) {
          return true;
        }
      }
      return false; // No limit exceeded
    } else {
      // Handle the case where the request fails
      console.error("Failed to fetch data:", message);
      throw new Error("Failed to fetch data");
    }
  };

  return { compareLimits, updateCount, userActions, limitExceededForStarter };
};

export default useFunctionsCount;
