import React, { useState, useEffect, useRef } from "react";
import classes from "../../assets/styles/Pages/QRCode/QRCode.module.css";
import { QRCode } from "react-qrcode-logo";
import qrCodeBorder from "../../assets/images/qr-code-border.svg";
import { SHARE_QR } from "../../utils/constants/common";
import qrCodeLogo from "../../assets/images/sidebar_icons/collapsedLogo.svg";
import { GiShare } from "react-icons/gi";
import baseUrl from "../../utils/constants/baseUrl";
import Loader from "../../components/Loader";
import { useAtom } from "jotai";
import { GET_USER, DYNAMIC_QRCODE } from "../../utils/constants/apiUrl";
import { get, deleteRecord } from "../../server";
import showToast from "../../helpers/showToast";
import formatPhoneNumber from "../../helpers/formatPhoneNumber";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import ShareModal from "../../components/Modals/ShareModal";
import ProfileQRCode from "./ProfileQRCode";
import {
  loggedInUser,
  teamIDCheck,
  subscribedPackage,
  mobileViewCheck,
} from "../../utils/store";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
const functionTypeMap = {
  card: "Digital Card",
  payment: "Payment Service",
  emergency_contact: "Emergency Contact",
  custom_url: "Custom URL’s",
  file_upload: "File Upload URL",
};
const QRCodePage = ({
  url,
  qr_bg_color,
  qr_front_color,
  qr_image,
  qrCodeId,
}) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [teamID, setTeamID] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  console.log("subscribedPackage", subscribedPackage);
  const [bgColor, setBgColor] = useState(qr_bg_color || "#ffffff");
  const [fgColor, setFgColor] = useState(qr_front_color || "#000000");
  const [logoImageURL, setLogoImageURL] = useState(qr_image || qrCodeLogo);
  const [qrCodeData, setQRCodeData] = useState([]);
  const [shareOpen, setShareOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [uuid, setUuid] = useState();
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [mobileView] = useAtom(mobileViewCheck);
  console.log("qrCodeId", qrCodeId);
  const target = useRef(null);

  // const [qrCodeId, setQRCodeId] = useState([]);
  const [data, setData] = useState({});
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [activeTab, setActiveTab] = useState("contactCode");

  const handleTabChange = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const loadQRCode = async () => {
    setLoading(true);
    const queryString = `?team_id=${teamID}`;
    if (!teamID) {
      let { status, message, data } = await get(
        DYNAMIC_QRCODE,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        //console.log("dgvszdvg", data);
        if (data.length > 0) {
          setQRCodeData(data);
          // const qrCodeIds = data.map((item) => item.id);
          //const qrCodeUUIds = data.map((item) => item.uuid);
          // setQRCodeId(qrCodeIds);
          //const qrLogoImage = data.map((item) => item.uuid);
          //setLogoImageURL(qrLogoImage);
          //console.log("setLogoImageURL", qrLogoImage);
        }
      } else {
        showToast(status, message);
      }
    } else {
      let { status, message, data } = await get(
        DYNAMIC_QRCODE + queryString,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        console.log("dgvszdvg", data);
        if (data.length > 0) {
          setQRCodeData(data);
        }
      } else {
        showToast(status, message);
      }
    }
    setLoading(false);
  };

  const handleShareOpen = (uuid) => {
    setShareOpen(true);
    setUuid(uuid);
  };

  const handleShareClose = () => {
    setShareOpen(false);
    setShowCopyPopup(false);
    setShow(false);
  };

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${DYNAMIC_QRCODE}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      setQRCodeData((prevData) =>
        prevData.filter((item) => item.id !== deleteId)
      );
      //await loadQRCode();
      setLoading(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    loadQRCode();
  }, []);
  return (
    <>
      <div>
        <div className="row align-items-center">
          <div className="col-6">
            <p className={classes.title}>QR Code</p>
          </div>
          <div className="col-6 text-end">
            {/* Limiting the creation of dynamic QR codes */}
            {/* {purchasedPackage.id === 2 && qrCodeData.length >= 3 ? null : (
              <Link to="/qrcode/addEditQRCode">
                <button className="primary_button">Add QR Code</button>
              </Link>
            )} */}
            {purchasedPackage.id === 1 ? (
              <button
                className="primary_button"
                onClick={() => {
                  showToast(
                    false,
                    "The requested feature is not compatible with your current plan. Upgrade your plan to using these features."
                  );
                }}
              >
                Create Dynamic QR Code
              </button>
            ) : purchasedPackage.id === 2 && qrCodeData.length >= 3 ? (
              <button
                className="primary_button"
                onClick={() => {
                  showToast(
                    false,
                    "The requested feature is not compatible with your current plan. Upgrade your plan to using these features."
                  );
                }}
              >
                Add QR Code
              </button>
            ) : (
              <Link to="/qrcode/addEditQRCode">
                <button className="primary_button">Add QR Code</button>
              </Link>
            )}
          </div>
        </div>
        <div className={classes.boxWrapper}>
          <Tabs
            defaultActiveKey="all"
            activeKey={activeTab}
            className="customSubscriptionTabs"
            transition={false}
            onSelect={handleTabChange}
            justify
          >
            <Tab eventKey="contactCode" title="Contact Code">
              <ProfileQRCode />
            </Tab>
            <Tab eventKey="dynamicQRCode" title="Dynamic QR Code">
              {purchasedPackage.id !== 1 ? (
                <>
                  <div className="row">
                    {qrCodeData.map((single, index) => {
                      console.log("Single Object:", single);
                      return (
                        <div key={index} className="col-md-6 col-12 text">
                          <div className={classes.qrWrapper}>
                            <div className={classes.qrCodeInner}>
                              <div className={classes.qrCode}>
                                <div
                                  className={classes.modalGradientCircle}
                                ></div>
                                <img src={qrCodeBorder} />
                                <QRCode
                                  value={
                                    url
                                      ? url
                                      : `${SHARE_QR}profile/${single.uuid}`
                                  }
                                  id={qrCodeId ? qrCodeId : "qr-code"}
                                  // logoImage={
                                  //   single?.qrCodeCustomize?.qr_image
                                  //     ? single?.qrCodeCustomize?.qr_image
                                  //     : qrCodeLogo
                                  // }
                                  logoImage={
                                    single?.qrCodeCustomize?.qr_image
                                      ? baseUrl.PUBLIC_URL +
                                        single?.qrCodeCustomize?.qr_image
                                      : qrCodeLogo
                                  }
                                  logoOpacity={1}
                                  logoPadding={3}
                                  bgColor={
                                    single?.qrCodeCustomize?.qr_bg_color ||
                                    bgColor
                                  }
                                  fgColor={
                                    single?.qrCodeCustomize?.qr_front_color ||
                                    fgColor
                                  }
                                  eyeRadius={0}
                                  removeQrCodeBehindLogo
                                />
                              </div>
                            </div>
                            <hr />
                            <h6
                              style={{
                                margin: "10px 0 20px",
                                textAlign: "left",
                                fontSize: "14px",
                              }}
                            >
                              Functions
                            </h6>
                            <div className="row text-start">
                              {[
                                "card",
                                "payment",
                                "emergency_contact",
                                "custom_url",
                                "file_upload",
                              ].map((type, index) => (
                                <div className="col-sm-6 col-12" key={index}>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name={type}
                                      checked={single.dynamicQRCodeFunctions.some(
                                        (func) => func.function_type === type
                                      )}
                                      disabled
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={type}
                                    >
                                      {functionTypeMap[type]}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className={classes.action}>
                              <button
                                className="btn customActionDropdown dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <BsThreeDotsVertical
                                  className={"globalVerticalThreeDots"}
                                />
                              </button>
                              <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={`addEditQRCode/${single.id}`}
                                  >
                                    Edit
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => handleShareOpen(single.uuid)}
                                  >
                                    Share
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    style={{ color: "#E35252" }}
                                    onClick={() => handleDelete(single.id)}
                                  >
                                    Delete
                                  </Link>
                                  {/* {console.log(row.id)} */}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <ConfirmationModal
                    show={showConfirmationModal}
                    handleCloseModal={handleCloseConfirmationModal}
                    handleConfirmDelete={handleConfirmDelete}
                    confirmTitle="QR Code"
                  />
                  {shareOpen && (
                    <ShareModal
                      shareOpen={shareOpen}
                      target={target}
                      handleShareClose={handleShareClose}
                      setShowCopyPopup={setShowCopyPopup}
                      functionType="dynamic_qr_code"
                      functionUuid={uuid}
                    />
                  )}
                  <Overlay
                    target={target.current}
                    show={showCopyPopup}
                    placement={mobileView ? "left" : "right"}
                  >
                    {(props) => (
                      <Tooltip id="overlay-example" {...props}>
                        Link Copied
                      </Tooltip>
                    )}
                  </Overlay>
                </>
              ) : (
                <p className="text-center py-5">No records Found</p>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
      <style type="text/css">
        {`
          .form-check{
            background-color: var(--secondary_color);
            border-radius: 10px;
            padding: .75rem .5rem .75rem 2.15rem;
            margin-bottom:.75rem;
            width:100%;
          }
          .form-check-input:disabled, .form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label{
            opacity: 1;
          }
          .form-check-label{
            font-size:13px;
          }
          .imageWrapper {
            position: relative;
            width: 16px !important;
            height: 16px !important;
            margin: auto;
          }
          .imageWrapper::before{
            background:transparent !important;
          }
          .imageWrapper img{display:none;}
          .imageWrapper .icon {
            color:var(--primary_button_color);
            font-size:18px
          }
          .customizedSidebar{
            background-color: var(--secondary_color);
            border-radius: 10px;
            padding: 15px;
            height:fit-content;
          }
          .customizedSidebar li img{
            width: 16px !important;
            height: 16px !important;
          }
          .customizedSidebar li input{
            width: 16px;
            height: 16px;
            border: 0;
            padding: 0;
            border-radius:50%;
          }
          .customizedSidebar li button{
            background-color:transparent;
            border:0;
            padding:0;
          }
          .customSubscriptionTabs button{
            background: var(--accent_color) !important;
          }
          .customSubscriptionTabs button.active{
            background: var(--primary_button_color) !important;
          }
          .secondary_button {
            padding: 6px 15px;
            background: var(--primary_color);
            border-radius: 7px;
            color: var(--primary_text_color) !important;
            transition: all 0.3s ease;
            font-weight: 500;
            font-size: 12px;
            border: 0;
            white-space: nowrap;
            width:100%;
          }
          .mainWrapper .primary_button {
            padding: 6px 15px;
            border-radius: 7px;
            font-size: 12px;
            width:100%;
          }
        `}
      </style>
    </>
  );
};
export default QRCodePage;
