import React, { useState, useEffect } from "react";
import { SHARE_FUNCTIONS } from "../utils/constants/apiUrl";
import { get, post } from "../server";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import showToast from "../helpers/showToast";
import Loader from "../components/Loader";
import { useParams, useNavigate } from "react-router-dom";
import PublicCard from "./PublicCard";
import PublicEmergencyContact from "./PublicEmergencyContact";
import PublicProfile from "./PublicProfile";
import PublicDynamicQR from "./PublicDynamicQR";
import { AddMoreCustomUrls } from "../utils/data/CardFunctionsData";
import PublicContact from "./PublicContact";
import PublicFormBuilder from "./PublicFormBuilder";

function PublicFunctionsQR() {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(true);
  const [emergencyContact, setEmergencyContact] = useState({});
  const [profile, setProfile] = useState({});
  const [dynamicQR, setDynamicQR] = useState({});
  const [formBuilderData, setFormBuilderData] = useState({});
  const [VCFData, setVCFData] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isCardType, setIsCardType] = useState(false);
  const [cardFunction, setCardFunction] = useState({
    card_type: "",
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    company_position: "",
    logo: "",
    background_image: "",
    profile_image: "",
    color: "",
    country_phone: "",
    phone_number: "",
    description: "",
    address: "",
    country: "",
    city: "",
    state: "",
    website_url: "",
    card_social_media: [],
    pronouns: "",
    card_phone: [{ phone_type: "", country_phone: "", phone_number: "" }],
    contact_card_other_data: [],
  });

  const { functionType, uuid } = useParams();
  const navigate = useNavigate();

  const loadPublicFunctions = async () => {
    let { status, message, data } = await get(
      `${SHARE_FUNCTIONS}${functionType}/${uuid}`
    );

    setVCFData(data);
    if (status) {
      if (functionType === "card" || functionType === "contact") {
        if (functionType === "contact") {
          data = data.contactCard;
        }
        let card_social_media = [];

        data?.cardSocialMedia?.forEach((item) => {
          let foundIcon = AddMoreCustomUrls.find(
            (single) => single.name === item.media_type
          );
          console.log("mn vbjf", foundIcon);

          card_social_media.push({
            media_type: item.media_type,
            url: item.url,
            icon: item.media_type === "custom" ? "custom" : foundIcon?.icon,
            custom_label: item.custom_label,
          });
        });
        if (functionType === "contact") {
          setCardFunction({
            card_type: data.card_type,
            first_name: data.first_name ? data.first_name : "",
            last_name: data.last_name ? data.last_name : "",
            email: data.email ? data.email : "",
            company: data.company ? data.company : "",
            company_position: data.company_position
              ? data.company_position
              : "",
            logo: data.logo ? data.logo : "",
            background_image: data.background_image
              ? data.background_image
              : "",
            profile_image: data.profile_image ? data.profile_image : "",
            color: data.color ? data.color : "",
            country_phone: data.country_phone ? `+${data.country_phone}` : "",
            phone_number: data.contact_card_phone.phone_number
              ? `${
                  data.contact_card_phone.country_phone
                    ? data.contact_card_phone.country_phone
                    : ""
                }${data.contact_card_phone.phone_number}`
              : "",
            description: data.description ? data.description : "",
            address: data.address ? data.address : "",
            country: data.country ? data.country : "",
            city: data?.city ? data?.city : "",
            state: data.state ? data.state : "",
            website_url: data.website_url ? data.website_url : "",
            card_social_media: card_social_media,
            pronouns: data.pronouns ? data.pronouns : "",
            card_phone: data.contact_card_phone,
            contact_card_other_data: data.contact_card_other_data,
          });
        } else {
          setCardFunction({
            card_type: data.card_type,
            first_name: data.first_name ? data.first_name : "",
            last_name: data.last_name ? data.last_name : "",
            email: data.email ? data.email : "",
            company: data.company ? data.company : "",
            company_position: data.company_position
              ? data.company_position
              : "",
            logo: data.logo ? data.logo : "",
            background_image: data.background_image
              ? data.background_image
              : "",
            profile_image: data.profile_image ? data.profile_image : "",
            color: data.color ? data.color : "",
            country_phone: data.country_phone ? `+${data.country_phone}` : "",
            phone_number: data.phone_number
              ? `${data.country_phone ? data.country_phone : ""}${
                  data.phone_number
                }`
              : "",
            description: data.description ? data.description : "",
            address: data.address ? data.address : "",
            country: data.country ? data.country : "",
            city: data?.city ? data?.city : "",
            state: data.state ? data.state : "",
            website_url: data.website_url ? data.website_url : "",
            card_social_media: card_social_media,
            pronouns: data.pronouns ? data.pronouns : "",
            card_phone: data.cardPhone,
          });
        }
      } else if (functionType === "emergencyContact") {
        setEmergencyContact(data);
      } else if (
        functionType === "profile_personal" ||
        functionType === "profile_owner" ||
        functionType === "admin_qr_code"
      ) {
        setProfile(data);
        console.log("setProfile", profile);
      } else if (functionType === "dynamic_qr_code") {
        setDynamicQR(data);
        console.log("setDynamicQR", dynamicQR);
      } else if (functionType === "form_builder") {
        setFormBuilderData(data);
        // console.log("setFormBuilderData", formBuilderData);
      } else {
        window.location.replace(data?.url_path);
      }

      // if (
      //   [
      //     "customUrl",
      //     "fileUpload",
      //     "venmo",
      //     "cashApp",
      //     "customPayment",
      //   ].includes(functionType)
      // ) {
      //   // navigate(data?.url_path)
      //   window.location.replace(data?.url_path);
      // }

      setEmergencyContact(data);
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadPublicFunctions();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {functionType === "card" || functionType === "contact" ? (
        <div
          className="py-5"
          style={{
            backgroundColor: "var(--secondary_Color)",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {functionType === "card" ? (
            <PublicCard cardFunction={cardFunction} />
          ) : (
            <PublicContact cardFunction={cardFunction} />
          )}
        </div>
      ) : functionType === "emergencyContact" ? (
        <PublicEmergencyContact emergencyContact={emergencyContact} />
      ) : functionType === "profile_personal" ||
        functionType === "profile_owner" ||
        functionType === "admin_qr_code" ? (
        <PublicProfile profile={profile} />
      ) : functionType === "dynamic_qr_code" ? (
        <PublicDynamicQR dynamicQR={dynamicQR} />
      ) : functionType === "form_builder" ? (
        <PublicFormBuilder formBuilderData={formBuilderData} />
      ) : null}
    </>
  );
}

export default PublicFunctionsQR;
