import React, { useState, useEffect, useRef } from "react";
import classes from "../../../assets/styles/Pages/Subscriptions/ManageSubscriptions.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import SettingsInput from "../../../components/Forms/SettingsInput";
import FormInput from "../../../components/Forms/FormInput";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { post, get } from "../../../server";
import _ from "lodash";
import {
  PACKAGE_CREATE,
  ALLTEAMOWNERS,
  IMAGE_UPLOAD,
  PACKAGES_UPDATE,
  PACKAGES_DETAILS,
} from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
import ImageUpload from "../../../components/ImageUpload";
import pictureicon from "../../../assets/images/function_page_icons/picIcon.svg";
import deleteIcon from "../../../assets/images/function_page_icons/socialdelete.svg";
import Table from "react-bootstrap/Table";
import ImageUploadSquare from "../../../components/ImageUploadSquare";
import baseUrl from "../../../utils/constants/baseUrl";
import qrCodeLogo from "../../../assets/images/sidebar_icons/collapsedLogo.svg";
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";
import { useDropzone } from "react-dropzone";
import { BsImageFill } from "react-icons/bs";
const AddEditPackage = ({ logo }) => {
  const [emptyObj, setEmptyObj] = useState({
    id: "",
    package_name: "",
    duration: "",
    duration_type: "",
    package_type: "customer_specific_owner",
    price_limit: "",
    individual_person_fee: "",
    user_id: "",
    price: "",
    is_nerve_ending: "",
    logo: "",
    user_limit: "",
    price_per_user: "",
    limit_card_create: "",
    limit_payment_create: "",
    limit_emergency_contact_create: "",
    limit_file_upload_create: "",
    limit_form_builder: "",
    limit_connections_save: "",
    limit_custom_url_create: "",
    enable_custom_buttons: "",
  });
  let { id } = useParams();
  // console.log("paramId", id);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [errors, setErrors] = useState({});
  const [teamOwners, setTeamOwners] = useState([]);
  const [createPackages, setCreatePackages] = useState(emptyObj);
  const [customButton, setcustomButton] = useState(false);
  const [isNeverEndingPackage, setIsNeverEndingPackage] = useState(false);

  //const [isUnlimited, setIsUnlimited] = useState({});
  const [image, selectImage] = useState("");
  const [logoImageURL, setLogoImageURL] = useState();
  const [getLogo, setGetLogo] = useState();

  const navigate = useNavigate();

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  // const handleRadioChange = (e) => {
  // setCreatePackages({
  // ...createPackages,
  // enable_custom_buttons: e.target.value,
  // });
  // };
  const handleRadioChange = (e) => {
    const value = e.target.value === "true" ? 1 : 0;
    setCreatePackages({
      ...createPackages,
      enable_custom_buttons: value,
    });
  };
  const handleNeverEndingPackageChange = (e) => {
    setIsNeverEndingPackage(e.target.checked);
  };
  // const handleMakeUnlimited = (e, type) => {
  // setIsUnlimited((prevState) => ({
  // ...prevState,
  // [type]: e.target.checked,
  // }));
  // };
  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreatePackages({ ...createPackages, [name]: value });
  };

  const handleRemoveImage = () => {
    selectImage("");
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   let uploadedImageUrl = "";
  //   if (image && typeof image == "object") {
  //     let formDataObj = new FormData();
  //     formDataObj.append("image_type", "logo");
  //     formDataObj.append("image", image);

  //     let { status, message, data } = await post(
  //       IMAGE_UPLOAD,
  //       formDataObj,
  //       Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //     );
  //     if (status) {
  //       uploadedImageUrl = data || "";
  //       setLogoImageURL(uploadedImageUrl);
  //       console.log("logoImageURL-----------", logoImageURL);
  //     } else {
  //       showToast(status, message);
  //       setLoading(false);
  //       return false;
  //     }
  //     console.log("uploadedImageUrl:", uploadedImageUrl);
  //   }

  //   let payload = { ...createPackages };
  //   payload.is_nerve_ending = isNeverEndingPackage ? "1" : "0";
  //   payload.logo = logoImageURL;
  //   if (id) {
  //     payload.package_id = id;
  //   }
  //   let { status, message, data } = await post(
  //     id ? PACKAGES_UPDATE : PACKAGE_CREATE,
  //     payload,
  //     Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //   );

  //   if (status) {
  //     setCreatePackages(data);
  //     navigate("/manageSubscriptions");
  //   } else {
  //     showToast(status, message);
  //   }

  //   setLoading(false);
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let uploadedImageUrl = "";

    try {
      if (image && typeof image === "object") {
        let formDataObj = new FormData();
        formDataObj.append("image_type", "logo");
        formDataObj.append("image", image);

        let { status, message, data } = await post(
          IMAGE_UPLOAD,
          formDataObj,
          Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
        );

        if (status) {
          uploadedImageUrl = data || "";
          setLogoImageURL(uploadedImageUrl);
          console.log("logoImageURL:", logoImageURL);
          let payload = { ...createPackages };
          payload.is_nerve_ending = isNeverEndingPackage ? "1" : "0";
          payload.logo = uploadedImageUrl;
          if (id) {
            payload.package_id = id;
          }

          let {
            status: packagesStatus,
            message: packagesMessage,
            data: packagesData,
          } = await post(
            id ? PACKAGES_UPDATE : PACKAGE_CREATE,
            payload,
            Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
          );

          if (packagesStatus) {
            setCreatePackages(packagesData);
            navigate("/manageSubscriptions");
          } else {
            showToast(packagesStatus, packagesMessage);
          }
        } else {
          showToast(status, message);
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }

    setLoading(false);
  };
  const getAllTeamOwners = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      ALLTEAMOWNERS,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0) {
        setTeamOwners(data);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const loadPackages = async () => {
    setLoading(true);
    const packageId = id;
    const queryString = `?package_id=${packageId}`;
    let { status, message, data } = await get(
      PACKAGES_DETAILS + queryString,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setCreatePackages(data);
      setIsNeverEndingPackage(true);
      setGetLogo(data?.logo);
      console.log(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      loadPackages();
    }
  }, [id]);

  useEffect(() => {
    getAllTeamOwners();
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle"> {id ? "Edit" : "Add"} Package</p>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              </div>
            </div>
          </div>
          <div className={classes.content}>
            <div className={`mainWrapper ${classes.outerWrapper}`}>
              <div className="row gx-5 gy-3 align-items-center">
                <div className="col-md-4 col-12">
                  <div className={classes.selectWrapper}>
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                        lineHeight: "18px",
                      }}
                    >
                      Company Name
                    </label>
                    <div className="input-group globalInputWithIcon">
                      <select
                        className="form-select customSelect"
                        name="user_id"
                        value={createPackages.user_id}
                        onChange={handleChange}
                      >
                        <option>Select Company Name</option>
                        {Array.isArray(teamOwners) &&
                          teamOwners.map((owner) => (
                            <option key={owner.id} value={owner.id}>
                              {owner.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <span className="errorText">{errors.package_id}</span>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <label
                    htmlFor=""
                    style={{
                      fontSize: "12px",
                      marginBottom: "10px",
                      lineHeight: "18px",
                    }}
                  >
                    Subscription type
                  </label>
                  <div className={`inputSelect ${classes.inputSelect}`}>
                    <FormInput
                      type="text"
                      placeholder="Enter Subscription Types"
                      name="duration"
                      value={createPackages.duration}
                      onChange={handleChange}
                      error={errors?.title}
                    />
                    <select
                      className="form-select customSelect "
                      name="duration_type"
                      value={createPackages.duration_type}
                      onChange={handleChange}
                    >
                      <option selected>Select</option>
                      <option value="year">Yearly</option>
                      <option value="month">Monthly</option>
                      <option value="days">Days</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="form-check text-start">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="is_nerve_ending"
                      value={createPackages.is_nerve_ending}
                      checked={isNeverEndingPackage}
                      onChange={handleNeverEndingPackageChange}
                    />
                    <label className="form-check-label" htmlFor="">
                      Never Ending Package
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Package Name"
                    type="text"
                    placeholder="Enter Package Name"
                    name="package_name"
                    value={createPackages.package_name}
                    handleChange={handleChange}
                    error={errors.package_name}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Package Price"
                    type="text"
                    placeholder="Enter discount value"
                    name="price"
                    value={createPackages.price}
                    handleChange={handleChange}
                    error={errors.price}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <div className="companyLogo">
                    <ImageUploadSquare
                      selectedImage={
                        typeof image == "object"
                          ? image
                          : baseUrl.PUBLIC_URL + image
                      }
                      setSelectedImage={logoImageURL || selectImage}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.features}>
                <div className="row gx-5 gy-3 align-items-center">
                  <div className="col-md-4 col-12">
                    <SettingsInput
                      label="Maximum person limit"
                      type="text"
                      placeholder="Maximum person limit"
                      name="user_limit"
                      value={createPackages.user_limit}
                      handleChange={handleChange}
                      error={errors.user_limit}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <SettingsInput
                      label="Individual Person Price"
                      type="text"
                      placeholder="Individual Person Price"
                      name="price_per_user"
                      value={createPackages.price_per_user}
                      handleChange={handleChange}
                      error={errors.price_per_user}
                    />
                  </div>
                  <div className="col-12">
                    <p>Features list</p>
                    <Table className={classes.table}>
                      <thead>
                        <tr>
                          <th>Feature name</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Personal/Business Card</td>
                          <td className={`quantity ${classes.quantity}`}>
                            <SettingsInput
                              type="text"
                              name="limit_card_create"
                              value={createPackages.limit_card_create}
                              handleChange={handleChange}
                              error={errors.name}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Connections</td>
                          <td className={`quantity ${classes.quantity}`}>
                            <SettingsInput
                              type="text"
                              name="limit_connections_save"
                              value={createPackages.limit_connections_save}
                              handleChange={handleChange}
                              error={errors.name}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Payments cards</td>
                          <td className={`quantity ${classes.quantity}`}>
                            <SettingsInput
                              type="text"
                              name="limit_payment_create"
                              value={createPackages.limit_payment_create}
                              handleChange={handleChange}
                              error={errors.limit_payment_create}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Emergency Contacts</td>
                          <td className={`quantity ${classes.quantity}`}>
                            <SettingsInput
                              type="text"
                              name="limit_emergency_contact_create"
                              value={
                                createPackages.limit_emergency_contact_create
                              }
                              handleChange={handleChange}
                              error={errors.limit_emergency_contact_create}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Custom URL</td>
                          <td className={`quantity ${classes.quantity}`}>
                            <SettingsInput
                              type="text"
                              name="limit_custom_url_create"
                              value={createPackages.limit_custom_url_create}
                              handleChange={handleChange}
                              error={errors.limit_custom_url_create}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>File Upload URL</td>
                          <td className={`quantity ${classes.quantity}`}>
                            <SettingsInput
                              type="text"
                              name="limit_file_upload_create"
                              value={createPackages.limit_file_upload_create}
                              handleChange={handleChange}
                              error={errors.limit_file_upload_create}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Form Builder</td>
                          <td className={`quantity ${classes.quantity}`}>
                            <SettingsInput
                              type="text"
                              name="limit_form_builder"
                              value={createPackages.limit_form_builder}
                              handleChange={handleChange}
                              error={errors.limit_form_builder}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Custom Button</td>
                          <td className={`quantity ${classes.quantity}`}>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="enable_custom_buttons"
                                value="true"
                                checked={
                                  createPackages.enable_custom_buttons === 1
                                }
                                onChange={handleRadioChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="enable_custom_buttons_true"
                              >
                                True
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="enable_custom_buttons"
                                value="false"
                                checked={
                                  createPackages.enable_custom_buttons === 0
                                }
                                onChange={handleRadioChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="enable_custom_buttons_false"
                              >
                                False
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              <div className="formBottom">
                <Button
                  className="submitButton"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <style type="text/css">
        {`
          .inputSelect .globalInputWithIcon{
          border-right: 0px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          height:100%
          }
          .inputSelect .customSelect{
          border: 2px solid var(--border_color) !important;
          border-radius: 60px !important;
          border-left: 0px;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          width:50%
          }
          .imageWrapper {
          width: 80px !important;
          height: 80px !important;
          border-radius:50%;
          }
          .imageWrapper::before{
          background:transparent !important;
          border-radius:50%;
          }
          .imageWrapper img{
          width: 80px !important;
          height: 80px !important;
          border-radius:50%;
          }
          .companyLogo{
          width: 80px !important;
          height: 80px !important;
          position:relative;
          }
          .removeImgBtn{
          position:absolute;
          bottom:0;
          right:0;
          background-color:#2D374A;
          color:#fff;
          width:30px;
          border-radius:50%;
          }
          // .imageWrapper .icon {
          // color:var(--primary_button_color);
          // font-size:18px
          // }
          table td.quantity > div.form-check{
          display: inline-block;
          margin-left: 20px;
          width:auto;
          }
        `}
      </style>
    </>
  );
};

export default AddEditPackage;
