import React, { useEffect } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Settings/Subscription.module.css";

import basicicon from "../../../assets/images/settings_page_icons/basicicon.svg";
import proicon from "../../../assets/images/settings_page_icons/proicon.svg";
import premiumicon from "../../../assets/images/settings_page_icons/premiumicon.svg";
import SubscriptionModal from "../../../components/Modals/SubscriptionModal";
import CouponBtn from "../../../assets/images/settings_page_icons/coupon-btn.svg";
import { Link } from "react-router-dom";
import {
  loggedInUser,
  subscriptionPackages,
  subscribedPackage,
} from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../Loader";
import { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import playstore from "../../../assets/images/home_page_images/playstore.svg";
import appstore from "../../../assets/images/home_page_images/appstore.svg";
import { AiFillCheckCircle } from "react-icons/ai";
import { loadStripe } from "@stripe/stripe-js";
import { post } from "../../../server";
import { COUPON_APPLY } from "../../../utils/constants/apiUrl";
import { IoSend } from "react-icons/io5";
// Load Stripe Data
const stripePromise = loadStripe(
  // "pk_test_51OZ7XQSBnxMCCnjEI1odHcAyBmW5WZd48fFUxnvHPpg2LDNKRGGCOJ3sU78SW9vi5wOmuLrwX2qxf8slIZpXFukR00DuK51ScU"
  "pk_live_51LsD48ErALx3fhV7pL6Gg2Wd2ymytgrKjj1tjb9cJICFlMAO24seG1sVDFTtKBFuOeD9luN1KSlS7Ho3xuY9tRZt00GZDEzFrx"
);

// Stripe OPtions
const elementsOptions = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
  locale: "en",
  placeholder: {
    number: "Card number",
    cvc: "CVC",
    expiry: "MM/YY",
  },
  style: {
    base: {
      fontSize: "16px",
      color: "#fff",
      "::placeholder": {
        color: "#fff",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};
const Subscription = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [purchasePackages, setPurchasePackages] = useAtom(subscriptionPackages);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [subscriptions] = useState(purchasePackages);
  const [loading, setLoading] = useState(false);
  const [activeKeys, setActiveKeys] = useState([]);
  const [selectedSubscriptionType, setSelectedSubscriptionType] =
    useState("monthly");
  const [couponCode, setCouponCode] = useState("");

  const [hasCouponMonthly, setHasCouponMonthly] = useState([]);
  const [hasCouponYearly, setHasCouponYearly] = useState([]);
  const [blankCouponField, setBlankCouponField] = useState(false);
  const [correctCouponEntered, setCorrectCouponEntered] = useState(false);
  const [incorrectCouponEntered, setIncorrectCouponEntered] = useState(false);
  const [couponData, setCouponData] = useState("");

  // const handleCheckboxChangeMonthly = () => {
  //   setHasCouponMonthly((prev) => !prev);
  // };
  // const handleCheckboxChangeYearly = () => {
  //   setHasCouponYearly((prev) => !prev);
  // };

  const handleCheckboxChangeMonthly = (index) => {
    setHasCouponMonthly((prev) => {
      const updated = [...prev];
      updated[index] = !updated[index];
      return updated;
    });
  };

  const handleCheckboxChangeYearly = (index) => {
    setHasCouponYearly((prev) => {
      const updated = [...prev];
      updated[index] = !updated[index];
      return updated;
    });
  };

  const handleTabSelect = (subscriptionIndex, key) => {
    setActiveKeys((prevActiveKeys) => {
      // Create a copy of the previous activeKeys array
      const updatedActiveKeys = [...prevActiveKeys];
      // Update the active key for the corresponding subscription
      updatedActiveKeys[subscriptionIndex] = key;
      // console.log("tab changes");
      return updatedActiveKeys;
    });
  };

  useEffect(() => {
    setCorrectCouponEntered(false);
    setIncorrectCouponEntered(false);
    console.log("set false");
  }, [activeKeys]);

  const handleCouponInputChange = (event) => {
    setCouponCode(event.target.value);
  };
  // const applyCoupon = async () => {
  //   setLoading(true);
  //   const payload = {
  //     // package_id:,
  //     coupon_code:couponCode,
  //     subscription_type: selectedSubscriptionType,
  //   };
  //   let { status, message, data } = await post(
  //     COUPON_APPLY,
  //     payload,
  //     Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //   );
  //   if (status) {
  //     console.log("COUPON_APPLY", data);
  //   }
  // };

  const applyCoupon = async (subscriptionType, packageId) => {
    if (!couponCode) {
      setBlankCouponField(true);
      return;
    }
    // setLoading(true);
    setCorrectCouponEntered(false);
    setIncorrectCouponEntered(false);

    const payload = {
      package_id: packageId,
      coupon_code: couponCode,
      subscription_type: subscriptionType,
    };
    let { status, message, data } = await post(
      COUPON_APPLY,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setCorrectCouponEntered(true);
      setCouponData(data);
    } else {
      setIncorrectCouponEntered(true);
    }
  };

  const handlePayment = async (obj, subscription_type) => {
    setSelectedPackage(obj);
    setSelectedSubscriptionType(subscription_type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className={classes.cardsWrapper}>
        {subscriptions.length !== 0
          ? subscriptions
              .filter((opt) => opt.package_type == "personal")
              .map((singlePackage, index) => (
                <div className={classes.card} key={`pricingCard${index}`}>
                  <button className={classes.subscriptionType}>
                    {purchasedPackage.id === singlePackage.id ? (
                      <>
                        {singlePackage.package_name} &nbsp;
                        <AiFillCheckCircle />
                      </>
                    ) : (
                      <>{singlePackage.package_name}</>
                    )}
                  </button>
                  <div className={classes.tabsWrapper}>
                    <Tabs
                      defaultActiveKey="monthly"
                      id={`uncontrolled-tab-example-${index}`}
                      className="customSubscriptionTabs"
                      transition={false}
                      activeKey={activeKeys[index]}
                      onSelect={(k) => handleTabSelect(index, k)}
                    >
                      <Tab eventKey="monthly" title="Monthly">
                        <div className={classes.imgWrapper}>
                          <img
                            src={
                              singlePackage.package_name === "Starter"
                                ? basicicon
                                : singlePackage.package_name === "Premium"
                                ? proicon
                                : premiumicon
                            }
                            alt={
                              singlePackage.package_name === "Starter"
                                ? "Starter"
                                : singlePackage.package_name === "Premium"
                                ? "Premium"
                                : "Elite"
                            }
                          />
                        </div>
                        {/* <h3 className={classes.title}>
                          {singlePackage.package_name === "Starter"
                            ? "Free"
                            : singlePackage.package_name === "Enterprise"
                            ? "Custom Price"
                            : `$${singlePackage.price_monthly}`}
                        </h3> */}
                        <h3 className={classes.title}>
                          {couponData.package_id === singlePackage.id
                            ? `$${couponData.package_price_after_discount}`
                            : singlePackage.package_name === "Starter"
                            ? "Free"
                            : singlePackage.package_name === "Enterprise"
                            ? "Custom Price"
                            : `$${singlePackage.price_monthly}`}
                        </h3>

                        <ul className={classes.list}>
                          {/* Connection */}
                          {singlePackage.limit_connections_save == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Connections</p>
                                <p>{singlePackage.limit_connections_save}</p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Connections</p>
                                <p>{singlePackage.limit_connections_save}</p>
                              </div>
                            </li>
                          )}
                          {/*  Card Section */}
                          {singlePackage.limit_card_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Personal/Business Card</p>
                                <p>{singlePackage.limit_card_create}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Personal/Business Card</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Personal/Business Card</p>
                                <p>{singlePackage.limit_card_create}</p>
                              </div>
                            </li>
                          )}

                          {/* Payments */}
                          {singlePackage.limit_payment_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Payments cards</p>
                                <p>{singlePackage.limit_payment_create}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Payments cards</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Payments cards</p>
                                <p>{singlePackage.limit_payment_create}</p>
                              </div>
                            </li>
                          )}

                          {/* Emergency Contact */}
                          {singlePackage.limit_emergency_contact_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Emergency Contacts</p>
                                <p>
                                  {singlePackage.limit_emergency_contact_create}
                                </p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Emergency Contacts</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Emergency Contacts</p>
                                <p>
                                  {singlePackage.limit_emergency_contact_create}
                                </p>
                              </div>
                            </li>
                          )}

                          {/* Custom Url */}
                          {singlePackage.limit_custom_url_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Custom URL</p>
                                <p>{singlePackage.limit_custom_url_create}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Custom URL</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Custom URL</p>
                                <p>{singlePackage.limit_custom_url_create}</p>
                              </div>
                            </li>
                          )}

                          {/* File Upload Url */}
                          {singlePackage.limit_file_upload_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>File Upload URL</p>
                                <p>{singlePackage.limit_file_upload_create}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>File Upload URL</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>File Upload URL</p>
                                <p>{singlePackage.limit_file_upload_create}</p>
                              </div>
                            </li>
                          )}
                          {singlePackage.limit_form_builder == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Form Builder</p>
                                <p>{singlePackage.limit_form_builder}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Form Builder</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Form Builder</p>
                                <p>{singlePackage.limit_form_builder}</p>
                              </div>
                            </li>
                          )}

                          {/*Custom Button */}
                          {singlePackage.enable_custom_buttons == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Custom Button</p>
                                <p>
                                  {singlePackage.enable_custom_buttons
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Custom Button</p>
                                <p>
                                  {singlePackage.enable_custom_buttons
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            </li>
                          )}

                          {/*Email Notification Button */}
                          {/* {singlePackage.enable_email_notifications == 0 ? (
                         <li>
                           <div
                             className="d-flex align-items-center justify-content-between"
                             style={{ color: "var(--primary_text_color)" }}
                           >
                             <p>Email Notification</p>
                             <p>
                               {singlePackage.enable_email_notifications
                                 ? "Yes"
                                 : "No"}
                             </p>
                           </div>
                         </li>
                         ) : (
                           <li>
                             <div className="d-flex align-items-center justify-content-between">
                               <p>Email Notification</p>
                               <p>
                                 {singlePackage.enable_email_notifications
                                   ? "Yes"
                                   : "No"}
                               </p>
                             </div>
                           </li>
                         )} */}
                        </ul>
                        {singlePackage.id === 1 ? (
                          <p className="noteText">
                            In free mode you are only able to create one
                            function at a time and deleting it allows you to
                            create another.
                          </p>
                        ) : (
                          ""
                        )}
                        {singlePackage.id == 4 ? (
                          <>
                            <div className={classes.couponWrapper}>
                              <div class="form-check text-start">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Do you have a coupon?
                                </label>
                              </div>
                            </div>
                            <div className={classes.btnWrapper}>
                              <Link
                                to={`/PackageContactForm/${singlePackage.id}`}
                              >
                                <button className={classes.btn}>
                                  Contact Us
                                </button>
                              </Link>
                            </div>
                          </>
                        ) : purchasedPackage.id == singlePackage.id ? (
                          <>
                            <div className={classes.btnWrapper}>
                              <button className={classes.btn} disabled>
                                Subscribed{" "}
                                <div
                                  style={{
                                    display: "inline",
                                    // backgroundColor: "white",
                                  }}
                                >
                                  <AiFillCheckCircle />
                                </div>
                              </button>
                            </div>
                          </>
                        ) : (
                          // <>
                          //   {/* <div
                          //     style={{
                          //       borderTop: "1px solid var(--border_color)",
                          //       marginTop: "10px",
                          //     }}
                          //   > */}
                          //   <div className={classes.imagesWrapper}>
                          //     <div>
                          //       <a
                          //         href="https://play.google.com/store/apps/details?id=com.holguinmedia.contactapp"
                          //         target="_blank"
                          //       >
                          //         <img src={playstore} alt="" />
                          //       </a>
                          //     </div>
                          //     <div>
                          //       <a
                          //         href="https://apps.apple.com/pk/app/contact-share-digital-info/id1662851237"
                          //         target="_blank"
                          //       >
                          //         <img src={appstore} alt="" />
                          //       </a>
                          //     </div>
                          //   </div>
                          //   {/* </div> */}
                          // </>
                          <>
                            {singlePackage.package_name === "Starter" ? (
                              ""
                            ) : (
                              <div className={classes.couponWrapper}>
                                <div className="form-check text-start">
                                  {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onChange={handleCheckboxChangeMonthly}
                                  /> */}
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id={`monthlyCheckbox-${index}`}
                                    onChange={() =>
                                      handleCheckboxChangeMonthly(index)
                                    }
                                    checked={hasCouponMonthly[index]}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    Do you have a coupon?
                                  </label>
                                </div>
                                {hasCouponMonthly[index] && (
                                  <div className="input-group mt-3">
                                    <input
                                      type="text"
                                      className="form-control coupon-form-control"
                                      placeholder="Enter Coupon code"
                                      onChange={handleCouponInputChange}
                                    />
                                    <button
                                      className="btn btn-outline-secondary"
                                      type="button"
                                      onClick={() =>
                                        applyCoupon("monthly", singlePackage.id)
                                      }
                                    >
                                      <IoSend />
                                    </button>
                                    {blankCouponField && (
                                      <div className="failurText">
                                        Please enter coupon code
                                      </div>
                                    )}
                                    {correctCouponEntered && (
                                      <div className="successText">
                                        Yes, coupon successfully applied
                                      </div>
                                    )}
                                    {incorrectCouponEntered && (
                                      <div className="failurText">
                                        Sorry, coupon code invalid
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            <div className={classes.btnWrapper}>
                              <button
                                className={classes.btn}
                                onClick={() =>
                                  handlePayment(singlePackage, "monthly")
                                }
                              >
                                Select Package
                              </button>
                            </div>
                          </>
                        )}
                      </Tab>
                      <Tab eventKey="yearly" title="Yearly">
                        <div className={classes.imgWrapper}>
                          <img
                            src={
                              singlePackage.package_name === "Starter"
                                ? basicicon
                                : singlePackage.package_name === "Premium"
                                ? proicon
                                : premiumicon
                            }
                            alt={
                              singlePackage.package_name === "Starter"
                                ? "Starter"
                                : singlePackage.package_name === "Premium"
                                ? "Premium"
                                : "Elite"
                            }
                          />
                        </div>
                        {/* <h3 className={classes.title}>
                          {singlePackage.package_name === "Starter"
                            ? "Free"
                            : singlePackage.package_name === "Enterprise"
                            ? "Custom Price"
                            : `$${singlePackage.price_yearly}`}
                        </h3> */}
                        <h3 className={classes.title}>
                          {couponData.package_id === singlePackage.id
                            ? `$${couponData.package_price_after_discount}`
                            : singlePackage.package_name === "Starter"
                            ? "Free"
                            : singlePackage.package_name === "Enterprise"
                            ? "Custom Price"
                            : `$${singlePackage.price_yearly}`}
                        </h3>

                        <ul className={classes.list}>
                          {/* Connection */}
                          {singlePackage.limit_connections_save == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Connections</p>
                                <p>{singlePackage.limit_connections_save}</p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Connections</p>
                                <p>{singlePackage.limit_connections_save}</p>
                              </div>
                            </li>
                          )}

                          {/*  Card Section */}
                          {singlePackage.limit_card_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Personal/Business Card</p>
                                <p>{singlePackage.limit_card_create}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Personal/Business Card</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Personal/Business Card</p>
                                <p>{singlePackage.limit_card_create}</p>
                              </div>
                            </li>
                          )}

                          {/* Payments */}
                          {singlePackage.limit_payment_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Payments cards</p>
                                <p>{singlePackage.limit_payment_create}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Payments cards</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Payments cards</p>
                                <p>{singlePackage.limit_payment_create}</p>
                              </div>
                            </li>
                          )}

                          {/* Emergency Contact */}
                          {singlePackage.limit_emergency_contact_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Emergency Contacts</p>
                                <p>
                                  {singlePackage.limit_emergency_contact_create}
                                </p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Emergency Contacts</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Emergency Contacts</p>
                                <p>
                                  {singlePackage.limit_emergency_contact_create}
                                </p>
                              </div>
                            </li>
                          )}

                          {/* Custom Url */}
                          {singlePackage.limit_custom_url_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Custom URL</p>
                                <p>{singlePackage.limit_custom_url_create}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Custom URL</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Custom URL</p>
                                <p>{singlePackage.limit_custom_url_create}</p>
                              </div>
                            </li>
                          )}

                          {/* File Upload Url */}
                          {singlePackage.limit_file_upload_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>File Upload URL</p>
                                <p>{singlePackage.limit_file_upload_create}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>File Upload URL</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>File Upload URL</p>
                                <p>{singlePackage.limit_file_upload_create}</p>
                              </div>
                            </li>
                          )}
                          {/* Form Builder */}
                          {singlePackage.limit_form_builder == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Form Builder</p>
                                <p>{singlePackage.limit_form_builder}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Form Builder</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Form Builder</p>
                                <p>{singlePackage.limit_form_builder}</p>
                              </div>
                            </li>
                          )}

                          {/*Custom Button */}
                          {singlePackage.enable_custom_buttons == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "var(--primary_text_color)" }}
                              >
                                <p>Custom Button</p>
                                <p>
                                  {singlePackage.enable_custom_buttons
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Custom Button</p>
                                <p>
                                  {singlePackage.enable_custom_buttons
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            </li>
                          )}

                          {/*Email Notification Button */}
                          {/* {singlePackage.enable_email_notifications == 0 ? (
                         <li>
                           <div
                             className="d-flex align-items-center justify-content-between"
                             style={{ color: "var(--primary_text_color)" }}
                           >
                             <p>Email Notification</p>
                             <p>
                               {singlePackage.enable_email_notifications
                                 ? "Yes"
                                 : "No"}
                             </p>
                           </div>
                         </li>
                       ) : (
                         <li>
                           <div className="d-flex align-items-center justify-content-between">
                             <p>Email Notification</p>
                             <p>
                               {singlePackage.enable_email_notifications
                                 ? "Yes"
                                 : "No"}
                             </p>
                           </div>
                         </li>
                       )} */}
                        </ul>
                        {singlePackage.id === 1 ? (
                          <p className="noteText">
                            In free mode you are only able to create one
                            function at a time and deleting it allows you to
                            create another.
                          </p>
                        ) : (
                          ""
                        )}
                        {singlePackage.id == 4 ? (
                          <div className={classes.btnWrapper}>
                            <Link
                              to={`/PackageContactForm/${singlePackage.id}`}
                            >
                              <button className={classes.btn}>
                                Contact Us
                              </button>
                            </Link>
                          </div>
                        ) : purchasedPackage.id == singlePackage.id ? (
                          <>
                            <div className={classes.btnWrapper}>
                              <button className={classes.btn} disabled>
                                Subscribed{" "}
                                <div
                                  style={{
                                    display: "inline",
                                  }}
                                >
                                  <AiFillCheckCircle />
                                </div>
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            {singlePackage.package_name === "Starter" ? (
                              ""
                            ) : (
                              <div className={classes.couponWrapper}>
                                <div className="form-check text-start">
                                  {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onChange={handleCheckboxChangeYearly}
                                  /> */}
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id={`yearlyCheckbox-${index}`}
                                    onChange={() =>
                                      handleCheckboxChangeYearly(index)
                                    }
                                    checked={hasCouponYearly[index]}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Do you have a coupon?
                                  </label>
                                </div>
                                {hasCouponYearly[index] && (
                                  <div className="input-group mt-3">
                                    <input
                                      type="text"
                                      className="form-control coupon-form-control"
                                      placeholder="Enter Coupon code"
                                      onChange={handleCouponInputChange}
                                    />
                                    <button
                                      className="btn btn-outline-secondary"
                                      type="button"
                                      onClick={() =>
                                        applyCoupon("yearly", singlePackage.id)
                                      }
                                    >
                                      <IoSend />
                                    </button>

                                    {correctCouponEntered && (
                                      <div className="successText">
                                        Yes, coupon successfully applied.
                                      </div>
                                    )}
                                    {incorrectCouponEntered && (
                                      <div className="failurText">
                                        Sorry, coupon code invalid
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            <div className={classes.btnWrapper}>
                              <button
                                className={classes.btn}
                                onClick={() =>
                                  handlePayment(singlePackage, "yearly")
                                }
                              >
                                Select Package
                              </button>
                            </div>
                          </>
                        )}
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              ))
          : null}
      </div>
      {showModal && (
        <SubscriptionModal
          show={showModal}
          stripePromise={stripePromise}
          handleCloseModal={handleCloseModal}
          selectedPackage={selectedPackage}
          selectedSubscriptionType={selectedSubscriptionType}
          elementsOptions={elementsOptions}
          couponData={couponData}
        />
      )}
      <style type="text/css">
        {`
         .form-check-label {
           font-size: 13px;
           font-weight: 500;
         }
         .form-check-input, .form-check-input:focus{
          //  background: var(--primary_color);
           border: 1px solid var(--border_color);
          outline:none
         }
         .coupon-form-control, .coupon-form-control:focus{
           background-color:transparent;
           color:var(--heading_text_color);
           font-size:13px;
           font-weight:700;
           border-radius:5rem;
           box-shadow:none;
         }
         .coupon-form-control::placeholder{
           color:var(--heading_text_color);
         }
         .btn-outline-secondary, .btn-outline-secondary:focus{
           border-radius:5rem;
           border-top-right-radius: 5rem !important;
            border-bottom-right-radius: 5rem !important;
            border-color: var(--border_color);
         }
         .failurText, .successText{
          text-align:left;
          font-size:13px;
          color:#ff0000;
          display:block;
          width:100%;
        }
        .successText{
          color: #42764e;
        }
        .noteText{
          color: rgb(227, 82, 82); 
          font-size: 14px; 
          text-align: left;
        }
         @media only screen and (max-width: 1399.98px) {
           .form-check-label {
             font-size: 12px;
           }
         }
       `}
      </style>
    </>
  );
};

export default Subscription;
