const Joi = require("joi");

const AddEditUserSchema = Joi.object({
  id: Joi.allow(null),

  first_name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter first name`,
    "string.min": `First name must be minimum 1 characters!`,
    "string.max": `First name must be maximum 25 characters!`,
  }),

  last_name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter first name`,
    "string.min": `Last name must be minimum 1 characters!`,
    "string.max": `Last name must be maximum 25 characters!`,
  }),
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),

  // phone_number: Joi.string()
  //   .pattern(
  //     new RegExp(
  //       /^(?!\b(0)\1+\b)(\+?\d{1,3}[. -]?)?\(?\d{3}\)?([. -]?)\d{3}\3\d{4}$/
  //     )
  //   )
  //   .allow("")
  //   .allow("1")
  //   .messages({
  //     "string.empty": `Please enter your phone number`,
  //     "string.pattern.base": `Please enter a valid phone number`,
  //   }),

  password: Joi.string()
    .min(8)
    .when("id", {
      is: Joi.exist(),
      then: Joi.allow(""),
      otherwise: Joi.required().messages({
        "string.empty": `Please enter password`,
        "string.min": `Password must be minimum 8 characters!`,
      }),
    })
    .label("Password"),

  confirm_password: Joi.string()
    .when("id", {
      is: Joi.exist(),
      then: Joi.valid(Joi.ref("password")).messages({
        "string.empty": `Please enter confirm password `,
        "any.only": `Confirm password must match password`,
      }),
      otherwise: Joi.required().valid(Joi.ref("password")).messages({
        "string.empty": `Please enter confirm password `,
        "any.only": `Confirm password must match password`,
      }),
    })
    .label("Confirm password"),
});

export default AddEditUserSchema;
