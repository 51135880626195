import React from "react";
import classes from "../../assets/styles/Forms/SettingsInput.module.css";
import FormInput from "./FormInput";

const SettingsInput = ({ label, ...props }) => {
  return (
    <>
      <div>
        <label htmlFor="" className={classes.label}>
          {label}
        </label>
        <FormInput {...props} />
      </div>
    </>
  );
};

export default SettingsInput;
