import React, { useState, useEffect, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { useAtom } from "jotai";
import classes from "../../../assets/styles/Contact.module.css";
import { PAYMENT } from "../../../utils/constants/apiUrl";
import { get, deleteRecord, post, put } from "../../../server";
import showToast from "../../../helpers/showToast";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import VenmoAddEditModal from "../../../components/Modals/VenmoAddEditModal";
import { formatUrl } from "../../../utils/functions/index";
import _ from "lodash";

const AllVenmos = () => {
  let emptyVenmoData = {
    isNew: true,
    id: "",
    title: "",
    url_path: "",
    payment_type: "",
  };

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [venmos, setVenmos] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyVenmoData);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  // Handle main modal
  const handleShowModal = (obj) => {
    if (obj && Object.keys(obj).length) setSelectedData(obj);
    setShowModal(true);
  };

  const handleSaveShowModal = async () => {
    setLoading(true);

    let payload = _.cloneDeep(selectedData);
    payload.url_path = formatUrl(payload.url_path);
    payload.payment_type = "venmo";
    let result = {};
    if (selectedData.id) {
      result = await put(
        PAYMENT + `/${selectedData.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      result = await post(
        PAYMENT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      setLoading(false);

      let venmosArr = _.cloneDeep(venmos);

      // On Update
      if (selectedData.id) {
        const index = venmos.findIndex((el) => el.id == selectedData.id);
        venmosArr[index] = data;
      } else {
        venmosArr.unshift(data);
      }

      setVenmos(venmosArr);
      handleCloseModal();
    } else {
      setLoading(false);
    }

    showToast(status, message);
  };

  const handleCloseModal = () => {
    setSelectedData(emptyVenmoData);
    setShowModal(false);
  };

  const loadVenmos = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      PAYMENT + "/getPaymentByType/venmo",
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0) {
        setVenmos(data);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadVenmos();
  }, []);

  const filteredItems = useMemo(() => {
    const filtered = venmos.filter(
      (item) =>
        `${item?.user_data?.first_name || ""} ${
          item?.user_data?.last_name || ""
        }`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        item.title.toLowerCase().includes(filterText.toLowerCase()) ||
        item.url_path.includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, venmos]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Title",
      cell: (row) => (
        <div className="profileWrapper">
          <p className="globalDetail">{row.title}</p>
        </div>
      ),
    },
    {
      name: "Username",
      cell: (row) => (
        <div className={"globalDetail"}>
          {row?.user_data?.first_name} {row?.user_data?.last_name}
        </div>
      ),
    },
    {
      name: "Url",
      cell: (row) => (
        <div className="profileWrapper">
          <p className="globalDetail">{row.url_path}</p>
        </div>
      ),
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link
                className="dropdown-item"
                onClick={() => handleShowModal(row)}
              >
                Edit
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${PAYMENT}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await loadVenmos();
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div className="">
          <div className="page_Header">
            {/* <Link to="AddEditAdminUser">
                <button className="primary_button align_right">Add</button>
              </Link> */}
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Venmo <span>({venmos.length})</span>
              </p>
              <FormInput
                icon={searchIcon}
                placeholder="Search..."
                value={filterText}
                handleChange={handleFilter}
              />
            </div>
          </div>

          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              paginationResetDefaultPage={resetPaginationToggle}
              pagination
              theme="solarized"
            />
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="cash app"
          />

          {/* venmo modals */}
          {showModal && (
            <VenmoAddEditModal
              show={showModal}
              data={selectedData}
              setSelectedData={setSelectedData}
              handleSaveShowModal={handleSaveShowModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AllVenmos;
