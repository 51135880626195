import React from "react";

const CardFunctionLogo = ({ primaryColor, secondaryColor }) => (
  <svg
    width="157"
    height="120"
    viewBox="0 0 157 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M82.7524 106.879C82.2062 106.879 81.6595 106.869 81.1122 106.848C80.7026 106.832 80.316 106.655 80.0375 106.354C79.7589 106.053 79.6111 105.654 79.6267 105.245C79.6423 104.835 79.82 104.449 80.1207 104.17C80.4213 103.892 80.8203 103.744 81.2298 103.759C87.6638 104.001 94.0616 102.692 99.8838 99.9426C105.706 97.1935 110.782 93.0849 114.684 87.9632C118.585 82.8416 121.198 76.857 122.303 70.5138C123.407 64.1707 122.97 57.655 121.029 51.5161C120.914 51.1276 120.955 50.7094 121.145 50.3514C121.335 49.9934 121.658 49.7243 122.045 49.6022C122.431 49.48 122.85 49.5145 123.211 49.6982C123.572 49.882 123.847 50.2002 123.976 50.5845C126.024 57.0638 126.517 63.9346 125.415 70.64C124.312 77.3454 121.646 83.6968 117.631 89.1794C113.616 94.662 108.366 99.1216 102.306 102.197C96.2466 105.272 89.5475 106.876 82.7521 106.879L82.7524 106.879Z"
      fill={secondaryColor}
    />
    <path
      d="M143.159 1.54395H73.1314C66.7788 1.54395 61.629 6.69372 61.629 13.0463V53.7943C61.629 60.1469 66.7788 65.2966 73.1314 65.2966H143.159C149.512 65.2966 154.662 60.1469 154.662 53.7943V13.0463C154.662 6.69372 149.512 1.54395 143.159 1.54395Z"
      fill={primaryColor}
    />
    <path
      d="M143.159 66.8437H73.1313C69.6721 66.8398 66.3556 65.4638 63.9096 63.0176C61.4635 60.5715 60.0877 57.255 60.0839 53.7957V13.048C60.0877 9.58874 61.4635 6.2722 63.9096 3.82607C66.3556 1.37993 69.6721 0.0039537 73.1313 0H143.159C146.618 0.00392644 149.935 1.37993 152.381 3.82607C154.827 6.2722 156.203 9.58872 156.207 13.048V53.7957C156.203 57.255 154.827 60.5715 152.381 63.0176C149.935 65.4638 146.618 66.8398 143.159 66.8437ZM73.1313 3.09048C70.4914 3.09348 67.9605 4.14355 66.0938 6.01031C64.2271 7.87708 63.1772 10.4081 63.1743 13.048V53.7957C63.1772 56.4356 64.2271 58.9666 66.0938 60.8334C67.9605 62.7002 70.4914 63.7502 73.1313 63.7532H143.159C145.799 63.7502 148.33 62.7002 150.197 60.8334C152.063 58.9666 153.113 56.4356 153.116 53.7957V13.048C153.113 10.4081 152.063 7.87708 150.197 6.01031C148.33 4.14355 145.799 3.09348 143.159 3.09048H73.1313Z"
      fill={secondaryColor}
    />
    <path
      d="M83.0754 54.7041H13.0475C6.69495 54.7041 1.54517 59.8539 1.54517 66.2065V106.954C1.54517 113.307 6.69495 118.457 13.0475 118.457H83.0754C89.428 118.457 94.5778 113.307 94.5778 106.954V66.2065C94.5778 59.8539 89.428 54.7041 83.0754 54.7041Z"
      fill={primaryColor}
    />
    <path
      d="M83.0757 120H13.0475C9.5882 119.996 6.27176 118.62 3.82572 116.174C1.37968 113.728 0.00381714 110.411 0 106.952V66.2043C0.00381714 62.745 1.37968 59.4285 3.82572 56.9823C6.27176 54.5362 9.5882 53.1602 13.0475 53.1562H83.0757C86.535 53.1602 89.8514 54.5362 92.2975 56.9823C94.7435 59.4285 96.1194 62.745 96.1232 66.2043V106.952C96.1193 110.411 94.7435 113.728 92.2975 116.174C89.8514 118.62 86.535 119.996 83.0757 120ZM13.0475 56.2467C10.4076 56.2497 7.87663 57.2998 6.00997 59.1666C4.1433 61.0333 3.09334 63.5643 3.09048 66.2043V106.952C3.09334 109.592 4.1433 112.123 6.00997 113.99C7.87663 115.857 10.4076 116.907 13.0475 116.91H83.0757C85.7156 116.907 88.2466 115.857 90.1132 113.99C91.9799 112.123 93.0298 109.592 93.0327 106.952V66.2043C93.0298 63.5643 91.9799 61.0333 90.1132 59.1666C88.2466 57.2998 85.7156 56.2497 83.0757 56.2467H13.0475Z"
      fill={secondaryColor}
    />
    <path
      d="M40.0809 75.683C39.7525 75.6829 39.4327 75.5783 39.1678 75.3841C38.9029 75.19 38.7068 74.9166 38.6078 74.6035C36.5172 67.992 36.0467 60.9747 37.2362 54.1433C38.4257 47.3119 41.2401 40.8666 45.4423 35.3508C49.6445 29.835 55.1112 25.4104 61.3816 22.4499C67.652 19.4894 74.5423 18.0798 81.4716 18.34C81.6744 18.3478 81.8737 18.3954 82.0581 18.4801C82.2425 18.5649 82.4084 18.6851 82.5463 18.834C82.6842 18.9829 82.7915 19.1574 82.862 19.3478C82.9324 19.5381 82.9647 19.7404 82.957 19.9432C82.9493 20.146 82.9017 20.3452 82.8169 20.5296C82.7322 20.714 82.6119 20.8799 82.463 21.0179C82.3142 21.1558 82.1396 21.2631 81.9493 21.3336C81.759 21.404 81.5566 21.4363 81.3538 21.4286C74.9198 21.1871 68.5221 22.4961 62.6999 25.2451C56.8777 27.9942 51.8018 32.1027 47.9001 37.2244C43.9984 42.3461 41.3852 48.3307 40.2809 54.6739C39.1766 61.017 39.6135 67.5327 41.5547 73.6716C41.6276 73.9031 41.645 74.1486 41.6054 74.3881C41.5658 74.6276 41.4705 74.8545 41.327 75.0503C41.1835 75.2461 40.9959 75.4053 40.7794 75.5152C40.5629 75.625 40.3237 75.6825 40.0809 75.6827V75.683Z"
      fill={secondaryColor}
    />
    <path
      d="M92.0423 20.7877L85.6093 14.0513C85.4452 13.8794 85.2371 13.7559 85.0076 13.6941C84.7782 13.6323 84.5362 13.6346 84.3079 13.7008C84.0797 13.767 83.874 13.8946 83.7133 14.0696C83.5525 14.2446 83.4428 14.4603 83.3961 14.6933L82.3469 19.9308L81.2978 25.1684C81.2511 25.4014 81.2692 25.6427 81.3502 25.8661C81.4311 26.0896 81.5718 26.2865 81.7569 26.4355C81.9421 26.5846 82.1645 26.6799 82.4001 26.7113C82.6356 26.7426 82.8753 26.7088 83.0929 26.6134L91.6243 22.8746C91.8156 22.7908 91.9839 22.6621 92.1148 22.4994C92.2458 22.3367 92.3356 22.1448 92.3767 21.94C92.4177 21.7352 92.4087 21.5236 92.3505 21.323C92.2923 21.1224 92.1866 20.9388 92.0423 20.7877Z"
      fill={secondaryColor}
    />
    <path
      d="M82.5698 28.271C82.1491 28.2701 81.7338 28.1761 81.3538 27.9956C80.9738 27.8151 80.6385 27.5527 80.372 27.2272C80.1056 26.9017 79.9146 26.5211 79.8127 26.1129C79.7109 25.7048 79.7008 25.2791 79.7831 24.8666L81.8816 14.3909C81.9837 13.8807 82.2238 13.4083 82.5759 13.0251C82.9279 12.6419 83.3783 12.3627 83.8781 12.2178C84.3778 12.073 84.9078 12.0681 85.4102 12.2036C85.9125 12.3392 86.368 12.6099 86.7272 12.9864L93.1601 19.7218C93.476 20.0525 93.7075 20.4545 93.835 20.8938C93.9624 21.333 93.9821 21.7965 93.8924 22.245C93.8027 22.6935 93.6061 23.1137 93.3195 23.4701C93.0328 23.8264 92.6644 24.1084 92.2456 24.2922L83.7136 28.0305C83.353 28.1887 82.9636 28.2705 82.5698 28.271ZM84.8187 15.4624L82.9063 25.0104L90.6828 21.602L84.8187 15.4624ZM90.9248 21.8555L90.9257 21.8565L90.9248 21.8555Z"
      fill={secondaryColor}
    />
    <path
      d="M141.565 16.4219H125.75C124.162 16.4219 122.874 17.7093 122.874 19.2975V29.362C122.874 30.9502 124.162 32.2376 125.75 32.2376H141.565C143.154 32.2376 144.441 30.9502 144.441 29.362V19.2975C144.441 17.7093 143.154 16.4219 141.565 16.4219Z"
      fill={secondaryColor}
    />
    <path
      d="M106.218 39.7096H80.4306C80.0208 39.7096 79.6277 39.5468 79.338 39.257C79.0482 38.9673 78.8854 38.5742 78.8854 38.1644C78.8854 37.7546 79.0482 37.3615 79.338 37.0717C79.6277 36.7819 80.0208 36.6191 80.4306 36.6191H106.218C106.628 36.6191 107.021 36.7819 107.311 37.0717C107.601 37.3615 107.764 37.7546 107.764 38.1644C107.764 38.5742 107.601 38.9673 107.311 39.257C107.021 39.5468 106.628 39.7096 106.218 39.7096Z"
      fill={secondaryColor}
    />
    <path
      d="M121.002 50.6725H80.4306C80.0208 50.6725 79.6277 50.5097 79.338 50.2199C79.0482 49.9301 78.8854 49.5371 78.8854 49.1273C78.8854 48.7174 79.0482 48.3244 79.338 48.0346C79.6277 47.7448 80.0208 47.582 80.4306 47.582H121.002C121.412 47.582 121.805 47.7448 122.094 48.0346C122.384 48.3244 122.547 48.7174 122.547 49.1273C122.547 49.5371 122.384 49.9301 122.094 50.2199C121.805 50.5097 121.412 50.6725 121.002 50.6725Z"
      fill={secondaryColor}
    />
    <path
      d="M81.4825 69.5801H65.6667C64.0786 69.5801 62.7911 70.8675 62.7911 72.4557V82.5202C62.7911 84.1084 64.0786 85.3958 65.6667 85.3958H81.4825C83.0706 85.3958 84.3581 84.1084 84.3581 82.5202V72.4557C84.3581 70.8675 83.0706 69.5801 81.4825 69.5801Z"
      fill={secondaryColor}
    />
    <path
      d="M46.1341 92.8678H20.347C19.9372 92.8678 19.5442 92.705 19.2544 92.4152C18.9646 92.1255 18.8018 91.7324 18.8018 91.3226C18.8018 90.9128 18.9646 90.5198 19.2544 90.23C19.5442 89.9402 19.9372 89.7773 20.347 89.7773H46.1343C46.5441 89.7773 46.9372 89.9402 47.2269 90.23C47.5167 90.5198 47.6795 90.9128 47.6795 91.3226C47.6795 91.7324 47.5167 92.1255 47.2269 92.4152C46.9372 92.705 46.5441 92.8678 46.1343 92.8678H46.1341Z"
      fill={secondaryColor}
    />
    <path
      d="M60.9179 103.832H20.347C19.9372 103.832 19.5442 103.669 19.2544 103.379C18.9646 103.089 18.8018 102.696 18.8018 102.286C18.8018 101.877 18.9646 101.484 19.2544 101.194C19.5442 100.904 19.9372 100.741 20.347 100.741H60.9181C61.3279 100.741 61.721 100.904 62.0108 101.194C62.3005 101.484 62.4633 101.877 62.4633 102.286C62.4633 102.696 62.3005 103.089 62.0108 103.379C61.721 103.669 61.3279 103.832 60.9181 103.832H60.9179Z"
      fill={secondaryColor}
    />
  </svg>
);

export default CardFunctionLogo;
