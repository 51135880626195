import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PersonalCard from "../components/PageComponents/Home/PersonalCard";
import classes from "../assets/styles/Home.module.css";
import BusinessCard from "../components/PageComponents/Home/BusinessCard";
import AddNewCard from "../components/PageComponents/Home/AddNewCard";
import AddNewEmergencyContact from "../components/AddNewEmergencyContact";
import addcontact from "../assets/images/home_page_images/addcontact.svg";
import GetEmergencyContact from "../components/PageComponents/Home/GetEmergencyContact";
import ShowCustomURLS from "../components/PageComponents/Home/ShowCustomURLS";
import ShowFileUpload from "../components/PageComponents/Home/ShowFileUpload";
import ShowCashApp from "../components/PageComponents/Home/ShowCashApp";
import ShowVenmo from "../components/PageComponents/Home/ShowVenmo";
import ShowPayment from "../components/PageComponents/Home/ShowPayment";
import HeroSection from "../components/HeroSection";
import FunctionCard from "../components/PageComponents/Function/FunctionCard";
import FunctionCreateCardsData from "../utils/data/FunctionCreateCardsData";
import {
  Card_URL,
  EMERGENCY_CONTACT,
  PAYMENT,
  CUSTOME_URL,
  FILE_UPLOAD,
  HOMEDASHBOARD,
  APP_SETTING,
  THEME_GET,
  PRIORITY,
} from "../utils/constants/apiUrl";
import { get, deleteRecord, post, put } from "../server";
import {
  loggedInUser,
  globalAppSetting,
  teamIDCheck,
  themeColorAtom,
  buttonColorAtom,
} from "../utils/store";
import { useAtom } from "jotai";
import Loader from "../components/Loader";
import showToast from "../helpers/showToast";
import ConfirmationModal from "../components/Modals/ConfirmationModal";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import _ from "lodash";
import CustomURLSAddEditModal from "../components/Modals/CustomURLSAddEditModal";
import CashAppAddEditModal from "../components/Modals/CashAppAddEditModal";
import VenmoAddEditModal from "../components/Modals/VenmoAddEditModal";
import FileUploadAddEditModal from "../components/Modals/FileUploadAddEditModal";
import { formatUrl } from "../utils/functions";
import uploadImage from "../helpers/uploadImage";
import EmptyListingBanner from "../components/EmptyListingBanner";
import handlePhoneNumber from "../helpers/handlePhoneNumber";
import PaymentAddEditModal from "../components/Modals/PaymentAddEditModal";
import useFunctionsCount from "../hooks/useFunctionsCount";
import functionTypes from "../utils/constants/functionTypes";
import chroma from "chroma-js";
import { useLocation } from "react-router-dom";
import { RiListUnordered } from "react-icons/ri";
import { Modal, Form } from "react-bootstrap";
import crossicon from "../assets/images/global_icons/crossicon.svg";
import FormInput from "../components/Forms/FormInput";
import { isVisible } from "@testing-library/user-event/dist/cjs/utils/index.js";

const emptyEmergencyContactData = {
  isNew: true,
  id: "",
  first_name: "",
  last_name: "",
  country_phone: "",
  phone_number: "",
  image: "",
  description: "",
};

const emptyCustomUrlData = {
  isNew: true,
  id: "",
  title: "",
  url_path: "",
};

const emptyPaymentData = {
  isNew: true,
  id: "",
  title: "",
  url_path: "",
  payment_type: "",
};

const emptyFilesData = {
  isNew: true,
  id: "",
  title: "",
  url_path: "",
};

const Home = (props) => {
  const [loggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [appSetting, setAppSetting] = useAtom(globalAppSetting);
  const [themeColor, setThemeColor] = useAtom(themeColorAtom);
  const [buttonColor, setButtonColor] = useAtom(buttonColorAtom);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [personalCards, setPersonalCards] = useState([]);
  const [businessCards, setBusinessCards] = useState([]);
  const [personalCardsCount, setPersonalCardsCount] = useState(0);
  const [businessCardsCount, setBusinessCardsCount] = useState(0);
  const [formBuilderCount, setFormBuilderCount] = useState(0);
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [customUrls, setCustomUrls] = useState([]);
  const [payment, setPayment] = useState([]);
  const [cashApps, setCashApps] = useState([]);
  const [venmos, setVenmos] = useState([]);
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cards, setCards] = useState([]);

  const [deleteId, setDeleteId] = useState(null);
  const [deleteType, setDeleteType] = useState(null);

  const [emergencyContactData, setEmergencyContactData] = useState(
    emptyEmergencyContactData
  );
  const [customUrlData, setCustomUrlData] = useState(emptyCustomUrlData);
  const [paymentData, setPaymentData] = useState(emptyPaymentData);
  const [paymentCount, setPaymentCount] = useState("");
  const [fileUploadData, setFileUploadData] = useState(emptyFilesData);

  const [showCustomUrlModal, setShowCustomUrlModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showEmergencyModal, setShowEmergencyModal] = useState(false);

  const [showPriorityModal, setShowPriorityModal] = useState(false);
  const [sortedFunctionData, setSortedFunctionData] = useState([]);

  const { compareLimits, updateCount } = useFunctionsCount();
  const { teamId, userId } = useParams();

  const handleEmergencyOpenModal = () => {
    setShowEmergencyModal(true);
  };

  const handleCloseEmergencyModal = () => {
    setEmergencyContactData(emptyEmergencyContactData);
    setShowEmergencyModal(false);
  };

  const handlePaymentOpenModal = () => {
    const isLimitExceeded = compareLimits(functionTypes.PAYMENT);
    if (isLimitExceeded)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    setShowPaymentModal(true);
  };

  const handlePaymentCloseModal = () => {
    setPaymentData(emptyPaymentData);
    setShowPaymentModal(false);
  };
  const handleCustomUrlOpenModal = () => {
    const isLimitExceeded = compareLimits(functionTypes.CUSTOM_URL);
    if (isLimitExceeded)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    setShowCustomUrlModal(true);
  };

  const handleCustomUrlCloseModal = () => {
    setCustomUrlData(emptyCustomUrlData);
    setShowCustomUrlModal(false);
  };
  const handleFileUploadOpenModal = () => {
    const isLimitExceeded = compareLimits(functionTypes.FILE_UPLOAD);
    if (isLimitExceeded)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    setShowFileUploadModal(true);
  };

  const handleFileUploadCloseModal = () => {
    setFileUploadData(emptyFilesData);
    setShowFileUploadModal(false);
  };

  const handleDelete = (id, type) => {
    setDeleteId(id);

    setDeleteType(Card_URL);
    handleShowModal(true);
  };

  const handleEmergencyDelete = (id) => {
    setDeleteId(id);
    setDeleteType(EMERGENCY_CONTACT);
    handleShowModal(true);
  };

  const handleCustomDelete = (id) => {
    setDeleteId(id);
    setDeleteType(CUSTOME_URL);
    handleShowModal(true);
  };
  const handleFileUploadDelete = (id) => {
    setDeleteId(id);
    setDeleteType(FILE_UPLOAD);
    handleShowModal(true);
  };

  const handlePaymentDelete = (id) => {
    setDeleteId(id);
    setDeleteType(PAYMENT);
    handleShowModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseModal(true);
    setLoading(true);

    let { status, message } = await deleteRecord(
      `${deleteType}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (deleteType == Card_URL) {
        updateCount(functionTypes.CARD, functionTypes.DELETE);
      }
      if (deleteType == PAYMENT) {
        updateCount(functionTypes.PAYMENT, functionTypes.DELETE);
      }
      if (deleteType == CUSTOME_URL) {
        updateCount(functionTypes.CUSTOM_URL, functionTypes.DELETE);
      }
      if (deleteType == FILE_UPLOAD) {
        updateCount(functionTypes.FILE_UPLOAD, functionTypes.DELETE);
      }
      if (deleteType == EMERGENCY_CONTACT) {
        updateCount(functionTypes.EMERGENCY_CONTACT, functionTypes.DELETE);
      }

      await getHomeDashboardData();
      showToast(status, message);
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  const handlePersonalBusinessCard = (data) => {
    let personalCardsData = [];
    let businessCardsData = [];
    data.forEach((card) => {
      if (card.card_type === "personal") {
        personalCardsData.push(card);
      } else {
        businessCardsData.push(card);
      }

      setPersonalCards(personalCardsData);
      setBusinessCards(businessCardsData);
    });
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const getHomeDashboardData = async () => {
    setLoading(true);
    let queryParams = "";

    if (userTeamId) {
      queryParams = `?team_id=${userTeamId}`;
    }
    let { status, data, message } = await get(
      `${HOMEDASHBOARD}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (Object.keys(data).length > 0) {
        setPersonalCardsCount(data.personalCardCount);
        setBusinessCardsCount(data.businessCardCount);

        if (data.cards.length > 0) {
          setCards(data.cards);

          let personalCardsData = [];
          let businessCardsData = [];

          data.cards.forEach((card) => {
            if (card.card_type === "personal") {
              personalCardsData.push(card);
            } else {
              businessCardsData.push(card);
            }

            setPersonalCards(personalCardsData);
            setBusinessCards(businessCardsData);
          });
        } else {
          setCards([]);
          setPersonalCards([]);
          setBusinessCards([]);
        }

        setCustomUrls(data.customUrls);
        setEmergencyContacts(data.emergencyContacts);
        setPayment(data.onlyPayments);
        setPaymentCount(
          data.cashAppCount + data.customPaymentCount + data.venmoCount
        );
        setFiles(data.fileUploads);
        setFormBuilderCount(data.formBuilderCount);
      }
    } else {
      // error message
      showToast(status, message);
    }
    setLoading(false);
  };

  const handleSavePayment = async () => {
    setModalLoading(true);

    let payload = _.cloneDeep(paymentData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};

    if (payload.id) {
      result = await put(
        PAYMENT + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        PAYMENT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      if (!payload.id) {
        updateCount(functionTypes.PAYMENT, functionTypes.ADD);
      }
      await getHomeDashboardData();
      handleCloseModal();
    }

    showToast(status, message);
    setModalLoading(false);
  };

  const handleSaveCustomUrl = async () => {
    setModalLoading(true);
    let payload = _.cloneDeep(customUrlData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};

    if (payload.id) {
      result = await put(
        CUSTOME_URL + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        CUSTOME_URL,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      if (!payload.id) {
        updateCount(functionTypes.CUSTOM_URL, functionTypes.ADD);
      }
      await getHomeDashboardData();
      handleCloseModal();
    }

    showToast(status, message);
    setModalLoading(false);
  };
  const handleSaveFileUpload = async () => {
    setModalLoading(true);

    let payload = _.cloneDeep(fileUploadData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};

    if (payload.id) {
      result = await put(
        FILE_UPLOAD + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        FILE_UPLOAD,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      if (!payload.id) {
        updateCount(functionTypes.FILE_UPLOAD, functionTypes.ADD);
      }
      await getHomeDashboardData();
      handleCloseModal();
    }

    showToast(status, message);
    setModalLoading(false);
  };

  const handleSaveEmergencyContact = async (newData) => {
    setModalLoading(true);
    let payload = _.cloneDeep(newData);
    handlePhoneNumber(payload);

    if (typeof payload.image == "object" && payload.image) {
      const { status, message, data } = await uploadImage(
        "emergency_contact",
        payload.image,
        loggedUser?.token
      );

      if (status) {
        payload.image = data;
      } else {
        setModalLoading(false);
        showToast(status, message);
        return;
      }
    }

    let result = {};

    if (payload.id) {
      result = await put(
        EMERGENCY_CONTACT + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        EMERGENCY_CONTACT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      if (!payload.id) {
        updateCount(functionTypes.EMERGENCY_CONTACT, functionTypes.ADD);
      }
      handleCloseEmergencyModal();
      await getHomeDashboardData();
      setEmergencyContactData(emptyEmergencyContactData);
    } else {
      setModalLoading(false);
    }
    showToast(status, message);
    setModalLoading(false);
    setLoading(false);
  };

  const handlePersonalScrollLeft = () => {
    document.getElementById("personalCardWrapper").scrollLeft -= 220;
  };

  const handlePersonalScrollRight = () => {
    document.getElementById("personalCardWrapper").scrollLeft += 220;
  };

  const handleBusinessScrollLeft = () => {
    document.getElementById("businessCardWrapper").scrollLeft -= 220;
  };

  const handleBusinessScrollRight = () => {
    document.getElementById("businessCardWrapper").scrollLeft += 220;
  };

  const getAppSetting = async () => {
    let { status, data, message } = await get(
      APP_SETTING,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (Object.keys(data).length > 0) setAppSetting(data[0]);
    } else {
      showToast(status, message);
    }
  };

  useEffect(() => {
    getHomeDashboardData();
    getAppSetting();
  }, [userTeamId]);

  // const [themeColor, setThemeColor] = useState(null);
  // const [buttonColor, setButtonColor] = useState(null);
  const location = useLocation();
  const role = location.state?.userRole;
  const fetchColors = async (role) => {
    setLoading(true);
    let { status, message, data } = await get(
      THEME_GET,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if ((role !== "super_admin" || role !== "sub_admin") && status) {
      // const themeColor = data?.theme_color;
      // const buttonColor = data?.button_color;
      const newThemeColor = data?.theme_color;
      const newButtonColor = data?.button_color;
      // Update atoms with new colors
      setThemeColor(newThemeColor);
      setButtonColor(newButtonColor);
      if (data?.theme_color !== null || data?.button_color !== null) {
        const isLight = chroma(newThemeColor).luminance() > 0.4;
        const isBtnLight = chroma(newButtonColor).luminance() > 0.4;
        const primaryTextColor = isLight
          ? chroma(newThemeColor).darken(4.5).hex()
          : chroma(newThemeColor).brighten(3.5).hex();
        const headingTextColor = isLight ? "#000000" : "#ffffff";
        const secondaryColor = isLight
          ? chroma(newThemeColor).darken(0.1).hex()
          : chroma(newThemeColor).brighten(0.2).hex();
        const accentColor = isLight
          ? chroma(newThemeColor).darken(0.4).hex()
          : chroma(newThemeColor).brighten(0.1).hex();
        const tertiaryColor = isLight
          ? chroma(newThemeColor).darken(0.3).hex()
          : chroma(newThemeColor).brighten(0.3).hex();
        const borderColor = isLight
          ? chroma(newThemeColor).darken(0.4).hex()
          : chroma(newThemeColor).brighten(0.5).hex();
        const buttonColorLight = isBtnLight
          ? chroma(newButtonColor).darken(1.5).hex()
          : chroma(newButtonColor).brighten(1.5).hex();
        const socialBg = isLight
          ? chroma(newThemeColor).darken(0.8).hex()
          : chroma(newThemeColor).brighten(-0.5).hex();
        const buttonTextColor = isBtnLight ? "#000000" : "#ffffff";
        const gradientColorDark = isLight ? "#ffffff" : "#22a081";
        const gradientColorLight = isLight ? "#ffffff" : "#76e2c6";
        const heroGradientColor = isLight ? secondaryColor : "#535f79";

        const root = document.documentElement;
        root.style.setProperty("--primary_color", newThemeColor);
        root.style.setProperty("--secondary_color", secondaryColor);
        root.style.setProperty("--accent_color", accentColor);
        root.style.setProperty("--tertiary_color", tertiaryColor);
        root.style.setProperty("--border_color", borderColor);
        root.style.setProperty("--primary_text_color", primaryTextColor);
        root.style.setProperty("--heading_text_color", headingTextColor);
        root.style.setProperty("--primary_button_color", newButtonColor);
        root.style.setProperty(
          "--primary_button_color_light",
          buttonColorLight
        );
        root.style.setProperty("--social_bg", socialBg);
        root.style.setProperty("--button_text_color", buttonTextColor);
        root.style.setProperty("--gradient_color_dark", gradientColorDark);
        root.style.setProperty("--gradient_color_light", gradientColorLight);
        root.style.setProperty("--hero_gradient_color", heroGradientColor);
      } else {
        const root = document.documentElement;
        root.style.setProperty("--primary_color", "#1e1e1e");
        root.style.setProperty("--secondary_color", "#21293b");
        root.style.setProperty("--accent_color", "#19202e");
        root.style.setProperty("--tertiary_color", "#232c3d");
        root.style.setProperty("--border_color", "#333e56");
        root.style.setProperty("--primary_text_color", "#535f79");
        root.style.setProperty("--heading_text_color", "#ffffff");
        root.style.setProperty("--primary_button_color", "#22a081");
        root.style.setProperty("--primary_button_color_light", "#76e2c6");
        root.style.setProperty("--social_bg", "#10151b");
        root.style.setProperty("--gradient_color_dark", "#22a081");
        root.style.setProperty("--gradient_color_light", "#76e2c6");
        root.style.setProperty("--hero_gradient_color", "#535f79");
      }
    } else {
      const root = document.documentElement;
      root.style.setProperty("--primary_color", "#1e1e1e");
      root.style.setProperty("--secondary_color", "#21293b");
      root.style.setProperty("--accent_color", "#19202e");
      root.style.setProperty("--tertiary_color", "#232c3d");
      root.style.setProperty("--border_color", "#333e56");
      root.style.setProperty("--primary_text_color", "#535f79");
      root.style.setProperty("--heading_text_color", "#ffffff");
      root.style.setProperty("--primary_button_color", "#22a081");
      root.style.setProperty("--primary_button_color_light", "#76e2c6");
      root.style.setProperty("--social_bg", "#10151b");
      root.style.setProperty("--gradient_color_dark", "#22a081");
      root.style.setProperty("--gradient_color_light", "#76e2c6");
      root.style.setProperty("--hero_gradient_color", "#535f79");
    }
    setLoading(false);
  };

  const handlePriorityModalShow = () => {
    setShowPriorityModal(true);
  };

  const handlePriorityModalClose = () => {
    setShowPriorityModal(false);
  };

  const handlePriorityChange = (index, newValue) => {
    const updatedSortedFunctionData = [...sortedFunctionData];
    updatedSortedFunctionData[index].priority = newValue;
    setSortedFunctionData(updatedSortedFunctionData);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userFunctionSettings = [];
    const functionRows = document.querySelectorAll(".function-row");
    functionRows.forEach((row) => {
      // const functionType = row.querySelector(
      //   'select[name="function_type"]'
      // ).value;
      const functionType = row.querySelector(
        'input[name="function_type"]'
      ).value;
      const priority = row.querySelector('input[name="priority"]').value;
      const isVisible = row.querySelector('input[type="checkbox"]').checked
        ? 1
        : 0;
      userFunctionSettings.push({
        function_type: functionType,
        priority: priority,
        is_visible: isVisible,
      });
    });
    const payload = {
      team_id: teamId,
      userFunctionSettings: userFunctionSettings,
    };

    // Make API call
    let { status, message, data } = await post(
      PRIORITY,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      priorityFunction();
      setShowPriorityModal(false);
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };
  const priorityFunction = async () => {
    setLoading(true);
    const queryString = `?team_id=${userTeamId}`;
    let { status, message, data } = await get(
      userTeamId ? PRIORITY + queryString : PRIORITY,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      priorityData(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const priorityData = (sortPriorityData) => {
    const sortedData = [...FunctionCreateCardsData].map((card) => {
      const priorityItem = sortPriorityData.find(
        (item) => item.function_type === card.function_type
      );
      console.log("priorityItem", priorityItem);
      return {
        ...card,
        priority: priorityItem ? priorityItem.priority : 0,
        is_visible: priorityItem ? priorityItem.is_visible === "1" : "0",
      };
    });

    sortedData.sort((a, b) => {
      if (a.priority !== b.priority) {
        return a.priority - b.priority;
      }
    });

    setSortedFunctionData(sortedData);
  };

  useEffect(() => {
    // if (loggedUser && Object.keys(loggedUser).length > 0) {
    fetchColors(role);
    priorityFunction();
    // }
  }, [role]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="mt-3">
            <h6 className={classes.title}>Functions</h6>
          </div>
          <div className={classes.mainWrapper}>
            <HeroSection />
          </div>

          <div className={classes.boxWrapper}>
            <button
              className={classes.priorityBtn}
              onClick={handlePriorityModalShow}
            >
              <RiListUnordered /> <span>PRIORITY FUNCTION</span>
            </button>
            {/* {FunctionCreateCardsData.map((card) => {
              return (
                <FunctionCard
                  key={card.id}
                  {...card}
                  teamId={teamId}
                  userId={userId}
                  personalCardsCount={personalCardsCount}
                  businessCardsCount={businessCardsCount}
                  customUrls={customUrls}
                  emergencyContacts={emergencyContacts}
                  payment={payment}
                  files={files}
                />
              );
            })} */}
            {sortedFunctionData.map((card) => (
              <FunctionCard
                key={card.id}
                {...card}
                teamId={teamId}
                userId={userId}
                personalCardsCount={personalCardsCount}
                businessCardsCount={businessCardsCount}
                customUrls={customUrls}
                emergencyContacts={emergencyContacts}
                payment={payment}
                files={files}
                formBuilderCount={formBuilderCount}
                isVisible={card.is_visible ? " " : "cardHide"}
              />
            ))}
          </div>

          <CustomURLSAddEditModal
            loading={modalLoading}
            show={showCustomUrlModal}
            data={customUrlData}
            setSelectedData={setCustomUrlData}
            handleSaveShowModal={async () => {
              await handleSaveCustomUrl();
              handleCustomUrlCloseModal();
            }}
            handleCloseModal={handleCustomUrlCloseModal}
          />
          <FileUploadAddEditModal
            loading={modalLoading}
            show={showFileUploadModal}
            data={fileUploadData}
            setSelectedData={setFileUploadData}
            handleSaveShowModal={async () => {
              await handleSaveFileUpload();
              handleFileUploadCloseModal();
            }}
            handleCloseModal={handleFileUploadCloseModal}
          />

          <PaymentAddEditModal
            loading={modalLoading}
            show={showPaymentModal}
            data={paymentData}
            setSelectedData={setPaymentData}
            handleSaveShowModal={async () => {
              await handleSavePayment();
              handlePaymentCloseModal();
            }}
            handleCloseModal={handlePaymentCloseModal}
          />

          <ConfirmationModal
            show={showModal}
            handleCloseModal={handleCloseModal}
            handleConfirmDelete={handleConfirmDelete}
          />
          <Modal
            centered
            className={`customModal priorityModal`}
            size="md"
            show={showPriorityModal}
          >
            <Modal.Header className={classes.priorityModalHeader}>
              <span>Function Priority List</span>
              <img
                src={crossicon}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={handlePriorityModalClose}
              />
            </Modal.Header>

            <Modal.Body className={classes.priorityModalBody}>
              <div className={`${classes.imageUpload}`}>
                {sortedFunctionData.map((functionItem, index) => (
                  <div
                    className="row function-row align-items-center mb-3"
                    key={index}
                  >
                    {/* <div className="col-lg-5">
                      <label className={classes.label}>
                        Select Function Type
                      </label>
                      <div className="input-group globalInputWithIcon">
                        <select
                          style={{ border: "0" }}
                          className="form-select customSelect"
                          name="function_type"
                          value={functionItem.function_type}
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          <option value="card">Digital Card</option>
                          <option value="custom_url">Custom Urls</option>
                          <option value="emergency_contact">
                            Emergency Contacts
                          </option>
                          <option value="file_upload">File Uploads</option>
                          <option value="payment">Payment Service</option>
                          <option value="form_builder">Form Builder</option>
                        </select>
                      </div>
                    </div> */}
                    <div className="col-lg-2 d-none">
                      <FormInput
                        type="text"
                        name="function_type"
                        label="Function Name"
                        value={functionItem.function_type}
                        disabled
                      />
                    </div>
                    <div className="col-lg-9">
                      <FormInput
                        type="text"
                        name="priority"
                        label={`Enter Priority Number for ${functionItem.title}`}
                        value={
                          functionItem.priority === 0
                            ? ""
                            : functionItem.priority
                        }
                        handleChange={(e) =>
                          handlePriorityChange(index, e.target.value)
                        }
                      />
                    </div>
                    <div className="col-lg-3">
                      <div className="form-check mt-4">
                        {console.log(
                          "functionItem.is_visible",
                          functionItem.is_visible
                        )}
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={`isVisible${index}`}
                        />
                        <label
                          className="form-check-label mb-0"
                          htmlFor={`isVisible${index}`}
                        >
                          Visible
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
                {/* {[1, 2, 3, 4, 5].map((index) => (
                  <div
                    className="row function-row align-items-center mb-3"
                    key={index}
                  >
                    <div className="col-lg-5">
                      <label className={classes.label}>
                        Select Function Type
                      </label>
                      <div className="input-group globalInputWithIcon">
                        <select
                          style={{ border: "0" }}
                          className="form-select customSelect"
                          name="function_type"
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          <option value="card">Digital Card</option>
                          <option value="custom_url">Custom Urls</option>
                          <option value="emergency_contact">
                            Emergency Contacts
                          </option>
                          <option value="file_upload">File Uploads</option>
                          <option value="payment">Payment Service</option>
                          <option value="form_builder">Form Builder</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <FormInput
                        type="text"
                        name="priority"
                        label="Enter Priority Number"
                      />
                    </div>
                    <div className="col-lg-3">
                      <div className="form-check text-start">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={`isVisible${index}`}
                        />
                        <label
                          className="form-check-label mb-0"
                          htmlFor={`isVisible${index}`}
                        >
                          Visible
                        </label>
                      </div>
                    </div>
                  </div>
                ))} */}
              </div>
              <div className="text-center mt-4">
                <button
                  className={`${classes.saveBtn} mb-3 mt-0`}
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default Home;
