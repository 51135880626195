import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const showToast = (status, message) => {
  if (status) {
    toast.success(message);
  } else if (typeof status === "undefined") {
    toast.error("No Server Response");
  } else {
    if (message == "Unauthenticated." || message == "Unauthorize User") {
      if (!window.location.pathname.includes("/unauthorized")) {
        return (window.location.href = "/unauthorized");
      }
    }
    toast.error(message);
  }
};
export default showToast;
