import React, { useState, useEffect, useRef } from "react";
import { Image } from "react-bootstrap";
import defaultImage from "../assets/images/not-image-found.png";
import classes from "../assets/styles/ImageUpload.module.css";
import { BsFillCameraFill } from "react-icons/bs";

const ImageUploadSquare = ({ selectedImage, setSelectedImage, title }) => {
  const [image, setImage] = useState(selectedImage);

  useEffect(() => {
    if (selectedImage instanceof Object) {
      setImage(URL.createObjectURL(selectedImage));
    } else {
      setImage(selectedImage);
    }
  }, [selectedImage]);

  const inputRef = useRef(null);
  const handleImageClick = () => {
    inputRef.current.click();
  };

  const onChangeImage = async (event) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      setSelectedImage(file);
    }
  };

  return (
    <>
      <div className="imageWrapper" onClick={() => handleImageClick()}>
        <Image
          className={classes.profileImage}
          src={image ? image : defaultImage}
          width="200px"
          height="200px"
          onError={(e) => {
            e.currentTarget.src = defaultImage;
          }}
          loading="lazy"
          decoding="async"
        />
        <div className="icon">
          <BsFillCameraFill />
        </div>
        <input
          accept="image/*"
          type="file"
          name="profile_image"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={onChangeImage}
        />
      </div>
      <style type="text/css">
        {`
        .imageWrapper {
          position: relative;
          width: 200px;
          height: 200px;
        }
        .imageWrapper::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.1);
        }
        .icon {
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size:25px
        }
        }
	`}
      </style>
    </>
  );
};

export default ImageUploadSquare;
