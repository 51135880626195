import React, { useState } from "react";
import classes from "../assets/styles/Pages/Settings/AddNewPage.module.css";
import FormInput from "../components/Forms/FormInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import personicon from "../assets/images/signup_icons/personicon.svg";
import emailicon from "../assets/images/signup_icons/email.svg";
import { Button } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import ValidateSchema from "../helpers/ValidateSchema";
import PackageContactFormSchema from "../utils/SchemasValidation/PackageContactFormSchema";
import { post } from "../server";
import showToast from "../helpers/showToast";
import { PACKAGE } from "../utils/constants/apiUrl";
import Loader from "../components/Loader";
import { right } from "@popperjs/core";

const PackageContactForm = () => {
  const [contactForm, setContactForm] = useState({
    title: "",
    email: "",
    description: "",
  });

  const { packageId } = useParams();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setContactForm({ ...contactForm, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(contactForm, PackageContactFormSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    let contactObj = contactForm;
    contactObj.package_id = packageId;
    let { status, message } = await post(
      `${PACKAGE}/packageContactForm`,
      contactForm
    );
    if (status) {
      showToast(status, "Request successfully send");
      setContactForm({
        title: "",
        email: "",
        description: "",
      });
      navigate(-1);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="py-4 px-3">
        <h6 className="pageTitle">Contact Us</h6>
        <button
          style={{ float: "right" }}
          className="primary_button"
          onClick={() => navigate(-1)}
        >
          Go Back
        </button>
      </div>
      <div className={`mainWrapper ${classes.subscriptionWrapper}`}>
        <div className="row">
          <div className="col-md-6 col-12">
            <FormInput
              label="Title"
              type="text"
              name="title"
              placeholder="Enter Title"
              value={contactForm.title}
              handleChange={handleChange}
              error={errors?.title}
              icon={personicon}
              autofocus={true}
            />
          </div>
          <div className="col-md-6 col-12">
            <FormInput
              label="Email"
              type="text"
              name="email"
              placeholder="Email Address"
              value={contactForm.email}
              handleChange={handleChange}
              error={errors?.email}
              icon={emailicon}
            />
          </div>
          <div className="col-12">
            <label htmlFor="" className={classes.label}>
              Description
            </label>
            <textarea
              name="description"
              id=""
              cols="100"
              rows="10"
              value={contactForm.description}
              placeholder="Enter Description"
              onChange={handleChange}
              className={
                errors?.description
                  ? "globalTextArea errorInput"
                  : "globalTextArea"
              }
            />
            <p className="errorText">{errors?.description || ""}</p>
            <div className="formBottom">
              <Button
                className="submitButton"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageContactForm;
