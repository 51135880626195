import React, { useState, useRef, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import classes from "../../assets/styles/QRCodeModal.module.css";
import { GiShare } from "react-icons/gi";
import { QRCode } from "react-qrcode-logo";
import qrCodeBorder from "../../assets/images/qr-code-border.svg";
import { SHARE_QR } from "../../utils/constants/common";
import {
  CUSTOMIZE_QRCODE,
  Card_URL,
  IMAGE_UPLOAD,
  EMERGENCY_CONTACT,
  CUSTOME_URL,
  FILE_UPLOAD,
  PAYMENT,
} from "../../utils/constants/apiUrl";
import qrCodeLogo from "../../assets/images/sidebar_icons/collapsedLogoDark.svg";
import uploadQrLogo from "../../assets/images/function_page_icons/camera.svg";
import removeQrLogo from "../../assets/images/function_page_icons/nocamera.svg";
import { put, get, post } from "../../server";
import { useAtom } from "jotai";
import showToast from "../../helpers/showToast";
import ImageUploadSquare from "../../components/ImageUploadSquare";
import baseUrl from "../../utils/constants/baseUrl";
import {
  loggedInUser,
  teamIDCheck,
  subscribedPackage,
} from "../../utils/store";
import { useDropzone } from "react-dropzone";
import { BsImageFill } from "react-icons/bs";
import crossicon from "../../assets/images/global_icons/crossicon.svg";
import html2canvas from "html2canvas";
const QRCodeModal = ({
  id,
  target,
  qrOpen,
  handleQRClose,
  setShowCopyPopup,
  handleDownload,
  qrCodeId,
  functionType,
  functionUuid,
  url,
  qr_bg_color,
  qr_front_color,
  qr_image,
  qr_code_image,
  function_type,
  function_id,
  payment_type,
  qrCodeCustomize,
  selectedData,
}) => {
  console.log("functionType", functionType);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const qrLogoImageRef = useRef(null);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [bgColor, setBgColor] = useState(qr_bg_color || "#ffffff");
  const [editBgColor, setEditBgColor] = useState(bgColor);
  const [fgColor, setFgColor] = useState(qr_front_color || "#000000");
  const [editFgColor, setEditFgColor] = useState(fgColor);
  const [image, selectImage] = useState("");
  const [logoImageURL, setLogoImageURL] = useState(qr_image || qrCodeLogo);
  const [editLogoImageURL, setEditLogoImageURL] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [qrCodeImage, setQRCodeImage] = useState();
  const [editQRCodeImage, setEditQRCodeImage] = useState(qrCodeImage);
  console.log("qrCodeImage", qrCodeImage);
  console.log("editQRCodeImage", editQRCodeImage);
  let uploadedImageUrl = "";
  let qrCodeuploadedImageUrl = "";
  const handleEditModalOpen = () => {
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };
  const handleUploadModalOpen = () => {
    setUploadModalOpen(true);
  };

  const handleUploadModalClose = () => {
    setUploadModalOpen(false);
  };
  const getCardData = () => {
    let queryParams = "";
    if (userTeamId) {
      queryParams = `?team_id=${userTeamId}`;
    }
    (async () => {
      let { status, message, data } = await get(
        `${Card_URL}${queryParams}`,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        setEditBgColor(data[0]?.qrCodeCustomize?.qr_bg_color);
        setBgColor(data[0]?.qrCodeCustomize?.qr_bg_color);
        setEditFgColor(data[0]?.qrCodeCustomize?.qr_front_color);
        setFgColor(data[0]?.qrCodeCustomize?.qr_front_color);
        setLogoImageURL(
          baseUrl.PUBLIC_URL + data[0]?.qrCodeCustomize?.qr_image || qrCodeLogo
        );
        setEditLogoImageURL(
          baseUrl.PUBLIC_URL + data[0]?.qrCodeCustomize?.qr_image || qrCodeLogo
        );
        setEditQRCodeImage(
          baseUrl.PUBLIC_URL + data[0]?.qrCodeCustomize?.qr_code_image
        );
        setQRCodeImage(
          baseUrl.PUBLIC_URL + data[0]?.qrCodeCustomize?.qr_code_image
        );
        console.log("setQRCodeImagecarddddddddddddd", qrCodeImage);
      }
    })();
  };
  const getEmergencyData = () => {
    let queryParams = "";

    if (userTeamId) {
      queryParams = `?team_id=${userTeamId}`;
    }
    (async () => {
      let { status, message, data } = await get(
        `${EMERGENCY_CONTACT}${queryParams}`,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        setEditBgColor(data[0]?.qrCodeCustomize?.qr_bg_color);
        setBgColor(data[0]?.qrCodeCustomize?.qr_bg_color);
        setEditFgColor(data[0]?.qrCodeCustomize?.qr_front_color);
        setFgColor(data[0]?.qrCodeCustomize?.qr_front_color);
        setLogoImageURL(
          baseUrl.PUBLIC_URL + data[0]?.qrCodeCustomize?.qr_image || qrCodeLogo
        );
        setEditLogoImageURL(
          baseUrl.PUBLIC_URL + data[0]?.qrCodeCustomize?.qr_image || qrCodeLogo
        );
        setEditQRCodeImage(
          baseUrl.PUBLIC_URL + data[0]?.qrCodeCustomize?.qr_code_image
        );
        setQRCodeImage(
          baseUrl.PUBLIC_URL + data[0]?.qrCodeCustomize?.qr_code_image
        );
      }
    })();
  };
  const getCustomURLSData = () => {
    let queryParams = "";

    if (userTeamId) {
      queryParams = `?team_id=${userTeamId}`;
    }
    (async () => {
      let { status, message, data } = await get(
        `${CUSTOME_URL}${queryParams}`,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        console.log("data", data);
        setEditBgColor(data[0]?.qrCodeCustomize?.qr_bg_color);
        setBgColor(data[0]?.qrCodeCustomize?.qr_bg_color);
        setEditFgColor(data[0]?.qrCodeCustomize?.qr_front_color);
        setFgColor(data[0]?.qrCodeCustomize?.qr_front_color);
        setLogoImageURL(data[0]?.qrCodeCustomize?.qr_image || qrCodeLogo);
        setEditLogoImageURL(
          baseUrl.PUBLIC_URL + data[0]?.qrCodeCustomize?.qr_image || qrCodeLogo
        );
        setEditQRCodeImage(
          baseUrl.PUBLIC_URL + data[0]?.qrCodeCustomize?.qr_code_image
        );
        setQRCodeImage(
          baseUrl.PUBLIC_URL + data[0]?.qrCodeCustomize?.qr_code_image
        );
      }
    })();
  };
  const getFileUploadData = () => {
    let queryParams = "";

    if (userTeamId) {
      queryParams = `?team_id=${userTeamId}`;
    }
    (async () => {
      let { status, message, data } = await get(
        `${FILE_UPLOAD}${queryParams}`,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        setEditBgColor(data[0]?.qrCodeCustomize?.qr_bg_color);
        setBgColor(data[0]?.qrCodeCustomize?.qr_bg_color);
        setEditFgColor(data[0]?.qrCodeCustomize?.qr_front_color);
        setFgColor(data[0]?.qrCodeCustomize?.qr_front_color);
        setLogoImageURL(
          baseUrl.PUBLIC_URL + data[0]?.qrCodeCustomize?.qr_image || qrCodeLogo
        );
        setEditLogoImageURL(
          baseUrl.PUBLIC_URL + data[0]?.qrCodeCustomize?.qr_image || qrCodeLogo
        );
      }
    })();
  };
  const getPaymentData = () => {
    let queryParams = "";

    if (userTeamId) {
      queryParams = `?team_id=${userTeamId}`;
    }
    (async () => {
      let { status, message, data } = await get(
        `${PAYMENT}${queryParams}`,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        const paymentId = data.find((item) => item.id === id);
        setEditBgColor(paymentId?.qrCodeCustomize?.qr_bg_color);
        setBgColor(paymentId?.qrCodeCustomize?.qr_bg_color);
        setEditFgColor(paymentId?.qrCodeCustomize?.qr_front_color);
        setFgColor(paymentId?.qrCodeCustomize?.qr_front_color);
        setLogoImageURL(
          baseUrl.PUBLIC_URL + paymentId?.qrCodeCustomize?.qr_image ||
            qrCodeLogo
        );
        setEditLogoImageURL(
          baseUrl.PUBLIC_URL + paymentId?.qrCodeCustomize?.qr_image ||
            qrCodeLogo
        );
      }
    })();
  };
  const handleRemoveLogoImage = () => {
    setLogoImageURL("");
    setEditLogoImageURL("");
  };
  const handleQRLogoSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Upload Image
    //console.log(setLogoImageURL);
    if (image && typeof image == "object") {
      let formDataObj = new FormData();
      formDataObj.append("image_type", "card_logo");
      formDataObj.append("image", image);

      let { status, message, data } = await post(
        IMAGE_UPLOAD,
        formDataObj,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        uploadedImageUrl = data || "";
        setLogoImageURL(uploadedImageUrl);
        setUploadModalOpen(false);
      } else {
        showToast(status, message);
        setLoading(false);
        return false;
      }
      console.log("uploadedImageUrl:", uploadedImageUrl);
    }
  };
  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const qrCodeContainer = document.getElementById("qrCodeContainer");
    const childElement = qrCodeContainer.querySelector("#qr-code");
    const childELementDurl = childElement.toDataURL("image/png");
    //console.log("childELementDurl", childELementDurl);
    const blob = await fetch(childELementDurl).then((res) => res.blob());

    let formDataObj = new FormData();
    formDataObj.append("image_type", "qr_code_image");
    const file = new File([blob], "qr_code_image.png", { type: "image/png" });
    formDataObj.append("image", file);
    //console.log("formDataObj", formDataObj);
    let { status, message, data } = await post(
      IMAGE_UPLOAD,
      formDataObj,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setEditQRCodeImage(data);
      console.log("setQRCodeImage", editQRCodeImage);
      const payload = {
        id: id,
        function_type: function_type,
        function_id: function_id,
        qr_bg_color: editBgColor,
        qr_front_color: editFgColor,
        qr_image: logoImageURL,
        qr_code_image: data,
      };
      console.log("payload", payload);
      let { status, message } = await post(
        CUSTOMIZE_QRCODE,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        setBgColor(editBgColor);
        setFgColor(editFgColor);
        setLogoImageURL(editLogoImageURL);
      } else {
        showToast(status, message);
        setLoading(false);
        return false;
      }

      if (function_type === "card") {
        getCardData();
      } else if (function_type === "emergency_contact") {
        getEmergencyData();
      } else if (function_type === "custom_url") {
        getCustomURLSData();
      } else if (function_type === "file_upload") {
        getFileUploadData();
      } else if (function_type === "payment") {
        getPaymentData();
      }
    } else {
      showToast(status, message);
      setLoading(false);
      return false;
    }
    handleRemoveLogoImage();
    setEditModalOpen(false);
  };

  useEffect(() => {
    // uploadCardData();
    if (function_type === "card") {
      getCardData();
    } else if (function_type === "emergency_contact") {
      getEmergencyData();
    } else if (function_type === "custom_url") {
      getCustomURLSData();
    } else if (function_type === "file_upload") {
      getFileUploadData();
    } else if (function_type === "payment") {
      getPaymentData();
    }
  }, []);

  return (
    <>
      <Modal
        show={qrOpen}
        onHide={handleQRClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <div className="modalGradientCircle"></div>
          <MdClose onClick={handleQRClose} className="modalCloseBtn" />
        </Modal.Header>

        <Modal.Body style={{ padding: "0" }}>
          <div
            className={`d-flex justify-content-center flex-column align-items-center`}
          >
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ width: "fit-content", marginTop: "-120px" }}
            >
              <div className={classes.qrWrapper}>
                <img className={classes.qrBorder} src={qrCodeBorder} />
                {qrCodeImage == undefined ? (
                  <>
                    <QRCode
                      value={
                        url ? url : `${SHARE_QR}${functionType}/${functionUuid}`
                      }
                      id={qrCodeId ? qrCodeId : "qr-code"}
                      // logoImage={qrCodeLogo}
                      logoImage={logoImageURL ? logoImageURL : qrCodeLogo}
                      logoOpacity={1}
                      logoPadding={3}
                      bgColor={bgColor !== null ? bgColor : "#ffffff"}
                      fgColor={fgColor !== null ? fgColor : "#000000"}
                      eyeRadius={0}
                      removeQrCodeBehindLogo
                    />
                  </>
                ) : (
                  <img
                    className={classes.qrCodeImage}
                    src={qrCodeImage}
                    width="170px"
                    height="170px"
                    loading="lazy"
                    decoding="async"
                  />
                )}
              </div>
              <h5 style={{ margin: "10px" }}>QR Code</h5>
              <p
                style={{
                  lineHeight: "18px",
                  marginBottom: "5px",
                  textAlign: "center",
                }}
              >
                Edit, Share or Download
                <br />
                Your QR Code
              </p>
            </div>
          </div>
          <div className="row justify-content-center mx-0 px-3 gx-2">
            <div className="col-sm-6 text-center">
              <button
                className={`${classes.editBtn} d-block w-100`}
                ref={target}
                onClick={() => handleEditModalOpen({})}
              >
                Edit Qr Code
              </button>
            </div>
            <div className="col-sm-6 text-center">
              <button
                className={`${classes.shareBtn} d-block w-100`}
                ref={target}
                onClick={() => {
                  setShowCopyPopup(true);
                  setTimeout(() => {
                    setShowCopyPopup(false);
                  }, 2000);
                  navigator.clipboard.writeText(
                    url ? url : `${SHARE_QR}${functionType}/${functionUuid}`
                  );
                }}
              >
                <GiShare />
                Share
              </button>
            </div>
          </div>
          <div className={`customModalBtnWrapper ${classes.btnWrapper}`}>
            <button onClick={handleDownload}>Download QR</button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isEditModalOpen}
        onHide={handleEditModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal editQRModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <div className="modalGradientCircle"></div>
          <MdClose onClick={handleEditModalClose} className="modalCloseBtn" />
        </Modal.Header>

        <Modal.Body
          style={{ padding: "0" }}
          className={`d-flex justify-content-center flex-column align-items-center`}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ width: "fit-content", marginTop: "-120px" }}
          >
            <div className={classes.qrWrapper}>
              <img className={classes.qrBorder} src={qrCodeBorder} />
              <div id="qrCodeContainer">
                {/* {console.log("logoImageURL", logoImageURL)} */}
                <QRCode
                  value={
                    url ? url : `${SHARE_QR}${functionType}/${functionUuid}`
                  }
                  id={qrCodeId ? qrCodeId : "qr-code"}
                  // logoImage={logoImageURL ? logoImageURL : qrCodeLogo}
                  logoImage={qrCodeLogo}
                  logoOpacity={1}
                  logoPadding={3}
                  bgColor={editBgColor !== null ? editBgColor : "#ffffff"}
                  fgColor={editFgColor !== null ? editFgColor : "#000000"}
                  eyeRadius={0}
                  removeQrCodeBehindLogo
                />
              </div>
            </div>
            <button
              className={classes.saveBtn}
              ref={target}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className={classes.qrEditFooter}>
          <ul className="list-unstyled m-0">
            <li className="mb-3">
              {/* <ImageUploadSquare
                selectedImage={
                  typeof image == "object" ? image : baseUrl.PUBLIC_URL + image
                }
                setSelectedImage={selectImage}
              /> */}
              <button
                className={classes.uploadBtn}
                onClick={handleUploadModalOpen}
              >
                <img src={uploadQrLogo} />
              </button>
            </li>
            {/* <li className="mb-3">
              <button
                className={classes.uploadBtn}
                onClick={() => qrLogoImageRef.current.click()}
              >
                <img src={uploadQrLogo} />
              </button>
              <input
                accept="image/*"
                type="file"
                name="profile_image"
                ref={qrLogoImageRef}
                style={{ display: "none" }}
                // onChange={handleLogoImageUpload}
              />
            </li> */}
            <li className="mb-3">
              <button
                className={classes.removeImgBtn}
                onClick={handleRemoveLogoImage}
              >
                <img src={removeQrLogo} />
              </button>
            </li>
            <li className="mb-3">
              <Form.Group controlId="">
                <input
                  type="color"
                  //value={}
                  className={classes.colorPicker}
                  //onChange={handleBgColorChange}
                  onChange={(e) => setEditBgColor(e.target.value)}
                />
              </Form.Group>
            </li>
            <li>
              <Form.Group controlId="">
                <input
                  type="color"
                  //value={}
                  className={classes.colorPicker}
                  //onChange={handleFgColorChange}
                  onChange={(e) => setEditFgColor(e.target.value)}
                />
              </Form.Group>
            </li>
          </ul>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        className={`customModal uploadModal`}
        size="md"
        show={uploadModalOpen}
      >
        <Modal.Header className={classes.mediaModalHeader}>
          <span>Upload</span>
          <img
            src={crossicon}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleUploadModalClose}
          />
        </Modal.Header>

        <Modal.Body className={classes.mediaModalBody}>
          <div className="text-center">
            <div
              className={`d-flex justify-content-center align-items-center flex-column position-relative ${classes.imageUpload}`}
            >
              <ImageUploadSquare
                selectedImage={
                  typeof image == "object" ? image : baseUrl.PUBLIC_URL + image
                }
                setSelectedImage={selectImage}
              />
              <BsImageFill />
              <span>
                Drop your Images here, or <span>Browse</span>
              </span>
              <span>Support JPG, PNG, Only</span>
            </div>
            <button
              className={`${classes.saveBtn} mb-3 mt-0`}
              ref={target}
              onClick={handleQRLogoSave}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <style type="text/css">
        {`
          .imageWrapper {
            position: absolute !important;
            width: 100% !important;
            height: 100% !important;
            margin: auto;
          }
          .imageWrapper::before{
            background:transparent !important;
          }
          .imageWrapper img{
            width: 45px;
            height: 45px;
            position: absolute;
            top: 45px;
            left: 0;
            right: 0;
            margin: auto;
            background:var(--primary_color);
          }
          .imageWrapper .icon {
            opacity:0
          }
        `}
      </style>
    </>
  );
};

export default QRCodeModal;
