import React, { useState, useRef, useEffect } from "react";
import urlicon from "../../../assets/images/function_page_icons/url.svg";
import { BsThreeDotsVertical } from "react-icons/bs";
import classes from "../../../assets/styles/PageComponentsStyles/Function/ShowCustomURLSFunctions.module.css";
import DataTable, { createTheme } from "react-data-table-component";
import { MdContentCopy } from "react-icons/md";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import html2canvas from "html2canvas";
import { GiShare } from "react-icons/gi";
import { QRCode } from "react-qrcode-logo";
import { MdClose } from "react-icons/md";
import { Image, Modal } from "react-bootstrap";
import qrCodeBorder from "../../../assets/images/qr-code-border.svg";
import cashapplogo from "../../../assets/images/function_page_icons/cashapplogo.svg";
import venmologo from "../../../assets/images/function_page_icons/venmologo.svg";
import paymentlogo from "../../../assets/images/function_page_icons/paymenticon.svg";
import { useAtom } from "jotai";
import { mobileViewCheck } from "../../../utils/store";
import ShareModal from "../../Modals/ShareModal";
import QRCodeModal from "../../Modals/QRCodeModal";
import Form from "react-bootstrap/Form";

const ShowPaymentFunctions = ({
  show,
  handleShowModal,
  handleCloseModal,
  payments,
  handleDelete,
  handleStatus,
}) => {
  const target = useRef(null);

  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [mobileView] = useAtom(mobileViewCheck);

  const handleQrOpen = (obj) => {
    setSelectedData(obj);
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleShareOpen = (obj) => {
    setSelectedData(obj);
    setShareOpen(true);
  };

  const handleShareClose = () => {
    setShareOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Image",
      width: "10%",
      cell: (row) => (
        <div className={classes.titleWrapper}>
          {row.payment_type === "cash_app" ? (
            <Image
              src={cashapplogo}
              width="50px"
              height="50px"
              roundedCircle={true}
            />
          ) : row.payment_type === "venmo" ? (
            <Image
              src={venmologo}
              width="50px"
              height="50px"
              roundedCircle={true}
            />
          ) : (
            <div
              style={{
                width: "50px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid var(--primary_text_color)",
                borderRadius: "50px",
                background: "var(--primary_button_color)",
              }}
            >
              <Image
                src={paymentlogo}
                width="30px"
                height="30px"
                roundedCircle={true}
              />
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Title",
      width: "20%",
      cell: (row) => <p className="globalTitle">{row.title}</p>,
    },

    {
      name: "Payment Service Type",
      width: "20%",
      cell: (row) => (
        <div className="profileWrapper">
          <p className="globalDetail">
            {row.payment_type === "cash_app"
              ? "cash app"
              : row.payment_type === "custom_payment"
              ? "other"
              : row.payment_type}
          </p>
        </div>
      ),
    },
    {
      name: "URL Path",
      width: "30%",
      cell: (row) => (
        <a
          href={row.url_path}
          target="_blank"
          className={"globalUrlPath"}
          style={{ display: "block" }}
        >
          {row.url_path}
        </a>
      ),
    },
    {
      name: "Status",
      width: "10%",
      center: "true",
      cell: (row) => (
        <Form.Check
          type="switch"
          checked={row.status}
          onClick={() => handleStatus(row.id, row.payment_type)}
        />
      ),
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <a className="dropdown-item" href={row.url_path} target="_blank">
                Visit Link
              </a>
            </li>
            <li>
              <span className="dropdown-item" onClick={() => handleQrOpen(row)}>
                QR Code
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={() => handleShowModal(row)}
              >
                Edit
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                onClick={() => handleStatus(row.id, row.payment_type)}
              >
                {row.status == true ? "Inactive" : "Active"}
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                onClick={() => handleShareOpen(row)}
              >
                Share
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252", cursor: "pointer" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </span>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="listingWrapper">
        <DataTable columns={columns} data={payments} theme="solarized" />
      </div>

      {qrOpen && (
        <QRCodeModal
          qrOpen={qrOpen}
          target={target}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleDownload}
          functionType={
            selectedData.payment_type === "custom_payment"
              ? "customPayment"
              : selectedData.payment_type === "cash_app"
              ? "cashApp"
              : selectedData.payment_type
          }
          functionUuid={selectedData.uuid}
          selectedData={selectedData}
          id={selectedData.id}
          function_type="payment"
          function_id={selectedData.id}
          qr_bg_color={selectedData.qrCodeCustomize?.qr_bg_color}
          qr_front_color={selectedData.qrCodeCustomize?.qr_front_color}
          qr_image={selectedData.qrCodeCustomize?.qr_image}
        />
      )}

      {shareOpen && (
        <ShareModal
          target={target}
          shareOpen={shareOpen}
          handleShareClose={handleShareClose}
          setShowCopyPopup={setShowCopyPopup}
          functionType={
            selectedData.payment_type === "custom_payment"
              ? "customPayment"
              : selectedData.payment_type === "cash_app"
              ? "cashApp"
              : selectedData.payment_type
          }
          functionUuid={selectedData.uuid}
        />
      )}

      <Overlay
        target={target.current}
        show={showCopyPopup}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
      <style type="">
        {`
          .form-switch{
            padding-left: 2.52rem;
          }
        `}
      </style>
    </>
  );
};

export default ShowPaymentFunctions;
