import { IMAGE_UPLOAD } from "../utils/constants/apiUrl";
import { post } from "../server";

const uploadImage = async (image_type, image, token=null) => {
  let formDataObj = new FormData();

  formDataObj.append("image_type", image_type);
  formDataObj.append("image", image);

  let { status, message, data } = await post(
    IMAGE_UPLOAD,
    formDataObj,
    token
  );

  return { status, message, data };
}

export default uploadImage;