import React, { useState, useEffect, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { useAtom } from "jotai";
import classes from "../../../assets/styles/Contact.module.css";
import { EMERGENCY_CONTACT } from "../../../utils/constants/apiUrl";
import { get, deleteRecord, post, put } from "../../../server";
import showToast from "../../../helpers/showToast";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import EmergencyAddEditModal from "../../../components/Modals/EmergencyAddEditModal";
import { formatUrl } from "../../../utils/functions/index";
import Image from "react-bootstrap/Image";
import baseUrl from "../../../utils/constants/baseUrl";
// import dummyImage from "../../../assets/images/no_image.jpg";
import dummyImage from "../../../assets/images/function_page_icons/picIcon.svg";
import uploadImage from "../../../helpers/uploadImage";
import _ from "lodash";
import formatPhoneNumber from "../../../helpers/formatPhoneNumber";

const AllEmergencyContacts = () => {
  let emptyEmergencyContactData = {
    isNew: true,
    id: "",
    first_name: "",
    last_name: "",
    country_phone: "",
    phone_number: "",
    image: "",
  };

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyEmergencyContactData);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  // Handle main modal
  const handleShowModal = (obj) => {
    let newObj = _.cloneDeep(obj);

    if (newObj && Object.keys(newObj).length) {
      if (newObj.phone_number) {
        newObj.phone_number = `${
          newObj.country_phone ? newObj.country_phone : ""
        }${newObj.phone_number}`;
      }

      if (newObj.country_phone) {
        newObj.country_phone = `+${newObj.country_phone}`;
      }

      setSelectedData(newObj);
    }

    setShowModal(true);
  };

  const handleSaveShowModal = async (newData) => {
    setLoading(true);

    let payload = _.cloneDeep(newData);

    if (payload.image && typeof payload.image == "object") {
      const { status, message, data } = await uploadImage(
        "emergency_contact",
        payload.image,
        loggedUser?.token
      );

      if (status) {
        payload.image = data;
      } else {
        showToast(status, message);
        setLoading(false);
        return;
      }
    }

    if (payload.phone_number) {
      payload.phone_number = payload?.phone_number.slice(
        payload?.country_phone?.length - 1,
        payload.phone_number.length
      );

      payload.country_phone = payload?.country_phone?.slice(
        1,
        payload?.country_phone?.length
      );
    }

    let result = {};
    if (selectedData.id) {
      result = await put(
        EMERGENCY_CONTACT + `/${selectedData.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      result = await post(
        EMERGENCY_CONTACT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      setLoading(false);

      let emergencyContactArr = _.cloneDeep(emergencyContacts);

      // On Update
      if (selectedData.id) {
        const index = emergencyContacts.findIndex(
          (el) => el.id == selectedData.id
        );
        emergencyContactArr[index] = data;
      } else {
        emergencyContactArr.unshift(data);
      }

      setEmergencyContacts(emergencyContactArr);
      handleCloseModal();
    } else {
      setLoading(false);
    }

    showToast(status, message);
  };

  const handleCloseModal = () => {
    setSelectedData(emptyEmergencyContactData);
    setShowModal(false);
  };

  const loadEmergencyContacts = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      EMERGENCY_CONTACT,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0) {
        setEmergencyContacts(data);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadEmergencyContacts();
  }, []);

  const filteredItems = useMemo(() => {
    const filtered = emergencyContacts.filter(
      (item) =>
        `${item?.first_name || ""} ${item?.last_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.country_phone ? `+${item.country_phone}` : ""}${
          item.phone_number || ""
        }`.includes(filterText)
    );

    return filtered;
  }, [filterText, emergencyContacts]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Image",
      width: "100px",
      cell: (row) => (
        <Image
          className="globalIMageBorder globalIMageFit"
          src={baseUrl.PUBLIC_URL + row.image || dummyImage}
          roundedCircle={true}
          width="50px"
          height="50px"
          onError={(e) => {
            e.currentTarget.src = dummyImage;
          }}
        />
      ),
    },
    {
      name: "Name",
      width: "200px",
      cell: (row) => (
        <div className={"globalDetail"}>
          {row?.first_name} {row?.last_name}
        </div>
      ),
    },
    {
      name: "Phone #",
      width: "200px",
      cell: (row) => (
        <a
          href={`tel:${
            row.phone_number
              ? row.country_phone
                ? "+" +
                  row.country_phone +
                  " " +
                  formatPhoneNumber(row.phone_number)
                : row.phone_number || ""
              : "-"
          }`}
          className={"globalDetail"}
        >
          {row.phone_number
            ? row.country_phone
              ? "+" +
                row.country_phone +
                " " +
                formatPhoneNumber(row.phone_number)
              : row.phone_number || ""
            : "-"}
        </a>
      ),
    },
    {
      name: "Username",
      width: "200px",
      cell: (row) => (
        <div className={"globalDetail"}>
          {row?.user_data?.first_name} {row?.user_data?.last_name}
        </div>
      ),
    },
    {
      name: "Created in",
      width: "150px",
      cell: (row) => (
        <div className={"globalDetail"}>
          {row.team_detail !== null ? "Team Mode" : "Individual"}
        </div>
      ),
    },
    {
      name: "Team Name",
      width: "200px",
      cell: (row) => (
        <div className={"globalDetail"}>
          {row.team_detail !== null ? row.team_detail.name : "-"}
        </div>
      ),
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link
                className="dropdown-item"
                onClick={() => handleShowModal(row)}
              >
                Edit
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${EMERGENCY_CONTACT}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await loadEmergencyContacts();
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div className="">
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Emergency Contacts <span>({emergencyContacts.length})</span>
              </p>
              <FormInput
                icon={searchIcon}
                placeholder="Search..."
                value={filterText}
                handleChange={handleFilter}
              />
            </div>
          </div>

          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              paginationResetDefaultPage={resetPaginationToggle}
              pagination
              theme="solarized"
            />
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="emergency contact"
          />

          {/* Emergency Contact modals */}
          {showModal && (
            <EmergencyAddEditModal
              show={showModal}
              data={selectedData}
              setSelectedData={setSelectedData}
              handleSaveShowModal={handleSaveShowModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AllEmergencyContacts;
