import React, { useEffect } from "react";
import { Image, Modal } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import classes from "../../../assets/styles/PageComponentsStyles/Contact/ListView.module.css";
import DataTable, { createTheme } from "react-data-table-component";
import dummyImage from "../../../assets/images/global_icons/dummyimage.svg";
import { MdContentCopy } from "react-icons/md";
import baseURL from "../../../utils/constants/baseUrl";
import { MdClose } from "react-icons/md";
import { useState, useRef } from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { useAtom } from "jotai";
import { mobileViewCheck } from "../../../utils/store";
import formatPhoneNumber from "../../../helpers/formatPhoneNumber";
import ShareModal from "../../Modals/ShareModal";

const ListView = ({ contacts, handleDelete }) => {
  const [shareOpen, setShareOpen] = useState(false);
  const [uuid, setUuid] = useState("");
  const [show, setShow] = useState(false);
  const [mobileView] = useAtom(mobileViewCheck);

  const target = useRef(null);

  const handleShareOpen = (contact_uuid) => {
    setShareOpen(true);
    setUuid(contact_uuid);
  };

  const handleShareClose = () => {
    setShareOpen(false);
    setUuid("");
    setShow(false);
  };

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Name",
      width: "30%",
      cell: (row) => (
        <div className={classes.profileWrapper}>
          <Image
            src={`${baseURL.PUBLIC_URL}${row.profile_image}` || dummyImage}
            roundedCircle={true}
            loading="lazy"
            decoding="async"
            width="50px"
            height="50px"
            onError={(e) => {
              e.currentTarget.src = dummyImage;
            }}
          />
          <div>
            <p className="globalName">
              {row.first_name} {row.last_name}
            </p>
            <p className="globalDetail">{row.description}</p>
          </div>
        </div>
      ),
    },
    // {
    //   name: "Number",
    //   width: "20%",
    //   cell: (row) => (
    //     <div className={"globalNumber"}>
    //       {formatPhoneNumber(row.country_phone + row.phone_number) || "-"}
    //       {/* {`${row.country_phone ? `+${row.country_phone}` : ""}${
    //         row.phone_number || "-"
    //       }`} */}
    //     </div>
    //   ),
    // },
    {
      name: "Address",
      cell: (row) => (
        <>
          {row.city || row.state || row.country ? (
            <a
              className={"detailInput"}
              href={`http://maps.google.com/?q=${row.city ? row.city : ""}${
                row.city && (row.state || row.country) ? ", " : ""
              }${row.state ? row.state : ""}${
                row.state && row.country ? ", " : ""
              }${row.country ? row.country : ""}`}
              target="_blank"
              rel="noreferrer"
            >
              {row.city ? row.city : ""}
              {row.city && (row.state || row.country) ? ", " : ""}
              {row.state ? row.state : ""}
              {row.state && row.country ? ", " : ""}
              {row.country ? row.country : ""}
            </a>
          ) : (
            <p className="globalDetail">-</p>
          )}
        </>
      ),
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link
                className="dropdown-item"
                to={`/contacts/viewcontact/${row.contact_id}`}
              >
                View
              </Link>
            </li>
            {row.is_manual ? (
              <li>
                <Link
                  className="dropdown-item"
                  to={`/contacts/${row.contact_id} `}
                >
                  Edit
                </Link>
              </li>
            ) : null}

            <li>
              <span
                className="dropdown-item"
                onClick={() => handleShareOpen(row.uuid)}
              >
                Share
              </span>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.contact_id)}
              >
                Delete
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="listingWrapper">
        <DataTable
          columns={columns}
          data={contacts}
          theme="solarized"
          responsive={true}
          noDataComponent="No record found" //or your component
        />
      </div>

      {shareOpen && (
        <ShareModal
          target={target}
          shareOpen={shareOpen}
          handleShareClose={handleShareClose}
          setShowCopyPopup={setShow}
          functionType="contact"
          functionUuid={uuid}
        />
      )}

      <Overlay
        target={target.current}
        show={show}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default ListView;
