import React, { useEffect, useState, useMemo } from "react";
import classes from "../../../assets/styles/Pages/Subscriptions/ManageSubscriptions.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import {
  SUBSCRIPTIONS_PACKAGES,
  PACKAGES_REMOVE,
  PACKAGES_STATUS,
  ACTIVE_SUBSCRIPTIONS_PACKAGES,
} from "../../../utils/constants/apiUrl";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { BsThreeDotsVertical } from "react-icons/bs";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import { get, post } from "../../../server";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
const ActiveSubscriptions = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [packages, setPackages] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [statusId, setStatusId] = useState(null);
  let { id } = useParams();
  console.log("thfrtgyjnrfygtj", id);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const loadPackages = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      ACTIVE_SUBSCRIPTIONS_PACKAGES,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      console.log("Active Sub....", data);
      if (data.length > 0) {
        setPackages(data);
        const status = data.map((statusValue) => statusValue.status);
        setStatusId(status);
        console.log("setStatusId", statusId);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const filteredItems = useMemo(() => {
    const filtered = packages.filter(
      (item) =>
        `${item.first_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.last_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.package_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.package_name || ""}`.includes(filterText)
    );

    return filtered;
  }, [filterText, packages]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Name",
      width: "200px",
      cell: (row) => (
        <p className="globalDetail">
          {row.first_name} {row.last_name}
        </p>
      ),
    },
    {
      name: "Email",
      width: "200px",
      // cell: (row) => <p className="globalDetail">{row.email}</p>,
      cell: (row) => (
        <a
          href={`mailto:${row.email}`}
          className="globalDetail"
          style={{ display: "block" }}
        >
          {row.email}
        </a>
      ),
    },
    {
      name: "Package Name",
      width: "150px",
      cell: (row) => <p className="globalDetail">{row.package_name}</p>,
    },
    {
      name: "Package Type",
      width: "150px",
      cell: (row) => <p className="globalDetail">{row.package_type}</p>,
    },
    {
      name: "Creation Date",
      width: "200px",
      //cell: (row) => <p className="globalDetail">{row.creation_date}</p>,
      cell: (row) => {
        const creationDate = new Date(row.start_date);
        const formattedDate = creationDate.toLocaleString();
        return <p className="globalDetail">{formattedDate}</p>;
      },
    },
    {
      name: "End Date",
      width: "200px",
      cell: (row) => {
        const creationDate = new Date(row.end_date);
        const formattedDate = creationDate.toLocaleString();
        return <p className="globalDetail">{formattedDate}</p>;
      },
    },
  ];

  useEffect(() => {
    loadPackages();
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">Active Subscriptions</p>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              </div>
            </div>
          </div>
          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              theme="solarized"
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ActiveSubscriptions;
