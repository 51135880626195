import React from "react";
import TeamFunctionCard from "../../components/PageComponents/Function/TeamFunctionCard";
import classes from "../../assets/styles/Pages/Function/Function.module.css";
import HeroSection from "../../components/HeroSection";
import TeamData from "../../utils/data/TeamData";

const Team = () => {
  return (
    <>
      <div className="mt-3">
        <h6 className={classes.title}>Team</h6>
      </div>
      <div className={classes.mainWrapper}>
        <HeroSection />
      </div>

      <div className={classes.boxWrapper}>
        {TeamData.map((card) => {
          return <TeamFunctionCard key={card.id} {...card} />;
        })}
      </div>
    </>
  );
};

export default Team;
