import React from "react";
import classes from "../assets/styles/Loader.module.css";
const Loader = () => {
  return (
    <>
      <div
        className={`d-flex justify-content-center align-items-center ${classes.loaderWrapper}`}
      >
        <div className={classes.loader}></div>
      </div>
    </>
  );
};

export default Loader;
