import React, { useState, useEffect } from "react";
import classes from "../../assets/styles/Pages/Help/PrivacyAndPolicy.module.css";
import privacy_image from "../../assets/images/help/privacy.svg";
import { Link } from "react-router-dom";
import { SETTING } from "../../utils/constants/apiUrl";
import { get } from "../../server";
import showToast from "../../helpers/showToast";
import Loader from "../../components/Loader";

const ExchangePolicy = () => {
  const [loading, setLoading] = useState(false);
  const [exchangePolicy, setExchangePolicy] = useState({});

  const loadExchangePolicy = async () => {
    setLoading(true);
    let { status, message, data } = await get(SETTING + "/page");
    if (status) {
      if (data.length > 0) {
        const policy = data.filter(
          (page) => page.page_type === "exchange_policy"
        );
        setExchangePolicy(policy["0"]);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadExchangePolicy();
  }, []);
  return (
    <>
      <div className="helpBackLinkWrapper">
        <Link to="/help">Back</Link>
      </div>
      <div className={classes.helpHeader}>
        <div className="row flex-md-row flex-column-reverse gy-4">
          {/* PrivacyAndPolicy top left section */}
          <div className="col-sm-6">
            <h3 className="helpHeading">Exchange Policy</h3>
            <p className="helpSubHeading">
              Contact Wearable’s Exchange and Return Policy
            </p>
            <p className="helpDetail">
              Thank you for choosing Contact Co. We’re dedicated to delivering
              quality products and exceptional customer experiences. To provide
              you with clear information, we’ve outlined our policies regarding
              exchanges, subscription adjustments, and warranty coverage.
            </p>
          </div>

          {/*Right Section Image */}
          <div className="col-sm-6">
            <img src={privacy_image} alt="" className="helpImage" />
          </div>
        </div>
      </div>

      {!loading ? (
        <>
          <div className={`${classes.contentWrapper}`}>
            <p className="helpSubHeading">{exchangePolicy?.title}</p>

            <p
              className={classes.detail}
              dangerouslySetInnerHTML={{ __html: exchangePolicy?.description }}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ExchangePolicy;
