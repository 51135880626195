import React, { useState, useEffect } from "react";
import classes from "../../assets/styles/Pages/Function/FormBuilder.module.css";
import { Modal, Button } from "react-bootstrap";
import Loader from "../../components/Loader";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import { useAtom } from "jotai";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormInput from "../../components/Forms/FormInput";
import { post, put, get } from "../../server";
import { FORM_BUILDER } from "../../utils/constants/apiUrl";
import showToast from "../../helpers/showToast";
import { Form } from "react-bootstrap";
import { Colors } from "../../utils/data/CardFunctionsData";
import PhoneInput from "react-phone-input-2";
import countries from "country-codes-list";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillPersonFill, BsFillPeopleFill } from "react-icons/bs";
import { MdEmail, MdLocationOn, MdOutlineContentCopy } from "react-icons/md";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import {
  BiGlobe,
  BiSolidPhoneCall,
  BiLogoFacebookCircle,
  BiSolidTrash,
  BiEdit,
  BiLogoTiktok,
  BiLogoSnapchat,
} from "react-icons/bi";
import { PiBuildingsBold } from "react-icons/pi";
import { IoDocumentText } from "react-icons/io5";
import { IoLogoWhatsapp, IoLogoGoogle, IoLogoLinkedin } from "react-icons/io";
import { RiInstagramFill, RiTwitterXFill, RiYoutubeFill } from "react-icons/ri";
import InputText from "../../assets/images/function_page_icons/input.svg";
import Line from "../../assets/images/function_page_icons/line.svg";
import Heading from "../../assets/images/function_page_icons/heading.svg";
import List from "../../assets/images/function_page_icons/list.svg";
import RadioGroup from "../../assets/images/function_page_icons/radio.svg";
import Checkbox from "../../assets/images/function_page_icons/checkbox.svg";
import Dropdown from "../../assets/images/function_page_icons/dropdown.svg";
import Date from "../../assets/images/function_page_icons/calendar.svg";
import Paragraph from "../../assets/images/function_page_icons/paragraph.svg";
import Textarea from "../../assets/images/function_page_icons/textarea.svg";
import Upload from "../../assets/images/function_page_icons/upload.svg";
import AddLink from "../../assets/images/function_page_icons/link.svg";

function FormBuilder() {
  let { id } = useParams();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(false);
  const [formElements, setFormElements] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editLabel, setEditLabel] = useState("");
  const [editOptions, setEditOptions] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState([]);
  const [addedPreDefinedComponents, setAddedPreDefinedComponents] = useState(
    []
  );
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [selectedFieldTypes, setSelectedFieldTypes] = useState(
    new Array(formElements.length).fill("")
  );
  const [isRequired, setIsRequired] = useState(
    new Array(formElements.length).fill(false)
  );
  const [formName, setFormName] = useState("");
  const navigate = useNavigate();

  const countryCodes = require("country-codes-list");

  const generateCountryOptions = () => {
    const myCountryCodesObject = countryCodes.customList(
      "countryCode",
      "[{countryCode}] {countryNameEn}: +{countryCallingCode}"
    );
    const countryOptions = Object.entries(myCountryCodesObject).map(
      ([code, label]) => ({
        value: code,
        label: label.replace(`[${code}] `, ""),
      })
    );

    return countryOptions;
  };

  const [preDefineItems, setPreDefineItems] = useState([
    {
      id: 1,
      icon: <BsFillPersonFill />,
      label: "Pronouns",
      checked: false,
      type: "pronounsDropdown",
      field_type: "pronouns",
    },
    {
      id: 2,
      icon: <BsFillPersonFill />,
      label: "First Name",
      checked: false,
      type: "input",
      field_type: "first_name",
    },
    {
      id: 3,
      icon: <BsFillPersonFill />,
      label: "Last Name",
      checked: false,
      type: "input",
      field_type: "last_name",
    },
    {
      id: 4,
      icon: <MdEmail />,
      label: "Email Address",
      checked: false,
      type: "input",
      field_type: "email",
    },
    {
      id: 5,
      icon: <PiBuildingsBold />,
      label: "Company",
      checked: false,
      type: "input",
      field_type: "company",
    },
    {
      id: 6,
      icon: <BsFillPeopleFill />,
      label: "Job Position",
      checked: false,
      type: "input",
      field_type: "company_position",
    },
    {
      id: 7,
      icon: <MdLocationOn />,
      label: "Country",
      checked: false,
      type: "input",
      field_type: "country",
    },
    {
      id: 8,
      icon: <MdLocationOn />,
      label: "State",
      checked: false,
      type: "input",
      field_type: "state",
    },
    {
      id: 9,
      icon: <MdLocationOn />,
      label: "City",
      checked: false,
      type: "input",
      field_type: "city",
    },
    {
      id: 10,
      icon: <MdLocationOn />,
      label: "Address",
      checked: false,
      type: "input",
      field_type: "address",
    },
    {
      id: 11,
      icon: <BiGlobe />,
      label: "Website",
      checked: false,
      type: "input",
      field_type: "website_url",
    },
    {
      id: 12,
      icon: <IoDocumentText />,
      label: "Information",
      checked: false,
      type: "textarea",
      field_type: "description",
    },
    {
      id: 13,
      icon: <BiSolidPhoneCall />,
      label: "Phone Type",
      checked: false,
      type: "preDropdown",
      field_type: "phone_type",
    },
    {
      id: 14,
      icon: <BiSolidPhoneCall />,
      label: "Country Code",
      checked: false,
      type: "countryList",
      field_type: "country_phone",
    },
    {
      id: 15,
      icon: <BiSolidPhoneCall />,
      label: "Phone Number",
      checked: false,
      type: "input",
      field_type: "phone_number",
    },
    {
      id: 16,
      icon: <BiLogoFacebookCircle />,
      label: "Color",
      checked: false,
      type: "color",
      field_type: "color",
    },
    {
      id: 17,
      icon: <BiLogoFacebookCircle />,
      label: "Facebook",
      checked: false,
      type: "input",
      field_type: "facebook",
    },
    {
      id: 18,
      icon: <RiInstagramFill />,
      label: "Instagram",
      checked: false,
      type: "input",
      field_type: "instagram",
    },
    {
      id: 19,
      icon: <IoLogoLinkedin />,
      label: "Linkedin",
      checked: false,
      type: "input",
      field_type: "linkedIn",
    },
    {
      id: 20,
      icon: <RiTwitterXFill />,
      label: "Twitter",
      checked: false,
      type: "input",
      field_type: "twitter",
    },
    {
      id: 21,
      icon: <BiLogoSnapchat />,
      label: "SnapChat",
      checked: false,
      type: "input",
      field_type: "snapChat",
    },
    {
      id: 22,
      icon: <BiLogoTiktok />,
      label: "Tiktok",
      checked: false,
      type: "input",
      field_type: "tiktok",
    },
    {
      id: 23,
      icon: <IoLogoGoogle />,
      label: "Google",
      checked: false,
      type: "input",
      field_type: "Google",
    },
    {
      id: 24,
      icon: <RiYoutubeFill />,
      label: "Youtube",
      checked: false,
      type: "input",
      field_type: "youtube",
    },
  ]);

  const handleFieldTypeChange = (value, index) => {
    const updatedSelectedFieldTypes = [...selectedFieldTypes];
    updatedSelectedFieldTypes[index] = value;
    setSelectedFieldTypes(updatedSelectedFieldTypes);
  };

  const toggleIsRequired = (index) => {
    const updatedIsRequired = [...isRequired];
    updatedIsRequired[index] = !updatedIsRequired[index];
    setIsRequired(updatedIsRequired);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const toggleCheckbox = (id) => {
    const updatedCheckboxItems = preDefineItems.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setPreDefineItems(updatedCheckboxItems);
  };

  const addElement = (type, label, options = []) => {
    if (
      (type === "radio" && options.length < 2) ||
      (type === "checkbox" && options.length < 1) ||
      (type === "list" && options.length < 1) ||
      (type === "dropdown" && options.length < 2)
    ) {
      return;
    }

    setFormElements([...formElements, { type, label, options }]);
    setShowSubmitButton(true);
  };

  const addPredefinedElementsToForm = () => {
    const checkedItems = preDefineItems.filter(
      (item) => item.checked && !addedPreDefinedComponents.includes(item.id)
    );
    console.log("checkedItems", checkedItems);
    const elementsToAdd = checkedItems.map((item) => ({
      type: item.type,
      label: item.label,
      isPreDefined: true,
      fieldType: item.field_type,
    }));
    console.log("elementsToAdd", elementsToAdd);
    setFormElements([...formElements, ...elementsToAdd]);
    setAddedPreDefinedComponents([
      ...addedPreDefinedComponents,
      ...checkedItems.map((item) => item.id),
    ]);

    showToast("success", "Add component submitted successfully");
    setShowSubmitButton(true);
  };

  const removeElement = (index) => {
    const updatedElements = [...formElements];
    updatedElements.splice(index, 1);
    setFormElements(updatedElements);
    setShowSubmitButton(updatedElements.length > 0);
  };

  const copyElement = (index) => {
    const copiedElement = { ...formElements[index] };
    setFormElements([...formElements, copiedElement]);
    setShowSubmitButton(true);
  };

  const openEditModal = (index, label, options) => {
    setEditingIndex(index);
    setEditLabel(label);
    setEditOptions(options || []);
  };

  const saveEdit = () => {
    if (editingIndex !== null) {
      const updatedElements = [...formElements];
      if (editOptions.length === 0) {
        updatedElements[editingIndex].label = editLabel;
      } else {
        updatedElements[editingIndex].label = editLabel;
        updatedElements[editingIndex].options = editOptions;
      }
      setFormElements(updatedElements);
    }
    setEditingIndex(null);
    setEditLabel("");
    setEditOptions([]);
  };

  const getFormData = async () => {
    let { status, message, data } = await get(
      `${FORM_BUILDER}/${id}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setFormName(data.name);
      const formElementsFromAPI = data.formBuilderField.map((field) => ({
        type: field.type === "inputbox" ? "input" : field.type,
        label: field.label,
        options: field.formBuilderFieldData.map((option) => option.data),
        isPreDefined: field.is_predefined,
        fieldType: field.field_type,
      }));
      setFormElements(formElementsFromAPI);
      const updatedIsRequired = data.formBuilderField.map(
        (field) => field.is_required
      );
      setIsRequired(updatedIsRequired);
      setShowSubmitButton(true);

      const fieldTypeValues = data.formBuilderField.map(
        (field) => field.field_type
      );
      setSelectedFieldTypes(fieldTypeValues);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getFormData();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formBuilderFields = formElements.map((element, index) => {
      //const fieldData = element.options || [];
      const fieldData =
        element.type === "color"
          ? Colors.map((color) => color.bgColor)
          : element.options || [];

      return {
        label: element.label,
        type: element.type === "input" ? "inputbox" : element.type,
        field_type: selectedFieldTypes[index] || element.fieldType,
        is_required: element.isPreDefined
          ? true
          : isRequired[index]
          ? isRequired[index]
          : false,
        is_predefined: element.isPreDefined || false,
        formBuilderFieldData: fieldData,
      };
    });

    const payload = {
      team_id: userTeamId,
      name: formName,
      formBuilderFields: formBuilderFields,
    };
    if (id) {
      let { status, message, data } = await put(
        userTeamId
          ? `${FORM_BUILDER}/${id} + queryString`
          : `${FORM_BUILDER}/${id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        navigate("/function/formBuilderListing");
      } else {
        showToast(status, message);
      }
    } else {
      let { status, message, data } = await post(
        `${FORM_BUILDER}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        navigate("/function/formBuilderListing");
      } else {
        showToast(status, message);
      }
      showToast(status, message);
    }
    // let { status, message, data } = await post(
    //   FORM_BUILDER,
    //   payload,
    //   Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    // );

    setLoading(false);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="">
            <div className={classes.mainWrapper}>
              <h6 className={classes.title}>
                <Link
                  onClick={handleGoBack}
                  style={{ color: "var(--heading_text_color)" }}
                >
                  Form Builder Listing
                </Link>
                {` > Form Builder`}
              </h6>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className={classes.builderWrapper}>
                  <h6>Elements</h6>
                  <div className={classes.preDefineComponentWrapper}>
                    <h6>Predefined components</h6>
                    <p>
                      Choose the components from below dropdowns and add to the
                      form.
                    </p>
                    <div
                      className={classes.preDefineComponentButton}
                      onClick={toggleMenu}
                    >
                      Select
                      {menuOpen ? (
                        <BiChevronUp className={classes.arrow} />
                      ) : (
                        <BiChevronDown className={classes.arrow} />
                      )}
                    </div>
                    {menuOpen && (
                      <div className={classes.preDefineComponentMenu}>
                        {preDefineItems.map((item) => (
                          <div
                            key={item.id}
                            className={classes.preDefineComponent}
                          >
                            <input
                              type="checkbox"
                              id={`checkbox-${item.id}`}
                              value={item.label}
                              checked={item.checked}
                              onChange={() => toggleCheckbox(item.id)}
                            />
                            <label htmlFor={`checkbox-${item.id}`}>
                              {item.icon}
                              {item.label}
                            </label>
                          </div>
                        ))}
                        <div className="text-center">
                          <button
                            type="button"
                            className={`${classes.btnWrapper} w-100`}
                            onClick={addPredefinedElementsToForm}
                          >
                            Add to Form
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.customComponentWrapper}>
                    <h6>Custom components</h6>
                    <p>Pick your custom components to create the form.</p>
                    <div className={classes.customComponentWrapperInner}>
                      <button
                        className={classes.customComponent}
                        onClick={() => addElement("input", "Input Text")}
                      >
                        <div>
                          <div className={classes.imgWrapper}>
                            <img src={InputText} alt="" />
                          </div>
                          <p>Input Text</p>
                        </div>
                      </button>
                      <button
                        className={classes.customComponent}
                        onClick={() => addElement("line", "Line")}
                      >
                        <div>
                          <div className={classes.imgWrapper}>
                            <img src={Line} alt="" />
                          </div>
                          <p>Line</p>
                        </div>
                      </button>
                      <button
                        className={classes.customComponent}
                        onClick={() => addElement("heading", "Heading")}
                      >
                        <div>
                          <div className={classes.imgWrapper}>
                            <img src={Heading} alt="" />
                          </div>
                          <p>Heading</p>
                        </div>
                      </button>
                      <button
                        className={classes.customComponent}
                        onClick={() => addElement("list", "List", ["Option 1"])}
                      >
                        <div>
                          <div className={classes.imgWrapper}>
                            <img src={List} alt="" />
                          </div>
                          <p>List</p>
                        </div>
                      </button>
                      <button
                        className={classes.customComponent}
                        onClick={() =>
                          addElement("radio", "Radio Button", [
                            "Option 1",
                            "Option 2",
                          ])
                        }
                      >
                        <div>
                          <div className={classes.imgWrapper}>
                            <img src={RadioGroup} alt="" />
                          </div>
                          <p>Radio group</p>
                        </div>
                      </button>
                      <button
                        className={classes.customComponent}
                        onClick={() =>
                          addElement("checkbox", "Checkbox", ["Option 1"])
                        }
                      >
                        <div>
                          <div className={classes.imgWrapper}>
                            <img src={Checkbox} alt="" />
                          </div>
                          <p>Checkbox</p>
                        </div>
                      </button>
                      <button
                        className={classes.customComponent}
                        onClick={() =>
                          addElement("dropdown", "Dropdown", [
                            "Option 1",
                            "Option 2",
                          ])
                        }
                      >
                        <div>
                          <div className={classes.imgWrapper}>
                            <img src={Dropdown} alt="" />
                          </div>
                          <p>Dropdown</p>
                        </div>
                      </button>
                      <button
                        className={classes.customComponent}
                        onClick={() => addElement("date", "Date")}
                      >
                        <div>
                          <div className={classes.imgWrapper}>
                            <img src={Date} alt="" />
                          </div>
                          <p>Date</p>
                        </div>
                      </button>
                      {/* <button
                        className={classes.customComponent}
                        onClick={() => addElement("time", "Time Picker")}
                      >
                        <div>
                          <p>Time</p>
                        </div>
                      </button> */}
                      <button
                        className={classes.customComponent}
                        onClick={() => addElement("paragraph", "Paragraph")}
                      >
                        <div>
                          <div className={classes.imgWrapper}>
                            <img src={Paragraph} alt="" />
                          </div>
                          <p>Paragraph</p>
                        </div>
                      </button>
                      <button
                        className={classes.customComponent}
                        onClick={() => addElement("textarea", "Textarea")}
                      >
                        <div>
                          <div className={classes.imgWrapper}>
                            <img src={Textarea} alt="" />
                          </div>
                          <p>Textarea</p>
                        </div>
                      </button>
                      <button
                        className={classes.customComponent}
                        onClick={() => addElement("upload", "Upload")}
                      >
                        <div>
                          <div className={classes.imgWrapper}>
                            <img src={Upload} alt="" />
                          </div>
                          <p>Upload</p>
                        </div>
                      </button>
                      <button
                        className={classes.customComponent}
                        onClick={() => addElement("link", "Link")}
                      >
                        <div>
                          <div className={classes.imgWrapper}>
                            <img src={AddLink} alt="" />
                          </div>
                          <p>Link</p>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className={classes.builderWrapper}>
                  <div className={`${classes.formName} formName`}>
                    {/* <FormInput
                      type="text"
                      label="Form name"
                      placeholder="Enter form name"
                    /> */}
                    <FormInput
                      type="text"
                      label="Form name"
                      placeholder="Enter form name"
                      value={formName}
                      onChange={(e) => setFormName(e.target.value)}
                    />
                  </div>
                  <h6 className="mb-3">Create a Form</h6>
                  <form className={`${classes.formWrapper} formWrapper`}>
                    {formElements.map((element, index) => (
                      <div className="position-relative mb-4" key={index}>
                        {/* {!element.isPreDefined || !id (
                          <label className="mainLabel">{element.label}</label>
                        )} */}

                        {/* {element.type === "preContactInput" && (
                          <>
                            <h6 className="mb-1">{element?.label}</h6>
                            <div
                              className={`d-flex align-items-center phoneInputWrapper ${classes.phoneInputMainWrapper} ${classes.formInput}`}
                            >
                              <div
                                className="input-group globalInputWithIcon addCardSelect"
                                style={{ maxWidth: "20%" }}
                              >
                                <select
                                  style={{ border: "0" }}
                                  className="form-select customSelect"
                                >
                                  <option value="">Select</option>
                                  <option value="home">Home</option>
                                  <option value="office">Mobile</option>
                                  <option value="custom">Custom</option>
                                </select>
                              </div>
                              <div
                                className="addPhoneInput"
                                style={{ minWidth: "80%" }}
                              >
                                <PhoneInput
                                  country={"us"}
                                  enableSearch={true}
                                  countryCodeEditable={false}
                                  inputClass={"phoneNumberInput"}
                                />
                              </div>
                            </div>
                          </>
                        )} */}

                        {element.type === "input" && (
                          <div className={classes.formInput}>
                            <FormInput
                              type="text"
                              label={element?.label}
                              name={element?.label}
                            />
                          </div>
                        )}
                        {element.type === "textarea" && (
                          <div className={classes.formInput}>
                            <h6 className="mb-3">{element?.label}</h6>
                            <textarea
                              className="globalTextArea"
                              type="text"
                              name="description"
                            ></textarea>
                          </div>
                        )}
                        {element.type === "radio" && (
                          <div className={classes.formInput}>
                            <h6>{element.label}</h6>
                            {element.options.map((option, optionIndex) => (
                              <div key={optionIndex}>
                                <input type="radio" />
                                <label>{option}</label>
                              </div>
                            ))}
                          </div>
                        )}
                        {element.type === "checkbox" && (
                          <div className={classes.formInput}>
                            <h6>{element.label}</h6>
                            {element.options.map((option, optionIndex) => (
                              <div key={optionIndex}>
                                <input type="checkbox" />
                                <label>{option}</label>
                              </div>
                            ))}
                          </div>
                        )}

                        {/* {element.type === "color" && (
                          <div className={classes.formInput}>
                            <h6>{element.label}</h6>
                            {Colors.map((color) => {
                              const { id, bgColor } = { ...color };
                              return (
                                <div
                                  className="form-check form-check-inline"
                                  key={`color${id}`}
                                >
                                  <input
                                    type="radio"
                                    class="form-check-input"
                                    name="color"
                                  />
                                  <label>
                                    <div
                                      style={{ backgroundColor: bgColor }}
                                      className="colorBox"
                                    />
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        )} */}
                        {element.type === "color" && (
                          <>
                            <h6>{element.label}</h6>
                            <div className="colorBorder">
                              {Colors.map((color) => {
                                const { id, bgColor } = { ...color };
                                return (
                                  <div
                                    className="form-check form-check-inline form-check-color"
                                    key={`color${id}`}
                                  >
                                    <input
                                      type="checkbox"
                                      id={`color${id}`}
                                      className="form-check-input"
                                      name="color"
                                      value={bgColor}
                                    />
                                    <label>
                                      <div
                                        style={{ backgroundColor: bgColor }}
                                        className="colorBox"
                                      />
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}

                        {element.type === "list" && (
                          <div className={classes.formInput}>
                            <h5>{element.label}</h5>
                            <ul
                              style={{
                                listStyleType: "disc",
                                paddingLeft: "1rem",
                              }}
                            >
                              {element.options.map((option, optionIndex) => (
                                <li key={optionIndex}>{option}</li>
                              ))}
                            </ul>
                          </div>
                        )}

                        {element.type === "line" && (
                          <div>
                            <h6>{element.label}</h6>
                            <hr
                              style={{
                                borderTopColor: "var(--border_color)",
                                opacity: "1",
                              }}
                            />
                          </div>
                        )}
                        {element.type === "paragraph" && (
                          <div>
                            <h6>Paragraph</h6>
                            <p>{element.label}</p>
                          </div>
                        )}
                        {element.type === "heading" && <h4>{element.label}</h4>}
                        {element.type === "preDropdown" && (
                          <div className={classes.selectWrapper}>
                            <h6 className="mb-1">{element.label}</h6>
                            <div className="input-group globalInputWithIcon">
                              <select
                                style={{ border: "0" }}
                                className="form-select customSelect"
                              >
                                <option value="" disabled>
                                  Select
                                </option>
                                <option value="home">Home</option>
                                <option value="mobile">Mobile</option>
                                <option value="office">Office</option>
                              </select>
                            </div>
                          </div>
                        )}
                        {element.type === "pronounsDropdown" && (
                          <div className={classes.selectWrapper}>
                            <h6>{element.label}</h6>
                            <div className="input-group globalInputWithIcon">
                              <select
                                style={{ border: "0" }}
                                className="form-select customSelect"
                              >
                                <option value="" disabled>
                                  Select
                                </option>
                                <option value="she/her">She/Her</option>
                                <option value="he/him">He/Him</option>
                                <option value="they/them">They/Them</option>
                              </select>
                            </div>
                          </div>
                        )}
                        {element.type === "dropdown" && (
                          <div className={classes.selectWrapper}>
                            <h6>{element.label}</h6>
                            <div className="input-group globalInputWithIcon">
                              <select
                                style={{ border: "0" }}
                                className="form-select customSelect"
                              >
                                {element.options.map((option, optionIndex) => (
                                  <option key={optionIndex} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                        {element.type === "countryList" && (
                          <div className={classes.formInput}>
                            <h6 className="mb-3">{element.label}</h6>
                            <div className="input-group globalInputWithIcon">
                              <select
                                style={{ border: "0" }}
                                className="form-select customSelect"
                              >
                                {generateCountryOptions().map(
                                  (country, index) => (
                                    <option key={index} value={country.value}>
                                      {country.label}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                        )}

                        {element.type === "date" && (
                          <div className={classes.formInput}>
                            <div>
                              <h6 className="mb-3">{element.label}</h6>
                              <DatePicker
                                // selected={formData[field.label]}
                                // onChange={(date) =>
                                //   handleDateInputChange(field.label, date)
                                // }
                                name={element?.label}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="MM/dd/yyyy"
                                showYearDropdown
                                className="dobInput profile"
                                isClearable
                                //maxDate={new Date()}
                                yearDropdownItemNumber={110}
                                scrollableYearDropdown
                              />
                            </div>
                          </div>
                        )}
                        {element.type === "upload" && (
                          <div className={classes.formInput}>
                            <h6 className="mb-3">{element.label}</h6>
                            <input type="file" />
                          </div>
                        )}
                        {element.type === "link" && (
                          <div className={classes.formInput}>
                            <h6 className="mb-3">{element.label}</h6>
                            <input type="url" />
                          </div>
                        )}
                        <div
                          className={`${classes.customizedButton} customizedButton`}
                        >
                          {!element.isPreDefined && (
                            <select
                              className="form-select fieldTypeSelect"
                              value={selectedFieldTypes[index]}
                              onChange={(e) =>
                                handleFieldTypeChange(e.target.value, index)
                              }
                            >
                              <option disabled value="" selected>
                                <span style={{ color: "#ff0000" }}>*</span>
                                Select Field Type
                              </option>
                              <option value="first_name">First Name</option>
                              <option value="last_name">Last Name</option>
                              <option value="email">Email</option>
                              <option value="card_type">Card Type</option>
                              <option value="company">Company</option>
                              <option value="company_position">
                                Company Position
                              </option>
                              <option value="logo">Logo</option>
                              <option value="background_image">
                                Background Image
                              </option>
                              <option value="profile_image">
                                Profile Image
                              </option>
                              <option value="country">Country</option>
                              <option value="city">City</option>
                              <option value="state">State</option>
                              <option value="address">Address</option>
                              <option value="description">Information</option>
                              <option value="website_url">Website</option>
                              <option value="pronouns">Pronouns</option>
                              <option value="phone_type">Phone Type</option>
                              <option value="country_phone">
                                Country Phone
                              </option>
                              <option value="phone_number">Phone Number</option>
                              <option value="flag_code">Country Flag</option>
                              <option value="facebook">Facebook</option>
                              <option value="instagram">Instagram</option>
                              <option value="linkedIn">LinkedIn</option>
                              <option value="twitter">Twitter</option>
                              <option value="snapChat">SnapChat</option>
                              <option value="tiktok">Tiktok</option>
                              <option value="youtube">Youtube</option>
                              <option value="Google">Google</option>
                              <option value="custom">Custom</option>
                              <option value="other">Other</option>
                            </select>
                          )}
                          {!element.isPreDefined && element.type !== "line" && (
                            <Form.Check
                              type="checkbox"
                              label="Is Required"
                              checked={isRequired[index]}
                              onChange={() => toggleIsRequired(index)}
                            />
                          )}
                          <button
                            type="button"
                            className={`${classes.deleteButton} deleteButton`}
                            onClick={() => removeElement(index)}
                          >
                            <BiSolidTrash />
                          </button>
                          {!element.isPreDefined && (
                            <>
                              <button
                                type="button"
                                onClick={() => copyElement(index)}
                              >
                                <MdOutlineContentCopy />
                              </button>
                              {element.type !== "line" && (
                                <button
                                  type="button"
                                  className={`${classes.deleteButton} ${classes.editButton}`}
                                  onClick={() =>
                                    openEditModal(
                                      index,
                                      element.label,
                                      element.options
                                    )
                                  }
                                >
                                  <BiEdit />
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </form>

                  {showSubmitButton && (
                    <div className="text-center mt-4">
                      <button
                        type="submit"
                        className="submitButton"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
      <Modal show={editingIndex !== null} onHide={() => setEditingIndex(null)}>
        <Modal.Header data-bs-theme="dark" closeButton>
          <Modal.Title>Property Editor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editOptions.length === 0 ? (
            <input
              type="text"
              value={editLabel}
              onChange={(e) => setEditLabel(e.target.value)}
            />
          ) : (
            <div>
              <input
                type="text"
                value={editLabel}
                onChange={(e) => setEditLabel(e.target.value)}
              />
              {editOptions.map((option, index) => (
                <div key={index}>
                  <input
                    type="text"
                    value={option}
                    onChange={(e) => {
                      const newOptions = [...editOptions];
                      newOptions[index] = e.target.value;
                      setEditOptions(newOptions);
                    }}
                  />
                </div>
              ))}
              <button onClick={() => setEditOptions([...editOptions, ""])}>
                Add Option
              </button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditingIndex(null)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveEdit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <style type="text/css">
        {`
        .colorBorder{
          border: 2px solid var(--border_color);
          border-radius: 60px;
          padding: 9px 15px 0;
        }
        .form-check-color{
          padding-left: 0;
          position:relative;
        }
        .form-check-color .form-check-input{
          float: none;
          margin-left: 0;
          position: absolute;
          opacity: 0;
          margin-top: 0;
          width: 20px;
          height: 20px;
        }
        .form-check-color .form-check-input + label{margin-bottom:0}
        .form-check-color .form-check-input + label .colorBox {
          width:20px;
          height:20px;
          border-radius:50%;
          border: 3px solid transparent;
        } 
        .form-check-color .form-check-input:checked + label .colorBox {
          width:20px;
          height:20px;
          border-radius:50%;
          border: 3px solid var(--border_color);
        } 
        .phoneInputWrapper {
          border: 2px solid var(--border_color);
          border-radius: 25px;
        }
        .addCardSelect.globalInputWithIcon {
          border: 0 !important;
          border-radius: 0;
          border-right: 2px solid var(--border_color) !important;
        }
        .addPhoneInput .react-tel-input .form-control {
            padding: 18.5px 5px 18.5px 52px;
            border: 0 !important;
        }
        .fieldTypeSelect, .fieldTypeSelect:focus{
          display: inline-block;
          padding-top: .235rem;
          padding-bottom: .235rem;
          margin-bottom: 0;
          margin-right: 0.5em;
          background-color: var(--accent_color);
          border: 1px solid var(--accent_color);
          border-radius: 5px;
          color: var(--heading_text_color);
          width: auto;
          font-size: 11px;
          font-weight: 400;
          box-shadow:none;
        }
        .formWrapper h5{
          font-size: 14px;
          font-weight: 400;
          color: var(--heading_text_color);
          margin-bottom:10px;
        }
        .formWrapper h6{
          font-size: 11px;
          font-weight: 400;
          color: var(--heading_text_color);
          margin-bottom:10px;
        }
        .formName .globalInputWithIcon{
          border-color:var(--primary_color);
        }
        .formName .globalInputWithIcon .form-control{
          background: var(--primary_color);
        }
        .formInputWrapper .form-check-label, label {
          font-size: 13px;
          font-weight: 400;
          color: var(--primary_text_color);
        }
        .globalTextArea{
          height:100px
        }
        .globalInputUpload input{
          background: var(--secondary_color);
          border-left: 0;
          box-shadow: none;
          font-size: 14px;
          line-height: 100%;
          padding: 20px 10px;
          color: var(--primary_text_color);
          border: 0;
          margin-left: 0px;
          opacity:0    
        }
        .globalInputUpload span{
          position:absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
        }
        .globalInputUpload {
          border: 2px dashed var(--border_color);
          border-radius: 10px;
          position:relative;
        }
        .customizedButton .form-check {
          display: inline-block;
          padding-left: 0.5rem;
          padding-right: 1.7em;
          margin-bottom: 0;
          margin-right: 0.5em;
          background-color: var(--accent_color);
          border: 1px solid var(--accent_color);
          border-radius: 5px;
          color: var(--heading_text_color);
        }
        .customizedButton .form-check .form-check-input {
          float: right;
          margin-right: -1.2em;
          width:0.75em;
          height:0.75em;
          border-radius: 0.15em;
          margin-top:0.42em;
        }
        .customizedButton .form-check .form-check-label {
          font-size: 11px;
        }
        .modal{padding-right:0 !important;}
        .modal.fade .modal-dialog{
          transform: translate(50px, 0);
        }
        .modal.show .modal-dialog {
          transform: none;
        }
        .modal-dialog{
          margin: 0;
          margin-left: auto;
          margin-right: 0;
          height: 100vh;
        }
        .modal-content{
          height: 100%;
          background-color: var(--primary_color);
          border-radius: 0;
          border: 0;
          color: var(--heading_text_color);
        }
        .modal-header, .modal-footer{
          border-color:var(--border_color);
        }
        .modal-title{
          font-size:16px;
          font-weight: 600;
        }
        .modal-header .btn-close{
          background-size: 0.65em;
          opacity:1
        }
        @media only screen and (min-width: 576px){
          .modal-dialog{
            max-width: 26%;
          }
        }
      `}
      </style>
    </>
  );
}

export default FormBuilder;
