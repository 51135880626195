import React, { useState, useEffect, useRef } from "react";
import classes from "../assets/styles/Pages/Function/ViewPublicProfile.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import headingicon from "../assets/images/function_page_icons/headingicon.svg";
import contacticon from "../assets/images/function_page_icons/contacticon.svg";
import playstore from "../assets/images/footer_icons/playstore.svg";
import appstore from "../assets/images/footer_icons/appstore.svg";
import Loader from "../components/Loader";
import Image from "react-bootstrap/Image";
import {
  SHARE_EMERGENCY_CONTACT,
  EMERGENCY_CONTACT_VCF_FILE_URL,
} from "../utils/constants/apiUrl";
import { SHARE_QR } from "../utils/constants/common";
import { get, post } from "../server";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import showToast from "../helpers/showToast";
import baseURL from "../utils/constants/baseUrl";
import FormInput from "../components/Forms/FormInput";
import { Link, useParams } from "react-router-dom";
import dummyImage from "../assets/images/global_icons/dummyimage.png";
import personicon from "../assets/images/global_icons/personicon.png";
import _ from "lodash";
import formatPhoneNumber from "../helpers/formatPhoneNumber";
import { FiMail } from "react-icons/fi";
import { MdLocationOn } from "react-icons/md";
import { BiLinkExternal } from "react-icons/bi";
import {
  BsGlobe,
  BsFillTelephoneFill,
  BsFillEnvelopeFill,
  BsFillCalendarFill,
  BsFillPeopleFill,
  BsFillSuitcase2Fill,
} from "react-icons/bs";
import locationIcon from "../assets/images/function_page_icons/location-icon.svg";
import mailIcon from "../assets/images/function_page_icons/mail-icon.svg";
import websiteIcon from "../assets/images/function_page_icons/website-icon.svg";
import phoneIcon from "../assets/images/function_page_icons/phone-icon.svg";
import { ReactComponent as HomePhoneIcon } from "../assets/images/function_page_icons/HomePhone.svg";
import { ReactComponent as OfficePhoneIcon } from "../assets/images/function_page_icons/OfficePhone.svg";
import { AddMoreCustomUrls } from "../utils/data/CardFunctionsData";

const PublicProfile = ({ profile }) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [VCFData, setVCFData] = useState(profile);
  console.log("VCFData", VCFData);
  const { uuid } = useParams();

  const [showFullText, setShowFullText] = useState(false);
  const [address, setAddress] = useState("");
  const target = useRef(null);
  const [cardSocialMedia, setCardSocialMedia] = useState([]);

  const { cards, customURLs, payments, fileUploads, emergencyContacts } =
    profile;

  // const isSingleItemPresent =
  //   [cards, customURLs, payments, fileUploads, emergencyContacts].filter(
  //     (array) => array.length === 1
  //   ).length === 1;
  const isSingleItemPresent =
    [cards, customURLs, payments, fileUploads, emergencyContacts].filter(
      (arr) => arr.length === 1
    ).length === 1 &&
    [cards, customURLs, payments, fileUploads, emergencyContacts].filter(
      (arr) => arr.length === 0
    ).length === 4;

  const isSingleCardPresent = cards && cards.length === 1;
  const isSingleEmergencyContactPresent =
    emergencyContacts && emergencyContacts.length === 1;
  const isSingleCustomURLPresent = customURLs && customURLs.length === 1;
  const isSingleFileUploadPresent = fileUploads && fileUploads.length === 1;
  const isSinglePaymentPresent = payments && payments.length === 1;
  console.log("isSingleItemPresent", isSingleItemPresent);
  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const handleVcfFile = async () => {
    let { status, message, data } = await post(
      EMERGENCY_CONTACT_VCF_FILE_URL + `/${VCFData.uuid}`,
      VCFData,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const link = document.createElement("a");
      link.download = "temp.vcf";
      link.href = data.url;
      link.click();
    } else {
      showToast(status, message);
    }
  };

  const skeletonColor = "#E8E8E8";
  const tagBorderColors = {
    "#22A081": "#ABEDDD",
    "#E35252": "#C52020",
    "#E4A56B": "#DB8A3D",
    "#7000B5": "#D899FF",
    "#EEB8C9": "#E184A1",
    "#DFF87C": "#B1DA0B",
    "#34D844": "#21AB2F",
    "#3B58BD": "#9EACE0",
    "#FF5099": "#FF1A79",
  };
  const displayText = showFullText
    ? profile?.cards[0]?.description
    : profile?.cards[0]?.description?.length > 60
    ? profile?.cards[0]?.description?.slice(0, 60) + "..."
    : profile?.cards[0]?.description;

  const emDisplayText = showFullText
    ? profile?.emergencyContacts[0]?.description
    : profile?.emergencyContacts[0]?.description?.length > 60
    ? profile?.emergencyContacts[0]?.description?.slice(0, 60) + "..."
    : profile?.emergencyContacts[0]?.description;

  useEffect(() => {
    if (
      profile?.cards[0]?.city ||
      profile?.cards[0]?.state ||
      profile?.cards[0]?.country
    ) {
      let addressArr = [];
      if (profile?.cards[0]?.address)
        addressArr.push(profile?.cards[0]?.address);
      if (profile?.cards[0]?.city) addressArr.push(profile?.cards[0]?.city);
      if (profile?.cards[0]?.state) addressArr.push(profile?.cards[0]?.state);
      if (profile?.cards[0]?.country)
        addressArr.push(profile?.cards[0]?.country);
      handleAddress(addressArr);
    }
  }, [
    profile?.cards[0]?.address,
    profile?.cards[0]?.city,
    profile?.cards[0]?.state,
    profile?.cards[0]?.country,
  ]);

  const handleAddress = (data) => {
    let commaAddress = data.join(", ");
    setAddress(commaAddress);
  };

  useEffect(() => {
    if (profile?.cards[0]?.cardSocialMedia) {
      const updatedCardSocialMedia = profile.cards[0].cardSocialMedia.map(
        (item) => {
          let foundIcon = AddMoreCustomUrls.find(
            (single) => single.name === item.media_type
          );

          return {
            media_type: item.media_type,
            url: item.url,
            icon: item.media_type === "custom" ? "custom" : foundIcon?.icon,
            custom_label: item.custom_label,
          };
        }
      );
      setCardSocialMedia(updatedCardSocialMedia);
    }
  }, [profile]);

  return (
    <>
      <div className="container">
        <div className="py-5">
          <div className="row justify-content-center">
            <div className="col-md-5 col-12">
              <div className={classes.mainWrapper}>
                {/* <h6 className={classes.title}>Profile</h6> */}
                <div className={classes.cardWrapper}>
                  <div className="">
                    <div className={classes.profileCardWrapper}>
                      <div className={`${classes.contactInfo} contactInfo`}>
                        {!isSingleItemPresent &&
                        (profile.cards !== 0 ||
                          profile.payments !== 0 ||
                          profile.emergencyContacts !== 0 ||
                          profile.customURLs !== 0 ||
                          profile.fileUploads !== 0) ? (
                          <>
                            {profile.cards != 0 ? (
                              <div
                                className={`${classes.contactInfoInner} mt-0`}
                              >
                                <h6 className={classes.functionTitle}>
                                  Digital Card
                                </h6>
                                {profile.cards.map((card, index) => (
                                  <div
                                    key={index}
                                    className={classes.functionInfoInner}
                                  >
                                    <div
                                      className={`${classes.imgWrapper} imgWrapper mb-2`}
                                    >
                                      <Image
                                        className="globalIMageFit"
                                        src={
                                          card.image
                                            ? `${baseURL.PUBLIC_URL}${card.image}`
                                            : dummyImage
                                        }
                                        alt="img"
                                        roundedCircle={true}
                                        onLoad={() => setImageLoaded(true)}
                                      />
                                    </div>
                                    <div
                                      className={`{classes.detailWrapper} mb-2`}
                                    >
                                      {card.first_name || card.last_name ? (
                                        <h6 className="mb-0 mt-1">{`${
                                          card.first_name || ""
                                        } ${card.last_name || ""}`}</h6>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {card.email ? (
                                      <div
                                        className={`${classes.infoContnt} infoContnt`}
                                        style={{
                                          borderColor: "var(--tertiary_color)",
                                        }}
                                      >
                                        <>
                                          <div>
                                            <p
                                              className={`${classes.subHeading} subHeading`}
                                            >
                                              Email
                                            </p>
                                            <a
                                              href={`mailto:${card.email}`}
                                              className={classes.subHeadingData}
                                            >
                                              {card.email}
                                            </a>
                                          </div>
                                        </>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {card.cardPhone[0]?.phone_number ? (
                                      <div
                                        className={`${classes.infoContnt} infoContnt`}
                                        style={{
                                          borderColor: "var(--tertiary_color)",
                                        }}
                                      >
                                        <>
                                          <div>
                                            <p
                                              className={`${classes.subHeading} subHeading`}
                                            >
                                              Phone no.
                                            </p>
                                            <a
                                              href={`tel:${card.cardPhone[0]?.phone_number}`}
                                              className={classes.subHeadingData}
                                            >
                                              {`+${
                                                card.cardPhone[0]?.country_phone
                                              }${formatPhoneNumber(
                                                card.cardPhone[0]?.phone_number
                                              )}`}
                                            </a>
                                          </div>
                                        </>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <button
                                      className={classes.shareBtnIcon}
                                      ref={target}
                                      onClick={() => {
                                        const shareUrl = `${SHARE_QR}card/${card.uuid}`;
                                        window.open(shareUrl, "_blank");
                                      }}
                                    >
                                      <BiLinkExternal />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              ""
                            )}
                            {profile.payments != 0 ? (
                              <div className={classes.contactInfoInner}>
                                <h6 className={classes.functionTitle}>
                                  Payment Service
                                </h6>
                                {profile.payments.map((payment, index) => (
                                  <div
                                    key={index}
                                    className={classes.functionInfoInner}
                                  >
                                    <p
                                      className={`${classes.subHeading} subHeading`}
                                    >
                                      {payment.title}
                                    </p>
                                    <p className={classes.subHeadingData}>
                                      {payment.url_path}
                                    </p>
                                    <button
                                      className={classes.shareBtnIcon}
                                      ref={target}
                                      onClick={() => {
                                        const shareUrl = `${SHARE_QR}customPayment/${payment.uuid}`;
                                        window.open(shareUrl, "_blank");
                                      }}
                                    >
                                      <BiLinkExternal />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              ""
                            )}
                            {profile.emergencyContacts != 0 ? (
                              <div className={classes.contactInfoInner}>
                                <h6 className={classes.functionTitle}>
                                  Emergency Contact
                                </h6>
                                {profile.emergencyContacts.map(
                                  (emergencyContact, index) => (
                                    <div
                                      key={index}
                                      className={classes.functionInfoInner}
                                    >
                                      <p
                                        className={`${classes.subHeading} subHeading`}
                                      >
                                        {`${
                                          emergencyContact?.first_name || ""
                                        } ${emergencyContact?.last_name || ""}`}
                                      </p>
                                      <a
                                        href={`tel:${emergencyContact.phone_number}`}
                                        className={classes.subHeadingData}
                                        style={{
                                          fontSize: "11px",
                                        }}
                                      >
                                        {`+${
                                          emergencyContact?.country_phone
                                        }${formatPhoneNumber(
                                          emergencyContact?.phone_number
                                        )}`}
                                      </a>
                                      <button
                                        className={classes.shareBtnIcon}
                                        ref={target}
                                        onClick={() => {
                                          const shareUrl = `${SHARE_QR}emergencyContact/${emergencyContact.uuid}`;
                                          window.open(shareUrl, "_blank");
                                        }}
                                      >
                                        <BiLinkExternal />
                                      </button>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            {profile.customURLs != 0 ? (
                              <div className={classes.contactInfoInner}>
                                <h6 className={classes.functionTitle}>
                                  Custom URL
                                </h6>
                                {profile.customURLs.map((customURL, index) => (
                                  <div
                                    key={index}
                                    className={classes.functionInfoInner}
                                  >
                                    <p
                                      className={`${classes.subHeading} subHeading`}
                                    >
                                      {customURL.title}
                                    </p>
                                    <p className={classes.subHeadingData}>
                                      {customURL.url_path}
                                    </p>
                                    <button
                                      className={classes.shareBtnIcon}
                                      ref={target}
                                      onClick={() => {
                                        const shareUrl = `${SHARE_QR}customUrl/${customURL.uuid}`;
                                        window.open(shareUrl, "_blank");
                                      }}
                                    >
                                      <BiLinkExternal />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              ""
                            )}
                            {profile.fileUploads != 0 ? (
                              <div className={classes.contactInfoInner}>
                                <h6 className={classes.functionTitle}>
                                  File Upload
                                </h6>
                                {profile.fileUploads.map(
                                  (fileUpload, index) => (
                                    <div
                                      key={index}
                                      className={classes.functionInfoInner}
                                    >
                                      <p
                                        className={`${classes.subHeading} subHeading`}
                                      >
                                        {fileUpload.title}
                                      </p>
                                      <p className={classes.subHeadingData}>
                                        {fileUpload.url_path}
                                      </p>
                                      <button
                                        className={classes.shareBtnIcon}
                                        ref={target}
                                        onClick={() => {
                                          const shareUrl = `${SHARE_QR}fileUpload/${fileUpload.uuid}`;
                                          window.open(shareUrl, "_blank");
                                        }}
                                      >
                                        <BiLinkExternal />
                                      </button>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : isSingleItemPresent ? (
                          <>
                            {isSingleCardPresent &&
                              !isSingleEmergencyContactPresent &&
                              !isSingleCustomURLPresent &&
                              !isSinglePaymentPresent &&
                              !isSingleFileUploadPresent && (
                                <div className={`${classes.cardWrapper} mt-0`}>
                                  <div className={classes.cardImgWrapper}>
                                    <div className={classes.coverImg}>
                                      {profile?.cards[0]?.background_image ? (
                                        <Image
                                          src={
                                            typeof profile?.cards[0]
                                              ?.background_image == "object"
                                              ? `${URL.createObjectURL(
                                                  profile?.cards[0]
                                                    ?.background_image
                                                )}`
                                              : `${baseURL.PUBLIC_URL}${profile?.cards[0]?.background_image}`
                                          }
                                          alt="cover-img"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    <div
                                      className={`${classes.profileImg} ${
                                        profile?.cards[0]?.profile_image_shape
                                          ? profile?.cards[0]
                                              ?.profile_image_shape
                                          : classes.defaultShape
                                      } profileImg`}
                                    >
                                      {profile?.cards[0]?.profile_image ? (
                                        <Image
                                          src={
                                            typeof profile?.cards[0]
                                              ?.profile_image == "object"
                                              ? `${URL.createObjectURL(
                                                  profile?.cards[0]
                                                    ?.profile_image
                                                )}`
                                              : `${baseURL.PUBLIC_URL}${profile?.cards[0]?.profile_image}`
                                          }
                                          alt="profile-img"
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            backgroundColor: skeletonColor,
                                          }}
                                        />
                                      )}
                                    </div>

                                    {profile?.cards[0]?.card_type ===
                                    "business" ? (
                                      <div
                                        className={`${classes.logoWrapper} ${
                                          profile?.cards[0]?.logo_shape
                                            ? profile?.cards[0].logo_shape
                                            : classes.defaultShape
                                        } logoWrapper`}
                                      >
                                        {profile?.cards[0]?.logo ? (
                                          <Image
                                            src={
                                              typeof profile?.cards[0]?.logo ==
                                              "object"
                                                ? `${URL.createObjectURL(
                                                    profile?.cards[0]?.logo
                                                  )}`
                                                : `${baseURL.PUBLIC_URL}${profile?.cards[0]?.logo}`
                                            }
                                            alt="logo-img"
                                          />
                                        ) : (
                                          <div
                                            style={{
                                              backgroundColor: skeletonColor,
                                            }}
                                          />
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className={classes.infoWrapper}>
                                    <div className={classes.leftSide}>
                                      {profile?.cards[0]?.card_type ? (
                                        <div
                                          className={classes.cardType}
                                          style={{
                                            backgroundColor: profile?.cards[0]
                                              ?.color
                                              ? profile?.cards[0]?.color
                                              : "#22A081",
                                            border: `1px solid ${
                                              profile?.cards[0]?.color
                                                ? tagBorderColors[
                                                    `${profile?.cards[0]?.color}`
                                                  ]
                                                : "ABEDDD"
                                            }`,
                                          }}
                                        >
                                          <span>
                                            {profile?.cards[0]?.card_type}
                                          </span>
                                        </div>
                                      ) : null}

                                      {profile?.cards[0]?.first_name ||
                                      profile?.cards[0]?.last_name ? (
                                        <div
                                          className={classes.name}
                                          style={{
                                            color: profile?.cards[0].color
                                              ? profile?.cards[0].color
                                              : "#232c3d",
                                          }}
                                        >
                                          {`${
                                            profile?.cards[0]?.first_name || ""
                                          } ${
                                            profile?.cards[0]?.last_name || ""
                                          }`}
                                        </div>
                                      ) : (
                                        <div
                                          className={classes.textSkeleton}
                                          style={{
                                            width: "130px",
                                            height: "17px",
                                            margin: "auto",
                                            marginBottom: "15px",
                                          }}
                                        />
                                      )}

                                      {profile?.cards[0]?.pronouns && (
                                        <>
                                          {profile?.cards[0]?.pronouns ? (
                                            <div
                                              className={classes.name}
                                              style={{
                                                color: profile?.cards[0].color
                                                  ? profile?.cards[0].color
                                                  : "#232c3d",
                                                fontSize: "12px",
                                                textTransform: [
                                                  "she/her",
                                                  "he/him",
                                                  "they/them",
                                                ].includes(
                                                  profile?.cards[0]?.pronouns
                                                )
                                                  ? "capitalize"
                                                  : null,
                                              }}
                                            >
                                              {`(${
                                                profile?.cards[0]?.pronouns ||
                                                ""
                                              })`}
                                            </div>
                                          ) : (
                                            <div
                                              className={classes.textSkeleton}
                                              style={{
                                                width: "100px",
                                                height: "12px",
                                                margin: "auto",
                                                marginBottom: "15px",
                                                marginTop: "5px",
                                              }}
                                            />
                                          )}
                                        </>
                                      )}

                                      {profile?.cards[0]?.card_type ===
                                      "business" ? (
                                        <>
                                          {profile?.cards[0]
                                            .company_position && (
                                            <>
                                              {profile?.cards[0]
                                                .company_position ? (
                                                <div
                                                  className={classes.profession}
                                                >
                                                  {
                                                    profile?.cards[0]
                                                      .company_position
                                                  }
                                                </div>
                                              ) : (
                                                <div
                                                  className={
                                                    classes.textSkeleton
                                                  }
                                                  style={{
                                                    width: "92px",
                                                    height: "10px",
                                                    margin: "auto",
                                                    marginBottom: "10px",
                                                  }}
                                                />
                                              )}
                                            </>
                                          )}

                                          {profile?.cards[0].company && (
                                            <>
                                              {profile?.cards[0].company ? (
                                                <div
                                                  className={classes.company}
                                                >
                                                  {profile?.cards[0].company}
                                                </div>
                                              ) : (
                                                <div
                                                  className={
                                                    classes.textSkeleton
                                                  }
                                                  style={{
                                                    width: "92px",
                                                    height: "10px",
                                                    margin: "auto",
                                                  }}
                                                />
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className={classes.descriptionWrapper}>
                                    <div className={classes.leftSide}>
                                      {profile?.cards[0]?.description ? (
                                        <span>
                                          {displayText}
                                          {profile?.cards[0]?.description
                                            ?.length > 60 && (
                                            <button onClick={toggleText}>
                                              {showFullText
                                                ? "Show Less"
                                                : "Read More"}
                                            </button>
                                          )}
                                        </span>
                                      ) : (
                                        <>
                                          <div
                                            className={classes.textSkeleton}
                                            style={{
                                              width: "100%",
                                              height: "10px",
                                              marginBottom: "10px",
                                              marginTop: "10px",
                                            }}
                                          />
                                          <div
                                            className={classes.textSkeleton}
                                            style={{
                                              width: "100%",
                                              height: "10px",
                                              marginBottom: "10px",
                                            }}
                                          />
                                          <div
                                            className={classes.textSkeleton}
                                            style={{
                                              width: "100%",
                                              height: "10px",
                                              marginBottom: "10px",
                                            }}
                                          />
                                        </>
                                      )}
                                      <hr />
                                    </div>
                                  </div>
                                  <div
                                    className={`${classes.contactInfoInner} contactInfoInner`}
                                  >
                                    <div className="contactInfoInner">
                                      {profile?.cards[0]?.email ||
                                      profile?.cards[0]?.address ||
                                      profile?.cards[0]?.city ||
                                      profile?.cards[0]?.state ||
                                      profile?.cards[0]?.country ||
                                      profile?.cards[0]?.website_url ||
                                      profile?.cards[0]?.phone_number ||
                                      profile?.cards[0]?.card_phone?.length !==
                                        0 ||
                                      profile?.cards[0]?.contact_card_phone
                                        ?.length !== 0 ? (
                                        <h6 className={classes.functionTitle}>
                                          Contact Info
                                        </h6>
                                      ) : (
                                        <div
                                          className={classes.textSkeleton}
                                          style={{
                                            width: "100px",
                                            height: "13px",
                                            marginBottom: "10px",
                                          }}
                                        />
                                      )}

                                      {profile?.cards[0]?.email && (
                                        <div
                                          className={`${classes.infoContnt} infoContnt align-items-center`}
                                          style={{
                                            borderColor: profile?.cards[0].color
                                              ? profile?.cards[0].color
                                              : "#232c3d",
                                          }}
                                        >
                                          {profile?.cards[0].color ? (
                                            <>
                                              {profile?.cards[0]?.email ? (
                                                <a
                                                  href={`mailto:${profile?.cards[0]?.email}`}
                                                  style={{
                                                    backgroundColor:
                                                      profile?.cards[0].color,
                                                  }}
                                                  className={`${classes.moreInfoIcon} moreInfoIcon`}
                                                >
                                                  {profile?.cards[0]?.email ? (
                                                    <FiMail />
                                                  ) : null}
                                                </a>
                                              ) : (
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      profile?.cards[0].color,
                                                  }}
                                                  className={`${classes.moreInfoIcon} moreInfoIcon`}
                                                >
                                                  {profile?.cards[0]?.email ? (
                                                    <FiMail />
                                                  ) : null}
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <div
                                              className={classes.iconSkeleton}
                                            />
                                          )}

                                          {profile?.cards[0].email ? (
                                            <>
                                              <div>
                                                <p
                                                  className={`${classes.subHeading} subHeading`}
                                                  style={{
                                                    color: profile?.cards[0]
                                                      .color
                                                      ? profile?.cards[0].color
                                                      : "#232c3d",
                                                  }}
                                                >
                                                  Email
                                                </p>
                                                {profile?.cards[0]
                                                  .content_view_type ===
                                                "iconDesign" ? (
                                                  <a
                                                    className={`${classes.subHeadingData} subHeadingData`}
                                                    href={`mailto:${profile?.cards[0].email}`}
                                                  >
                                                    <img
                                                      src={mailIcon}
                                                      alt=""
                                                    />
                                                  </a>
                                                ) : (
                                                  <a
                                                    className={`${classes.subHeadingData} subHeadingData`}
                                                    href={`mailto:${profile?.cards[0].email}`}
                                                  >
                                                    {profile?.cards[0].email}
                                                  </a>
                                                )}
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              className={classes.textSkeleton}
                                              style={{
                                                width: "60%",
                                                height: "10px",
                                              }}
                                            />
                                          )}
                                        </div>
                                      )}

                                      {(profile?.cards[0]?.address ||
                                        profile?.cards[0]?.city ||
                                        profile?.cards[0]?.state ||
                                        profile?.cards[0]?.country) && (
                                        <div
                                          className={`${classes.infoContnt} infoContnt align-items-center`}
                                          style={{
                                            borderColor: profile?.cards[0].color
                                              ? profile?.cards[0].color
                                              : "#232c3d",
                                          }}
                                        >
                                          {profile?.cards[0].color ? (
                                            <>
                                              {profile?.cards[0]?.address ||
                                              profile?.cards[0]?.city ||
                                              profile?.cards[0]?.state ||
                                              profile?.cards[0]?.country ? (
                                                <a
                                                  href={`http://maps.google.com/?q=${address}`}
                                                  style={{
                                                    backgroundColor:
                                                      profile?.cards[0].color,
                                                  }}
                                                  className={`${classes.moreInfoIcon} moreInfoIcon`}
                                                  target="_blank"
                                                >
                                                  {profile?.cards[0]?.address ||
                                                  profile?.cards[0]?.city ||
                                                  profile?.cards[0]?.state ||
                                                  profile?.cards[0]?.country ? (
                                                    <MdLocationOn />
                                                  ) : null}
                                                </a>
                                              ) : (
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      profile?.cards[0].color,
                                                  }}
                                                  className={`${classes.moreInfoIcon} moreInfoIcon`}
                                                >
                                                  {profile?.cards[0]?.address ||
                                                  profile?.cards[0]?.city ||
                                                  profile?.cards[0]?.state ||
                                                  profile?.cards[0]?.country ? (
                                                    <MdLocationOn />
                                                  ) : null}
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <div
                                              className={classes.iconSkeleton}
                                            />
                                          )}

                                          {profile?.cards[0]?.address ||
                                          profile?.cards[0]?.city ||
                                          profile?.cards[0]?.state ||
                                          profile?.cards[0]?.country ? (
                                            <>
                                              <div>
                                                <p
                                                  className={`${classes.subHeading} subHeading`}
                                                  style={{
                                                    color: profile?.cards[0]
                                                      .color
                                                      ? profile?.cards[0].color
                                                      : "#232c3d",
                                                  }}
                                                >
                                                  Location
                                                </p>
                                                {profile?.cards[0]
                                                  .content_view_type ===
                                                "iconDesign" ? (
                                                  <a
                                                    href={`http://maps.google.com/?q=${address}`}
                                                    target="_blank"
                                                    style={{
                                                      lineHeight: "1.3",
                                                      display: "inline-flex",
                                                      whiteSpace: "normal",
                                                    }}
                                                  >
                                                    <img
                                                      src={locationIcon}
                                                      alt=""
                                                    />
                                                  </a>
                                                ) : (
                                                  <a
                                                    className={`${classes.subHeadingData} subHeadingData`}
                                                    href={`http://maps.google.com/?q=${address}`}
                                                    target="_blank"
                                                    style={{
                                                      lineHeight: "1.3",
                                                      display: "inline-flex",
                                                      whiteSpace: "normal",
                                                    }}
                                                  >
                                                    {address}
                                                  </a>
                                                )}
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              className={classes.textSkeleton}
                                              style={{
                                                width: "60%",
                                                height: "10px",
                                              }}
                                            />
                                          )}
                                        </div>
                                      )}

                                      {profile?.cards[0]?.website_url && (
                                        <div
                                          className={`${classes.infoContnt} infoContnt`}
                                          style={{
                                            borderColor: profile?.cards[0].color
                                              ? profile?.cards[0].color
                                              : "#232c3d",
                                          }}
                                        >
                                          {profile?.cards[0].color ? (
                                            <>
                                              {profile?.cards[0]
                                                ?.website_url ? (
                                                <a
                                                  style={{
                                                    backgroundColor:
                                                      profile?.cards[0].color,
                                                  }}
                                                  className={`${classes.moreInfoIcon} moreInfoIcon`}
                                                  href={
                                                    profile?.cards[0]
                                                      ?.website_url
                                                  }
                                                  target="_blank"
                                                >
                                                  {profile?.cards[0]
                                                    ?.website_url ? (
                                                    <BsGlobe />
                                                  ) : null}
                                                </a>
                                              ) : (
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      profile?.cards[0].color,
                                                  }}
                                                  className={`${classes.moreInfoIcon} moreInfoIcon`}
                                                >
                                                  {profile?.cards[0]
                                                    ?.website_url ? (
                                                    <BsGlobe />
                                                  ) : null}
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <div
                                              className={classes.iconSkeleton}
                                            />
                                          )}

                                          {profile?.cards[0]?.website_url ? (
                                            <>
                                              <div>
                                                <p
                                                  className={`${classes.subHeading} subHeading`}
                                                  style={{
                                                    color: profile?.cards[0]
                                                      .color
                                                      ? profile?.cards[0].color
                                                      : "#232c3d",
                                                  }}
                                                >
                                                  Website
                                                </p>
                                                {profile?.cards[0]
                                                  .content_view_type ===
                                                "iconDesign" ? (
                                                  <a
                                                    href={`mailto:${profile?.cards[0].email}`}
                                                  >
                                                    <img
                                                      src={websiteIcon}
                                                      alt=""
                                                    />
                                                  </a>
                                                ) : (
                                                  <a
                                                    className={`${classes.subHeadingData} subHeadingData`}
                                                    href={
                                                      profile?.cards[0]
                                                        ?.website_url
                                                    }
                                                    target="_blank"
                                                  >
                                                    {
                                                      profile?.cards[0]
                                                        ?.website_url
                                                    }
                                                  </a>
                                                )}
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              className={classes.textSkeleton}
                                              style={{
                                                width: "60%",
                                                height: "10px",
                                              }}
                                            />
                                          )}
                                        </div>
                                      )}

                                      {profile?.cards[0]?.cardPhone?.length !==
                                        0 && (
                                        <>
                                          {profile?.cards[0]?.cardPhone?.map(
                                            (singlePhone) => {
                                              return (
                                                <div
                                                  className={`${classes.infoContnt} infoContnt`}
                                                  style={{
                                                    borderColor: profile
                                                      ?.cards[0].color
                                                      ? profile?.cards[0].color
                                                      : "#232c3d",
                                                  }}
                                                >
                                                  {profile?.cards[0]?.color ? (
                                                    <>
                                                      {profile?.cards[0]
                                                        ?.cardPhone?.length !==
                                                        0 &&
                                                      singlePhone?.phone_number ? (
                                                        <a
                                                          style={{
                                                            backgroundColor:
                                                              profile?.cards[0]
                                                                .color,
                                                          }}
                                                          className={`${classes.moreInfoIcon} moreInfoIcon`}
                                                          href={`tel:${`+${
                                                            singlePhone.country_phone
                                                          }${formatPhoneNumber(
                                                            singlePhone.phone_number.slice(
                                                              singlePhone
                                                                .country_phone
                                                                .length,
                                                              singlePhone
                                                                .phone_number
                                                                .length
                                                            )
                                                          )}`}`}
                                                        >
                                                          {singlePhone.phone_number ? (
                                                            <>
                                                              {singlePhone.phone_type ===
                                                              "home" ? (
                                                                <HomePhoneIcon />
                                                              ) : singlePhone.phone_type ===
                                                                "office" ? (
                                                                <OfficePhoneIcon />
                                                              ) : (
                                                                <BsFillTelephoneFill />
                                                              )}
                                                            </>
                                                          ) : null}
                                                        </a>
                                                      ) : (
                                                        <div
                                                          style={{
                                                            backgroundColor:
                                                              profile?.cards[0]
                                                                .color,
                                                          }}
                                                          className={`${classes.moreInfoIcon} moreInfoIcon`}
                                                        >
                                                          {singlePhone.phone_number ? (
                                                            <>
                                                              {singlePhone.phone_type ===
                                                              "home" ? (
                                                                <HomePhoneIcon />
                                                              ) : singlePhone.phone_type ===
                                                                "office" ? (
                                                                <OfficePhoneIcon />
                                                              ) : (
                                                                <BsFillTelephoneFill />
                                                              )}
                                                            </>
                                                          ) : null}
                                                        </div>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <div
                                                      className={
                                                        classes.iconSkeleton
                                                      }
                                                    />
                                                  )}

                                                  {singlePhone.phone_number ? (
                                                    <>
                                                      {console.log(
                                                        "singlePhone.phone_number",
                                                        singlePhone.phone_number
                                                      )}
                                                      <div>
                                                        <p
                                                          className={`${classes.subHeading} subHeading`}
                                                          style={{
                                                            color: profile
                                                              ?.cards[0].color
                                                              ? profile
                                                                  ?.cards[0]
                                                                  .color
                                                              : "#232c3d",
                                                          }}
                                                        >
                                                          Phone
                                                        </p>
                                                        <a
                                                          className={`${classes.subHeadingData} subHeadingData`}
                                                          href={`tel:${`+${
                                                            singlePhone.country_phone
                                                          }${formatPhoneNumber(
                                                            singlePhone.phone_number.slice(
                                                              singlePhone
                                                                .country_phone
                                                                .length,
                                                              singlePhone
                                                                .phone_number
                                                                .length
                                                            )
                                                          )}`}`}
                                                        >
                                                          {`+${
                                                            singlePhone.country_phone
                                                          }${formatPhoneNumber(
                                                            singlePhone.phone_number.slice(
                                                              singlePhone
                                                                .country_phone
                                                                .length,
                                                              singlePhone
                                                                .phone_number
                                                                .length
                                                            )
                                                          )}`}
                                                        </a>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <div
                                                      className={
                                                        classes.textSkeleton
                                                      }
                                                      style={{
                                                        width: "60%",
                                                        height: "10px",
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className={`${classes.contactInfoInner} mb-3`}
                                  >
                                    {cardSocialMedia?.length !== 0 ? (
                                      <h6 className={classes.functionTitle}>
                                        Social Media
                                      </h6>
                                    ) : (
                                      <div
                                        className={classes.textSkeleton}
                                        style={{
                                          width: "100px",
                                          height: "13px",
                                          marginBottom: "10px",
                                        }}
                                      />
                                    )}

                                    <div
                                      className={classes.socialMediaIconWrapper}
                                    >
                                      {cardSocialMedia?.map((single, index) => (
                                        <>
                                          {console.log("single.icon", single)}
                                          <a
                                            key={`socialMediaCard${index}`}
                                            style={{
                                              backgroundColor:
                                                profile?.cards[0].color,
                                            }}
                                            className={classes.socialMediaIcon}
                                            href={single.url}
                                            target="_blank"
                                          >
                                            {single.icon === "custom"
                                              ? single.custom_label.charAt(0)
                                              : single.icon}
                                          </a>
                                        </>
                                      ))}
                                      {profile?.cards[0]?.cardSocialMedia
                                        ?.length === 0 ? (
                                        <>
                                          {profile?.cards[0].color ? (
                                            <>
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    profile?.cards[0].color,
                                                }}
                                                className={classes.iconSkeleton}
                                              />
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    profile?.cards[0].color,
                                                }}
                                                className={classes.iconSkeleton}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <div
                                                className={classes.iconSkeleton}
                                              />
                                              <div
                                                className={classes.iconSkeleton}
                                              />
                                            </>
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              )}
                            {isSingleEmergencyContactPresent &&
                              !isSingleCardPresent &&
                              !isSingleCustomURLPresent &&
                              !isSinglePaymentPresent &&
                              !isSingleFileUploadPresent && (
                                <div className={classes.cardWrapper}>
                                  <div className="">
                                    <div className={classes.profileCardWrapper}>
                                      <div className={classes.imgWrapper}>
                                        <Image
                                          className="globalIMageFit"
                                          src={
                                            profile?.emergencyContacts[0]?.image
                                              ? `${baseURL.PUBLIC_URL}${profile?.emergencyContacts[0]?.image}`
                                              : dummyImage
                                          }
                                          alt="img"
                                          roundedCircle={true}
                                          onLoad={() => setImageLoaded(true)}
                                        />
                                      </div>
                                      <div className={classes.detailWrapper}>
                                        <h6>{`${
                                          profile?.emergencyContacts[0]
                                            ?.first_name || ""
                                        } ${
                                          profile?.emergencyContacts[0]
                                            ?.last_name || ""
                                        }`}</h6>

                                        <p>{`+${
                                          profile?.emergencyContacts[0]
                                            ?.country_phone
                                        }${formatPhoneNumber(
                                          profile?.emergencyContacts[0]
                                            ?.phone_number
                                        )}`}</p>
                                        <p className={classes.description}>
                                          {emDisplayText}

                                          {profile?.emergencyContacts[0]
                                            ?.description?.length > 60 && (
                                            <button onClick={toggleText}>
                                              {showFullText
                                                ? "Show Less"
                                                : "Read More"}
                                            </button>
                                          )}
                                        </p>
                                      </div>
                                      <div
                                        className={` text-center ${classes.savePhonebookBtnWrapper}`}
                                      >
                                        <div>
                                          <a
                                            href={`tel: ${`+${profile?.emergencyContacts[0]?.country_phone}${profile?.emergencyContacts[0]?.phone_number}`}`}
                                          >
                                            <button>Call Now</button>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {isSingleCustomURLPresent &&
                              !isSingleCardPresent &&
                              !isSingleEmergencyContactPresent &&
                              !isSinglePaymentPresent &&
                              !isSingleFileUploadPresent &&
                              (window.location.href = `${SHARE_QR}customUrl/${profile.customURLs[0].uuid}`)}

                            {isSinglePaymentPresent &&
                              !isSingleCardPresent &&
                              !isSingleEmergencyContactPresent &&
                              !isSingleCustomURLPresent &&
                              !isSingleFileUploadPresent &&
                              (profile.payments[0].payment_type === "cash_app"
                                ? (window.location.href = `${SHARE_QR}cashApp/${profile.payments[0].uuid}`)
                                : profile.payments[0].payment_type === "venmo"
                                ? (window.location.href = `${SHARE_QR}venmo/${profile.payments[0].uuid}`)
                                : (window.location.href = `${SHARE_QR}customPayment/${profile.payments[0].uuid}`))}

                            {isSingleFileUploadPresent &&
                              !isSingleCardPresent &&
                              !isSingleEmergencyContactPresent &&
                              !isSingleCustomURLPresent &&
                              !isSinglePaymentPresent &&
                              (window.location.href = `${SHARE_QR}fileUpload/${profile.fileUploads[0].uuid}`)}
                          </>
                        ) : (
                          <div className="text-center">
                            <p>
                              <strong>You have no active function</strong>
                            </p>
                          </div>
                        )}
                      </div>
                      {isSingleItemPresent ? (
                        <div
                          className={` text-center ${classes.savePhonebookBtnWrapper}`}
                        >
                          <button onClick={handleVcfFile}>
                            <span>
                              <img src={contacticon} alt="" />
                            </span>
                            Save Contact
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.cardFooter}>
                  <div className="d-flex align-items-center gap-3 justify-content-center">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.holguinmedia.contactapp"
                      target="_blank"
                    >
                      <img src={playstore} alt="" />
                    </a>
                    <a
                      href="https://apps.apple.com/pk/app/contact-share-digital-info/id1662851237"
                      target="_blank"
                    >
                      <img src={appstore} alt="" />
                    </a>
                  </div>
                  <p>
                    © {new Date().getFullYear()} Contact Co, All rights
                    reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style type="text/css">{`
        .imgWrapper{
          display: inline-block;
          position:relative;
        }
        .globalIMageFit{
          width:60px !important;
          height:60px !important;
        }
        .globalIMageFitSmall{
          width:30px !important;
          height:30px !important;
          position:absolute;
          bottom:-3px;
          right:-10px;
        }
      `}</style>
    </>
  );
};

export default PublicProfile;
