import React from "react";

const UploadFilesIcon = ({ primaryColor, secondaryColor }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="512"
    height="512"
    viewBox="0 0 211.667 211.667"
  >
    <g transform="matrix(1.1599999999999997,0,0,1.1599999999999997,-16.93328002929684,-16.933281860351528)">
      <g fill={primaryColor}>
        <path
          d="M155.196 150.827h-28.673v-7.817h1.637c7.256 0 11.857-7.836 8.312-14.176l-22.32-39.92c-3.629-6.49-13.002-6.489-16.63 0l-22.32 39.92c-3.543 6.336 1.051 14.176 8.311 14.176h1.637v7.82H56.47c-22.577 0-40.946-18.368-40.946-40.945 0-21.928 17.325-39.884 39.006-40.9 3.392-19.528 20.404-33.898 40.342-33.898 21.84 0 39.74 17.187 40.89 38.748 5.933-3.213 12.551-4.893 19.434-4.893 22.577 0 40.946 18.369 40.946 40.946 0 22.57-18.369 40.939-40.946 40.939z"
          fill={primaryColor}
          opacity="1"
          data-original={primaryColor}
          class=""
        ></path>
        <path
          d="M116.995 176.579h-22.32a3.175 3.175 0 0 1-3.175-3.175V136.66h-7.986c-2.42 0-3.95-2.615-2.77-4.728l22.32-39.919c1.21-2.165 4.334-2.163 5.546 0l22.32 39.92c1.183 2.116-.357 4.727-2.77 4.727h-7.986v36.744a3.18 3.18 0 0 1-3.179 3.175z"
          fill={secondaryColor}
          opacity="1"
          data-original={secondaryColor}
          class=""
        ></path>
      </g>
    </g>
  </svg>
);

export default UploadFilesIcon;
