import React, { useState, useEffect } from "react";
import logo from "../assets/images/signup_icons/logo.svg";
import google from "../assets/images/signup_icons/google.png";
import facebook from "../assets/images/signup_icons/face.svg";
import emailicon from "../assets/images/signup_icons/email.svg";
import lockicon from "../assets/images/signup_icons/lock.svg";
import { Link } from "react-router-dom";
import FormInput from "../components/Forms/FormInput";
import classes from "../assets/styles/Login.module.css";
import { LOGIN, SOCIAL_LOGIN } from "../utils/constants/apiUrl";
import { post } from "../server";
import { useAtom } from "jotai";
import { loggedInUser, isOwnerPackage, accountType } from "../utils/store";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import LoginSchema from "../utils/SchemasValidation/LoginSchema";
import ValidateSchema from "../helpers/ValidateSchema";
import showToast from "../helpers/showToast";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { logoutFbApi } from "../utils/functions";
import useFunctionsCount from "../hooks/useFunctionsCount";

const Login = () => {
  let emptyObj = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();
  const [login, setLogin] = useState(emptyObj);

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { userActions } = useFunctionsCount();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLogin({ ...login, [name]: value.trim() });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(login, LoginSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    let { status, message, data } = await post(LOGIN, login);
    if (status) {
      if (Object.keys(data).length > 0) setLoggedUser(data);
      setPersonalAccount(true);
      userActions(data);
      console.log("Role in Login Component:", data);
      const role = data?.role;
      console.log("Role in Login Component:", data?.role);
      navigate("/", { state: { userRole: role } });
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const loginButton = useGoogleLogin({
    onSuccess: (codeResponse) => {
      fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${codeResponse.access_token}`
      )
        .then((res) => {
          return res.json();
        })
        .then(async (users) => {
          return await sendSocialToken(users);
        })
        .catch((error) => {
          showToast(false, error);
          setLoading(false);
          return;
        });
    },
    onError: (err) => {
      setLoading(false);
      showToast(false, err);
    },
  });

  const sendSocialToken = async (user) => {
    setLoading(true);
    let payload = user;
    payload.first_name = user.given_name;
    payload.last_name = user.family_name;
    payload.token = user.sub;
    payload.provider_type = "google";
    let { status, message, data } = await post(SOCIAL_LOGIN, payload);
    if (status) {
      if (Object.keys(data).length > 0) setLoggedUser(data);
      userActions(data);
      setPersonalAccount(true);
      navigate("/");
      // if (data.role[0] === "team_owner") {
      //   let isTeamPackage = data?.package_details.filter(
      //     (obj) => obj.team_id == data?.teams.id
      //   );

      //   let endDate = new Date(isTeamPackage[0]?.end_date);
      //   let todayDate = new Date();
      //   if (isTeamPackage.length <= 0 || todayDate >= endDate) {
      //     navigate("/paymentNotPaid");
      //     return showToast(
      //       false,
      //       "Kindly purchase package for using application"
      //     );
      //   } else {
      //     setIsOwnerPackageActive(true);
      //     navigate("/team");
      //   }
      // } else {
      //   navigate("/");
      // }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const responseFacebook = async (response) => {
    // check error
    if (response?.error?.message) {
      showToast(false, response.error.message);
      return;
    }

    if (!response.id) {
      showToast(false, "Authentication faild");
      logoutFbApi();
      return;
    }

    setLoading(true);
    let payload = response;

    if (response?.name) {
      let dataName = handleFaceBookName(response?.name);
      if (dataName.length > 0) {
        payload.first_name = dataName["0"];
        payload.last_name = dataName?.["1"];
      }
    }

    payload.token = payload.id;
    payload.provider_type = "facebook";
    delete payload.id;

    let { status, message, data } = await post(SOCIAL_LOGIN, payload);
    if (status) {
      if (Object.keys(data).length > 0) setLoggedUser(data);
      setPersonalAccount(true);
      userActions(data);
      navigate("/");
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const handleFaceBookName = (str) => {
    if (str) {
      let lastString = str.trim().split(" ").slice(-1);
      if (lastString.length > 0) {
        let firstName = str.replace(lastString["0"], "");
        let nameArr = [];
        nameArr.push(firstName, lastString["0"]);
        return nameArr;
      }
    }
    return [];
  };

  useEffect(() => {
    if (loggedUser?.token) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <section className={classes.page}>
        {!loading ? (
          <div className="container">
            <header>
              <nav className={classes.navbar}>
                <ul className="d-flex justify-content-between align-items-center">
                  <li>
                    <Link to="#">
                      <img src={logo} alt="logo" className={classes.logo} />
                    </Link>
                  </li>
                  <ul className={classes.list}>
                    <li className="">
                      <Link to="/signup">
                        <button className={classes.signBtn}>Sign Up</button>
                      </Link>
                    </li>
                    <li>
                      <Link to="/login">
                        <button className={classes.signBtn}>Sign in</button>
                      </Link>
                    </li>
                  </ul>
                </ul>
              </nav>
            </header>

            <div className={classes.accountWrapper}>
              <div>
                <h1 className={classes.heading}>Hello, Welcome Back</h1>
                <p className={classes.subHeading}>
                  Please enter your credentials to login.
                </p>
              </div>
              <div>
                <div className={classes.createAccountBox}>
                  <form>
                    <div className="loginInput">
                      <FormInput
                        // label="Email"
                        type="text"
                        name="email"
                        placeholder="Email Address"
                        value={login.email}
                        handleChange={handleChange}
                        error={errors?.email}
                        icon={emailicon}
                        autofocus={true}
                      />
                    </div>

                    <div className={`${classes.inputsGap} loginInput`}>
                      <FormInput
                        // label="Password"
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={login.password}
                        handleChange={handleChange}
                        error={errors?.password}
                        icon={lockicon}
                      />
                    </div>
                    <div className={classes.forgotPasswordWrapper}>
                      <Link to="/forgotpassword">Forgot Password ?</Link>
                    </div>
                    <div className={classes.submitBtnWrapper}>
                      <button
                        className={classes.submitBtn}
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                  <h6 className={classes.moreSignOptions}>OR Sign in with</h6>
                  <ul className={classes.socialIconsWrapper}>
                    <li
                      className={classes.iconAvatar}
                      onClick={() => {
                        loginButton();
                      }}
                    >
                      <button>
                        <img src={google} alt="" />
                      </button>
                    </li>
                    <FacebookLogin
                      // appId="3507512309478815"
                      appId="1263530301266635"
                      //appId="292102873859116" // for testing
                      autoLoad={false}
                      //fields="name,email,picture"
                      fields="name,email,birthday,picture,gender,link"
                      scope="public_profile,email,user_birthday,user_gender,user_link"
                      callback={responseFacebook}
                      render={(renderProps) => (
                        <li
                          className={classes.iconAvatar}
                          onClick={renderProps.onClick}
                        >
                          <button>
                            <img src={facebook} alt="" />
                          </button>
                        </li>
                        // <button onClick={renderProps.onClick}>This is my custom FB button</button>
                      )}
                    />
                    {/* <li className={classes.iconAvatar}>
                      <Link to="#">
                        <img src={insta} alt="" />
                      </Link>
                    </li> */}
                  </ul>

                  <div className={classes.notAccountWrapper}>
                    <p>Don’t have an account?</p>
                    <Link to="/signup">Sign Up</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </section>
      <style type="text/css">
        {`
          html, body{
            background: #1e1e1e;
          }
          .loginInput .globalInputWithIcon, .loginInput .globalInputWithIcon.input-group:focus-within {
            border-color: #333e56;
          }
          .loginInput .globalInputWithIcon .form-control, 
          .loginInput .globalInputWithIcon .input-group-text {
            background: #21293b;
          }
          .loginInput input:-webkit-autofill, .loginInput input:-webkit-autofill:hover, 
          .loginInput input:-webkit-autofill:focus, .loginInput input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #21293b inset !important;
          }
        `}
      </style>
    </>
  );
};

export default Login;
