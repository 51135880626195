import React from "react";

const ManageTeamMembersIcon = ({ primaryColor }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill={primaryColor}
      stroke="none"
    >
      <path
        d="M2260 4961 c-70 -22 -121 -65 -156 -131 -16 -29 -20 -65 -24 -224
l-5 -188 -80 -29 c-44 -16 -115 -46 -158 -66 l-78 -36 -127 125 c-139 138
-173 158 -261 158 -96 0 -134 -26 -353 -247 -186 -188 -197 -201 -213 -257
-34 -116 -7 -175 157 -342 l118 -121 -44 -94 c-25 -52 -53 -121 -65 -154 l-20
-60 -188 -5 c-168 -5 -192 -8 -228 -27 -52 -28 -89 -67 -114 -123 -19 -42 -21
-65 -21 -326 0 -305 4 -331 55 -399 30 -39 115 -85 157 -85 l28 0 0 80 c0 65
-3 80 -15 80 -9 0 -27 11 -40 25 l-25 24 0 269 c0 284 -2 271 43 314 1 1 107
5 235 8 l234 5 32 105 c37 120 78 216 137 320 l41 73 -163 163 c-150 150 -163
166 -163 199 0 33 14 50 189 225 247 248 212 245 430 30 l162 -161 116 61
c124 65 237 110 330 130 l57 13 0 231 c1 242 2 251 47 274 29 16 512 17 546 1
43 -19 47 -46 47 -283 l0 -224 37 -7 c81 -15 225 -70 346 -133 l128 -66 162
162 c148 148 165 162 197 162 32 0 51 -16 225 -190 175 -175 189 -192 189
-225 0 -33 -13 -49 -163 -199 l-163 -163 45 -79 c55 -95 108 -222 141 -335
l24 -84 228 -5 c126 -3 236 -9 245 -13 35 -17 39 -53 39 -318 l0 -260 -25 -24
c-13 -14 -31 -25 -40 -25 -12 0 -15 -15 -15 -80 l0 -80 28 0 c41 1 104 33 145
75 62 64 67 93 67 409 0 261 -2 284 -21 326 -25 56 -62 95 -114 123 -36 19
-60 22 -228 27 l-188 5 -20 60 c-12 33 -40 102 -65 154 l-44 94 118 121 c164
167 191 226 157 342 -16 56 -27 69 -213 257 -219 221 -257 247 -353 247 -88 0
-122 -20 -261 -158 l-128 -126 -87 42 c-49 23 -119 52 -158 65 l-70 24 -5 189
c-5 207 -13 236 -77 295 -67 63 -71 64 -388 66 -159 1 -301 -2 -315 -6z"
      />
      <path
        d="M2415 3919 c-296 -37 -592 -215 -764 -459 -122 -174 -190 -358 -207
-569 l-7 -81 82 0 81 0 0 39 c0 21 7 76 15 122 60 347 306 633 640 747 104 35
176 46 305 46 206 0 367 -49 540 -163 228 -151 393 -432 416 -712 l7 -79 80 0
80 0 -7 81 c-54 644 -622 1108 -1261 1028z"
      />
      <path
        d="M2465 3118 c-136 -26 -277 -136 -336 -263 -120 -256 5 -563 269 -657
226 -81 482 22 588 238 94 189 59 401 -90 549 -120 119 -268 164 -431 133z
m229 -182 c66 -30 122 -87 155 -156 36 -77 37 -192 1 -265 -98 -200 -353 -248
-511 -94 -70 68 -94 124 -94 224 0 66 5 90 26 135 77 163 262 231 423 156z"
      />
      <path
        d="M1105 2798 c-136 -26 -277 -136 -336 -263 -120 -256 5 -563 269 -657
226 -81 482 22 588 238 94 189 59 401 -90 549 -120 119 -268 164 -431 133z
m229 -182 c66 -30 122 -87 155 -156 36 -77 37 -192 1 -265 -98 -200 -353 -248
-511 -94 -70 68 -94 124 -94 224 0 66 5 90 26 135 77 163 262 231 423 156z"
      />
      <path
        d="M3825 2798 c-136 -26 -277 -136 -336 -263 -120 -256 5 -563 269 -657
226 -81 482 22 588 238 94 189 59 401 -90 549 -120 119 -268 164 -431 133z
m229 -182 c66 -30 122 -87 155 -156 36 -77 37 -192 1 -265 -98 -200 -353 -248
-511 -94 -70 68 -94 124 -94 224 0 66 5 90 26 135 77 163 262 231 423 156z"
      />
      <path
        d="M2129 2080 c-170 -26 -327 -116 -439 -251 l-52 -62 -396 2 c-428 2
-510 -5 -622 -51 -236 -96 -399 -293 -446 -536 -20 -105 -19 -494 1 -581 36
-150 146 -292 281 -363 127 -67 138 -68 626 -68 l438 0 0 80 0 80 -360 0 -360
0 0 440 0 440 -80 0 -80 0 0 -436 0 -436 -27 7 c-93 22 -210 119 -251 208 -39
82 -44 135 -40 380 4 219 6 236 30 299 71 187 227 323 416 363 51 11 154 15
431 15 l363 0 -6 -22 c-35 -119 -37 -159 -34 -637 3 -446 4 -479 23 -524 45
-110 145 -203 254 -237 51 -16 92 -20 212 -20 l149 0 0 640 0 640 -80 0 -80 0
0 -560 0 -560 -68 0 c-117 0 -202 59 -238 164 -13 37 -15 119 -12 517 l3 474
26 67 c14 37 41 89 58 117 46 71 149 164 223 200 117 58 169 63 603 59 377 -3
392 -4 455 -26 89 -32 145 -66 212 -130 69 -65 112 -130 147 -220 l26 -67 3
-473 c3 -531 3 -537 -65 -607 -52 -53 -105 -75 -185 -75 l-68 0 0 560 0 560
-80 0 -80 0 0 -641 0 -641 168 4 c210 5 256 20 347 112 65 65 99 125 116 205
7 32 9 218 7 528 -3 417 -6 484 -21 528 -9 28 -17 53 -17 58 0 4 163 7 363 7
275 0 378 -4 429 -15 192 -40 355 -185 420 -373 22 -63 23 -81 23 -328 l0
-260 -38 -76 c-53 -108 -148 -189 -249 -213 l-28 -7 0 436 0 436 -80 0 -80 0
0 -440 0 -440 -360 0 -360 0 0 -80 0 -81 463 3 c460 3 462 3 522 27 83 33 187
103 235 159 56 66 108 170 125 249 21 99 20 478 -1 581 -50 245 -209 434 -444
530 -112 46 -194 53 -622 51 l-396 -2 -51 60 c-91 110 -187 175 -335 226 l-91
32 -410 1 c-225 1 -435 -2 -466 -6z"
      />
      <path d="M2240 250 l0 -80 320 0 320 0 0 80 0 80 -320 0 -320 0 0 -80z" />
    </g>
  </svg>
);

export default ManageTeamMembersIcon;
